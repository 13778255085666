import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.workflow}salesforce/workorder/create`;

interface CreateWorkOrderCommandPayload {
  fileId: FileId;
  workTypeId: string;
}

async function createWorkOrderCommand({
  fileId,
  workTypeId,
}: CreateWorkOrderCommandPayload): Promise<BaseBackResponse> {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        fileId,
        workTypeId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createWorkOrderCommand;
