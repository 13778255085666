import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'view.toast.success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'view.toast.error',
    defaultMessage: 'Error',
  },
  warning: {
    id: 'view.toast.warning',
    defaultMessage: 'Warning',
  },
  information: {
    id: 'view.toast.information',
    defaultMessage: 'Information',
  },
});
