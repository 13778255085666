import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

const MoveUpDown = keyframes`
  0% {
    cy: 40;
  }
  15% {
    cy: 30;
  }
  30% {
    cy: 40;
  }
  100% {
    cy: 40;
  }
`;

const $Cicle = styled.circle`
  animation: ${MoveUpDown} 2s ease infinite;
  r: 3;
  cy: 40;
  fill: 'currentColor';
  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
`;

const TypingIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="30 20 40 40">
    <$Cicle cx="35" />
    <$Cicle cx="45" />
    <$Cicle cx="55" />
  </Icon>
);

TypingIcon.displayName = 'TypingIcon';

export default TypingIcon;
