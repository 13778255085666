import createAnonymousOrder from './createAnonymousOrder';
import createOrderAndShareLink from './createOrderAndShareLink';
import createShareLinkByOrderReference from './createShareLinkByOrderReference';
import getOrderByOrderReferenceAndOrderPrice from './getOrderByOrderReferenceAndOrderPrice';
import setCreateOrderGenericProduct from './setCreateOrderGenericProduct';
import getOwnerSummaryQuery from './getOwnerSummaryQuery';

export const OwnerService = {
  createShareLinkByOrderReference,
  createOrderAndShareLink,
  getOrderByOrderReferenceAndOrderPrice,
  createAnonymousOrder,
  setCreateOrderGenericProduct,
  getOwnerSummaryQuery,
};
