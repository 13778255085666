import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'crash.error.title',
    defaultMessage: 'A bug occured',
  },
  didactic: {
    id: 'crash.error.didactic',
    defaultMessage:
      'Please contact the support and explain what you were doing, it will be a big help!',
  },
  link: {
    id: 'crash.error.link',
    defaultMessage: 'Go to home',
  },
  refresh: {
    id: 'view.dashboard.button.refresh',
    defaultMessage: 'Refresh',
  },

  chunkErrorBoundaryTitle: {
    id: 'chunkErrorBoundary.title',
    defaultMessage: 'Please check you connection',
  },
  chunkErrorBoundaryDidactic: {
    id: 'chunkErrorBoundary.didactic',
    defaultMessage:
      "This application need to be online, it looks like you're not.",
  },
});
