import { defineMessages } from 'react-intl';

export default defineMessages({
  None: {
    id: 'view.files.filter.partnerScenarioType.None',
    defaultMessage: 'None',
  },
  Auto: {
    id: 'view.files.filter.partnerScenarioType.Auto',
    defaultMessage: 'Auto',
  },
  Mail: {
    id: 'view.files.filter.partnerScenarioType.Mail',
    defaultMessage: 'Mail',
  },
  Manual: {
    id: 'view.file.filter.partnerScenarioType.Manual',
    defaultMessage: 'Manual',
  },
  WebPortal: {
    id: 'view.file.filter.partnerScenarioType.WebPortal',
    defaultMessage: 'WebPortal',
  },
  Revers: {
    id: 'view.file.filter.partnerScenarioType.Revers',
    defaultMessage: 'Revers',
  },
});
