import { defineMessages } from 'react-intl';

export default defineMessages({
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
  previousButton: {
    id: 'view.myaccount.component.previousButton',
    defaultMessage: 'Previous',
  },
});
