import React, { FunctionComponent } from 'react';

import { $FormGroupContainer } from './FormGroup.styles';

interface FormGroupProps {
  withoutPaddingBottom?: boolean;
  withoutPaddingTop?: boolean;
  isOpen?: boolean;
  dataTestId?: string;
  id?: string;
  isFluid?: boolean;
}

const FormGroup: FunctionComponent<React.PropsWithChildren<FormGroupProps>> = ({
  withoutPaddingBottom = false,
  withoutPaddingTop = false,
  isOpen = true,
  dataTestId,
  children,
  id,
  isFluid = true,
}) => {
  return (
    <$FormGroupContainer
      id={id}
      $withoutPaddingBottom={withoutPaddingBottom}
      $withoutPaddingTop={withoutPaddingTop}
      $isOpen={isOpen}
      data-testid={dataTestId}
      $isFluid={isFluid}
    >
      {children}
    </$FormGroupContainer>
  );
};

FormGroup.displayName = 'FormGroup';

export default FormGroup;
