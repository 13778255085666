import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import uploadAttachmentWithProgress from '../Attachment/uploadAttachmentWithProgress';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/additionalInformation/${COMMON_QUERY_PARAMS.ID}/file/token/${COMMON_QUERY_PARAMS.TOKEN}`;

interface SetClaimAdditionalInformationWithFilesCommandPayload {
  claimId: string;
  token: string;
  additionalInformationId: string;
  file?: File;
  files?: File[];
  notify?: ({ progress }: { progress: number }) => void;
}

export const setClaimAdditionalInformationWithFilesCommand = async ({
  claimId,
  token,
  additionalInformationId,
  file,
  files,
  notify,
}: SetClaimAdditionalInformationWithFilesCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId)
      .replace(COMMON_QUERY_PARAMS.ID, additionalInformationId)
      .replace(COMMON_QUERY_PARAMS.TOKEN, token);

    return await uploadAttachmentWithProgress({
      file,
      files,
      endpoint: url,
      notify,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
