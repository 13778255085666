import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { LoaderIcon } from '../../protons/icons';

import { $LoaderWrapper } from './Loader.styles';

interface LoaderProps {
  color?: string;
  size?: string;
  dataTestId?: string;
  strokeWidth?: string;
}

const Loader: FunctionComponent<LoaderProps> = ({
  color,
  size = '20px',
  dataTestId = 'loader',
  strokeWidth = '6',
}) => {
  const theme = useTheme();

  return (
    <$LoaderWrapper data-testid={dataTestId} $size={size}>
      <LoaderIcon
        color={color || theme.colors.primary}
        size={size}
        strokeWidth={strokeWidth}
      />
    </$LoaderWrapper>
  );
};

Loader.displayName = 'Loader';

export default Loader;
