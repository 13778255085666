import { defineMessages } from 'react-intl';

export default defineMessages({
  customerInformationTitle: {
    id: 'view.MyAccountLoginPage.customerInformation.title',
    defaultMessage: 'I fill up some information about me',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Back',
  },
  connectButton: {
    id: 'view.MyAccountLoginPage.connectButton',
    defaultMessage: 'Connect',
  },
  requiredField: {
    id: 'components.common.addressManagement.addressForm.requiredField',
    defaultMessage: 'Ce champs est requis',
  },
  badFormatError: {
    id: 'components.common.addressManagement.addressForm.badFormatError',
    defaultMessage:
      'Ce champs ne peut pas contenir de caractères spéciaux (@,€,#,...)',
  },
  phoneFormatError: {
    id: 'components.common.addressManagement.addressForm.phoneFormatError',
    defaultMessage: 'Phone number formatted incorrectly',
  },
  canOnlyContainLetters: {
    id: 'components.common.addressManagement.addressForm.canOnlyContainLetters',
    defaultMessage: 'Ce champs ne peut que contenir des lettres [Aa-Zz]',
  },
  maxLengthError: {
    id: 'components.common.addressManagement.addressForm.maxLengthError',
    defaultMessage: 'Ce champs est trop long :',
  },
  'Reverse.Common.Errors.Data.NotFound': {
    id: 'components.common.addressManagement.storeNotFound',
    defaultMessage: 'Store not found',
  },
  addressDoNotExistError: {
    id: 'components.common.addressManagement.addressDoNotExistError.',
    defaultMessage: 'Address do not exist',
  },
});
