import React, { FunctionComponent, Ref } from 'react';

import { INPUT_TYPES } from '@savgroup-front-common/constants';

import Input from '../Input';
import { InputProps } from '../Input.types';

interface FloatInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void;
}

interface FloatInputPropsRef extends FloatInputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const FloatInput: FunctionComponent<React.PropsWithChildren<FloatInputPropsRef>> = ({
  onChange = () => undefined,
  value = '',
  forwardedRef,
  name,
  ...props
}) => {
  const { isRequired } = props;

  return (
    <Input
      {...props}
      name={name}
      isRequired={isRequired}
      type={INPUT_TYPES.TEXT}
      value={value}
      onChange={(event) => {
        const reg = new RegExp(/^[0-9]*([.,][0-9]*)?$/);
        const newValue = event.target.value;

        if (reg.test(newValue)) {
          return onChange(newValue);
        }

        return value;
      }}
      ref={forwardedRef}
    />
  );
};

FloatInput.displayName = 'FloatInput';

export default React.forwardRef<HTMLInputElement, FloatInputProps>(
  (props, ref) => <FloatInput forwardedRef={ref} {...props} />,
);
