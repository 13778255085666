import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../../helpers';
import { apiCall } from '../../../services';

const ENDPOINT = `${APIConfiguration.owner}video/session`;

interface VideoSessionSummaryDto {
  sessionId: string;
  url: string;
  isConversationInProgress: boolean;
}

interface GetVideoSessionQueryPayload {
  fileId: string;
}

export const getVideoSessionQuery = async ({
  fileId,
}: GetVideoSessionQueryPayload): Promise<
  BackResponse<VideoSessionSummaryDto> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<VideoSessionSummaryDto>>(
      buildUrl(ENDPOINT, { fileId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
