import React from 'react';
import styled from 'styled-components';

export const CustomIcon = styled(({ icon, ...props }) =>
  React.cloneElement(icon, props),
)`
  svg {
    width: 18px;
    height: 18px;
  }
`;
