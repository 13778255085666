import md5 from 'md5';
import React, { useCallback, useRef, useState } from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { AuthConfiguration } from '../../../configuration';
import { useClientInformationContext } from '../../atoms';

import { $LogoContainer, $LogoImg } from './ClientLogo.styles';

const HASHED_CLIENT_ID = 'HASHED_CLIENT_ID';
const SELLER_ID = 'SELLER_ID';

const URL_LOGO_FALLBACK = `${
  import.meta.env.VITE_STATIC_HASH
}/images/logo-blank-rvl.svg`;
const URL_LOGO_FROM_UI_STYLES = `${APIConfiguration.uiStylesCDN}logos/${HASHED_CLIENT_ID}`;
const URL_LOGO_FROM_PUBLIC_DOCUMENT = `${
  APIConfiguration.imageCDN
}sellers/${SELLER_ID}/${DOCUMENT_TYPES.LOGO.toLocaleLowerCase()}`;

const ClientLogo: React.FC = () => {
  const { clientInformation } = useClientInformationContext();
  const imgRef = useRef<HTMLImageElement>(null);
  const sellerId = clientInformation.sellerId || clientInformation.tenantId;
  const list = [
    URL_LOGO_FROM_PUBLIC_DOCUMENT.replace(SELLER_ID, sellerId),
    URL_LOGO_FROM_UI_STYLES.replace(
      HASHED_CLIENT_ID,
      md5(AuthConfiguration.clientId),
    ),
    URL_LOGO_FALLBACK,
  ].filter((x) => x);

  const [listAlternatives, setListAlternatives] = useState(list);
  const [firstAlternative, ...rest] = listAlternatives;

  const onError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.onerror = rest.length > 0 ? onError : null;
      imgRef.current.src = firstAlternative;

      setListAlternatives(rest);
    }
  }, [firstAlternative, rest]);

  return (
    <$LogoContainer componentThemeName={SUPPORTED_COMPONENTS.LOGO}>
      <$LogoImg
        ref={imgRef}
        alt="logo"
        src={firstAlternative}
        onError={onError}
      />
    </$LogoContainer>
  );
};

ClientLogo.displayName = 'ClientLogo';

export default ClientLogo;
