import React from 'react';

import MyAccountLoginPageNewDesignContent from './MyAccountLoginPageNewDesignContent';
import NewDesignLayout from '../../../app/layout/NewDesignLayout';

export const MyAccountLoginPageNewDesign: React.FC = () => {
  return (
    <NewDesignLayout>
      <MyAccountLoginPageNewDesignContent />
    </NewDesignLayout>
  );
};

MyAccountLoginPageNewDesign.displayName = 'CreateClaim';
