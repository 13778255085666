import { Xmark } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const CrossIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Xmark width={size} height={size} color={color} />;

CrossIcon.displayName = 'CrossIcon';
export default CrossIcon;
