import styled from 'styled-components';

import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import {
  DefaultStyledProps,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

interface LogoContainerProps extends DefaultStyledProps {
  componentThemeName?: string;
}

export const $LogoContainer = styled.div<LogoContainerProps>`
  width: 100%;
  height: 64px;
  padding: ${({ componentThemeName, theme }) => {
    const themeValue = getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
    });

    return themeValue || '21px 0';
  }};
  cursor: pointer;
`;

export const $LogoImg = styled.img`
  height: 100%;
  max-height: 100%;
  max-width: 100%;
`;
