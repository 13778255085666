import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $PageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
`;

export const $ImageWrapper = styled.div`
  flex: 1;
  display: none;
  position: relative;
  @media ${media.minWidth.md} {
    display: block;
  }

  img {
    width: 1px;
    height: 1px;
    visibility: hidden;
  }
`;
export const $FixedImage = styled.div<{ $url: string }>`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  background-position: center center;
  background-size: cover;
  background-image: url(${({ $url }) => $url});
  @media ${media.minWidth.md} {
    display: block;
  }
`;
export const $ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 1.5rem;
  @media ${media.minWidth.lg} {
    max-width: ${rem(550)};
  }
  @media ${media.minWidth.sm} {
    padding: 2rem 3rem;
  }
`;

export const $Footer = styled.footer`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  & > *:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

export const $FooterImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${media.maxWidth.xs} {
    width: 100%;
    & > img {
      width: 100%;
    }
  }
`;
