import md5 from 'md5';
import React, { FunctionComponent, useMemo, useRef } from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { AuthConfiguration } from '../../../configuration';

import { $LoginImageContainer, $LoginImg } from './LoginImage.styles';

const LoginImage: FunctionComponent = () => {
  const hashedClientId = useMemo(() => md5(AuthConfiguration.clientId), []);

  const imgRef = useRef<HTMLImageElement>(null);

  const onError = () => {
    if (imgRef && imgRef.current) {
      imgRef.current.onerror = () => undefined;
      imgRef.current.src = `${
        import.meta.env.VITE_STATIC_HASH
      }/images/illu_valid.svg`;
    }
  };

  return (
    <$LoginImageContainer componentThemeName={SUPPORTED_COMPONENTS.LOGO}>
      <$LoginImg
        ref={imgRef}
        alt="login-image"
        src={`${APIConfiguration.uiStylesCDN}login-images/${hashedClientId}`}
        onError={onError}
      />
    </$LoginImageContainer>
  );
};

LoginImage.displayName = 'Logo';

export default LoginImage;
