import React, { PropsWithChildren } from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { CreateClaimValues } from 'authenticator/types';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

import StepFooter from './StepFooter/StepFooter';
import StepHeader from './StepHeader/StepHeader';
import {
  $Banner,
  $ColContainer,
  $LayoutGrid,
  $StepContainer,
} from './StepLayout.styles';

interface StepLayoutProps {
  values: CreateClaimValues;
  noConfirmButton?: boolean;
  isDisabled: boolean;
  isLoading?: boolean;
  stepName: STEP_CONFIG;
}

const StepLayout: React.FC<PropsWithChildren<StepLayoutProps>> = ({
  values,
  noConfirmButton = false,
  isDisabled,
  isLoading = false,
  stepName,
  children,
}) => {
  const { clientInformation } = useGetClientInformation();
  const bannerUrl = clientInformation?.bannerUrl;
  const { isFirstStep, previousStep } = useStepOrchestratorContext();

  const hasBannerUrl = bannerUrl && bannerUrl.length;

  const handlePreviousStep = () => previousStep({ values });

  return (
    <>
      {hasBannerUrl && <$Banner src={bannerUrl} width="100%" alt="banner" />}
      <$LayoutGrid>
        <Row center="xs">
          <$ColContainer xs={12} sm={12}>
            {stepName && (
              <StepHeader stepName={stepName} sellerName={values.sellerName} />
            )}
            <$StepContainer>{children}</$StepContainer>
            <StepFooter
              isFirstStep={isFirstStep}
              isDisabled={isDisabled}
              isLoading={isLoading}
              handlePreviousStep={handlePreviousStep}
              noConfirmButton={noConfirmButton}
            />
          </$ColContainer>
        </Row>
      </$LayoutGrid>
    </>
  );
};

StepLayout.displayName = 'StepLayout';

export default StepLayout;
