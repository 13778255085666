import { Check } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const CheckIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Check width={size} height={size} color={color} />;

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
