import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const $RouterLink = styled(RouterLink)<{
  $isWrap: boolean;
  $isInline?: boolean;
  $isNaked?: boolean;
}>`
  display: ${({ $isInline }) => ($isInline ? 'inline' : 'flex')};
  align-items: center;
  flex-direction: row;

  color: ${({ theme }) => theme.colors.primary};

  white-space: ${({ $isWrap }) => ($isWrap ? 'nowrap' : undefined)};

  ${({ $isNaked }) =>
    $isNaked
      ? css`
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
        `
      : undefined}

  &:hover {
    text-decoration: underline;
  }
`;

export const $Link = styled.a<{ $isWrap: boolean; $isInline?: boolean }>`
  white-space: ${({ $isWrap }) => ($isWrap ? 'nowrap' : undefined)};

  color: ${({ theme }) => theme.colors.linkColor};
  &:hover {
    text-decoration: underline;
  }
`;
