import { css, keyframes, SimpleInterpolation } from 'styled-components';

import {
  ANIMATION_STATE,
  AnimationDirection,
} from '@savgroup-front-common/constants/src/shared/animation';

import { StateAnimationProps } from './stateAnimation/types';
import { decelerateTimingFunctionAnimation } from './timingFunction';

const convertToSecond = (value: number): string => `${value}ms`;

export const getAnimationDuration = ({
  animationDuration = 150,
}: { animationDuration?: number } = {}) => convertToSecond(animationDuration);

export const getAnimationDelay = ({
  animationDelay = 0,
}: { animationDelay?: number } = {}) => convertToSecond(animationDelay);

export const getAnimationDirectionClassNames = (
  animationDirection: AnimationDirection,
): string => {
  return `${animationDirection} `;
};

interface ConstructStateAnimationArgs {
  enter: SimpleInterpolation;
  steady: SimpleInterpolation;
  exit: SimpleInterpolation;
}
interface ConstructStateAnimationOptions {
  hideOnExited?: boolean;
}
export const buildStateAnimation = (
  { enter, steady, exit }: ConstructStateAnimationArgs,
  { hideOnExited = true }: ConstructStateAnimationOptions = {},
) => {
  const hide = css`
    display: none;
  `;

  const enteringKeyframes = keyframes`
    from { ${enter} }
    to { ${steady} }
  `;

  const exitingKeyframes = keyframes`
    from { ${steady} }
    to { ${exit} }
  `;

  const enterAnimation = css<StateAnimationProps>`
    ${enter};
    animation-name: ${enteringKeyframes};
    animation-duration: ${getAnimationDuration};
    animation-delay: ${getAnimationDelay};
    animation-fill-mode: forwards;
    ${decelerateTimingFunctionAnimation};
  `;

  const exitAnimation = css<StateAnimationProps>`
    ${steady};
    animation-name: ${exitingKeyframes};
    animation-duration: ${getAnimationDuration};
    animation-fill-mode: forwards;
    ${decelerateTimingFunctionAnimation};
  `;

  return css<StateAnimationProps>`
    ${({ animationState }) => {
      switch (animationState) {
        case ANIMATION_STATE.ENTERING: {
          return enterAnimation;
        }
        case ANIMATION_STATE.ENTERED: {
          return null;
        }
        case ANIMATION_STATE.EXITING: {
          return exitAnimation;
        }
        case ANIMATION_STATE.EXITED: {
          return `${hide} ${hideOnExited ? exit : null}`;
        }
        default: {
          return null;
        }
      }
    }}
  `;
};
