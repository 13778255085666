import React, { useCallback, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import OnboardingActivities from 'authenticator/view/pages/Onboarding/OnboardingActivities/OnboardingActivities';
import OnboardingConfirmation from 'authenticator/view/pages/Onboarding/OnboardingConfirmation/OnboardingConfirmation';
import OnboardingEntry from 'authenticator/view/pages/Onboarding/OnboardingEntry/OnboardingEntry';
import OnboardingSetUp from 'authenticator/view/pages/Onboarding/OnboardingSetUp/OnboardingSetUp';

import { useInitializeDatadog } from '../../../../datadog.config';

export const OnboardingRoutes: React.FC = () => {
  const location = useLocation();
  const [userEmail, setUserEmail] = useState('');

  const handleUserEmail = useCallback((userEmail: string) => {
    setUserEmail(userEmail);
  }, []);

  useInitializeDatadog({ ratio: 100 });

  return (
    <Switch location={location}>
      <Route exact path="/onboarding">
        <OnboardingEntry handleUserEmail={handleUserEmail} />
      </Route>
      <Route exact path="/onboarding/:emailId">
        <OnboardingActivities />
      </Route>
      <Route exact path="/onboarding/:emailId/confirmation">
        <OnboardingConfirmation userEmail={userEmail} />
      </Route>
      <Route exact path="/confirm/:requestId/:requestToken">
        <OnboardingSetUp />
      </Route>

      <Redirect exact to="/onboarding" />
    </Switch>
  );
};

OnboardingRoutes.displayName = 'OnboardingRoutes';
