import validator from 'validator';
import * as Yup from 'yup';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  globalMessages,
  isAddress,
  isEmail,
  isValidPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';
import { AutocompleteOption } from '@savgroup-front-common/types';

import { CUSTOMER_INFORMATION_FIELDS } from './CustomerInformation.types';
import messages from './messages';

const CustomerInformationSchema = Yup.object().shape({
  [CUSTOMER_INFORMATION_FIELDS.FIRST_NAME]: Yup.string().required(
    messages.requiredField,
  ),
  [CUSTOMER_INFORMATION_FIELDS.LAST_NAME]: Yup.string().required(
    messages.requiredField,
  ),
  [CUSTOMER_INFORMATION_FIELDS.EMAIL]: Yup.string()
    .required(messages.requiredField)
    .test('is-email', globalMessages.form.invalidEmail, (value) => {
      return isEmail(value);
    }),
  [CUSTOMER_INFORMATION_FIELDS.PHONE]: Yup.string()
    .required(messages.requiredField)
    .when(
      CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE,
      (countryCode: AutocompleteOption, schema: Yup.StringSchema) => {
        const countryCodeValue = countryCode?.value || '';

        return schema.test(
          'test-country-phone',
          globalMessages.form.invalidPhoneNumber,
          (phone): boolean => {
            return isValidPhoneNumber(phone, countryCodeValue);
          },
        );
      },
    ),
  [CUSTOMER_INFORMATION_FIELDS.ADDRESS]: Yup.string()
    .required(messages.requiredField)
    .test('is-address', globalMessages.form.invalidAddress, (value) => {
      return isAddress(value);
    }),
  [CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE]: Yup.string()
    .required(globalMessages.form.required)
    .when(
      CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE,
      (countryCode: AutocompleteOption, schema: Yup.StringSchema) => {
        const countryCodeValue = countryCode?.value || '';

        return schema.test(
          'test-postal-code',
          globalMessages.form.invalidPostalCode,
          (postalCode): boolean => {
            try {
              return (
                !countryCodeValue ||
                !postalCode ||
                validator.isPostalCode(postalCode, countryCodeValue as any)
              );
            } catch (error) {
              logError(error);

              return true;
            }
          },
        );
      },
    ),
  [CUSTOMER_INFORMATION_FIELDS.CITY]: Yup.string().required(
    messages.requiredField,
  ),
  [CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE]: Yup.object().required(
    messages.requiredField,
  ),
});

export default CustomerInformationSchema;
