import { config, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useCallback, useEffect } from 'react';
import { useWindowSize } from 'react-use';

import { usePrevious } from '../../hooks';

import { DIRECTION, DRAWER_SIZE } from './SwipeableDrawer';

interface UseSwipeableDrawerArgs {
  isOpen: boolean;
  onClose: () => void;
  hasBackdropScroll?: boolean;
  direction: DIRECTION;
  size?: DRAWER_SIZE;
}

export const useSwipeableDrawer = ({
  isOpen,
  onClose,
  hasBackdropScroll = true,
  direction = DIRECTION.TOP,
  size = DRAWER_SIZE.SMALL,
}: UseSwipeableDrawerArgs) => {
  const previousIsOpen = usePrevious(isOpen);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const height = windowHeight + 100;
  const width =
    size === DRAWER_SIZE.FULL_SCREEN
      ? windowWidth
      : Math.min(size, windowWidth);

  const [{ x, y }, api] = useSpring(() =>
    direction === DIRECTION.TOP ? { y: height, x: 0 } : { x: width, y: 0 },
  );

  const open = useCallback(
    ({ canceled }: { canceled: boolean } = { canceled: false }) => {
      const isUserPassedTheUpwardsThreshold = canceled;

      if (direction === DIRECTION.TOP) {
        api.start({
          y: 0,
          immediate: false,
          config: isUserPassedTheUpwardsThreshold
            ? config.wobbly
            : config.stiff,
        });
      } else {
        api.start({
          x: 0,
          immediate: false,
          config: isUserPassedTheUpwardsThreshold
            ? config.wobbly
            : config.stiff,
        });
      }
    },
    [api, direction],
  );

  const close = useCallback(
    (velocity = 0) => {
      if (direction === DIRECTION.TOP) {
        api.start({
          y: height,
          immediate: false,
          config: { ...config.stiff, velocity },
          onResolve: () => {
            onClose();
          },
        });
      } else if (direction === DIRECTION.LEFT) {
        api.start({
          x: width,
          immediate: false,
          config: { ...config.stiff, velocity },
          onResolve: () => {
            onClose();
          },
        });
      }
    },
    [api, direction, height, onClose, width],
  );

  useEffect(() => {
    if (isOpen) {
      open();

      if (!hasBackdropScroll) {
        document.body.style.overflow = 'hidden';
      }

      return;
    }

    if (!isOpen && previousIsOpen === true) {
      if (!hasBackdropScroll) {
        document.body.style.overflow = 'visible';
      }
      close();
    }
  }, [close, hasBackdropScroll, isOpen, open, previousIsOpen]);

  const bind = useDrag(
    ({ last, velocity: [vx, vy], movement: [, my], cancel, canceled }) => {
      const shouldCancelDrag = my < -70;

      if (shouldCancelDrag) {
        cancel();
      }

      const isUserHasStoppedDragging = last;

      if (isUserHasStoppedDragging) {
        const shouldClose = my > windowHeight * 0.25;

        if (shouldClose) {
          close(direction === DIRECTION.TOP ? vy : vx);
        } else {
          open({ canceled });
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      drag: { x: true, y: false },
      filterTaps: true,
      bounds: { top: 0, right: 0 },
      rubberband: true,
    },
  );

  const display =
    direction === DIRECTION.TOP
      ? y.to((py) => (py < height ? 'block' : 'none'))
      : x.to((px) => (px < width ? 'block' : 'none'));

  return {
    bind,
    display,
    width,
    height,
    y,
    x,
  };
};
