import { defineMessages } from 'react-intl';

export default defineMessages({
  termsAndConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.termsAndConditions',
    defaultMessage:
      'I have read and accept the terms and Conditions of the use,terms and sale conditions.',
  },
  goToConditions: {
    id: 'components.myaccount.claims.confirmation.confirmationButton.goToConditions',
    defaultMessage: 'Read the Terms of services',
  },
});
