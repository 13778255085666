import qs from 'qs';

interface Parameters {
  [x: string]:
    | string
    | boolean
    | number
    | undefined
    | string[]
    | null
    | Parameters;
}

export function buildUrl(
  url: string,
  parameters?: Parameters,
  options: qs.IStringifyOptions = {},
): string {
  const params = qs.stringify(parameters, options);

  if (params.length === 0) {
    return url;
  }

  return `${url}?${params}`;
}
