import messages from './messages';

export enum SET_UP_PROGRESS {
  INFRASTRUCTURE_BUILDING = 'INFRASTRUCTURE_BUILDING',
  ENTITIES_AND_RULES = 'ENTITIES_AND_RULES',
  ORDERS_GENERATED = 'ORDERS_GENERATED',
}

const getMessagesList = (setUpProgress?: SET_UP_PROGRESS) => {
  switch (setUpProgress) {
    case SET_UP_PROGRESS.ORDERS_GENERATED:
      return [messages.waitingMessage5];
    case SET_UP_PROGRESS.ENTITIES_AND_RULES:
      return [
        messages.waitingMessage3,
        messages.waitingMessage4,
        messages.waitingMessage6,
      ];
    case SET_UP_PROGRESS.INFRASTRUCTURE_BUILDING:
    default:
      return [messages.waitingMessage1, messages.waitingMessage2];
  }
};

export default getMessagesList;
