import React, { FunctionComponent } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import { InputStatus } from '../helpers/getFinalFieldState.types';

import { $IcoContainer } from './Depiction.styles';
import DepictionSvg from './DepictionSvg';

interface DepictionIcoProps {
  status?: InputStatus;
  animationState: TransitionStatus;
  name: string | undefined;
}

const animationDuration = 250;

const DepictionIco: FunctionComponent<React.PropsWithChildren<DepictionIcoProps>> = ({
  status,
  animationState,
  name,
}) => {
  if (status) {
    return (
      <$IcoContainer
        data-testid={
          name && status
            ? `depiction-${status.toLocaleLowerCase()}-${name}`
            : undefined
        }
        animationState={animationState}
        animationDuration={animationDuration}
      >
        <DepictionSvg status={status} />
      </$IcoContainer>
    );
  }

  return null;
};

DepictionIco.displayName = 'DepictionIco';

export default DepictionIco;
