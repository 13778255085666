export const formatAmountToString = (
  amount: string | number,
  fractionDigits = 2,
): string => {
  const amountToBeFormatted =
    typeof amount === 'string'
      ? Number(amount.replace(',', '.'))
      : Number(amount);

  if (!Number.isFinite(amountToBeFormatted)) {
    return '-';
  }

  const rounder = 10 ** fractionDigits;

  const formattedAmount = parseFloat(
    String(Math.round(amountToBeFormatted * rounder) / rounder),
  );

  return formattedAmount.toFixed(fractionDigits);
};

export const formatAmountToNumber = (
  amount: string | number,
  fractionDigits = 2,
): number => {
  const amountToBeFormatted =
    typeof amount === 'string'
      ? Number(amount.replace(',', '.'))
      : Number(amount);

  if (!Number.isFinite(amountToBeFormatted)) {
    throw new Error('formatAmountToNumber: number is not finite');
  }

  const rounder = 10 ** fractionDigits;

  const formattedAmount = parseFloat(
    String(Math.round(amountToBeFormatted * rounder) / rounder),
  );

  return formattedAmount;
};
