import { OwnerSummary } from '@savgroup-front-common/types';
import { useQuery } from 'react-query';
import { OwnerService } from 'authenticator/api';

interface UseCookieReturnValues {
  ownerSummary?: OwnerSummary;
  ownerSummaryIsLoading: boolean;
}

const GetOwnerSummary = 'getOwnerSummary';

const useGetOwnerSummary = (): UseCookieReturnValues => {
  const { data: ownerSummary, isLoading: ownerSummaryIsLoading } = useQuery(
    [GetOwnerSummary],
    async () => {
      const response = await OwnerService.getOwnerSummaryQuery();

      if (!response) {
        return undefined;
      }

      return response.json();
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );

  return {
    ownerSummary,
    ownerSummaryIsLoading,
  };
};

export default useGetOwnerSummary;
