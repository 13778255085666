import React, { FunctionComponent } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { InputStatus } from '../helpers/getFinalFieldState.types';

import { $FieldMessage } from './FieldMessage.styles';

interface FieldMessageProps {
  status?: InputStatus;
  message?: string | MessageType;
  dataTestId?: string;
}

const FieldMessage: FunctionComponent<
  React.PropsWithChildren<FieldMessageProps>
> = ({ status, message, dataTestId }) => {
  if (message) {
    return (
      <$FieldMessage
        $status={status}
        data-testid={
          dataTestId && status
            ? `${dataTestId}-fieldMessage-${status.toLocaleLowerCase()}`
            : undefined
        }
      >
        {SafeFormattedMessage(message)}
      </$FieldMessage>
    );
  }

  return null;
};

FieldMessage.displayName = 'FieldMessage';

export default FieldMessage;
