import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const PageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`;

interface $FooterProps {
  $isLoginFooterImageFound: boolean | null;
}

export const $Footer = styled.footer<$FooterProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme, $isLoginFooterImageFound = null }) =>
    $isLoginFooterImageFound ? theme.colors.lightGrey : theme.colors.white};
  width: 100%;
  padding-top: 1rem;
`;

export const $FooterImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${media.maxWidth.xs} {
    width: 100%;
    & > img {
      width: 100%;
    }
  }
`;
