import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import messages from '../../messages';

import useChoiceProductGenericModel from './ChoiceProductGenericModel.hooks';

interface ChoiceProductGenericModelProps {
  values: CreateClaimValues;
}

const ChoiceProductGenericModel: React.FC<ChoiceProductGenericModelProps> = ({
  values,
}) => {
  const {
    formContext,
    modelTypesGeneric,
    modelTypesBrand,
    onSubmit,
    isLoadingModelTypeGeneric,
    isLoadingBrandGeneric,
  } = useChoiceProductGenericModel({
    values,
  });

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = formContext;

  const modelTypesGenericOptions = modelTypesGeneric?.map(
    (modelTypeGeneric) => {
      return {
        label: modelTypeGeneric.name,
        value: modelTypeGeneric.id,
      };
    },
  );

  const modelTypesBrandOptions = modelTypesBrand?.map((modelTypeBrand) => {
    return {
      label: modelTypeBrand.name,
      value: modelTypeBrand.id,
    };
  });

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CHOICE_MODEL_GENERIC}
          isDisabled={!isValid}
        >
          <FormGroup>
            <Controller
              control={control}
              name="productGeneric"
              render={({ field }) => {
                return (
                  <Grid fluid>
                    <Row>
                      <Col xs={12}>
                        <Autocomplete
                          ref={field.ref}
                          name={field.name}
                          isRequired
                          errors={errors}
                          options={modelTypesGenericOptions}
                          onChange={field.onChange}
                          value={field.value}
                          isLoading={isLoadingModelTypeGeneric}
                          shouldAutoSelectFirstOptions={
                            modelTypesGenericOptions?.length === 1
                          }
                          placeholder={
                            messages.choiceProductGenericModelFindMyProductCategory
                          }
                          tabIndex={0}
                        />
                      </Col>
                    </Row>
                  </Grid>
                );
              }}
            />
          </FormGroup>
          {modelTypesBrand && (
            <FormGroup>
              <Controller
                control={control}
                name="productBrand"
                render={({ field }) => {
                  return (
                    <Grid fluid>
                      <Row>
                        <Col xs={12}>
                          <Autocomplete
                            ref={field.ref}
                            name={field.name}
                            isRequired
                            errors={errors}
                            options={modelTypesBrandOptions}
                            onChange={field.onChange}
                            value={field.value}
                            isLoading={isLoadingBrandGeneric}
                            shouldAutoSelectFirstOptions={
                              modelTypesBrandOptions?.length === 1
                            }
                            placeholder={
                              messages.choiceProductGenericModelFindMyProductBrand
                            }
                            tabIndex={0}
                          />
                        </Col>
                      </Row>
                    </Grid>
                  );
                }}
              />
            </FormGroup>
          )}
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ChoiceProductGenericModel.displayName = 'ChoiceProductGenericModel';

export default ChoiceProductGenericModel;
