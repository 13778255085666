import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import {
  OrderInformationsContext,
  OrderInformationsValues,
} from '../OrderInformations.types';

const useOrderInformationsStepContext = () => {
  return useStepOrchestratorContext<
    OrderInformationsValues,
    OrderInformationsContext
  >();
};

export default useOrderInformationsStepContext;
