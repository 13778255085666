import { NetworkLink } from 'authenticator/types';
import React from 'react';

import { NetworkLinkDisplay } from '../../atoms/NetworkLinkDisplay';

import { $NetworkLinks } from './NetworkLinks.styles';

interface NetworkLinksProps {
  links: NetworkLink[];
}

const NetworkLinks: React.FC<NetworkLinksProps> = ({ links }) => {
  if (!links.length) {
    return <></>;
  }

  return (
    <$NetworkLinks>
      {links.map((link) => {
        return <NetworkLinkDisplay key={link.href} networkLink={link} />;
      })}
    </$NetworkLinks>
  );
};

NetworkLinks.displayName = 'NetworkLinks';

export default NetworkLinks;
