import { defineMessages } from 'react-intl';

export default defineMessages({
  choiceInvoice: {
    id: 'view.authenticator.step.choiceInvoice.subtitle',
    defaultMessage: 'choiceInvoice subtitle',
  },
  choicePartnerStore: {
    id: 'view.authenticator.step.choicePartnerStore.subtitle',
    defaultMessage: 'choicePartner subtitle store',
  },
  choicePartnerProduct: {
    id: 'view.authenticator.step.choicePartnerProduct.subtitle',
    defaultMessage: 'choicePartner subtitle product',
  },
  choiceUniversModel: {
    id: 'view.authenticator.step.choiceUniversModel.subtitle',
    defaultMessage: 'choiceUniversModel subtitle',
  },
  choiceModelGeneric: {
    id: 'view.authenticator.step.choiceModelGeneric.subtitle',
    defaultMessage: 'choiceModelGeneric subtitle',
  },
  customerInformationName: {
    id: 'view.authenticator.step.customerInformationName.subtitle',
    defaultMessage: 'customerInformationName subtitle',
  },
  customerInformationAddress: {
    id: 'view.authenticator.step.customerInformationAddress.subtitle',
    defaultMessage: 'customerInformationAddress subtitle',
  },
  customerInvoiceInformation: {
    id: 'view.authenticator.step.customerInvoiceInformation.subtitle',
    defaultMessage: 'customerInvoiceInformation subtitle',
  },
});
