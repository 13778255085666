import { Notification } from '@savgroup-front-common/types';

export enum BANNERS_PROVIDER_ACTION_TYPES {
  ADD_BANNERS = 'ADD_BANNERS',
  CLOSE_BANNER = 'CLOSE_BANNER',
  CLOSE_ALL_BANNERS = 'CLOSE_ALL_BANNERS',
}

export type NotificationId = string;
export type BANNERS_PROVIDER_ACTIONS =
  | {
      type: BANNERS_PROVIDER_ACTION_TYPES.CLOSE_BANNER;
      payload: { notificationId: NotificationId };
    }
  | {
      type: BANNERS_PROVIDER_ACTION_TYPES.CLOSE_ALL_BANNERS;
    }
  | {
      type: BANNERS_PROVIDER_ACTION_TYPES.ADD_BANNERS;
      payload: { notifications: Notification[] };
    };
