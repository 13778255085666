import { DefaultStyledProps } from '@savgroup-front-common/types';

interface ColorBasedOnProps extends DefaultStyledProps {
  disabled: boolean;
  danger: boolean;
  $isNewUiEnabled?: boolean;
}

export const colorBasedOnProps = ({
  theme,
  disabled = false,
  danger = false,
}: ColorBasedOnProps) => {
  if (disabled) {
    return theme.colors.disabled;
  }
  if (danger) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

export const colorHoverBasedOnProps = ({
  theme,
  danger = false,
  $isNewUiEnabled,
}: ColorBasedOnProps) => {
  if (danger) {
    return theme.colors.danger;
  }

  if ($isNewUiEnabled) {
    return theme.newUI.variationColors?.light1;
  }

  return theme.colors.primary;
};
