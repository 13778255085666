import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

interface ButtonGroupArgs {
  $isFluid?: boolean;
  $withMargin?: boolean;
}

export const $ButtonGroup = styled.div<ButtonGroupArgs>`
  margin-bottom: ${({ $withMargin }) => ($withMargin ? '1rem' : '0')};
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'fit-content')};
  display: flex;
  flex-direction: row;

  @media ${media.minWidth.sm} {
    & > button ~ button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    & > button:not([disabled]) ~ button {
      border-left: 1px transparent;
    }

    & > button:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    && > button ~ div {
      div > div > input,
      div button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    & > button:not([disabled]) ~ div {
      div > div > input {
        border-left: 1px transparent;
      }
      div button {
        border-left: 1px transparent;
      }
    }
  }

  @media ${media.maxWidth.xs} {
    flex-direction: column;

    width: 100%;

    & > button {
      width: 100%;
    }

    & > button ~ button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    & > button:not([disabled]) ~ button {
      border-top: 1px transparent;
    }

    & > button:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;
