import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/handling`;

export interface GetFileHandlingPayload {
  claimId: string;
  productLocationCountryCode: string;
  hasDeposit: boolean;
  hasDelivery: boolean;
  hasHome: boolean;
  delivery: { address: AddressInfoDto };
  deposit: {
    pickupPointAddress: AddressInfoDto;
    address: AddressInfoDto;
  };
  home: any; // empty object ?
  actorInfos: {
    id: string;
    type: string;
    address: AddressInfoDto;
  };
}
interface GetFileHandlingArgs {
  fileId: string;
}

async function getFileHandling({
  fileId,
}: GetFileHandlingArgs): Promise<
  BackResponse<GetFileHandlingPayload> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const response = await apiCall<BackResponse<GetFileHandlingPayload>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getFileHandling;
