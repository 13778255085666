import {
  AsYouType,
  CountryCode,
  formatNumber,
  ParsedNumber,
  parseNumber,
} from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';

export const formatPhoneNumber = (
  phoneNumber?: string | null,
  countryCode: string = COUNTRY_CODES.FR,
): string | undefined | null => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  const parsedPhoneNumber = parseNumber(
    phoneNumber.replace(/^00/, '+'),
    toUpper(countryCode) as CountryCode,
  );

  return isEmpty(parsedPhoneNumber)
    ? phoneNumber
    : formatNumber(parsedPhoneNumber as ParsedNumber, 'INTERNATIONAL');
};

export const formatPhoneNumberAsYouType = (
  phoneNumber: string,
  countryCode: string,
) => {
  if (!phoneNumber || !/^(\+|0)([0-9]|\s)/.test(phoneNumber)) {
    return phoneNumber;
  }
  const formatter = new AsYouType(toUpper(countryCode) as CountryCode);
  const formattedNumber = formatter.input(phoneNumber.replace(/^00/, '+'));

  return formattedNumber || phoneNumber;
};
