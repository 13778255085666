import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}sharelink/byorderreference`;

interface CreateShareLinkByOrderReferencePayload {
  orderReference: string;
  phoneNumberLastFourDigits: string;
  tenantId?: string;
}

async function createShareLinkByOrderReference({
  phoneNumberLastFourDigits,
  orderReference,
  tenantId,
}: CreateShareLinkByOrderReferencePayload): Promise<
  BackResponse<Location> | BackResponseFailure
> {
  if (!tenantId) {
    throw new RequiredPayloadFieldIsNotProvided(
      'tenantId',
      'createShareLinkByOrderReference',
    );
  }

  try {
    return await apiCall<BackResponse<Location>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        orderReference,
        phoneNumberLastFourDigits,
        tenantId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createShareLinkByOrderReference;
