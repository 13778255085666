import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';

import { ClientLogo } from '../../../../molecules';

import {
  $HeaderContainer,
  $MenuContainer,
  $RightSide,
} from './AppHeader.styles';

const AppHeader: FunctionComponent = () => {
  return (
    <$HeaderContainer>
      <$MenuContainer>
        <ClientLogo />
        <$RightSide>
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col>
              <LanguageChooser />
            </Col>
          </Row>
        </$RightSide>
      </$MenuContainer>
    </$HeaderContainer>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
