import {
  FocusEventHandler,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import { GroupBase } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { AsyncProps } from 'react-select/dist/declarations/src/useAsync';

import {
  BaseAutocompleteOption,
  MessageType,
} from '@savgroup-front-common/types';

import { FieldMessages } from '../../common/helpers/getFinalFieldState.types';

export interface CreatableAutocompleteProps<
  Option extends BaseAutocompleteOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Partial<
    Omit<
      AsyncProps<Option, IsMulti, Group>,
      | 'filterOption'
      | 'formatGroupLabel'
      | 'formatOptionLabel'
      | 'isDisabled'
      | 'isLoading'
      | 'loadingMessage'
      | 'noOptionsMessage'
      | 'onInputChange'
      | 'openMenuOnFocus'
      | 'placeholder'
      | 'styles'
      | 'backspaceRemovesValue'
      | 'tabIndex'
      | 'blurInputOnSelect'
      | 'captureMenuScroll'
      | 'closeMenuOnSelect'
    >
  > {
  name?: string;
  label?: MessageType | string;
  postLabel?: string | ReactElement;
  isDisabled?: boolean;
  isRequired?: boolean;
  style?: Record<string, unknown>;
  isClearable?: boolean;
  isLoading?: boolean;
  openMenuOnFocus?: boolean;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isWarning?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  withoutOptionSort?: boolean;
  styles?: Record<string, unknown>;
  noOptionsMessage?: MessageType;
  loadingMessage?: MessageType;
  placeholder?: MessageType;
  dataTestId?: string;
  dataTestIdForCypress?: string;
  componentThemeName?: string;
  closeMenuOnSelect?: boolean;
  suffix?: MessageType;
  prefix?: MessageType;
  suffixButton?: ReactElement;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  filterOption?: (
    option: FilterOptionOption<Option>,
    rawInput?: string,
  ) => boolean;
  onInputChange?: (input: string) => void;
  formatOptionLabel?: (
    option: Option,
    labelMeta: FormatOptionLabelMeta<Option>,
  ) => ReactNode;
  formatGroupLabel?: (option: GroupBase<Option>) => ReactNode;

  backspaceRemovesValue?: boolean;
  tabIndex?: number;
  blurInputOnSelect?: boolean;
  captureMenuScroll?: boolean;
  value?: Option;
  defaultValue?: Option | null;
  createLabel?: MessageType;
  isAcceptDuplicateValuesMode?: boolean;
}

export enum AUTOCOMPLETE_ACTION_TYPES {
  SELECT_OPTION = 'select-option',
  DESELECT_OPTION = 'deselect-option',
  REMOVE_VALUE = 'remove-value',
  POP_VALUE = 'pop-value',
  CLEAR = 'clear',
  CREATE_OPTION = 'create-option',
}
