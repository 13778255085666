import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { OwnerService } from 'authenticator/api';
import { ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE } from 'authenticator/api/Owner/getOrderByOrderReferenceAndOrderPrice';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';

import messages from '../messages';

import OnlyWithOrderReferenceSchema from './OnlyWithOrderReference.schema';
import { OnlyWithOrderReferenceValues } from './OnlyWithOrderReference.types';

const GetOrderByOrderReferenceAndOrderPrice =
  'getOrderByOrderReferenceAndOrderPrice';

interface UseCustomerInvoiceInformationsAgrs {
  values: CreateClaimValues;
}

interface UseCustomerInvoiceInformationsReturnValues {
  formContext: UseFormReturn<OnlyWithOrderReferenceValues>;
  isLoading: boolean;
  onSubmit: SubmitHandler<OnlyWithOrderReferenceValues>;
}

const useOnlyWithOrderReference = ({
  values,
}: UseCustomerInvoiceInformationsAgrs): UseCustomerInvoiceInformationsReturnValues => {
  const { pushErrors } = useToasts({
    extendedMessages: messages,
  });
  const { sellerId } = values;
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();

  const formContext = useForm<OnlyWithOrderReferenceValues>({
    resolver: yupResolver(OnlyWithOrderReferenceSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      orderReference: values.orderReference || '',
    },
  });

  const { watch } = formContext;

  const orderReference = watch('orderReference');

  const { mutateAsync: handleSubmitOrderInformations, isLoading } = useMutation(
    [GetOrderByOrderReferenceAndOrderPrice, { sellerId, orderReference }],
    async () => {
      if (!sellerId || !orderReference) {
        return undefined;
      }

      const response = await OwnerService.getOrderByOrderReferenceAndOrderPrice(
        {
          orderReference,
          sellerId,
        },
      );

      if (response.failure) {
        pushErrors(response.errors);

        logError(
          `Error with orderReference ${response.errors.map((e) => e.message)}`,
        );

        return undefined;
      }

      return response.value;
    },
  );

  const onSubmit: SubmitHandler<OnlyWithOrderReferenceValues> =
    useCallback(async () => {
      const response = await handleSubmitOrderInformations();

      if (
        response?.importStatus ===
        ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.ORDER_ALREADY_IMPORT
      ) {
        window.location = response?.irshUrl;

        return undefined;
      }
      if (
        response?.importStatus ===
        ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.CUSTOMER_IDENTIFIED
      ) {
        nextStep({
          ...values,
          orderReference,
          owner: values.owner ? values.owner : response?.owner,
        });

        return undefined;
      }
      if (
        response?.importStatus ===
        ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.ANONYMOUS_CUSTOMER
      ) {
        nextStep({
          ...values,
          orderReference,
        });

        return undefined;
      }

      return undefined;
    }, [handleSubmitOrderInformations, orderReference, nextStep, values]);

  return {
    formContext,
    isLoading,
    onSubmit,
  };
};

export default useOnlyWithOrderReference;
