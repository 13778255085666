import { CheckCircle } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const CheckCircleSolidIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <CheckCircle width={size} height={size} color={color} />;

CheckCircleSolidIcon.displayName = 'CheckCircleSolidIcon';

export default CheckCircleSolidIcon;
