 
import styled from 'styled-components';

import {
  DefaultStyledProps,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import {
  getCustomComponentProperty,
  styledGetCustomComponentProperty,
} from '../../../../helpers';

interface GetTextColorProps extends DefaultStyledProps {
  isError?: boolean;
  $isNewUi?: boolean;
}

const getTextColor = (props: GetTextColorProps) => {
  const { theme, $isNewUi } = props;

  if (props.isError) {
    return theme.colors.danger;
  }

  if ($isNewUi) {
    return theme.newUI.defaultColors.secondaryText;
  }

  return getCustomComponentProperty({
    theme: props.theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.COLOR,
    fallback: theme.colors.paragraphTextColor,
  });
};

interface LabelWrapProps {
  fullWidth?: boolean;
}

export const $LabelWrap = styled.div<LabelWrapProps>`
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
`;

export const $AsteriskSpan = styled.span`
  color: ${({ theme }) => theme.colors.danger} !important;
  margin-left: 0.25rem !important;
`;

interface LabelStyledProps extends DefaultStyledProps {
  isError: boolean;
  $hasNoMarginBottom: boolean;
  $isNewUi?: boolean;
}
export const $LabelStyled = styled.label<LabelStyledProps>`
  display: flex;
  align-items: center;
  font-size: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.FONT_SIZE,
  })};
  font-weight: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.FONT_WEIGHT,
  })};
  line-height: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.LINE_HEIGHT,
  })};
  color: ${getTextColor};
  min-height: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.MIN_HEIGHT,
  })};
  margin: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.MARGIN,
    fallback: '0 0 0.5rem 0',
  })};
  margin-bottom: ${({ $hasNoMarginBottom }) => ($hasNoMarginBottom ? 0 : '')};

  width: inherit;

  > div {
    height: 100%;
  }
  svg {
    height: ${({ theme }) => theme.fonts.size.ultraSmall};
    max-width: ${({ theme }) => theme.fonts.size.ultraSmall};
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.paragraphTextColor};
    margin-right: 0.5rem;
  }
`;
