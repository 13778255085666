import { Refresh } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const RefreshIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Refresh width={size} height={size} color={color} />;

RefreshIcon.displayName = 'RefreshIcon';

export default RefreshIcon;
