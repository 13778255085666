import React from 'react';

import { Notification } from '@savgroup-front-common/types';

import { NotificationId } from './ToastsProvider.types';

interface ToastsContextValue {
  pushNotifications: (notifications: Notification[]) => NotificationId[];
  removeNotification: (id: NotificationId) => void;
  removeAllNotifications: () => void;
  notifications: Notification[];
}

const ToastsProviderContext = React.createContext<ToastsContextValue>({
  pushNotifications: () => [],
  removeNotification: () => undefined,
  removeAllNotifications: () => undefined,
  notifications: [],
});

export default ToastsProviderContext;
