export enum CARRIER_CUSTOMER {
  CHRONO = 'Chrono13LocalPUDO',
  UPS = 'UpsStdFRFRHomePickup',
  IMMEDIATE_DROP_AT_STORE_DEPOSITE = 'ImmediateDropAtStoreDeposit',
  DROP_AT_STORE = 'DropAtStoreDeposit',
  PICKUP_STORE_DELIVERY = 'PickUpStoreDeliveryFRFR',
  SELLER_PROVIDED_CARRIER = 'SellerProvidedCarrier2',
}

export enum OnboardingActivityTypes {
  HIGH_TECH = 'High_tech',
  HOME_APPLIANCES = 'Electromenager',
  HANDIWORK = 'Bricolage',
  SPORT = 'Sports',
  MOBILITY = 'Mobilite',
  FURNISHING = 'Ameublement',
  BEAUTY = 'Beauté',
  MODE = 'Mode',
  JEWELRY = 'Bijoux',
  CHILDCARE = 'Puericulture',
  GAMES = 'Jouet_Loisirs_Creatifs',
  OTHER = 'Other',
  ENTERPRISE = 'Business',
  PRIVATE = 'Individual',
  BOTH_CUSTOMER = 'bothCustomer',
  ONLINE = 'online',
  STORE = 'store',
  BOTH_SALES = 'bothSales',
  STORE_RETURN = 'storeReturn',
  PICKUP_POINT = 'Chrono13LocalPUDO',
  HOME_PICKUP = 'UpsStdFRFRHomePickup',
  CLIENT = 'SellerProvidedCarrier2',
  RETRACTATION = 'Retractation',
  SAV = 'SAV',
  DELIVERY_INCIDENT = 'DeliveryIncident',
  MIN_RETURN_VOLUME = 'EnterpriseVolume_1',
  LITTLE_RETURN_VOLUME = 'EnterpriseVolume_2',
  MEDIUM_RETURN_VOLUME = 'EnterpriseVolume_3',
  HIGH_RETURN_VOLUME = 'EnterpriseVolume_4',
  MAX_RETURN_VOLUME = 'EnterpriseVolume_5',
}
