import * as Yup from 'yup';

import {
  globalMessages,
  isEmail,
} from '@savgroup-front-common/core/src/helpers';

const CustomerInformationNameSchema = ({
  isBusiness,
}: {
  isBusiness: boolean;
}) => {
  if (isBusiness) {
    return Yup.object().shape({
      companyName: Yup.string().required(globalMessages.form.required),
      email: Yup.string()
        .required(globalMessages.form.required)
        .test('is-email', globalMessages.form.invalidEmail, (value) => {
          return isEmail(value);
        }),
      phoneNumber: Yup.string().required(globalMessages.form.required),
    });
  }

  return Yup.object().shape({
    firstName: Yup.string().required(globalMessages.form.required),
    lastName: Yup.string().required(globalMessages.form.required),
    companyName: Yup.string(),
    email: Yup.string()
      .required(globalMessages.form.required)
      .test('is-email', globalMessages.form.invalidEmail, (value) => {
        return isEmail(value);
      }),
    phoneNumber: Yup.string().required(globalMessages.form.required),
  });
};

export default CustomerInformationNameSchema;
