import { useLayoutEffect } from 'react';

const useAppendNoIndexMeta = ({ VITE_ENV }: { VITE_ENV: string }) => {
  useLayoutEffect(() => {
    if (VITE_ENV !== 'production') {
      const noIndexSearchEngines = document.createElement('meta');

      noIndexSearchEngines.name = `robots`;
      noIndexSearchEngines.content = 'noindex';
      document.head.appendChild(noIndexSearchEngines);

      const noIndexGoogleBot = document.createElement('meta');

      noIndexGoogleBot.name = `googlebot`;
      noIndexGoogleBot.content = 'noindex';
      document.head.appendChild(noIndexGoogleBot);
    }
  }, [VITE_ENV]);
};

export default useAppendNoIndexMeta;
