import React from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import useLogo from '../../../hooks/useLogo';
import { $LogoContainer, $LogoImg } from '../Header.styles';

interface LogoProps {
  sellerId: string;
}

const Logo: React.FC<LogoProps> = ({ sellerId }) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { logoUrl, sellerUrl, imgRef, onError } = useLogo({ sellerId });

  if (isMobileView) {
    return (
      <Link
        href={sellerUrl.href}
        isExternal={sellerUrl.isExternal}
        dataTestId="headerlogo-image"
      >
        <$LogoContainer
          componentThemeName={SUPPORTED_COMPONENTS.LOGO}
          $isMobileView={isMobileView}
        >
          <$LogoImg ref={imgRef} alt="logo" src={logoUrl} onError={onError} />
        </$LogoContainer>
      </Link>
    );
  }

  return (
    <Link
      href={sellerUrl.href}
      isExternal={sellerUrl.isExternal}
      dataTestId="headerlogo-image"
    >
      <$LogoContainer componentThemeName={SUPPORTED_COMPONENTS.LOGO}>
        <$LogoImg ref={imgRef} alt="logo" src={logoUrl} onError={onError} />
      </$LogoContainer>
    </Link>
  );
};

Logo.displayName = 'Logo';

export default Logo;
