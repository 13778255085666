export enum DOCUMENT_STATUS {
  UNKNOWN = 'Unknown',
  IN_PROGRESS = 'InProgress',
  CREATED = 'Created',
  FAILURE = 'Failure',
  REGENERATE = 'Regenerate',
}

export const FILE_BUTTON_ORIGIN = {
  STATUS_UPDATE: 'statusUpdate',
  DOCUMENTS_TAB: 'documentsTab',
};

export enum DOCUMENT_TYPES {
  IMAGE = 'Image',
  LOGO = 'Logo',
  FAVICON = 'Favicon',
  VIDEO = 'Video',
  RETURN_VOUCHER = 'ReturnVoucher',
  SHIPPING_LABEL = 'ShippingLabel',
  QUOTE = 'Quote',
  TRANSPORT = 'Transport',
  THEME = 'Theme',
  VOUCHER_LOGO = 'VoucherLogo',
  STORE_LOGO = 'Storelogo',
  INTERVENTION_REPORT = 'InterventionReport',
  ADDITIONAL_INFORMATION_DOCUMENTS = 'ADDITIONAL_INFORMATION_DOCUMENTS',
  BILLING = 'Billing',

  CASHING_RECEIPT = 'CashingReceipt',
  DATA_LOSS_LIABILITY_WAIVER = 'DataLossLiabilityWaiver',
  DEPOSIT_VOUCHER = 'DepositVoucher',
  DESTRUCTION_VOUCHER = 'DestructionVoucher',
  FILE_SUMMARY_REPORT = 'FileSummaryReport',
  HAND_BACK_VOUCHER = 'HandBackVoucher',
  HANDLING_VOUCHER = 'HandlingVoucher',
  PROOF_OF_PURCHASE = 'ProofOfPurchae',
  REFUND_VOUCHER = 'RefundVoucher',
  SHIPPING_SUMMARY = 'ShippingSummary',
  SHIPPING_VOUCHER = 'ShippingVoucher',
  UNEXPECTED_RETURN_VOUCHER = 'UnexpectedReturnVoucher',
  PRODUCT_SUMMARY = 'ProductSummary',
  EUROPEAN_WARRANTY_FORM = 'EuropeanWarrantyForm',
  PICTURE = 'Picture',
  CREDIT_NOTE = 'CreditNote',
  NAME_PLATE = 'NamePlate',
  RCA = 'RCA',
  PRODUCTS_TO_COLLECT = 'ProductsToCollect',
  INVOICE = 'Invoice',
}

export const RMA_DOCUMENT_TYPES = {
  BILL_RMA: 'bill_rma',
};

export const IMAGE_DOCUMENT_TYPES = {
  [DOCUMENT_TYPES.CASHING_RECEIPT]: 'CashingReceipt',
  [DOCUMENT_TYPES.DATA_LOSS_LIABILITY_WAIVER]: 'DataLossLiabilityWaiver',
  [DOCUMENT_TYPES.DEPOSIT_VOUCHER]: 'DepositVoucher',
  [DOCUMENT_TYPES.DESTRUCTION_VOUCHER]: 'DestructionVoucher',
  [DOCUMENT_TYPES.FILE_SUMMARY_REPORT]: 'FileSummaryReport',
  [DOCUMENT_TYPES.HAND_BACK_VOUCHER]: 'HandBackVoucher',
  [DOCUMENT_TYPES.HANDLING_VOUCHER]: 'HandlingVoucher',
  [DOCUMENT_TYPES.PROOF_OF_PURCHASE]: 'ProofOfPurchase',
  [DOCUMENT_TYPES.REFUND_VOUCHER]: 'RefundVoucher',
  [DOCUMENT_TYPES.SHIPPING_SUMMARY]: 'ShippingSummary',
  [DOCUMENT_TYPES.SHIPPING_VOUCHER]: 'ShippingVoucher',
  [DOCUMENT_TYPES.UNEXPECTED_RETURN_VOUCHER]: 'UnexpectedReturnVoucher',
  [DOCUMENT_TYPES.PRODUCT_SUMMARY]: 'ProductSummary',
  [DOCUMENT_TYPES.EUROPEAN_WARRANTY_FORM]: 'EuropeanWarrantyForm',
  [DOCUMENT_TYPES.PICTURE]: 'Picture',
  [DOCUMENT_TYPES.CREDIT_NOTE]: 'CreditNote',
  [DOCUMENT_TYPES.NAME_PLATE]: 'NamePlate',
  [DOCUMENT_TYPES.RCA]: 'RCA',
  [DOCUMENT_TYPES.PRODUCTS_TO_COLLECT]: 'ProductsToCollect',
  [DOCUMENT_TYPES.INVOICE]: 'Invoice',
  [DOCUMENT_TYPES.BILLING]: 'Billing',
};
