import getClientTheme from './getClientTheme';
import getDefaultTheme from './getDefaultTheme';
import getSellerTheme from './getSellerTheme';

const themeService = {
  getClientTheme,
  getDefaultTheme,
  getSellerTheme,
};

export default themeService;
