export const transformToHumainNumber = (input: string | number) =>
  String(input).replace('.', ',');

/**
 * transform to valid number (replace comma with point)
 * @param {string|number} input number
 * @returns {number}
 */
export const transformToValidNumber = (input: number | string) =>
  Number(String(input).replace(',', '.'));

export const toDigits = (num: number) => num.toString().split('').map(Number);
