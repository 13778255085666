import { lighten } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

import { rem } from '../../helpers';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';

const progressing = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`;

interface ProgressBarProps extends DefaultStyledProps {
  $percent: number;
  $isAnimated: boolean;
  $isRounded: boolean;
  $isSuccess: boolean;
  $isError: boolean;
  $hideOnFinish: boolean;
  $isNewDesign: boolean;
}

const getColor =
  (filter: (color: string) => string = (color: string) => color) =>
  ({ $isSuccess, $isError, theme }: ProgressBarProps) => {
    if ($isSuccess) {
      return filter(theme.colors.success);
    }
    if ($isError) {
      return filter(theme.colors.danger);
    }

    return filter(theme.colors.primary);
  };

const gradient = css<ProgressBarProps>`
  background: linear-gradient(
    90deg,
    ${getColor()} 20%,
    ${getColor((color: string) => lighten(0.35, color))} 40%,
    ${getColor()} 60%
  );
`;

export const ProgressBarFiller = css<ProgressBarProps>`
  &:before {
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    width: ${({ $percent }) => $percent}%;
    transition-duration: 0.3s;
    ${decelerateTimingFunctionTransition};
    position: absolute;
    border-radius: ${({ $isRounded }) => ($isRounded ? rem(4) : 0)};
    background: ${getColor()};
    animation-iteration-count: ${({ $percent, $isAnimated }) =>
      $percent < 100 && $isAnimated ? 'infinite' : 0};

    animation-name: ${progressing};
    animation-duration: 3s;
    animation-timing-function: linear;
    ${({ $percent, $isAnimated }) =>
      $percent < 100 && $isAnimated ? gradient : null};
    background-size: 400% 100%;
    pointer-events: none;
    opacity: ${({ $hideOnFinish, $percent }) => {
      if ($hideOnFinish && $percent >= 100) {
        return 0;
      }

      return 1;
    }};
  }
`;

export const $ProgressBarBackgound = styled.div<ProgressBarProps>`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  min-height: ${({ $isNewDesign }) => ($isNewDesign ? rem(3) : rem(8))};
  width: 100%;
  position: relative;
  border-radius: ${({ $isRounded }) => ($isRounded ? rem(4) : 0)};
  overflow: hidden;
  ${ProgressBarFiller};
`;

export const $ProgressBarContent = styled.div`
  position: relative;
`;
