import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.workflow}salesForce/worktype`;

interface GetWorkTypeBySellerQueryPayload {
  sellerId: string;
  fileId?: string;
}

type GetWorkTypeBySellerQueryResponse = { id: string; label: string }[];

type GetWorkTypeBySellerQuery = (
  payload: GetWorkTypeBySellerQueryPayload,
) => Promise<
  BackResponse<GetWorkTypeBySellerQueryResponse> | BackResponseFailure
>;

const getWorkTypeBySellerQuery: GetWorkTypeBySellerQuery = async ({
  sellerId,
  fileId,
}) => {
  try {
    return await apiCall<BackResponse<GetWorkTypeBySellerQueryResponse>>(
      buildUrl(ENDPOINT, { sellerId, fileId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getWorkTypeBySellerQuery;
