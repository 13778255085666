export const getAuthToken = (webStorage, openidProviderConfig) => {
  if (!webStorage) {throw new Error('Storage not available');}

  if (webStorage.length > 0) {
     
    for (let key = webStorage.length - 1; key >= 0; key--) {
      const lastKnownStorageKey = webStorage.key(key);

      if (
        lastKnownStorageKey ===
        `oidc.user:${openidProviderConfig.authority}:${openidProviderConfig.client_id}`
      ) {
        const data = JSON.parse(webStorage.getItem(lastKnownStorageKey));

        if (data) {
          return data.access_token;
        }

        return null;
      }
    }
  }

  return false;
};
