import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $Form = styled.form`
  padding: 0 72px;

  @media ${media.maxWidth.sm} {
    padding: 0;
  }
`;

export const $SubmitButton = styled(Button)`
  margin-top: 24px;
`;

export const $InputRow = styled.div`
  margin-bottom: 18px;
`;
