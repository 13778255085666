import { darken } from 'polished';
import { DefaultTheme } from 'styled-components';

import { FIELD_STATUS, InputStatus } from '../helpers/getFinalFieldState.types';

interface GetColor {
  $status: InputStatus;
  theme: DefaultTheme;
}

export function getWrapperBackgroundFromStatus({ $status, theme }: GetColor) {
  switch ($status) {
    case FIELD_STATUS.ERROR:
      return theme.colors.danger;
    case FIELD_STATUS.WARNING:
      return theme.colors.alert;
    case FIELD_STATUS.SUCCESS:
      return theme.colors.success;
    default:
      return theme.colors.default;
  }
}
export function getWrapperHoverBackgroundFromStatus({
  $status,
  theme,
}: GetColor) {
  switch ($status) {
    case FIELD_STATUS.ERROR:
      return darken(0.1, theme.colors.danger);
    case FIELD_STATUS.WARNING:
      return darken(0.1, theme.colors.alert);
    case FIELD_STATUS.SUCCESS:
      return darken(0.1, theme.colors.success);
    default:
      return theme.colors.primary;
  }
}
export function getWrapperColorFromStatus({ $status = null, theme }: GetColor) {
  switch ($status) {
    case FIELD_STATUS.ERROR:
    case FIELD_STATUS.WARNING:
    case FIELD_STATUS.SUCCESS:
      return theme.colors.mainTextColor;
    default:
      return theme.colors.mainTextColor;
  }
}
export function getWrapperHoverColorFromStatus({
  $status = null,
  theme,
}: GetColor) {
  switch ($status) {
    case FIELD_STATUS.ERROR:
    case FIELD_STATUS.WARNING:
    case FIELD_STATUS.SUCCESS:
    default:
      return theme.colors.mainTextColor;
  }
}
