import { css, keyframes } from 'styled-components';

import { ANIMATION_STATE } from '@savgroup-front-common/constants/src/shared';

import { isAnimationExiting } from '../../helpers';
import { getAnimationDelay, getAnimationDuration } from '../helper';
import { decelerateTimingFunctionAnimation } from '../timingFunction/animation';

import { StateAnimationProps } from './types';

const hide = css`
  display: none;
`;

const floating = css`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
`;
const fix = css`
  z-index: 1;
`;

const enter = css`
  opacity: 0;
  transform: translateY(3rem);
`;

const steady = css`
  opacity: 1;
  transform: translateY(0);
`;

const exit = css`
  opacity: 0;
  transform: translateY(0);
`;

const enteringKeyframes = keyframes`
  from { ${enter} }
  to { ${steady} }
`;

const exitingKeyframes = keyframes`
  from { ${steady} }
  to { ${exit} }
`;

const enterAnimation = css<StateAnimationProps>`
  ${enter};
  animation-name: ${enteringKeyframes};
  animation-duration: ${getAnimationDuration};
  animation-delay: ${getAnimationDelay};
  animation-fill-mode: forwards;
  ${decelerateTimingFunctionAnimation};
`;

const exitAnimation = css<StateAnimationProps>`
  ${steady};
  animation-name: ${exitingKeyframes};
  animation-duration: ${getAnimationDuration};
  animation-fill-mode: forwards;
  ${decelerateTimingFunctionAnimation};
`;

const slideBottomToFloatingFadeOut = css<StateAnimationProps>`
  ${({ animationState }) => {
    if (isAnimationExiting(animationState)) {
      return floating;
    }

    return fix;
  }}
  ${({ animationState }) => {
    switch (animationState) {
      case ANIMATION_STATE.ENTERING: {
        return enterAnimation;
      }
      case ANIMATION_STATE.ENTERED: {
        return null;
      }
      case ANIMATION_STATE.EXITING: {
        return exitAnimation;
      }
      case ANIMATION_STATE.EXITED: {
        return `${hide} ${exit}`;
      }
      default: {
        return null;
      }
    }
  }}
`;

export default slideBottomToFloatingFadeOut;
