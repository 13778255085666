import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $ConfirmationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 48px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
`;

export const $ConfirmationContainer = styled.div`
  padding: 208px;

  @media ${media.maxWidth.md} {
    padding: 104px;
  }

  @media ${media.maxWidth.sm} {
    padding: 24px;
  }
`;

export const $IconWithTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const $AccountActivationTitle = styled.div`
  margin-left: 28px;
  font-size: ${({ theme }) => theme.fonts.size.big};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.newUI.variationColors?.dark1};
`;

export const $VerificationMessage = styled.div`
  margin-bottom: 32px;
  font-size: ${({ theme }) => theme.fonts.size.large};
`;

export const $EmailActivationMessage = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $SpamMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
`;

export const $ResendButton = styled(Button)`
  margin-top: 16px;
`;
