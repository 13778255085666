import { css } from 'styled-components';

import GridTheme from '../gridTheme';

import { MediaValue } from './mediaQueryWrapper';

// Getting breakpoints from GridTheme
const sizes = GridTheme.flexboxgrid.breakpoints;

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce<{
  [key: string]: MediaValue;
}>((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}em) and (orientation: landscape) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
