import { StrictNextStep } from '../StepsOrchestrator.types';

import useStepOrchestratorContext from './useStepOrchestratorContext';

const useStepOrchestratorNextStep = <
  NewValues = void,
  NewContext = Record<string, unknown>,
>(): StrictNextStep<NewValues, NewContext> =>
  useStepOrchestratorContext().nextStep as StrictNextStep<
    NewValues,
    NewContext
  >;

export default useStepOrchestratorNextStep;
