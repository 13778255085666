export enum EVENT_KEY {
  ESCAPE = 'Escape',
  SPACE = ' ',
  COLONS = ':',
  ENTER = 'Enter',
  TAB = 'Tab',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  CONTROL = 'Control',
  AT = '@',
  META = 'Meta',
  SHIFT = 'Shift',
  PERIOD = '.',
  COMMA = ',',
  Q = 'q',
  SLASH = '/',
  SEMICOLON = ';',
  QUOTE = "'",
  DOUBLE_QUOTE = '"',
  C_CEDILLA = 'ç',
  NUMBER_SIGN = '#',
  U_ACUTE_ACCENT = 'ú',
  U_GRAVE_ACCENT = 'ù',
  E_ACUTE_ACCENT = 'é',
  E_GRAVE_ACCENT = 'è',
  SECTION_SIGN = '§',
  EQUAL = '=',
  EXCLAMATION_POINT = '!',
  W = 'w',
  E = 'e',
  R = 'r',
  T = 't',
  Y = 'y',
  A = 'a',
  S = 's',
  Z = 'z',
  M = 'm',
  AMPERSAND = '&',
  OPENING_PARENTHESIS = '(',
  CLOSING_PARENTHESIS = ')',
  DIGIT_1 = '1',
  DIGIT_2 = '2',
  DIGIT_3 = '3',
  DIGIT_4 = '4',
  DIGIT_5 = '5',
  DIGIT_6 = '6',
  DIGIT_7 = '7',
  DIGIT_8 = '8',
  DIGIT_9 = '9',
}
export enum EVENT_CODE {
  ESCAPE = 'Escape',
  SPACE = 'Space',
  ENTER = 'Enter',
  TAB = 'Tab',
  SEMICOLON = 'Semicolon',
  QUOTE = 'Quote',
  SLASH = 'Slash',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  CONTROL_LEFT = 'ControlLeft',
  META_LEFT = 'MetaLeft',
  SHIFT_LEFT = 'ShiftLeft',
  ALT_LEFT = 'AltLeft',
  PERIOD = 'Period',
  COMMA = 'Comma',
  DIGIT_1 = 'Digit1',
  DIGIT_2 = 'Digit2',
  DIGIT_3 = 'Digit3',
  DIGIT_4 = 'Digit4',
  DIGIT_5 = 'Digit5',
  DIGIT_6 = 'Digit6',
  DIGIT_7 = 'Digit7',
  DIGIT_8 = 'Digit8',
  DIGIT_9 = 'Digit9',
  KEY_Q = 'KeyQ',
  KEY_W = 'KeyW',
  KEY_E = 'KeyE',
  KEY_R = 'KeyR',
  KEY_T = 'KeyT',
  KEY_Y = 'KeyY',
}
