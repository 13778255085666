import md5 from 'md5';
import { useCallback, useRef, useState } from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';
import { AuthConfiguration } from 'authenticator/configuration';
import useSellerSelfcareConfiguration from 'authenticator/hooks/useSellerSelfcareConfiguration';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

interface UseLogoArgs {
  sellerId: string;
}

const useLogo = ({ sellerId }: UseLogoArgs) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { sellerConfiguration } = useSellerSelfcareConfiguration({ sellerId });
  const { clientInformation } = useGetClientInformation();

  const sellerUrl = sellerConfiguration?.defaultOwnerOrderUrl
    ? { href: sellerConfiguration?.defaultOwnerOrderUrl, isExternal: true }
    : {
        href: clientInformation?.isSelfcareNewDesign ? '/new_design' : '/',
        isExternal: false,
      };

  const logoUrl = `${
    APIConfiguration.imageCDN
  }sellers/${sellerId}/${DOCUMENT_TYPES.LOGO.toLocaleLowerCase()}`;

  const hashedClientId = md5(AuthConfiguration.clientId);
  const logoFromUiStyles = `${APIConfiguration.uiStylesCDN}logos/${hashedClientId}`;
  const logoFallback = `${
    import.meta.env.VITE_STATIC_HASH
  }/images/logo-blank-rvl.svg`;

  const [listAlternatives, setListAlternatives] = useState(
    [logoUrl, logoFromUiStyles, logoFallback].filter((item) => item),
  );

  const [firstAlternative, ...rest] = listAlternatives;

  const onError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.onerror = rest.length > 0 ? onError : null;
      imgRef.current.src = firstAlternative;

      setListAlternatives(rest);
    }
  }, [firstAlternative, rest]);

  return {
    logoUrl: firstAlternative,
    sellerUrl,
    imgRef,
    onError,
  };
};

export default useLogo;
