export const focusOn = (ref, retries = 0) => {
  if (ref.current) {
    ref.current.focus();
  } else if (retries < 10) {
    // 10 times is more than enough
    window.setTimeout(() => focusOn(ref, retries + 1), 50);
  }
};

export const updateCaretPosition = (element, caretPos) => {
  if (element != null) {
    if (element.createTextRange) {
      const range = element.createTextRange();

      range.move('character', caretPos);
      range.select();
    } else if (element.selectionStart) {
      element.focus();
      element.setSelectionRange(caretPos, caretPos);
    } else {
      element.focus();
    }
  }
};
