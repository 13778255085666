import get from 'lodash/get';
import { FieldError, FieldErrors } from 'react-hook-form';

import { MessageType } from '@savgroup-front-common/types';

import {
  EnhancedState,
  FIELD_STATUS,
  GetFinalFieldStateResponse,
} from './getFinalFieldState.types';

function isMessageType(
  message: MessageType | FieldError,
): message is MessageType {
  return (message as MessageType).id !== undefined;
}

interface GetFieldStateArgs {
  messages?: FieldErrors | Record<string, string | MessageType>;
  name?: string;
  isStatus: boolean;
}

export function getFieldState({
  messages,
  name = '',
  isStatus,
}: GetFieldStateArgs): EnhancedState {
  const fieldMessage = get(messages, name) as
    | MessageType
    | FieldError
    | undefined;

  if (
    typeof fieldMessage === 'string' ||
    fieldMessage === undefined ||
    isMessageType(fieldMessage)
  ) {
    return {
      isStatus: isStatus || Boolean(fieldMessage),
      message: fieldMessage,
    };
  }

  return {
    isStatus: isStatus || Boolean(fieldMessage),
    message: fieldMessage.message,
  };
}

export function chooseState({
  error,
  warning,
  success,
}: {
  error: EnhancedState;
  warning: EnhancedState;
  success: EnhancedState;
}): GetFinalFieldStateResponse {
  if (error.isStatus) {
    return [FIELD_STATUS.ERROR, error.message];
  }
  if (warning.isStatus) {
    return [FIELD_STATUS.WARNING, warning.message];
  }
  if (success.isStatus) {
    return [FIELD_STATUS.SUCCESS, success.message];
  }

  return [null, undefined];
}
