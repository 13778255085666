import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

import { Notification } from '@savgroup-front-common/types';

import BannersProviderContext from './BannersProvider.context';
import {
  bannersProviderInit,
  bannersProviderReducer,
} from './BannersProvider.reducers';
import {
  BANNERS_PROVIDER_ACTION_TYPES,
  NotificationId,
} from './BannersProvider.types';

const BannersProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(
    bannersProviderReducer,
    null,
    bannersProviderInit,
  );

  const pushNotifications = useCallback(
    (notifications: Notification[]): NotificationId[] => {
      dispatch({
        type: BANNERS_PROVIDER_ACTION_TYPES.ADD_BANNERS,
        payload: { notifications },
      });

      return notifications.map(({ id }) => id);
    },
    [],
  );
  const removeNotification = useCallback((notificationId: NotificationId) => {
    dispatch({
      type: BANNERS_PROVIDER_ACTION_TYPES.CLOSE_BANNER,
      payload: { notificationId },
    });
  }, []);
  const removeAllNotifications = useCallback(() => {
    dispatch({
      type: BANNERS_PROVIDER_ACTION_TYPES.CLOSE_ALL_BANNERS,
    });
  }, []);

  const value = useMemo(
    () => ({
      pushNotifications,
      removeNotification,
      removeAllNotifications,
      notifications: state.notifications,
    }),
    [
      pushNotifications,
      removeAllNotifications,
      removeNotification,
      state.notifications,
    ],
  );

  return (
    <BannersProviderContext.Provider value={value}>
      {children}
    </BannersProviderContext.Provider>
  );
};

BannersProvider.displayName = 'BannersProvider';

export default BannersProvider;
