import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}salesleads/${COMMON_QUERY_PARAMS.ID}/onboardingstatus`;

export interface Onboardingstatus {
  demoContextPersonalized: boolean;
  emailConfirmed: boolean;
  demoContextInfrastructureBuilt: boolean;
  demoContextEntitiesAndRulesDuplicated: boolean;
  demoContextDemoOrdersGenerated: boolean;
  demoContextFullyProvisionned: boolean;
  registered: boolean;
  invitationId: string;
  email: string;
}

interface GetSalesLeadOnboardingStatusQueryArgs {
  id: string;
}

const getSalesLeadOnboardingStatusQuery = async ({
  id,
}: GetSalesLeadOnboardingStatusQueryArgs): Promise<
  BackResponse<Onboardingstatus> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ID, id);

    const response = await apiCall<BackResponse<Onboardingstatus>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSalesLeadOnboardingStatusQuery;
