import React, { ChangeEvent, FunctionComponent, ReactNode, Ref } from 'react';
import { useTheme } from 'styled-components';

import {
  INPUT_TYPES,
  POSITIONS,
} from '@savgroup-front-common/constants/src/shared';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import { FieldMessages } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import { SafeFormattedMessage } from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import {
  $RadioInput,
  $RadioLabel,
  $RadioSpan,
  $RadioSpanContainer,
} from './RadioButton.styles';

interface RadioNakedProps {
  disabled?: boolean;
  danger?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  type?: 'checkbox' | 'radio';
  name: string;
  value?: string;
  dataTestId?: string;
}

export interface RadioProps extends RadioNakedProps {
  label: MessageType | ReactNode;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  isFluid?: boolean;

  disabled?: boolean;
  danger?: boolean;
  tabIndex?: number;

  selected?: boolean;
  isHighlighted?: boolean;
  onClick?: () => void;
  labelPosition?: POSITIONS;
}
interface RadioPropsWithRef extends RadioProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const RadioButton: FunctionComponent<
  React.PropsWithChildren<RadioPropsWithRef>
> = ({
  name,
  tabIndex = 0,
  label = null,
  disabled = false,
  isFluid = false,
  dataTestId,
  forwardedRef,
  value,
  checked,
  isHighlighted = false,
  onClick,
  labelPosition = POSITIONS.RIGHT,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <$RadioLabel
      disabled={disabled}
      $isFluid={isFluid}
      $isChecked={checked}
      $labelPosition={labelPosition}
      data-testid={dataTestId}
    >
      <$RadioInput
        name={name}
        type={INPUT_TYPES.RADIO}
        tabIndex={tabIndex}
        ref={forwardedRef}
        value={value}
        checked={checked}
        isHighlighted={isHighlighted}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      />
      {label && labelPosition === POSITIONS.LEFT
        ? SafeFormattedMessage(label)
        : undefined}
      <$RadioSpanContainer>
        <$RadioSpan
          disabled={disabled}
          $isChecked={checked}
          $labelPosition={labelPosition}
        >
          <CheckIcon color={theme.colors.white} size="16" />
        </$RadioSpan>
      </$RadioSpanContainer>
      {label && labelPosition === POSITIONS.RIGHT
        ? SafeFormattedMessage(label)
        : undefined}
    </$RadioLabel>
  );
};

RadioButton.displayName = 'RadioButton';

export default React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <RadioButton forwardedRef={ref} {...props} />
));
