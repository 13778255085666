import { AuthConfiguration } from 'authenticator/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

const ENDPOINT = `${AuthConfiguration.authority}/account/me`;

const getOwnerSummaryQuery = async (): Promise<Response | undefined> => {
  try {
    const response = await fetch(ENDPOINT, {
      method: SUPPORTED_METHODS.GET,
      credentials: 'include',
    });

    return response;
  } catch (exception) {
    logError(`An error is occured to get the owner Summary ${exception}`);

    return undefined;
  }
};

export default getOwnerSummaryQuery;
