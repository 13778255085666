import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $OnboardingEntryFormContainer = styled(Col)`
  border-left: 1px solid #e0e0e0;
  padding: 24px 48px;
`;

export const $OnboardingEntry = styled(Row)`
  height: 100%;
`;

export const $LanguageChooser = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
`;

export const $BackgroundCol = styled(Col)`
  padding: 24px 48px;
  background: ${({ theme }) =>
    `linear-gradient(166deg, ${theme.newUI.gradients.purpleStart} 0%, ${theme.newUI.gradients.blueEnd} 98.71%)`};
  position: relative;

  svg {
    width: 120px;
    height: 23px;
  }

  img {
    margin: auto;
    display: flex;

    @media ${media.maxWidth.lg} {
      width: 500px;
    }
    @media ${media.maxWidth.md} {
      width: 400px;
    }
  }
`;

export const $ImgTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.big};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  display: flex;
  justify-content: center;
  margin-top: 88px;
`;

export const $ImgDescription = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 48px;
  text-align: center;
`;
