import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import {
  SalesChannel,
  StrictAutocompleteOptionForHookForm,
} from '@savgroup-front-common/types';

import { ProductReferenceLoginContext } from '../../../ProductReferenceLogin.types';
import ComplementaryInformationSchema from '../ComplementaryInformation.schema';
import {
  COMPLEMENTARY_INFORMATION_FIELDS,
  ComplementaryInformationValues,
} from '../ComplementaryInformation.types';

interface ComplementaryInformationFormValues {
  [COMPLEMENTARY_INFORMATION_FIELDS.PRODUCT_PRICE]: number;
  [COMPLEMENTARY_INFORMATION_FIELDS.PURCHASE_DATE]: any;
  [COMPLEMENTARY_INFORMATION_FIELDS.SALES_CHANNEL]: StrictAutocompleteOptionForHookForm<SalesChannel>;
}

const useComplementaryInformation = () => {
  const { nextStep, values } = useStepOrchestratorContext<
    ComplementaryInformationValues,
    ProductReferenceLoginContext
  >();
  const defaultSalesChannel = values.salesChannel;

  const formContext = useForm<ComplementaryInformationFormValues>({
    defaultValues: {
      [COMPLEMENTARY_INFORMATION_FIELDS.PRODUCT_PRICE]: values?.productPrice,
      [COMPLEMENTARY_INFORMATION_FIELDS.PURCHASE_DATE]: values?.purchaseDate,
      [COMPLEMENTARY_INFORMATION_FIELDS.SALES_CHANNEL]: defaultSalesChannel
        ? {
            value: defaultSalesChannel.id,
            label: defaultSalesChannel.name,
            data: defaultSalesChannel,
          }
        : undefined,
    },
    resolver: yupResolver(ComplementaryInformationSchema),
  });

  const handleComplementaryInformationSubmit = useCallback(
    (data: ComplementaryInformationFormValues) => {
      nextStep({
        [COMPLEMENTARY_INFORMATION_FIELDS.PRODUCT_PRICE]: data.productPrice,
        [COMPLEMENTARY_INFORMATION_FIELDS.PURCHASE_DATE]: data.purchaseDate,
        [COMPLEMENTARY_INFORMATION_FIELDS.SALES_CHANNEL]:
          data.salesChannel.data,
      });
    },
    [nextStep],
  );

  return { formContext, handleComplementaryInformationSubmit };
};

export default useComplementaryInformation;
