import styled from 'styled-components';

export const $CustomGrid = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
  max-width: 1000px;
`;
