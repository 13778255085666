import styled from 'styled-components';

import { rem } from '../../helpers';

export const $LoaderWrapper = styled.div<{ $size: string }>`
  width: ${({ $size }) => rem($size)};
  height: ${({ $size }) => rem($size)};
  display: flex;
  align-items: center;
`;
