import React, { ReactNode } from 'react';
import { toast as toastComponent, ToastOptions } from 'react-toastify';
import { OnChangeCallback } from 'react-toastify/dist/core';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../formatters';
import {
  CheckCircleIcon,
  CrossCircleIcon,
  InformationPlainIcon,
  WarningIcon,
} from '../../protons/icons';

import { ToastBody } from './Components';
import messages from './messages';
import { noticeOptions, toastOptions } from './Toast.config';
import { ReversToastOptions, ToastContent } from './Toast.types';

const adaptOptions = (
  defaultOptions: ReversToastOptions,
  options: ReversToastOptions,
  icon: ReactNode,
): ToastOptions => {
  const merged = {
    ...defaultOptions,
    ...options,
  };

  const { showIcon, closeButton: CloseButton, ...mergedRest } = merged;

  const returnValue: ToastOptions = mergedRest;

  if (typeof CloseButton === 'function') {
    returnValue.closeButton = (
      <CloseButton animationDuration={merged.autoClose || 0} />
    );
  }
  if (showIcon === true) {
    returnValue.icon = icon;
  }

  return returnValue;
};

const constructToast = (defaultOptions: ReversToastOptions) => {
  const BridgeToast = (
    content: ToastContent,
    options: ReversToastOptions = {},
  ) =>
    toastComponent(
      SafeFormattedMessage(content),
      adaptOptions(defaultOptions, options, <InformationPlainIcon />),
    );

  const ToastConfig =
    (
      icon: ReactNode,
      message: MessageType,
      fn: (
        content: ReactNode,
        options?: ToastOptions | undefined,
      ) => string | number,
    ) =>
    (content: ToastContent, options: ReversToastOptions = {}) =>
      fn(
        <ToastBody message={message} content={content} options={options} />,
        adaptOptions(defaultOptions, options, icon),
      );

  BridgeToast.info = ToastConfig(
    <InformationPlainIcon />,
    messages.information,
    toastComponent.info,
  );
  BridgeToast.success = ToastConfig(
    <CheckCircleIcon />,
    messages.success,
    toastComponent.success,
  );
  BridgeToast.warn = ToastConfig(
    <WarningIcon />,
    messages.warning,
    toastComponent.warn,
  );
  BridgeToast.error = ToastConfig(
    <CrossCircleIcon />,
    messages.error,
    toastComponent.error,
  );

  BridgeToast.isActive = (toastId: string | number) =>
    toastComponent.isActive(toastId);
  BridgeToast.dismiss = (toastId: string | number) =>
    toastComponent.dismiss(toastId);
  BridgeToast.update = (toastId: string | number, options: ToastOptions) =>
    toastComponent.update(toastId, options);

  BridgeToast.onChange = (callback: OnChangeCallback) =>
    toastComponent.onChange(callback);
  BridgeToast.done = (toastId: string | number) => toastComponent.done(toastId);

  return BridgeToast;
};

export const toast = constructToast(toastOptions);
export const notice = constructToast(noticeOptions);
