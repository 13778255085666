import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { CIVILITY } from '@savgroup-front-common/constants';
import {
  Autocomplete,
  EmailAutocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  InputHookForm,
  RadioButtonHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';

import { StepLayout } from '../../../../../atoms';
import { CUSTOMER_INFORMATION_FIELDS } from '../CustomerInformation.types';

import useCustomerAddressForm from './hooks/useCustomerAddressForm';
import messages from './messages';

const civilityOptions = [
  {
    label: messages.mister,
    value: CIVILITY.MR,
  },
  {
    label: messages.madam,
    value: CIVILITY.MRS,
  },
  {
    label: 'notSet',
    value: CIVILITY.NOT_SET,
  },
];

const CustomerAddressForm: FunctionComponent = () => {
  const formContext = useFormContext();
  const {
    register,
    control,
    formState: { errors },
  } = formContext;

  const { countries } = useCustomerAddressForm();

  return (
    <>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={messages.information} />
      </Heading>
      <Card>
        <Row>
          {civilityOptions.map((item) => (
            <Col key={item.value}>
              <FormGroup isOpen={item.value !== CIVILITY.NOT_SET}>
                <RadioButtonHookForm
                  name={CUSTOMER_INFORMATION_FIELDS.CIVILITY}
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  register={register}
                />
              </FormGroup>
            </Col>
          ))}
        </Row>
        <StepLayout>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name={CUSTOMER_INFORMATION_FIELDS.FIRST_NAME}
              placeholder={messages.firstName}
              label={messages.firstName}
              register={register}
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name={CUSTOMER_INFORMATION_FIELDS.LAST_NAME}
              placeholder={messages.lastName}
              label={messages.lastName}
              register={register}
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={CUSTOMER_INFORMATION_FIELDS.EMAIL}
              control={control}
              render={({ field }) => (
                <EmailAutocomplete
                  name={field.name}
                  errors={errors}
                  label={messages.email}
                  ref={field.ref}
                  dataTestId="search-customer-input"
                  value={field.value}
                  onChange={field.onChange}
                  isRequired
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <InputHookForm
              errors={errors}
              name={CUSTOMER_INFORMATION_FIELDS.COMPANY_NAME}
              placeholder={messages.companyName}
              label={messages.companyName}
              register={register}
            />
          </FormGroup>
          <FormGroup>
            <InputHookForm
              label={messages.phone}
              name={CUSTOMER_INFORMATION_FIELDS.PHONE}
              maxLength={25}
              register={register}
              errors={errors}
              isRequired
            />
          </FormGroup>
        </StepLayout>
      </Card>

      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={messages.address} />
      </Heading>
      <Card>
        <StepLayout>
          <FormGroup>
            <InputHookForm
              label={messages.address}
              name={CUSTOMER_INFORMATION_FIELDS.ADDRESS}
              register={register}
              errors={errors}
              isRequired
            />
          </FormGroup>

          <FormGroup>
            <InputHookForm
              label={messages.additionalAddress}
              name={CUSTOMER_INFORMATION_FIELDS.ADDITIONAL_ADDRESS}
              placeholder={messages.additionalInformationsPlaceHolder}
              register={register}
              errors={errors}
            />
          </FormGroup>

          <FormGroup>
            <InputHookForm
              label={messages.postalCode}
              name={CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE}
              register={register}
              errors={errors}
              isRequired
            />
          </FormGroup>

          <FormGroup>
            <InputHookForm
              label={messages.city}
              name={CUSTOMER_INFORMATION_FIELDS.CITY}
              register={register}
              errors={errors}
              isRequired
            />
          </FormGroup>

          <FormGroup>
            <Controller
              control={control}
              name={CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE}
              render={({ field }) => (
                <Autocomplete
                  ref={field.ref}
                  name={field.name}
                  label={messages.country}
                  placeholder={messages.chooseOption}
                  options={countries}
                  errors={errors}
                  value={field.value}
                  onChange={field.onChange}
                  isRequired
                />
              )}
            />
          </FormGroup>
        </StepLayout>
      </Card>
    </>
  );
};

CustomerAddressForm.displayName = 'AddressForm';

export default CustomerAddressForm;
