import size from 'lodash/size';
import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { BUTTON_TYPES, CURRENCIES } from '@savgroup-front-common/constants';
import {
  FloatInput,
  FormGroup,
  Input,
} from '@savgroup-front-common/core/src/atoms/Form';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  formatCurrencyCode,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { formatStringToIntlPrice } from '@savgroup-front-common/core/src/formatters/intl';
import { globalMessages } from '@savgroup-front-common/core/src/helpers';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { AuthConfiguration } from 'authenticator/configuration';

import { PostLabelTooltip, StepLayout } from '../../../../atoms';

import { useOrderInformationsFormContext } from './hooks';
import useOrderInformationsSubmit from './hooks/useOrderInformationsSubmit';
import messages from './messages';
import {
  $OrderInformationsFooter,
  $OrderRefDidacticImg,
} from './OrderInformations.styles';
import { ORDER_INFORMATIONS_FIELD_NAMES } from './OrderInformations.types';

function formatOrderRef(event: React.ChangeEvent<HTMLInputElement>) {
  const { value } = event.currentTarget;

  const match = value.match(/^([a-zA-Z0-9]{4})(:?[a-zA-Z0-9]{1,4})$/);

  if (match) {
    return `${match[1]}-${match[2]}`.toUpperCase();
  }

  return value.toUpperCase();
}

const OrderInformationsContent: FunctionComponent = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useOrderInformationsFormContext();

  const { onSubmit, isLoading } = useOrderInformationsSubmit();

  return (
    <StepLayout showImage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BannersConsumer />
        <FormGroup>
          <Controller
            control={control}
            name={ORDER_INFORMATIONS_FIELD_NAMES.ORDER_REFERENCE}
            render={({ field }) => {
              return (
                <Input
                  maxLength={9}
                  dataTestId="order-reference-input"
                  label={messages.orderRefLabel}
                  placeholder={messages.orderRefPlaceholder}
                  disableBrowserAutocomplete
                  onChange={(event) => field.onChange(formatOrderRef(event))}
                  value={field.value}
                  name={field.name}
                  selectAllOnFocus
                  ref={field.ref}
                  errors={errors}
                  isRequired
                  postLabel={
                    <PostLabelTooltip>
                      <$OrderRefDidacticImg
                        alt="login"
                        // eslint-disable-next-line max-len
                        src={`${APIConfiguration.catalogCDN}miscellaneous/didactics/form-price-order/order-ref-input/${AuthConfiguration.clientId}.jpg`}
                      />
                      <SafeFormattedMessageWithoutSpread
                        message={messages.orderReferencePostLabel}
                      />
                    </PostLabelTooltip>
                  }
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            control={control}
            name={ORDER_INFORMATIONS_FIELD_NAMES.ORDER_PRICE}
            render={({ field }) => {
              return (
                <FloatInput
                  placeholder={messages.orderPricePlaceholder}
                  suffix={formatCurrencyCode(CURRENCIES.EUR)}
                  label={messages.orderPriceLabel}
                  dataTestId="order-price-input"
                  disableBrowserAutocomplete
                  onChange={field.onChange}
                  value={field.value}
                  name={field.name}
                  selectAllOnFocus
                  ref={field.ref}
                  errors={errors}
                  isRequired
                  postLabel={
                    <PostLabelTooltip>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.orderPricePostLabel}
                      />
                    </PostLabelTooltip>
                  }
                  onBlur={(event) =>
                    field.onChange(
                      formatStringToIntlPrice({
                        amount: event.target.value,
                        currency: CURRENCIES.EUR,
                      }),
                    )
                  }
                />
              );
            }}
          />
        </FormGroup>
        <$OrderInformationsFooter>
          <ActionRow>
            <Button
              type={BUTTON_TYPES.SUBMIT}
              isError={size(errors) > 0}
              primary
              isLoading={isLoading}
            >
              <SafeFormattedMessageWithoutSpread
                message={globalMessages.stepsOrchestratorMessages.nextButton}
              />
            </Button>
          </ActionRow>
        </$OrderInformationsFooter>
      </form>
    </StepLayout>
  );
};

OrderInformationsContent.displayName = 'OrderInformationsContent';

export default OrderInformationsContent;
