import { useCallback, useState } from 'react';

import { useDebounce } from '@savgroup-front-common/core/src/hooks';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { AutocompleteOption } from '@savgroup-front-common/types';
import { CatalogService } from 'authenticator/api';
import { MinimalSeller } from 'authenticator/types';

import adaptRawModelsFromBackToOptions from '../adapters/adaptRawModelsFromBackToOptions';
import { SearchModel } from '../ChoicePartnerProduct.types';

const useGetModelOptions = ({ seller }: { seller?: MinimalSeller }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<AutocompleteOption<SearchModel>[]>([]);
  const [searchTerms, setSearchTerms] = useState('');

  const { pushErrors, removeAllNotifications } = useBanners();

  const asyncGetModelOptions = useCallback(async () => {
    if (!seller?.id || !searchTerms) {
      return;
    }
    removeAllNotifications();
    setIsLoading(true);

    const response = await CatalogService.getModelsByFullTextSearch({
      searchTerms,
      sellerId: seller?.id,
    });

    if (!response.failure) {
      setOptions(adaptRawModelsFromBackToOptions(response.value, searchTerms));
    }

    pushErrors(response.errors);

    setIsLoading(false);
  }, [pushErrors, removeAllNotifications, searchTerms, seller?.id]);

  useDebounce(asyncGetModelOptions, 500);

  return { options, searchTerms, setSearchTerms, isLoading };
};

export default useGetModelOptions;
