import filter from 'lodash/filter';
import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { LinkButton } from '@savgroup-front-common/core/src/atoms/link';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RadioButtonHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import {
  CUSTOMER_INFORMATION_FIELDS,
  CustomerInformationFormValues,
} from '../CustomerInformation.types';

import messages from './messages';
import { $AddressRow, $SuggestionTitle } from './SuggestedAddressModal.styles';
import { ModalFooter } from '@savgroup-front-common/core/src/atoms/modal';

interface SuggestedAddressModalContentProps {
  suggestedAddress?: CustomerInformationFormValues;
  addressError: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: () => void;
}

const SuggestedAddressModalContent: FunctionComponent<
  React.PropsWithChildren<SuggestedAddressModalContentProps>
> = ({ suggestedAddress, addressError, onClose, isLoading, onSubmit }) => {
  const formContext = useFormContext<CustomerInformationFormValues>();
  const { register, setValue, watch } = formContext;

  const { firstName, lastName, address, additionalAddress, postalCode, city } =
    watch();

  useDeepCompareEffect(() => {
    setTimeout(() => {
      if (suggestedAddress) {
        setValue(CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS, 'true');
      } else {
        setValue(CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS, 'false');
      }
    });
  }, [setValue, suggestedAddress]);

  const handleEditAddress = () => {
    if (!suggestedAddress) {
      onClose();

      return;
    }
    const { address, city, postalCode } = suggestedAddress;

    setValue(CUSTOMER_INFORMATION_FIELDS.ADDRESS, address);
    setValue(CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE, postalCode);
    setValue(CUSTOMER_INFORMATION_FIELDS.CITY, city);
    onClose();
  };

  return (
    <>
      <Heading level={1}>
        <SafeFormattedMessageWithoutSpread
          message={messages.suggestionModalTitle}
        />
      </Heading>
      {!addressError && suggestedAddress ? (
        <RadioButtonHookForm
          isFluid
          name={CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS}
          register={register}
          value="true"
          label={
            <>
              <$AddressRow>
                <$SuggestionTitle>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.suggestedAddress}
                  />
                </$SuggestionTitle>
                <div>
                  {suggestedAddress.firstName} {suggestedAddress.lastName}
                </div>
                {filter([
                  suggestedAddress.address,
                  suggestedAddress.additionalAddress,
                ]).join(', ')}
                <br />
                {suggestedAddress.postalCode} {suggestedAddress.city}
              </$AddressRow>
              <LinkButton
                type={BUTTON_TYPES.BUTTON}
                onClick={handleEditAddress}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.editAddress}
                />
              </LinkButton>
            </>
          }
        />
      ) : (
        <div>
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.addressNotFound}
            />
          </div>
          <div>
            <SafeFormattedMessageWithoutSpread
              message={messages.sureToContinue}
            />
          </div>
        </div>
      )}

      <RadioButtonHookForm
        isFluid
        value="false"
        name={CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS}
        register={register}
        label={
          <>
            <$AddressRow data-deliveryaddress-row>
              <$SuggestionTitle>
                <SafeFormattedMessageWithoutSpread
                  message={messages.deliveryAddress}
                />
              </$SuggestionTitle>
              <div>
                {firstName} {lastName}
              </div>
              {filter([address, additionalAddress]).join(', ')}
              <br />
              {postalCode} {city}
            </$AddressRow>
            <LinkButton type={BUTTON_TYPES.BUTTON} onClick={onClose}>
              <SafeFormattedMessageWithoutSpread
                message={messages.editAddress}
              />
            </LinkButton>
          </>
        }
      />
      <ModalFooter>
        <Button
          type={BUTTON_TYPES.BUTTON}
          dataTestId="nextstepsuggestionmodal"
          isLoading={isLoading}
          primary
          onClick={onSubmit}
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </ModalFooter>
    </>
  );
};

SuggestedAddressModalContent.displayName = 'SuggestedAddressModalContent';

export default SuggestedAddressModalContent;
