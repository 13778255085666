import React, {
  JSXElementConstructor,
  PropsWithChildren,
  ReactElement,
} from 'react';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../formatters';
import { CheckIcon } from '../../protons/icons';

import { getOrderedWizardSteps } from './Wizard.helpers';
import {
  $WizardHidingPart,
  $WizardIndex,
  $WizardItemList,
  $WizardList,
  $WizardTooltip,
} from './Wizard.styles';
import { WizardStep } from './Wizard.types';

interface WizardProps<Context, Step extends WizardStep<Context>> {
  config: Step[];
  context: Partial<Context>;
  activeStepName: string;
  CustomWizard?: JSXElementConstructor<{
    orderedStep: Step[];
    activeStepName: string;
  }>;
}

function Wizard<Context, Step extends WizardStep<Context>>({
  config,
  activeStepName,
  context,
  CustomWizard,
}: PropsWithChildren<WizardProps<Context, Step>>): ReactElement | null {
  const orderedStep = getOrderedWizardSteps<Context, Step>({
    config,
    context,
  });
  const isCompact = orderedStep.length > 3;
  let isAfterActive = false;

  if (orderedStep.length === 0) {
    return null;
  }

  if (CustomWizard) {
    return (
      <CustomWizard orderedStep={orderedStep} activeStepName={activeStepName} />
    );
  }

  return (
    <$WizardList>
      {orderedStep.map((item, index) => {
        const isActive = activeStepName === item.name;

        isAfterActive = isActive ? true : isAfterActive;

        if (!item.message) {
          throw new Error(
            `[Wizard] Item provided without message: ${JSON.stringify(item)}`,
          );
        }
        if (!item.name) {
          throw new Error(
            `[Wizard] Item provided without name: ${JSON.stringify(item)}`,
          );
        }

        return (
          <$WizardItemList
            key={item.name}
            $isActive={isActive}
            $isCompact={isCompact}
            $isSucceeded={!isAfterActive}
          >
            <$WizardTooltip
              title={safeFormattedIntlString(item.message)}
              disabled={isActive || !isCompact}
            >
              <$WizardIndex>{index + 1}</$WizardIndex>
              <CheckIcon color="#fff" size="16px" />
              <$WizardHidingPart
                $isActive={isActive}
                $isCompact={isCompact}
                $isSucceeded={!isAfterActive}
              >
                {SafeFormattedMessage(item.message)}
              </$WizardHidingPart>
            </$WizardTooltip>
          </$WizardItemList>
        );
      })}
    </$WizardList>
  );
}

Wizard.displayName = 'Wizard';

export default Wizard;
