import React from 'react';

import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { $HeaderContainer, $MenuContainer, $RightSide } from './Header.styles';
import Logo from './logo/Logo';

interface HeaderTestProps {
  sellerId: string;
}

const Header: React.FC<HeaderTestProps> = ({ sellerId }) => {
  return (
    <$HeaderContainer componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}>
      <$MenuContainer>
        <Logo sellerId={sellerId} />
        <$RightSide>
          <LanguageChooser sellerId={sellerId} />
        </$RightSide>
      </$MenuContainer>
    </$HeaderContainer>
  );
};

Header.displayName = 'Header';

export default Header;
