import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

import { RECEIPT_SEARCH_FIELDS } from './ReceiptSearch.types';

const ReceiptSearchSchema = Yup.object().shape({
  [RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID]: Yup.string().required(
    globalMessages.form.required,
  ),
});

export default ReceiptSearchSchema;
