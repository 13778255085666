import { OptionsOrGroups } from 'react-select';
import {
  GroupBase,
  MultiValue,
  SingleValue,
} from 'react-select/dist/declarations/src/types';

import { isOptionType } from '@savgroup-front-common/types';

const isGroupedOption = <Group>(
  objectToBeDetermined: any,
): objectToBeDetermined is Group => {
  return (
    Array.isArray(objectToBeDetermined) && 'options' in objectToBeDetermined
  );
};

const getOption = <Option, Group extends GroupBase<Option>>(
  options: OptionsOrGroups<Option, Group>,
  value: string | boolean,
): SingleValue<Option> => {
  if (Array.isArray(options)) {
    const filteredOptions = options.reduce<Option[]>((acc, option) => {
      if (isGroupedOption<Group>(option)) {
        return [...acc, ...option.options];
      }

      return [...acc, option];
    }, []);

    return (
      filteredOptions.find((item) => {
        if (item && isOptionType(item)) {
          return item.value === value;
        }

        return false;
      }) || null
    );
  }

  return null;
};

const getSingleValue = <
  Option extends { isNew?: boolean; value: string | boolean },
  Group extends GroupBase<Option>,
>(
  value: Option | string,
  options: OptionsOrGroups<Option, Group>,
): Option | null => {
  if (typeof value === 'string') {
    return getOption(options, value);
  }

  if (value && value.isNew) {
    return value;
  }

  if (value && value.value) {
    return getOption(options, value.value);
  }

  return value;
};

export const getSelectedOption = <
  Option extends { isNew?: boolean; value: string | boolean },
  Group extends GroupBase<Option>,
>(
  value: MultiValue<Option> | SingleValue<Option>,
  options: OptionsOrGroups<Option, Group>,
): MultiValue<Option> | SingleValue<Option> | null => {
  if (!value) {
    return null;
  }

  if (value && Array.isArray(value)) {
    const selectedOptions = value
      .map((v) => getSingleValue(v, options))
      .filter((option) => !!option);

    return selectedOptions as MultiValue<Option>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return getSingleValue(value, options);
};

export const sortOptions = <Option extends { isNew?: boolean; label?: string }>(
  options: readonly Option[],
): Option[] =>
  options.slice().sort((a: Option, b: Option) => {
    if (a.label === undefined || b.label === undefined) {
      return 0;
    }

    if (a.label > b.label) {
      return 1;
    }

    if (b.label > a.label) {
      return -1;
    }

    return 0;
  });
