import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/configuration/acceptedLanguages`;

interface GetSellerAcceptedLanguagesArgs {
  sellerId: string;
}

const getSellerAcceptedLanguages = async ({
  sellerId,
}: GetSellerAcceptedLanguagesArgs): Promise<
  BackResponse<string[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<BackResponse<string[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellerAcceptedLanguages;
