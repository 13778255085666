import { defineMessages } from 'react-intl';

export default defineMessages({
  address: {
    id: 'modal.editAddress.suggestion.radio.description.address',
    defaultMessage: 'Address',
  },
  modifyTitle: {
    id: 'modal.editAddress.suggestion.radio.description.modifyTitle',
    defaultMessage: 'Modify this choice before submitting',
  },
  modifyButton: {
    id: 'modal.editAddress.suggestion.radio.description.modifyButton',
    defaultMessage: 'Modify',
  },
});
