import React, { FunctionComponent } from 'react';

import { CrossIcon } from '../../../../protons/icons';

import { $ToastCloseHover, $ToastProgress } from './ToastClose.styles';

export interface ToastCloseProps {
  animationDuration: number;
}

const ToastClose: FunctionComponent<ToastCloseProps> = ({
  animationDuration,
}) => (
  <$ToastCloseHover>
    <CrossIcon />
    <$ToastProgress animationDuration={animationDuration} />
  </$ToastCloseHover>
);

ToastClose.displayName = 'CloseButton';

export default ToastClose;
