import { defineMessages } from 'react-intl';

export default defineMessages({
  lastName: {
    id: 'authenticator.onboarding.form.lastNameLabel',
    defaultMessage: 'Last name',
  },
  firstName: {
    id: 'authenticator.onboarding.form.firstNameLabel',
    defaultMessage: 'First name',
  },
  companyName: {
    id: 'authenticator.onboarding.form.companyNameLabel',
    defaultMessage: 'Company name',
  },
  email: {
    id: 'authenticator.onboarding.form.emailLabel',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'authenticator.onboarding.form.phoneLabel',
    defaultMessage: 'Phone',
  },
  jobTitle: {
    id: 'authenticator.onboarding.form.jobTitleLabel',
    defaultMessage: 'Job title',
  },
  country: {
    id: 'authenticator.onboarding.form.country',
    defaultMessage: 'Country',
  },
  trialStart: {
    id: 'authenticator.onboarding.form.trialStart',
    defaultMessage: 'I start my trial',
  },

  generalManagement: {
    id: 'authenticator.onboarding.form.jobTitle.GeneralManagement',
    defaultMessage: 'General Management',
  },
  customerService: {
    id: 'authenticator.onboarding.form.jobTitle.CustomerService',
    defaultMessage: 'Customer Service',
  },
  afterSalesService: {
    id: 'authenticator.onboarding.form.jobTitle.AfteSalesService',
    defaultMessage: 'After Sales Service',
  },
  logistics: {
    id: 'authenticator.onboarding.form.jobTitle.Logistics',
    defaultMessage: 'Logistics',
  },
  supplyChain: {
    id: 'authenticator.onboarding.form.jobTitle.supplyChain',
    defaultMessage: 'supplyChain',
  },
  digitalTransformation: {
    id: 'authenticator.onboarding.form.jobTitle.digitalTransformation',
    defaultMessage: 'Digital Transformation',
  },
  it: {
    id: 'authenticator.onboarding.form.jobTitle.iT',
    defaultMessage: 'It',
  },
  finance: {
    id: 'authenticator.onboarding.form.jobTitle.finance',
    defaultMessage: 'Finance',
  },
  purchasing: {
    id: 'authenticator.onboarding.form.jobTitle.purchasing',
    defaultMessage: 'Purchasing',
  },
  other: {
    id: 'authenticator.onboarding.form.jobTitle.other',
    defaultMessage: 'Other',
  },

  accoundCreationSuccess: {
    id: 'authenticator.onboarding.form.accoundCreationSuccess',
    defaultMessage: 'Account created successfully',
  },
  accoundCreationError: {
    id: 'authenticator.onboarding.form.accoundCreationError',
    defaultMessage: 'Account creation failed',
  },
  cgvRequired: {
    id: 'authenticator.onboarding.form.cgvRequired',
    defaultMessage: 'You must accept the general conditions of use',
  },
  invalidEmail: {
    id: 'authenticator.onboarding.form.invalidEmail',
    defaultMessage: 'This email is invalid, please use an other one',
  },
});
