import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { rem, styledGetCustomComponentProperty } from '../../helpers';

import { FIELD_ALIGN } from './Field.types';

const transitionMixin = css`
  transition-duration: 300ms;
  ${decelerateTimingFunctionTransition};
`;

export const $FieldLabelNewUi = styled.div<{ $align: FIELD_ALIGN }>`
  color: ${({ theme }) => theme.newUI.defaultColors.secondaryText};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: ${({ theme }) => theme.fonts.size.large};
  font-weight: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_LABEL,
    property: SUPPORTED_PROPERTIES.FONT_WEIGHT,
  })};

  display: flex;
  align-items: start;
  justify-content: ${({ $align }) =>
    $align === FIELD_ALIGN.RIGHT ? 'flex-end' : null};
  > div {
    height: 100%;
  }
  svg {
    height: ${({ theme }) => theme.fonts.size.ultraSmall};
    max-width: ${({ theme }) => theme.fonts.size.ultraSmall};
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.paragraphTextColor};
  }

  & * {
    text-transform: lowercase;
  }
  & *::first-letter {
    text-transform: uppercase;
  }

  &:after {
    content: ':';
    margin: 0 4px;
  }
`;

export const $FieldLabel = styled.div<{ $align: FIELD_ALIGN }>`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  width: inherit;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: ${({ $align }) =>
    $align === FIELD_ALIGN.RIGHT ? 'flex-end' : null};
  > div {
    height: 100%;
  }
  svg {
    height: ${({ theme }) => theme.fonts.size.ultraSmall};
    max-width: ${({ theme }) => theme.fonts.size.ultraSmall};
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.paragraphTextColor};
  }
`;

const copyMixin = css`
  position: relative;
  border-radius: ${({ theme }) => theme.borders.radius};

  background: ${rgba('#ddd', 0)};
  svg {
    opacity: 0;
    margin-left: ${rem(8)};
    &:first-child {
      margin-left: 0;
    }
    ${transitionMixin};
    transition-duration: 500ms;
    min-width: ${rem(12)};
    transform: scale(1);
  }

  &:hover {
    svg {
      opacity: 1;
    }
    svg:hover {
      transition-duration: 100ms;
      transform: scale(1.3);
    }

    background: ${rgba('#ddd', 1)};
  }
  &:active {
    background: ${rgba('#ccc', 1)};
    user-select: none;
    svg:active {
      transition-duration: 100ms;
      transform: scale(1.6);
    }
  }
`;

export const $FieldValueContainer = styled.div<{
  $align: FIELD_ALIGN;
  $isError?: boolean;
  $isSuccess?: boolean;
  $isFluid?: boolean;
}>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === FIELD_ALIGN.RIGHT ? 'flex-end' : null};
  border-bottom: ${({ theme, $isError, $isSuccess }) => {
    if ($isError) {
      return `3px solid ${rgba(theme.colors.danger, 0.4)}`;
    }
    if ($isSuccess) {
      return `3px solid ${rgba(theme.colors.success, 0.3)}`;
    }

    return null;
  }};
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'auto')};
`;
export const $FieldValue = styled.div<{
  $hasCopy: boolean;
  hasMaxWidth: boolean;
  $noMargin: boolean;
  $isEllipsisEnabled: boolean;
  $isError?: boolean;
  $isSuccess?: boolean;
  $isNewUi?: boolean;
  $isFluid?: boolean;
}>`
  color: ${({ theme, $isError, $isSuccess, $isNewUi }) => {
    if ($isError) {
      return theme.colors.danger;
    }
    if ($isSuccess) {
      return theme.colors.success;
    }
    if ($isNewUi) {
      return theme.newUI.defaultColors.text;
    }

    return theme.colors.mainTextColor;
  }};
  font-size: ${({ theme }) => theme.fonts.size.small};
  line-height: ${({ theme }) => theme.fonts.size.medium};
  font-weight: ${({ theme, $isError, $isSuccess }) =>
    $isError || $isSuccess
      ? theme.fonts.weight.bold
      : theme.fonts.weight.normal};
  ${({ $isEllipsisEnabled, $isFluid }) => {
    return $isEllipsisEnabled
      ? `
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
  `
      : `
          white-space: pre-line;
          width: ${$isFluid ? '100%' : 'fit-content'}; `;
  }};

  display: flex;
  align-items: center;

  max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '100%' : 'none')};

  ${transitionMixin};
  padding: ${({ $isNewUi }) =>
    $isNewUi ? `${rem(2)} ${rem(3)}` : `${rem(3)} ${rem(3)}`};
  margin: ${({ $isNewUi }) => ($isNewUi ? '0 5px' : undefined)};
  transform: translateX(-3px);
  ${({ $hasCopy }) => ($hasCopy ? copyMixin : null)};
  &:not(:last-child) {
    margin-bottom: ${({ $noMargin }) => ($noMargin ? 'unset' : '1rem')};
  }
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'auto')};
`;
export const $FieldContainer = styled.div<{
  $noMargin: boolean;
  $isNewUi: boolean;
  $isFluid?: boolean;
}>`
  & ${$FieldValue} ${$FieldValue} {
    padding: ${({ $isNewUi }) => ($isNewUi ? '0 5px' : undefined)};
    margin: ${({ $isNewUi }) => ($isNewUi ? '0 5px' : undefined)};
  }
  &:not(:last-child) {
    margin-bottom: ${({ $noMargin, $isNewUi }) => {
      if ($isNewUi) {
        return $noMargin ? 'unset' : rem(8);
      }

      return $noMargin ? 'unset' : '1rem';
    }};
  }

  display: ${({ $isNewUi }) => ($isNewUi ? 'flex' : 'block')};
  align-items: ${({ $isNewUi }) => ($isNewUi ? 'baseline' : null)};
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'auto')};
`;

export const $MaxWidthDiv = styled.div<{
  hasMaxWidth: boolean;
  $isFluid?: boolean;
}>`
  max-width: ${({ hasMaxWidth }) => (hasMaxWidth ? '100%' : 'none')};
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'auto')};
`;

export const $Icon = styled.div`
  margin-left: 5px;
  cursor: pointer;
`;

export const $LabelContainer = styled.div<{ $align: FIELD_ALIGN }>`
  ${({ $align }) => {
    if ($align === FIELD_ALIGN.LEFT) {
      return 'margin-right: 1rem';
    }

    return 'margin-left: 1rem';
  }};
`;

export const $SpannedButton = styled.button`
  display: flex;
  justify-content: space-between;
  height: inherit;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  white-space: nowrap;
`;
