import React, { FC, useCallback, useRef, useState } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { createGenericContext } from '../../helpers';

import Confirmation from './Confirmation';
import { createPromise } from './ConfirmationContextProvider.helpers';

export interface ConfirmationContextArgs {
  isOpen: boolean;
  resolver?: {
    resolve?: (value: boolean) => void;
  };
  title?: MessageType;
  didactic?: MessageType;
  getConfirmation: ({
    title,
    didactic,
    children,
  }: {
    title: MessageType;
    didactic: MessageType;
    children?: React.ReactNode;
  }) => Promise<boolean>;
  refConfirmationModal: React.RefObject<HTMLDivElement>;
}

const [useConfirmationContext, ConfirmationContext] =
  createGenericContext<ConfirmationContextArgs>();

const ConfirmationContextProvider: FC<React.PropsWithChildren<any>> =
  React.memo(({ children }) => {
    const refConfirmationModal = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [resolver, setResolver] = useState<{
      resolve?: (value: boolean) => void;
    }>({});
    const [title, setTitle] = useState<MessageType>();
    const [didactic, setDidactic] = useState<MessageType>();
    const [confirmationChildren, setConfirmationChildren] =
      useState<React.ReactNode>();

    const getConfirmation = useCallback(
      async ({
        title,
        didactic,
        children,
      }: {
        title: MessageType;
        didactic: MessageType;
        children?: React.ReactNode;
      }) => {
        setTitle(title);
        setDidactic(didactic);
        setIsOpen(true);
        setConfirmationChildren(children);

        const { promise, resolve } = await createPromise();

        setResolver({ resolve });

        return promise;
      },
      [],
    );

    const handleClick = async (status: boolean) => {
      setIsOpen(false);
      setTitle(undefined);
      setDidactic(undefined);
      setConfirmationChildren(undefined);

      if (resolver?.resolve) {
        resolver.resolve(status);
      }
    };

    return (
      <ConfirmationContext
        value={{
          isOpen,
          title,
          didactic,
          resolver,
          getConfirmation,
          refConfirmationModal,
        }}
      >
        <Confirmation onClick={handleClick} ref={refConfirmationModal}>
          {confirmationChildren}
        </Confirmation>
        {children}
      </ConfirmationContext>
    );
  });

ConfirmationContextProvider.displayName = 'ConfirmationContextProvider';

export { ConfirmationContextProvider, useConfirmationContext };
