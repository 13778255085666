import React, { FunctionComponent } from 'react';

import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import OrderInformationsContent from './OrderInformationsContent';

const OrderInformations: FunctionComponent = () => {
  return (
    <BannersProvider>
      <OrderInformationsContent />
    </BannersProvider>
  );
};

OrderInformations.displayName = 'OrderInformations';

export default OrderInformations;
