import * as Yup from 'yup';

import messages from './messages';
import { ORDER_NUMBER_FORM_FIELDS } from './OrderNumberLogin.types';

const OrderNumberLoginSchema = Yup.object().shape({
  [ORDER_NUMBER_FORM_FIELDS.ORDER_NUMBER]: Yup.string().required(
    messages.orderNumberIsRequired,
  ),
  [ORDER_NUMBER_FORM_FIELDS.PHONE_NUMBER]: Yup.string()
    .required(messages.phoneNumberIsRequired)
    .matches(/^\d{4}$/, { message: messages.digitsOnlyAreAuthorized }),
});

export default OrderNumberLoginSchema;
