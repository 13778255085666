export const getAddress = (street?: string, streetNumber?: string): string => {
  if (street && streetNumber) {
    return `${streetNumber} ${street}`;
  }
  if (!streetNumber && street) {
    return street;
  }

  return '';
};
