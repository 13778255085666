const isDefault = (props: any): boolean => {
  return (
    !props.primary &&
    !props.secondary &&
    !props.success &&
    !props.danger &&
    !props.alert
  );
};

export default isDefault;
