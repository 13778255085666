import { useSelector } from 'react-redux';

import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';

const useCustomerAddressForm = () => {
  const countries = useSelector(countryList);

  return {
    countries,
  };
};

export default useCustomerAddressForm;
