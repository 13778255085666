import { EVENT_CODE, EVENT_KEY } from '@savgroup-front-common/constants';
import { KEYBOARD_LAYOUT } from '@savgroup-front-common/types';

import shortcutLabelMessages from '../helpers/i18n/messages/shortcutLabelMessages';

import { safeFormattedIntlString } from './safeFormattedIntlString';

const KeyboardKeyByKeyboardCode: Record<
  KEYBOARD_LAYOUT,
  Record<EVENT_CODE, any>
> = {
  [KEYBOARD_LAYOUT.QWERTZ]: {
    [EVENT_CODE.SLASH]: EVENT_KEY.SLASH,
    [EVENT_CODE.QUOTE]: EVENT_KEY.NUMBER_SIGN,
    [EVENT_CODE.PERIOD]: EVENT_KEY.PERIOD,
    [EVENT_CODE.SPACE]: shortcutLabelMessages.space,
    [EVENT_CODE.ENTER]: shortcutLabelMessages.enter,
    [EVENT_CODE.TAB]: shortcutLabelMessages.tab,
    [EVENT_CODE.META_LEFT]: shortcutLabelMessages.metaLeft,
    [EVENT_CODE.COMMA]: EVENT_KEY.COMMA,
    [EVENT_CODE.SEMICOLON]: EVENT_KEY.SEMICOLON,
    [EVENT_CODE.CONTROL_LEFT]: shortcutLabelMessages.controlLeft,
    [EVENT_CODE.SHIFT_LEFT]: shortcutLabelMessages.shiftLeft,
    [EVENT_CODE.ALT_LEFT]: shortcutLabelMessages.altLeft,
    [EVENT_CODE.ESCAPE]: shortcutLabelMessages.escape,
    [EVENT_CODE.ARROW_UP]: shortcutLabelMessages.arrowUp,
    [EVENT_CODE.ARROW_DOWN]: shortcutLabelMessages.arrowDown,
    [EVENT_CODE.ARROW_LEFT]: shortcutLabelMessages.arrowLeft,
    [EVENT_CODE.ARROW_RIGHT]: shortcutLabelMessages.arrowRight,
    [EVENT_CODE.DIGIT_1]: EVENT_KEY.DIGIT_1,
    [EVENT_CODE.DIGIT_2]: EVENT_KEY.DIGIT_2,
    [EVENT_CODE.DIGIT_3]: EVENT_KEY.DIGIT_3,
    [EVENT_CODE.DIGIT_4]: EVENT_KEY.DIGIT_4,
    [EVENT_CODE.DIGIT_5]: EVENT_KEY.DIGIT_5,
    [EVENT_CODE.DIGIT_6]: EVENT_KEY.DIGIT_6,
    [EVENT_CODE.DIGIT_7]: EVENT_KEY.DIGIT_7,
    [EVENT_CODE.DIGIT_8]: EVENT_KEY.DIGIT_8,
    [EVENT_CODE.DIGIT_9]: EVENT_KEY.DIGIT_9,
    [EVENT_CODE.KEY_Q]: EVENT_KEY.Q,
    [EVENT_CODE.KEY_W]: EVENT_KEY.W,
    [EVENT_CODE.KEY_E]: EVENT_KEY.E,
    [EVENT_CODE.KEY_R]: EVENT_KEY.R,
    [EVENT_CODE.KEY_T]: EVENT_KEY.T,
    [EVENT_CODE.KEY_Y]: EVENT_KEY.Z,
  },
  [KEYBOARD_LAYOUT.QWERTY]: {
    [EVENT_CODE.SLASH]: EVENT_KEY.SLASH,
    [EVENT_CODE.QUOTE]: EVENT_KEY.QUOTE,
    [EVENT_CODE.PERIOD]: EVENT_KEY.PERIOD,
    [EVENT_CODE.SPACE]: shortcutLabelMessages.space,
    [EVENT_CODE.ENTER]: shortcutLabelMessages.enter,
    [EVENT_CODE.TAB]: shortcutLabelMessages.tab,
    [EVENT_CODE.META_LEFT]: shortcutLabelMessages.metaLeft,
    [EVENT_CODE.COMMA]: EVENT_KEY.COMMA,
    [EVENT_CODE.SEMICOLON]: EVENT_KEY.SEMICOLON,
    [EVENT_CODE.CONTROL_LEFT]: shortcutLabelMessages.controlLeft,
    [EVENT_CODE.SHIFT_LEFT]: shortcutLabelMessages.shiftLeft,
    [EVENT_CODE.ALT_LEFT]: shortcutLabelMessages.altLeft,
    [EVENT_CODE.ESCAPE]: shortcutLabelMessages.escape,
    [EVENT_CODE.ARROW_UP]: shortcutLabelMessages.arrowUp,
    [EVENT_CODE.ARROW_DOWN]: shortcutLabelMessages.arrowDown,
    [EVENT_CODE.ARROW_LEFT]: shortcutLabelMessages.arrowLeft,
    [EVENT_CODE.ARROW_RIGHT]: shortcutLabelMessages.arrowRight,
    [EVENT_CODE.DIGIT_1]: EVENT_KEY.DIGIT_1,
    [EVENT_CODE.DIGIT_2]: EVENT_KEY.DIGIT_2,
    [EVENT_CODE.DIGIT_3]: EVENT_KEY.DIGIT_3,
    [EVENT_CODE.DIGIT_4]: EVENT_KEY.DIGIT_4,
    [EVENT_CODE.DIGIT_5]: EVENT_KEY.DIGIT_5,
    [EVENT_CODE.DIGIT_6]: EVENT_KEY.DIGIT_6,
    [EVENT_CODE.DIGIT_7]: EVENT_KEY.DIGIT_7,
    [EVENT_CODE.DIGIT_8]: EVENT_KEY.DIGIT_8,
    [EVENT_CODE.DIGIT_9]: EVENT_KEY.DIGIT_9,
    [EVENT_CODE.KEY_Q]: EVENT_KEY.Q,
    [EVENT_CODE.KEY_W]: EVENT_KEY.W,
    [EVENT_CODE.KEY_E]: EVENT_KEY.E,
    [EVENT_CODE.KEY_R]: EVENT_KEY.R,
    [EVENT_CODE.KEY_T]: EVENT_KEY.T,
    [EVENT_CODE.KEY_Y]: EVENT_KEY.Y,
  },
  [KEYBOARD_LAYOUT.AZERTY]: {
    [EVENT_CODE.SLASH]: EVENT_KEY.EQUAL,
    [EVENT_CODE.QUOTE]: EVENT_KEY.U_GRAVE_ACCENT,
    [EVENT_CODE.PERIOD]: EVENT_KEY.COLONS,
    [EVENT_CODE.SPACE]: shortcutLabelMessages.space,
    [EVENT_CODE.ENTER]: shortcutLabelMessages.enter,
    [EVENT_CODE.TAB]: shortcutLabelMessages.tab,
    [EVENT_CODE.META_LEFT]: shortcutLabelMessages.metaLeft,
    [EVENT_CODE.COMMA]: EVENT_KEY.SEMICOLON,
    [EVENT_CODE.SEMICOLON]: EVENT_KEY.M,
    [EVENT_CODE.CONTROL_LEFT]: shortcutLabelMessages.controlLeft,
    [EVENT_CODE.SHIFT_LEFT]: shortcutLabelMessages.shiftLeft,
    [EVENT_CODE.ALT_LEFT]: shortcutLabelMessages.altLeft,
    [EVENT_CODE.ESCAPE]: shortcutLabelMessages.escape,
    [EVENT_CODE.ARROW_UP]: shortcutLabelMessages.arrowUp,
    [EVENT_CODE.ARROW_DOWN]: shortcutLabelMessages.arrowDown,
    [EVENT_CODE.ARROW_LEFT]: shortcutLabelMessages.arrowLeft,
    [EVENT_CODE.ARROW_RIGHT]: shortcutLabelMessages.arrowRight,
    [EVENT_CODE.DIGIT_1]: EVENT_KEY.AMPERSAND,
    [EVENT_CODE.DIGIT_2]: EVENT_KEY.E_ACUTE_ACCENT,
    [EVENT_CODE.DIGIT_3]: EVENT_KEY.DOUBLE_QUOTE,
    [EVENT_CODE.DIGIT_4]: EVENT_KEY.QUOTE,
    [EVENT_CODE.DIGIT_5]: EVENT_KEY.OPENING_PARENTHESIS,
    [EVENT_CODE.DIGIT_6]: EVENT_KEY.SECTION_SIGN,
    [EVENT_CODE.DIGIT_7]: EVENT_KEY.E_GRAVE_ACCENT,
    [EVENT_CODE.DIGIT_8]: EVENT_KEY.EXCLAMATION_POINT,
    [EVENT_CODE.DIGIT_9]: EVENT_KEY.C_CEDILLA,
    [EVENT_CODE.KEY_Q]: EVENT_KEY.Q,
    [EVENT_CODE.KEY_W]: EVENT_KEY.W,
    [EVENT_CODE.KEY_E]: EVENT_KEY.E,
    [EVENT_CODE.KEY_R]: EVENT_KEY.R,
    [EVENT_CODE.KEY_T]: EVENT_KEY.T,
    [EVENT_CODE.KEY_Y]: EVENT_KEY.Y,
  },
};

const getShortcutLabel = (
  shortcut: EVENT_CODE,
  layout: KEYBOARD_LAYOUT,
): ReturnType<typeof safeFormattedIntlString> => {
  const message = KeyboardKeyByKeyboardCode[layout][shortcut];

  return safeFormattedIntlString(message);
};

export default getShortcutLabel;
