import { NavArrowLeft, NavArrowRight } from 'iconoir-react';
import React, { useCallback, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import ActivitiesCardSelector from '../../ActivitiesCardSelector/ActivitiesCardSelector';

import { useGetCustomersActivity } from './CustomersPage.hooks';
import { $Footer, $MainTitle, $Separator } from './CustomersPage.styles';
import messages from './messages';

const CustomersPage: React.FC = () => {
  const activities = useGetCustomersActivity();
  const [selectedCustomers, setSelectedCustomers] = useState<
    Record<string, string[]>
  >({ customers: [], sales: [] });
  const { nextStep, previousStep } = useStepOrchestratorContext();

  const handleSelectedCustomers = useCallback(
    (customer: string, name?: string) => {
      if (!name) {
        return;
      }
      if (selectedCustomers[name].includes(customer)) {
        setSelectedCustomers({
          ...selectedCustomers,
          [name]: selectedCustomers[name].filter((c) => c !== customer),
        });

        return;
      }
      setSelectedCustomers({ ...selectedCustomers, [name]: [customer] });
    },
    [selectedCustomers],
  );

  return (
    <>
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread message={messages.customersTitle} />
      </$MainTitle>
      <ActivitiesCardSelector
        activities={activities.customers}
        rowSize={3}
        handleSelectedActivities={handleSelectedCustomers}
        selectedActivities={selectedCustomers.customers}
        name="customers"
      />
      <$Separator />
      <$MainTitle>
        <SafeFormattedMessageWithoutSpread message={messages.salesTitle} />
      </$MainTitle>
      <ActivitiesCardSelector
        activities={activities.sales}
        rowSize={3}
        handleSelectedActivities={handleSelectedCustomers}
        selectedActivities={selectedCustomers.sales}
        name="sales"
      />
      <$Footer>
        <Button
          isNewUi
          secondary
          type={BUTTON_TYPES.BUTTON}
          icon={<NavArrowLeft width="18px" height="18px" />}
          onClick={previousStep}
        >
          <SafeFormattedMessageWithoutSpread message={messages.previous} />
        </Button>
        <Button
          primary
          isNewUi
          type={BUTTON_TYPES.BUTTON}
          onClick={() => nextStep({ selectedCustomers })}
          icon={<NavArrowRight width="18px" height="18px" />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={
            !selectedCustomers.customers.length ||
            !selectedCustomers.sales.length
          }
        >
          <SafeFormattedMessageWithoutSpread message={messages.next} />
        </Button>
      </$Footer>
    </>
  );
};

CustomersPage.displayName = 'CustomersPage';

export default CustomersPage;
