import { CARRIER_PRODUCT_TYPE } from '../control';

import HANDLING_MODES from './handlingModes';

export const CARRIER_TYPES = {
  DEPOSIT_HOME_DELIVERY: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.HOME_DELIVERY}`,
  DEPOSIT_IMMEDIATE_DROP_AT_STORE: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.IMMEDIATE_DROP_AT_STORE}`,
  DEPOSIT_PICKUP: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.PICKUP}`,
  DEPOSIT_PICKUP_POST_OFFICE: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.PICKUP_POST_OFFICE}`,
  DEPOSIT_SELLER_PROVIDED_CARRIER: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.SELLER_PROVIDER_CARRIER}`,
  DEPOSIT_DROP_AT_STORE: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.DROP_AT_STORE}`,
  DEPOSIT_HOME_INTERVENTION: `${HANDLING_MODES.DEPOSIT}_${CARRIER_PRODUCT_TYPE.HOME_INTERVENTION}`,

  DELIVERY_HOME_DELIVERY: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.HOME_DELIVERY}`,
  DELIVERY_IMMEDIATE_DROP_AT_STORE: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.IMMEDIATE_DROP_AT_STORE}`,
  DELIVERY_PICKUP: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.PICKUP}`,
  DELIVERY_SELLER_PROVIDED_CARRIER: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.SELLER_PROVIDER_CARRIER}`,
  DELIVERY_PICKUP_COUNTER: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.PICKUP_COUNTER}`,
  DELIVERY_PICKUP_STORE_DELIVERY: `${HANDLING_MODES.DELIVERY}_${CARRIER_PRODUCT_TYPE.PICKUP_STORE_DELIVERY}`,
};

export const ADDRESS_VERIFY_FAILURE = 'ADDRESS.VERIFY.FAILURE'
