import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import apiCall from '../../services/apiCall';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/states`;

export interface FileStateHistoryModuleSummary {
  id?: string;
  definitionId?: string;
  wave: number;
  parameters?: {
    [key: string]: string;
  };
  referencedModuleType?: string;
}

export interface StateSummary {
  name?: string;
  shortLabel?: string;
  label?: string;
}

export interface FileStateHistorySummary {
  fileId: string;
  module: FileStateHistoryModuleSummary;
  stateDate: Date;
  state: StateSummary;
  comment?: string;
  toCustomerComment?: string;
  duration?: Date;
  responsible: {
    userId: string;
    fullname?: string;
  };
}

type GetFileStatesQuery = ({
  fileId,
}: {
  fileId: string;
}) => Promise<
  BackResponse<FileStateHistorySummary[] | undefined> | BackResponseFailure
>;

export const getFileStatesQuery: GetFileStatesQuery = async ({ fileId }) => {
  try {
    return await apiCall<BackResponse<FileStateHistorySummary[] | undefined>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
