import React, { FunctionComponent } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { InputStatus } from '../helpers/getFinalFieldState.types';

import { $InputDepiction } from './Depiction.styles';
import DepictionIco from './DepictionIco';

const animationDuration = 250;

interface DepictionProps {
  status?: InputStatus;
  name?: string;
  isSmall?: boolean;
}

const Depiction: FunctionComponent<React.PropsWithChildren<DepictionProps>> = ({
  status,
  name,
  isSmall = false,
}) => {
  return (
    <$InputDepiction $show={status !== null} $isSmall={isSmall}>
      <TransitionGroup component={null}>
        <Transition key={status} timeout={animationDuration}>
          {(animationState) => (
            <DepictionIco
              name={name}
              status={status}
              animationState={animationState}
            />
          )}
        </Transition>
      </TransitionGroup>
    </$InputDepiction>
  );
};

Depiction.displayName = 'Depiction';

export default Depiction;
