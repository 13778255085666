import { Theme } from '../Theme';

/**
 * @deprecated: No Need to use this function, need just be sure that theme is well injected
 * (if styled it's done automatically)
 * @param props
 * @returns {*}
 */
function getThemeFromProps(props = {}) {
  const { theme } = props;

  return theme && Object.keys(theme).length > 0 ? theme : Theme();
}

export default getThemeFromProps;
