export class GotAnErrorFromEndpoint extends Error {
  public errors: unknown;

  constructor(concernedEndpoint?: string, errors?: unknown) {
    const predefinedMessage = `Got and error for interaction with ${concernedEndpoint}.`;

    super(predefinedMessage);
    this.name = 'GotAnErrorFromEndpoint';

    this.errors = errors;
  }
}
