import { defineMessages } from 'react-intl';

export default defineMessages({
  customersTitle: {
    id: 'authenticator.onboarding.customers.customersTitle',
    defaultMessage: 'Who are your customers?',
  },
  salesTitle: {
    id: 'authenticator.onboarding.customers.salesTitle',
    defaultMessage: 'What are your sales channels?',
  },
  next: {
    id: 'authenticator.onboarding.button.next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'authenticator.onboarding.button.goBack',
    defaultMessage: 'Back to previous step',
  },
  enterprises: {
    id: 'authenticator.onboarding.customers.enterprises',
    defaultMessage: 'Enterprises',
  },
  private: {
    id: 'authenticator.onboarding.customers.private',
    defaultMessage: 'Private',
  },
  online: {
    id: 'authenticator.onboarding.sales.online',
    defaultMessage: 'Online',
  },
  store: {
    id: 'authenticator.onboarding.sales.store',
    defaultMessage: 'Store',
  },
  both: {
    id: 'authenticator.onboarding.customers.both',
    defaultMessage: 'Both',
  },
});
