import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { Grid } from '../../../../atoms';
import { ClientLogo } from '../../../../molecules';

import {
  $HeaderContainer,
  $RightSide,
  MenuContainer$,
} from './AppHeader.styles';

const AppHeader: FunctionComponent = () => {
  return (
    <$HeaderContainer componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}>
      <Grid>
        <MenuContainer$>
          <ClientLogo />
          <$RightSide>
            <Row style={{ flexWrap: 'nowrap' }}>
              <Col>
                <LanguageChooser />
              </Col>
            </Row>
          </$RightSide>
        </MenuContainer$>
      </Grid>
    </$HeaderContainer>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
