import React, { ChangeEvent, FunctionComponent, ReactNode, Ref } from 'react';

import {
  INPUT_TYPES,
  POSITIONS,
} from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { useIsNewUiEnabled } from '../../../../hooks/useIsNewUiEnabled';
import useScrollIntoView from '../../../../hooks/useScroll';
import { FieldMessages } from '../../common/helpers/getFinalFieldState.types';

import {
  $RadioDescription,
  $RadioInput,
  $RadioLabel,
  $RadioSpan,
} from './RadioButton.styles';

interface RadioNakedProps {
  disabled?: boolean;
  danger?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  type?: 'checkbox' | 'radio';
  name: string;
  value?: string;
  dataTestId?: string;
  isRequired?: boolean;
}

export interface RadioProps extends RadioNakedProps {
  label: MessageType | ReactNode;
  description?: string;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  isFluid?: boolean;

  disabled?: boolean;
  danger?: boolean;
  tabIndex?: number;

  selected?: boolean;
  isHighlighted?: boolean;
  isScrollIntoView?: boolean;
  onClick?: () => void;
  labelPosition?: POSITIONS;
  noRadioSpan?: boolean;
}
interface RadioPropsWithRef extends RadioProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const RadioButton: FunctionComponent<
  React.PropsWithChildren<RadioPropsWithRef>
> = ({
  name,
  tabIndex = 0,
  label = null,
  disabled = false,
  isFluid = false,
  dataTestId,
  forwardedRef,
  value,
  checked,
  isHighlighted = false,
  isScrollIntoView = false,
  onClick,
  description,
  noRadioSpan = false,
  isRequired = false,
  ...rest
}) => {
  const { scrollIntoView } = useScrollIntoView({
    ref: forwardedRef,
    isScrollIntoView,
  });
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$RadioLabel
      disabled={disabled}
      $isFluid={isFluid || isNewUiEnabled}
      $isChecked={checked}
      $isNewUiEnabled={isNewUiEnabled}
      data-testid={dataTestId}
      isRequired={isRequired}
    >
      <$RadioInput
        $isNewUiEnabled={isNewUiEnabled}
        $isChecked={checked}
        name={name}
        type={INPUT_TYPES.RADIO}
        tabIndex={tabIndex}
        ref={forwardedRef}
        value={value}
        checked={checked}
        isHighlighted={isHighlighted}
        disabled={disabled}
        onClick={onClick}
        onFocus={() => {
          if (isScrollIntoView) {
            scrollIntoView();
          }
        }}
        {...rest}
      />
      {!noRadioSpan && (
        <$RadioSpan
          disabled={disabled}
          $isChecked={checked}
          $hasDescription={!!description}
          $isNewUiEnabled={isNewUiEnabled}
        />
      )}
      {label ? SafeFormattedMessage(label) : undefined}
      {description && <$RadioDescription>{description}</$RadioDescription>}
    </$RadioLabel>
  );
};

RadioButton.displayName = 'RadioButton';

export default React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <RadioButton forwardedRef={ref} {...props} />
));
