import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import { getAnimationDuration } from '../../../../animations/helper';
import { rem } from '../../../../helpers';
import { decelerateTimingFunctionTransition } from '../../../../animations/timingFunction';

const rightSpin = keyframes`          
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }`;
const leftSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }`;
const close = keyframes`
  to {
    clip: rect(auto, auto, auto, auto);
  }
`;

interface ToastProgressProps {
  animationDuration: number;
}

export const $ToastProgress = styled.div<ToastProgressProps>`
  width: 30px;
  height: 30px;
  position: absolute;
  clip: rect(0px, 30px, 30px, 15px);
  animation-duration: 0.01s;
  animation-delay: ${({ animationDuration }) =>
    getAnimationDuration({
      animationDuration: animationDuration / 2,
    })};

  animation-name: ${close};
  opacity: 0.5;
  transition-duration: 0.5s;
  ${decelerateTimingFunctionTransition};

  &::before,
  &::after {
    content: '';
    width: 30px;
    height: 30px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;

    position: absolute;
    clip: rect(0px, 15px, 30px, 0px);
  }
  &,
  &::before,
  &::after {
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  &::before {
    animation-duration: ${getAnimationDuration};
    animation-name: ${leftSpin};
  }
  &::after {
    animation-duration: ${({ animationDuration }) =>
      getAnimationDuration({
        animationDuration: animationDuration / 2,
      })};
    animation-name: ${rightSpin};
  }
`;
export const $ToastCloseHover = styled.div`
  display: flex;
  position: absolute;
  background: ${({ theme }) => rgba(theme.colors.white, 0)};
  top: 50%;
  transform: translateY(-50%);
  right: ${rem(10)};
  width: ${rem(30)};
  height: ${rem(30)};
  border-radius: 50%;
  justify-content: center;
  ${decelerateTimingFunctionTransition};
  transition-duration: 0.5s;
  align-items: center;
  svg {
    min-width: 16px;
    height: auto;
  }
`;
