import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { BaseBackResponse } from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/bankaccountdetails`;

interface UpsertBankAccountDetailsPayload {
  fileId: string;
  accountHolder: string;
  bankIdentificationCode: string;
  iban: string;
  bankName: string;
}

type UpsertBankAccountDetails = (
  payload: UpsertBankAccountDetailsPayload,
) => Promise<BaseBackResponse>;

export const upsertBankAccountDetails: UpsertBankAccountDetails = async ({
  fileId,
  bankIdentificationCode,
  bankName,
  iban,
  accountHolder,
}) => {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.PUT,
        json: {
          bankIdentificationCode,
          bankName,
          iban,
          accountHolder,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
