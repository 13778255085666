import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { extendedRequest } from '../../services';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/${COMMON_QUERY_PARAMS.DOCUMENT_TYPE}/upload`;

interface DocumentInfo {
  name: string;
  originalFileName: string;
  extension: string;
  url: string;
  id: string;
}

export interface UploadSharedFileCommandArgs {
  file: File;
  claimIds: string[];
  claimGroupId: string;
  documentType: string;
  notify?: ({ progress }: { progress: number }) => void;
}

async function uploadSharedFileCommand({
  file,
  claimIds,
  claimGroupId,
  documentType,
  notify,
}: UploadSharedFileCommandArgs): Promise<
  BackResponse<DocumentInfo> | BackResponseFailure
> {
  try {
    const body = new FormData();

    body.append('file', file);
    body.append('claimIds', String(claimIds));

    const response = await extendedRequest<BackResponse<DocumentInfo>>(
      ENDPOINT.replace(
        COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
        claimGroupId,
      ).replace(COMMON_QUERY_PARAMS.DOCUMENT_TYPE, documentType),
      {
        method: SUPPORTED_METHODS.POST,
        body,
        notify,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default uploadSharedFileCommand;
