export enum COUNTRY_CODES {
  FR = 'FR',
  DE = 'DE',
  CN = 'CN',
  MA = 'MA',
  HK = 'HK',
  US = 'US',
  UK = 'UK',
  ES = 'ES',
  MY = 'MY',
  IN = 'IN',
  AE = 'AE',
  SG = 'SG',
  MG = 'MG',
  NL = 'NL',
  IT = 'IT',
  PT = 'PT',
}
