import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import AddressSuggestionSchema from '../AddressSuggestion.schema';
import {
  ADDRESS_SUGGESTION_FIELDS,
  ADDRESS_SUGGESTION_ORIGIN,
  AddressSuggestionFormValues,
} from '../AddressSuggestion.types';

const useAddressSuggestionForm = () => {
  const formContext = useForm<AddressSuggestionFormValues>({
    defaultValues: {
      [ADDRESS_SUGGESTION_FIELDS.ORIGIN]: ADDRESS_SUGGESTION_ORIGIN.SUGGESTION,
    },
    resolver: yupResolver(AddressSuggestionSchema),
  });

  return formContext;
};

export default useAddressSuggestionForm;
