import { useEffect } from 'react';

import { AuthConfiguration } from '../../../../configuration';

interface UseGetLoginErrorArgs {
  errorIdQuery?: string;
  setLoginError: (error: string) => void;
}

const useGetLoginError = ({
  errorIdQuery,
  setLoginError,
}: UseGetLoginErrorArgs): void => {
  useEffect(() => {
    const getError = async () => {
      const response = await fetch(
        `${AuthConfiguration.authority}/api/authenticate/error${errorIdQuery}`,
        {
          credentials: 'include',
        },
      );

      const error = await response.text();

      setLoginError(error);
    };

    if (errorIdQuery) {
      getError();
    }
  }, [errorIdQuery, setLoginError]);
};

export default useGetLoginError;
