import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

 
const ENDPOINT = `${APIConfiguration.carrier}carriers/products/${COMMON_QUERY_PARAMS.ID}`;

export interface CarrierProductSummary {
  id: string;
  carrierId: string;
  customerAccount?: string;
  productName?: string;
  carrierUniqueName?: string;
  handledTerritories?: string[];
  destinationTerritories?: string[];
  transportMethodId: string;
  maxVolume?: string;
  maxWeight: number;
  carrierService: string;
  carrierOperator: string;
  skipLabelCreation: boolean;
  cumbersome: boolean;
}

interface GetCarrierProductByIdQueryArgs {
  carrierProductId: string;
}

const getCarrierProductByIdQuery = async ({
  carrierProductId,
}: GetCarrierProductByIdQueryArgs): Promise<
  BackResponse<CarrierProductSummary> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ID, carrierProductId);

    const response = await apiCall<BackResponse<CarrierProductSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getCarrierProductByIdQuery;
