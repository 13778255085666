export enum FLEX_ALIGN {
  FLEX_START = 'fles-start',
  FLEX_END = 'flex-end',
  START = 'start',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly',
}

export enum FLEX_DIRECTION {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse',
}
