import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import {
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.carrier}homePickup/schedule/${COMMON_QUERY_PARAMS.COUNTRY_CODE}`;

export interface HomePickupPickupTimeRange {
  startTimeInLocalRecipientTimezone: string;
  endTimeInLocalRecipientTimezone: string;
}

interface GetHomePickupScheduleQueryPayload {
  carrier?: string;
  startTimeInLocalRecipientTimezone?: string;
  endTimeInLocalRecipientTimezone?: string;
  countryCode: COUNTRY_CODES;
  postalCode?: string;
  fileId: string;
}

const getHomePickupScheduleQuery = async ({
  carrier,
  startTimeInLocalRecipientTimezone,
  endTimeInLocalRecipientTimezone,
  countryCode,
  postalCode,
  fileId,
}: GetHomePickupScheduleQueryPayload): Promise<
  BackResponse<HomePickupPickupTimeRange[]> | BackResponseFailure
> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.COUNTRY_CODE, countryCode),
      {
        carrier,
        startTimeInLocalRecipientTimezone,
        endTimeInLocalRecipientTimezone,
        postalCode,
        fileId,
      },
    );

    const response = await apiCall<BackResponse<HomePickupPickupTimeRange[]>>(
      url,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getHomePickupScheduleQuery;
