import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import { CIVILITY } from '@savgroup-front-common/constants';
import {
  Autocomplete,
  FormGroup,
  PlacesAutocomplete,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  InputHookForm,
  RadioButtonHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { StepLayout } from '../../../../atoms';
import { CustomerInformationsFooter } from '../../components';

import { civilityOptions, filterOption } from './AddressInformations.helpers';
import { ADDRESS_INFORMATIONS_FIELDS as FIELDS } from './AddressInformations.types';
import {
  useHandleAddressInformationsSubmit,
  useHandleSelectAddress,
} from './hooks';
import useAddressInformationForm from './hooks/useAddressInformationForm';
import messages from './messages';

const AddressInformationsContent: FunctionComponent = () => {
  const formContext = useAddressInformationForm();

  const { handleAddressInformationsSubmit, isLoading, onPreviousStep } =
    useHandleAddressInformationsSubmit();

  const {
    formState: { errors },
    register,
    control,
    watch,
    handleSubmit,
  } = formContext;

  const watchedCountryCode = watch(FIELDS.COUNTRY_CODE);

  const { handleSelectAddress, countryOptions } = useHandleSelectAddress({
    formContext,
  });

  return (
    <form onSubmit={handleSubmit(handleAddressInformationsSubmit)}>
      <BannersConsumer />
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread
          message={messages.informationTitle}
        />
      </Heading>
      <Row>
        {civilityOptions.map((item) => (
          <Col key={item.value}>
            <FormGroup isOpen={item.value !== CIVILITY.NOT_SET}>
              <RadioButtonHookForm
                name={FIELDS.CIVILITY}
                key={item.value}
                value={item.value}
                label={item.label}
                register={register}
              />
            </FormGroup>
          </Col>
        ))}
      </Row>
      <StepLayout>
        <FormGroup>
          <InputHookForm
            label={messages.firstNameLabel}
            dataTestId="CustomerInformations-informations-firstName"
            name={FIELDS.FIRST_NAME}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            label={messages.lastNameLabel}
            dataTestId="CustomerInformations-informations-lastName"
            name={FIELDS.LAST_NAME}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            label={messages.companyNameLabel}
            dataTestId="CustomerInformations-informations-compagnyName"
            name={FIELDS.COMPANY_NAME}
            errors={errors}
            register={register}
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            label={messages.emailLabel}
            dataTestId="CustomerInformations-informations-email"
            name={FIELDS.EMAIL}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            label={messages.phoneLabel}
            dataTestId="CustomerInformations-informations-phoneNumber"
            name={FIELDS.PHONE_NUMBER}
            errors={errors}
            register={register}
            isRequired
          />
        </FormGroup>
      </StepLayout>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread
          message={messages.informationTitle}
        />
      </Heading>
      <StepLayout>
        <FormGroup>
          <Controller
            control={control}
            name={FIELDS.COUNTRY_CODE}
            render={({ field }) => (
              <Autocomplete
                onChange={(option) => field.onChange(option)}
                label={messages.countryLabel}
                filterOption={filterOption}
                dataTestId="CustomerInformations-informations-country"
                options={countryOptions}
                name={FIELDS.COUNTRY_CODE}
                ref={field.ref}
                errors={errors}
                value={field.value}
                isRequired
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <Controller
            name={FIELDS.ADDRESS}
            control={control}
            render={({ field }) => (
              <PlacesAutocomplete
                countryCode={watchedCountryCode?.value}
                label={messages.addressLabel}
                dataTestId="CustomerInformations-informations-address"
                name={FIELDS.ADDRESS}
                errors={errors}
                ref={field.ref}
                value={field.value}
                isRequired
                onSelect={handleSelectAddress}
                onChange={field.onChange}
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            dataTestId="CustomerInformations-informations-additionalAddress"
            name={FIELDS.ADDITIONAL_ADDRESS}
            errors={errors}
            label={messages.additionalAddressLabel}
            register={register}
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            dataTestId="CustomerInformations-informations-zipCode"
            name={FIELDS.POSTAL_CODE}
            errors={errors}
            label={messages.postalCodeLabel}
            isRequired
            register={register}
          />
        </FormGroup>

        <FormGroup>
          <InputHookForm
            dataTestId="CustomerInformations-informations-city"
            name={FIELDS.CITY}
            errors={errors}
            label={messages.cityLabel}
            isRequired
            register={register}
          />
        </FormGroup>
      </StepLayout>

      <CustomerInformationsFooter
        onPreviousStepClick={onPreviousStep}
        isLoading={isLoading}
        dataTestId="CustomerInformations-informations-submit"
        isError={Object.keys(errors).length > 0}
      />
    </form>
  );
};

AddressInformationsContent.displayName = 'AddressInformationsContent';

export default AddressInformationsContent;
