import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

import choicePartnerSchema from './ChoicePartnerStore.schema';
import { ChoicePartnerStoreValues } from './ChoicePartnerStore.types';

interface UseChoicePartnerArgs {
  values: CreateClaimValues;
}

const useChoicePartner = ({ values }: UseChoicePartnerArgs) => {
  const { clientInformation, isLoading: isGetClientInformationLoading } =
    useGetClientInformation();
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();

  const formContext = useForm<ChoicePartnerStoreValues>({
    resolver: yupResolver(choicePartnerSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      choicePartnerPurchaseDate:
        values.productReference?.purchaseDate || undefined,
      choicePartnerPurchaseStore: values?.productReference?.salesChannel
        ? {
            label: values?.productReference?.salesChannel?.name,
            value: values?.productReference?.salesChannel?.id,
          }
        : undefined,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formContext;

  const onSubmit = handleSubmit(
    async ({ choicePartnerPurchaseDate, choicePartnerPurchaseStore }) => {
      return nextStep({
        ...values,
        productReference: {
          ...values.productReference,
          purchaseDate: choicePartnerPurchaseDate,
          salesChannel: choicePartnerPurchaseStore?.data,
        },
      });
    },
  );

  const isLoading = isGetClientInformationLoading;

  return {
    formContext,
    onSubmit,
    errors,
    isLoading,
    partnerOptions: clientInformation?.activatedSellerPartners?.map((item) => {
      return {
        label: item.sellerName,
        value: item.sellerId,
      };
    }),
    partnerSelected: values.activatedSellerPartnersIdSelected,
    sellerType: clientInformation?.sellerType,
  };
};

export default useChoicePartner;
