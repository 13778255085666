import _ from 'lodash';

export function objectSubtract(originalObject, originalBase) {
  function changes(object, base) {
    const accumulator = {};

    return _.transform(
      object,
      (acc, value, key) => {
        if (base[key] === undefined) {
          acc[key] = value;
        } else if (!_.isEqual(value, base[key])) {
          acc[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      },
      accumulator,
    );
  }

  return changes(originalObject, originalBase);
}
