import { uniq } from 'lodash';
import get from 'lodash/get';
import { Action } from 'redux-actions';

import { Seller } from '@savgroup-front-common/types';

import * as types from './actionTypes';
import { SELLER_DOMAIN } from './constants';
import { SellersDomainState } from './types';

const initialState: SellersDomainState = {
  [SELLER_DOMAIN.VALUE]: {},
  [SELLER_DOMAIN.BY_TENANT_ID]: {},
  [SELLER_DOMAIN.SELLER_IDS]: [],
  [SELLER_DOMAIN.TENANT_IDS]: [],
};

export interface OnGetSellerSuccessPayload {
  value: Seller[];
}

function onGetSellerSuccess(
  state: SellersDomainState,
  { payload }: { payload: OnGetSellerSuccessPayload },
): SellersDomainState {
  const { value } = payload;
  const sellers = value;

  return {
    ...state,
    [SELLER_DOMAIN.VALUE]: sellers.reduce((acc, seller) => {
      return {
        ...acc,
        [seller.id]: seller,
      };
    }, state[SELLER_DOMAIN.VALUE]),
    [SELLER_DOMAIN.BY_TENANT_ID]: sellers.reduce((acc, seller) => {
      if (!seller?.tenantId) {
        return acc;
      }

      return {
        ...acc,
        [seller.tenantId]: uniq([...get(acc, seller.tenantId, []), seller.id]),
      };
    }, state[SELLER_DOMAIN.BY_TENANT_ID]),
    [SELLER_DOMAIN.SELLER_IDS]: uniq(
      sellers.reduce((acc, seller) => {
        if (!seller?.tenantId) {
          return acc;
        }

        return [...acc, seller.id];
      }, state[SELLER_DOMAIN.SELLER_IDS]),
    ),
    [SELLER_DOMAIN.TENANT_IDS]: uniq(
      sellers.reduce((acc, seller) => {
        if (seller?.tenantId) {
          return acc;
        }

        return [...acc, seller.id];
      }, state[SELLER_DOMAIN.TENANT_IDS]),
    ),
  };
}

export default function mainReducer(
  state: SellersDomainState = initialState,
  action: Action<OnGetSellerSuccessPayload>,
): SellersDomainState {
  switch (action.type) {
    case types.GET_SELLER.SUCCEEDED:
      return onGetSellerSuccess(state, action);

    default:
      return state;
  }
}
