 
import {
  css,
  CSSObject,
  FlattenSimpleInterpolation,
  SimpleInterpolation,
} from 'styled-components';

import GridTheme from '../gridTheme';

// Getting breakpoints from GridTheme
const sizes = GridTheme.flexboxgrid.breakpoints;

export type MediaValue = (
  first: TemplateStringsArray | CSSObject,
  ...args: SimpleInterpolation[]
) => FlattenSimpleInterpolation;

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce<{
  [key: string]: MediaValue;
}>((acc, label) => {
  acc[label] = (
    first: TemplateStringsArray | CSSObject,
    ...args: SimpleInterpolation[]
  ) => css`
    @media (min-width: ${sizes[label]}em) {
      ${css(first, ...args)}
    }
  `;

  return acc;
}, {});

export default media;
