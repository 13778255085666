import { defineMessages } from 'react-intl';

export default defineMessages({
  imgTitle: {
    id: 'authenticator.onboarding.imageTitle',
    defaultMessage: 'After sales new generation',
  },
  imgDescription: {
    id: 'authenticator.onboarding.imageDescription',
    defaultMessage:
      'Digitalize your after-sales service to increase your customer satisfaction and reduce your operational costs.',
  },
});
