import { safeFormattedIntlString } from '../formatters';

export const buildPageTitle = (titles = []) => {
  let cutNext = false;

  return titles
    .filter((title) => {
      if (!title || cutNext) {
        cutNext = true;

        return false;
      }

      return true;
    })
    .map(safeFormattedIntlString)
    .reverse()
    .join(' - ');
};
