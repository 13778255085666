import styled from 'styled-components';

import { Activity } from './ActivitiesCardSelector';

export const $ActivityCard = styled.div<{ $isActive: boolean }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.newUI.borders.bigRadius};
  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.newUI.defaultColors.primary
        : theme.newUI.variationColors?.light3};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.newUI.variationColors?.light6 : 'transparent'};
  cursor: pointer;
  position: relative;
`;

export const $CheckIconPosition = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const $Label = styled.div`
  margin-top: 8px;
`;

export const $LabelWithCheckbox = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const $LabelWithoutIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const $ActivityCardWithoutIcon = styled.div<{ $isActive: boolean }>`
  display: flex;
  padding: 16px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.newUI.borders.bigRadius};
  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive
        ? theme.newUI.defaultColors.primary
        : theme.newUI.variationColors?.light3};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.newUI.variationColors?.light6 : 'transparent'};
  cursor: pointer;
`;

export const $ActivitiesCardSelectorContainer = styled.div<{
  $rowSize: number;
  $activities: Activity[];
}>`
  margin-top: 28px;
  gap: 25px 24px;
  display: grid;
  grid-template-columns: ${({ $rowSize }) => `repeat(${$rowSize}, 1fr)`};
  grid-template-rows: ${({ $activities, $rowSize }) => {
    const rows = Math.ceil($activities.length / $rowSize);

    return `repeat(${rows}, 1fr)`;
  }};
`;
