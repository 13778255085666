import { defineMessages } from 'react-intl';

export default defineMessages({
  nextButton: {
    id: 'view.newForm.component.nextButton',
    defaultMessage: 'Next',
  },
  previousButton: {
    id: 'view.newForm.component.previousButton',
    defaultMessage: 'Previous',
  },
  choiceInvoiceTitle: {
    id: 'view.newForm.step.choiceInvoiceTitle',
    defaultMessage: 'Avez-vous une facture Boulanger ?',
  },
  choiceInvoiceButtonHasInvoice: {
    id: 'view.newForm.step.choiceInvoiceTitle.button.hasInvoice',
    defaultMessage: 'has invoice {sellerName}',
  },
  choiceInvoiceButtonHasPartner: {
    id: 'view.newForm.step.choiceInvoiceTitle.button.hasPartner',
    defaultMessage: 'has partner {sellerName}',
  },
  choiceInvoiceButtonHasNotInvoice: {
    id: 'view.newForm.step.choiceInvoiceTitle.button.hasNotInvoice',
    defaultMessage: 'has not invoice',
  },
  choiceInvoiceSubtitle: {
    id: 'view.newForm.step.choiceInvoiceSubtitle',
    defaultMessage:
      'Récupérez votre facture en vous connectant à votre espace client. {link}.',
  },
  choiceInvoiceSubtitleSuffix: {
    id: 'view.newForm.step.choiceInvoiceSubtitleSuffix',
    defaultMessage: 'Me connecter à espace client',
  },
  choiceProductUniversModelTitle: {
    id: 'view.newForm.step.choiceProductUniversModelTitle',
    defaultMessage: 'Sur quel type de produit avez-vous besoin d’aide ?',
  },
  choiceProductUniversModelSubtitle: {
    id: 'view.newForm.step.choiceProductUniversModelSubtitle',
    defaultMessage: 'En garantie ou non, Boulanger vous apporte une solution',
  },
  choiceProductGenericModelTitle: {
    id: 'view.newForm.step.choiceProductGenericModelTitle',
    defaultMessage: 'Nous avons besoin d’identifier votre produit',
  },
  choiceProductGenericModelSubtitle: {
    id: 'view.newForm.step.choiceProductGenericModelSubtitle',
    defaultMessage: 'Vous avez acheté votre produit chez Boulanger ? {link}.',
  },
  choiceProductGenericModelSubtitleSuffix: {
    id: 'view.newForm.step.choiceProductGenericModelSubtitleSuffix',
    defaultMessage: 'Saisissez votre n° de facture',
  },
  choiceProductGenericModelFindMyProductCategory: {
    id: 'view.newForm.step.choiceProductGenericModel.placeHolder.findMyProductCategory',
    defaultMessage: 'find my product category',
  },
  choiceProductGenericModelFindMyProductBrand: {
    id: 'view.newForm.step.choiceProductGenericModel.placeHolder.findMyProductBrand',
    defaultMessage: 'find my product brand',
  },
  choicePartnerPurchaseStore: {
    id: 'view.newForm.step.choicePartnerPurchaseStore.placeHolder.findMyProductStore',
    defaultMessage: 'find my product store',
  },
  findMyBrandName: {
    id: 'components.myaccount.claims.placeHolder.findMyBrandName',
    defaultMessage: 'find my product brand',
  },
  customerInformationNameTitle: {
    id: 'view.newForm.step.customerInformationNameTitle',
    defaultMessage: 'Informations personnelles',
  },
  customerInformationNameSubtitle: {
    id: 'view.newForm.step.customerInformationNameSubtitle',
    defaultMessage: 'Vous pourrez alors suivre l’avancement de votre demande.',
  },
  customerInformationAddressTitle: {
    id: 'view.newForm.step.customerInformationAddressTitle',
    defaultMessage: 'Saisissez votre adresse postale',
  },
  customerInformationAddressSubtitle: {
    id: 'view.newForm.step.customerInformationAddressSubtitle',
    defaultMessage: 'Vous pourrez alors suivre l’avancement de votre demande.',
  },
  customerInvoiceInformationTitle: {
    id: 'view.newForm.step.customerInvoiceInformationTitle',
    defaultMessage: 'Informations sur votre facture',
  },
  customerInvoiceInformationSubtitle: {
    id: 'view.newForm.step.customerInvoiceInformationSubtitle',
    defaultMessage:
      'Vous n’avez pas de facture ? Faire une demande sans facture',
  },
});
