import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/GetDefaultAddressForPickUpPointSearch`;

interface GetDefaultAddressForPickUpPointSearchArgs {
  claimGroupId: string;
}

async function getDefaultAddressForPickUpPointSearch({
  claimGroupId,
}: GetDefaultAddressForPickUpPointSearchArgs): Promise<
  BackResponse<AddressInfoDto> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<BackResponse<AddressInfoDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getDefaultAddressForPickUpPointSearch;
