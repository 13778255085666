import { useCallback, useContext } from 'react';

import {
  ErrorFromBack,
  getUniqueBuiltErrorNotifications,
  MessageDescriptorMap,
} from '../../../../helpers/errorsMessage';
import ToastsContext from '../ToastsProvider.context';
import { NotificationId } from '../ToastsProvider.types';

export type UsePushErrorReturnType = (
  error: Error | ErrorFromBack,
) => NotificationId;

interface UsePushErrorArgs {
  extendedMessages?: MessageDescriptorMap;
}

const usePushError = ({
  extendedMessages,
}: UsePushErrorArgs = {}): UsePushErrorReturnType => {
  const { pushNotifications } = useContext(ToastsContext);

  return useCallback(
    (error: Error | ErrorFromBack) => {
      const toasts = getUniqueBuiltErrorNotifications({
        errors: [error],
        extendedMessages,
      });

      const [id] = pushNotifications(toasts);

      return id;
    },
    [extendedMessages, pushNotifications],
  );
};

export default usePushError;
