import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { CIVILITY, COUNTRY_CODES } from '@savgroup-front-common/constants';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import { isAddress } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { BackError } from '@savgroup-front-common/types';

import adaptSuggestedAddress from '../adapters/verifyCustomerInformations';
import CustomerInformationSchema from '../CustomerInformation.schema';
import {
  CUSTOMER_INFORMATION_FIELDS,
  CustomerInformationFormValues,
  PartialCustomerInformationValues,
} from '../CustomerInformation.types';
import isAddressValid from '../helpers/isAddressValid';

interface UseCustomerInformationReturnValues {
  isSuggestedModalOpen: boolean;
  isSubmitLoading: boolean;
  backErrors: BackError[];
  formContext: UseFormReturn<CustomerInformationFormValues>;
  handleCustomerInformationSubmit: (
    data: CustomerInformationFormValues,
  ) => Promise<void>;
  handleAddressManagementSubmit: (
    data: CustomerInformationFormValues,
  ) => Promise<void>;
  suggestedAddress?: CustomerInformationFormValues;
  isSuggestedAddressLoading: boolean;
  onClose: () => void;
}

const useCustomerInformation = (): UseCustomerInformationReturnValues => {
  const { onSubmit } = useStepOrchestratorContext<
    PartialCustomerInformationValues,
    any
  >();
  const [backErrors, setBackErrors] = useState<BackError[]>([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSuggestedAddressLoading, setIsSuggestedAddressLoading] =
    useState<boolean>(false);
  const [suggestedAddress, setSuggestedAddress] = useState<
    CustomerInformationFormValues | undefined
  >(undefined);
  const [isSuggestedModalOpen, setIsSuggestedModalOpen] = useState(false);

  const formContext = useForm<CustomerInformationFormValues>({
    defaultValues: {
      [CUSTOMER_INFORMATION_FIELDS.CIVILITY]: CIVILITY.NOT_SET,
      [CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS]: suggestedAddress
        ? 'true'
        : 'false',
    },
    resolver: yupResolver(CustomerInformationSchema),
  });

  const handleCustomerInformationSubmit = useCallback(
    async (data: CustomerInformationFormValues): Promise<void> => {
      const { keepSuggestedAddress } = data;

      setIsSubmitLoading(true);
      try {
        if (keepSuggestedAddress === 'true' && suggestedAddress) {
          onSubmit({
            customerInformation: {
              additionalAddress: suggestedAddress.additionalAddress,
              address: suggestedAddress.address,
              firstName: suggestedAddress.firstName,
              lastName: suggestedAddress.lastName,
              phone: suggestedAddress.phone,
              email: suggestedAddress.email,
              companyName: suggestedAddress.companyName,
              civility: suggestedAddress.civility as CIVILITY,
              city: suggestedAddress.city,
              countryCode: suggestedAddress.countryCode.value as COUNTRY_CODES,
              postalCode: suggestedAddress.postalCode,
            },
          });
        } else {
          onSubmit({
            customerInformation: {
              additionalAddress: data.additionalAddress,
              address: data.address,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              email: data.email,
              companyName: data.companyName,
              civility: data.civility as CIVILITY,
              city: data.city,
              countryCode: data.countryCode.value as COUNTRY_CODES,
              postalCode: data.postalCode,
            },
          });
        }
      } catch (error) {
        setIsSubmitLoading(false);
        setBackErrors((error as any)?.errors || ([error] as BackError[]));
      }
    },
    [onSubmit, suggestedAddress],
  );

  const handleAddressManagementSubmit = useCallback(
    async (data: CustomerInformationFormValues): Promise<void> => {
      try {
        const { keepSuggestedAddress } = data;
        const countryCode =
          data[CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE].value;

        if (countryCode !== COUNTRY_CODES.FR) {
          handleCustomerInformationSubmit(data);

          return;
        }
        setIsSuggestedAddressLoading(true);

        const result = await DataGouvService.geoCodeAddressFromDataGouv({
          query: [
            data[CUSTOMER_INFORMATION_FIELDS.ADDRESS],
            data[CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE],
          ].join(' '),
        });

        const adaptedSuggestedAddress = adaptSuggestedAddress({
          suggestedAddress: result,
          customerInformation: data,
          keepSuggestedAddress,
        });
        const suggestedAddressRoad = adaptedSuggestedAddress?.address;
        const isSuggestedAddress = isAddress(suggestedAddressRoad);
        const isSuggestAddressValid = isAddressValid({
          suggestedAddress: adaptedSuggestedAddress,
          address: data,
        });

        setIsSuggestedAddressLoading(false);
        if (isSuggestAddressValid) {
          handleCustomerInformationSubmit(data);

          return;
        }

        if (!isSuggestedAddress) {
          setSuggestedAddress(undefined);
        } else {
          setSuggestedAddress(adaptedSuggestedAddress);
        }
        setIsSuggestedModalOpen(true);
      } catch (error) {
        setIsSubmitLoading(false);
        setBackErrors((error as any)?.errors || ([error] as BackError[]));
      }
    },
    [handleCustomerInformationSubmit],
  );

  const onClose = useCallback(() => {
    setIsSuggestedModalOpen(false);
  }, [setIsSuggestedModalOpen]);

  return {
    isSubmitLoading,
    backErrors,
    formContext,
    handleCustomerInformationSubmit,
    handleAddressManagementSubmit,
    isSuggestedModalOpen,
    suggestedAddress,
    isSuggestedAddressLoading,
    onClose,
  };
};

export default useCustomerInformation;
