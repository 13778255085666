export const SEPARATOR = '_';

export enum ACTION_STATE {
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  ERRORED = 'ERRORED',
  CANCELLED = 'CANCELLED',
  PROGRESS = 'PROGRESS',
  END = 'END',
}
export enum DATA_STATE {
  IS_LOADING = 'isLoading',
  WAS_LOADED = 'wasLoaded',
  HAS_ERRORS = 'hasErrors',
  ERRORS = 'errors',
}

export type ActionType = string;

export interface Action {
  BASE: ActionType;
}

export interface SuperAction {
  action: Action | ActionType;
  indexers?: string[];
  indexer?: string;
}

export interface DataState {
  [DATA_STATE.ERRORS]: any[];
  [DATA_STATE.IS_LOADING]: boolean;
  [DATA_STATE.HAS_ERRORS]: boolean | null;
  [DATA_STATE.WAS_LOADED]?: boolean;
}

export type SagaRequestMetadataState = Record<
  string,
  Record<string, DataState>
>;

export const SAGA_REQUEST_METADATA_KEY = 'sagaRequestMetadata';
