import messages from './messages';

export const getLabel = (label: string) => {
  switch (label) {
    case 'requests':
      return messages.requests;
    case 'solutions':
      return messages.solutions;
    case 'confirmation':
      return messages.confirmation;
    case 'informations':
    default:
      return messages.informations;
  }
};
