import React from 'react';

interface DepositIconProps {
  color?: string;
}

 
const DepositIcon: React.FC<DepositIconProps> = ({ color }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <g clipPath="url(#clip0_309_808)">
        <path
          d="M0.53125 12.1721L14.6727 18.4572L15.6311 16.3046L1.48972 10.0195L0.53125 12.1721ZM16.3304 34.6648V17.3809H13.9735V34.6648H16.3304ZM15.6311 18.4572L29.7726 12.1721L28.8141 10.0195L14.6727 16.3046L15.6311 18.4572Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1515 5.91544L2.0202 11.7526V27.723L15.1515 33.5602L28.2828 27.723V25.7987C28.2828 25.2409 28.7351 24.7886 29.2929 24.7886C29.8508 24.7886 30.303 25.2409 30.303 25.7987V27.767C30.3029 28.1445 30.1934 28.5141 29.9878 28.8306C29.7822 29.1472 29.4892 29.3973 29.1443 29.5509L15.9457 35.418C15.9455 35.418 15.9458 35.4179 15.9457 35.418C15.6958 35.5291 15.425 35.5867 15.1515 35.5867C14.8781 35.5867 14.6077 35.5293 14.3578 35.4182C14.3577 35.4181 14.358 35.4183 14.3578 35.4182L1.15917 29.5511C0.81431 29.3976 0.520863 29.1472 0.315224 28.8306C0.109584 28.5141 9.18353e-05 28.1447 0 27.7672V11.7086C9.18353e-05 11.3312 0.109584 10.9615 0.315224 10.645C0.520865 10.3284 0.813841 10.0783 1.1587 9.92477L14.3573 4.05766C14.3575 4.05759 14.3572 4.05773 14.3573 4.05766C14.6072 3.94652 14.8781 3.88889 15.1515 3.88889C15.425 3.88889 15.6954 3.94631 15.9452 4.05745C15.9454 4.05752 15.945 4.05738 15.9452 4.05745L29.1439 9.92456C29.4887 10.0781 29.7822 10.3284 29.9878 10.645C30.1934 10.9615 30.3029 11.3309 30.303 11.7084V17.0445C30.303 17.6024 29.8508 18.0546 29.2929 18.0546C28.7351 18.0546 28.2828 17.6024 28.2828 17.0445V11.7526L15.1515 5.91544ZM15.1241 33.5724C15.124 33.5724 15.1243 33.5723 15.1241 33.5724Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15763 7.54302C7.38421 7.03325 7.98115 6.80367 8.49093 7.03025L22.073 13.0671C22.4178 13.2206 22.7113 13.471 22.917 13.7875C23.1226 14.1041 23.2321 14.4734 23.2322 14.8509V20.5234C23.2322 21.0813 22.7799 21.5335 22.2221 21.5335C21.6642 21.5335 21.212 21.0813 21.212 20.5234V14.8951L7.6704 8.87632C7.16063 8.64974 6.93105 8.0528 7.15763 7.54302Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2351 16.33C34.6296 15.9355 35.2691 15.9355 35.6636 16.33L39.704 20.3704C40.0985 20.7648 40.0985 21.4044 39.704 21.7989L35.6636 25.8393C35.2691 26.2337 34.6296 26.2337 34.2351 25.8393C33.8406 25.4448 33.8406 24.8052 34.2351 24.4108L36.5512 22.0947H26.1615C25.6036 22.0947 25.1514 21.6425 25.1514 21.0846C25.1514 20.5267 25.6036 20.0745 26.1615 20.0745H36.5512L34.2351 17.7585C33.8406 17.364 33.8406 16.7244 34.2351 16.33Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_808">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

DepositIcon.displayName = 'DepositIcon';

export default DepositIcon;
