import { darken, rgba } from 'polished';
import { components } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

import { simpleSlideFromTopAnimation } from '../../../animations/simpleAnimation';
import { getFieldColorFromStatus } from '../common/helpers/getFieldColorFromStatus';

export const $OptionName = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: 1.1;
`;

export const $OptionDescription = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;
export const $SelectWrapper = styled.div`
  flex: 1;
`;

export const $AutocompleteAnimatedMenu = styled(components.Menu)`
  ${simpleSlideFromTopAnimation};
`;

export const getInputBorderColor = (
  { isFocused, selectProps }: any,
  theme: DefaultTheme,
  fallback: string,
) => {
  const { status } = selectProps;
  const colorFromStatus = getFieldColorFromStatus({ status, theme });

  if (colorFromStatus) {
    return colorFromStatus;
  }

  if (isFocused) {
    return theme.colors.primary;
  }

  return fallback;
};

export const getInputBorderHoverColor = (
  { isFocused, selectProps }: any,
  theme: DefaultTheme,
  fallback: string,
) => {
  const { status } = selectProps;
  const colorFromStatus = getFieldColorFromStatus({ status, theme });

  if (colorFromStatus) {
    return darken(0.1, colorFromStatus);
  }

  if (isFocused) {
    return theme.colors.primary;
  }

  return fallback;
};

export const getOptionBackgroundColor = ({
  isSelected,
  isFocused,
  theme,
}: any) => {
  if (isSelected || isFocused) {
    return rgba(theme.colors.primary, 0.05);
  }

  return theme.colors.white;
};

export const $Description = styled.div`
  font-style: italic;
  font-size: ${({ theme }) => theme.fonts.size.megaSmall};
`;
