import { DefaultStyledProps } from '@savgroup-front-common/types';

interface ColorBasedOnProps extends DefaultStyledProps {
  disabled?: boolean;
  danger?: boolean;
}
export const colorBasedOnProps = (props: ColorBasedOnProps) => {
  const { theme } = props;

  if (props.disabled) {
    return theme.colors.disabled;
  }
  if (props.danger) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

export const colorHoverBasedOnProps = ({ theme }: DefaultStyledProps) => {
  return theme.colors.primary;
};
