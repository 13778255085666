import { FieldErrors } from 'react-hook-form';

import { MessageType } from '@savgroup-front-common/types';

export enum FIELD_STATUS {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export type FieldMessages = FieldErrors | Record<string, MessageType>;

export type InputStatus = FIELD_STATUS | null;

export interface EnhancedState {
  isStatus: boolean;
  message?: string | MessageType | undefined;
}

export type GetFinalFieldStateResponse = [
  InputStatus,
  string | MessageType | undefined,
];

export interface FieldState {
  messages?: FieldErrors | Record<string, string | MessageType>;
  isStatus: boolean;
}

export interface GetFinalFieldStateArgs {
  name?: string;

  successes?: FieldState;
  warnings?: FieldState;
  errors: FieldState;
}
