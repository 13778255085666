import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src/shared';
import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import {
  STEPS_ORCHESTRATOR_ACTION_TYPES as ACTION_TYPES,
  Step,
  StepsOrchestratorHistory,
} from './StepsOrchestrator.types';

export interface StepsOrchestratorState<Values, Context> {
  animating: boolean;
  animationType: ANIMATION_DIRECTION;
  config: Step<Context>[];
  currentStep: Step<Context> | null;
  history: StepsOrchestratorHistory<Context>;
  context: Partial<Context>;
  values: Partial<Values>;
}

export type StepsOrchestratorAction<Values, Context> =
  | {
      type: ACTION_TYPES.DONE_ANIMATING;
    }
  | {
      type: ACTION_TYPES.NEXT_STEP;
      payload: {
        step: string;
        context?: Partial<Context>;
        values?: Partial<Values>;
      };
    }
  | {
      type: ACTION_TYPES.JUMP_TO_STEP;
      payload: {
        step: string;
        context?: Partial<Context>;
        values?: Partial<Values>;
      };
    }
  | {
      type: ACTION_TYPES.PREVIOUS_STEP;
      payload: {
        context?: Partial<Context>;
        values?: Partial<Values>;
      };
    };

export interface StepsOrchestratorInitArgs<Values, Context> {
  initialStep: string;
  config: Step<Context>[];
  initialContext: Partial<Context>;
  initialHistory: StepsOrchestratorHistory<Context>;
  initialValues: Partial<Values>;
}

export const stepsOrchestratorInit = <Values, Context>({
  initialStep,
  config,
  initialContext,
  initialHistory,
  initialValues,
}: StepsOrchestratorInitArgs<Values, Context>): StepsOrchestratorState<
  Values,
  Context
> => {
  const currentStep =
    config.find(({ name }: { name: string }) => name === initialStep) || null;

  return {
    animating: false,
    animationType: ANIMATION_DIRECTION.NEXT,
    config,
    currentStep,
    history: initialHistory,
    context: initialContext,
    values: initialValues,
  };
};
export const stepsOrchestratorReducer = <Values, Context>(
  state: StepsOrchestratorState<Values, Context>,
  action: StepsOrchestratorAction<Values, Context>,
): StepsOrchestratorState<Values, Context> => {
  switch (action.type) {
    case ACTION_TYPES.NEXT_STEP: {
      const {
        config,
        history,
        context,
        values,
        currentStep: previousStep,
      } = state;
      const {
        step: nextStep,
        context: newContext = {},
        values: newValues = {},
      } = action.payload;
      const newCurrentStep =
        config.find(({ name }) => name === nextStep) || null;

      if (!newCurrentStep) {
        throw new Error(
          `Could not find next step (Next step: ${nextStep}, action type: ${ACTION_TYPES.NEXT_STEP}).`,
        );
      }

      if (!previousStep) {
        throw new Error(
           
          `Could not find previous step (Next step: ${nextStep}, action type: ${ACTION_TYPES.NEXT_STEP}).`,
        );
      }

      return {
        ...state,
        animating: true,
        animationType: ANIMATION_DIRECTION.NEXT,
        currentStep: newCurrentStep,
        history: [...history, previousStep],
        context: { ...context, ...newContext },
        values: { ...values, ...newValues },
      };
    }
    case ACTION_TYPES.JUMP_TO_STEP: {
      const { config, history, context, values } = state;
      const {
        step: nextStep,
        context: newContext = {},
        values: newValues = {},
      } = action.payload;
      const newCurrentStep =
        config.find(({ name }) => name === nextStep) || null;

      if (!newCurrentStep) {
        throw new Error(
          `Didnt found next step (CurrentStep: ${nextStep}, ActionType: ${ACTION_TYPES.JUMP_TO_STEP}).`,
        );
      }

      const nextStepIndex = history.findIndex(({ name }) => name === nextStep);

      if (nextStepIndex >= 0) {
        const newHistory = history.slice(0, nextStepIndex);

        return {
          ...state,
          animating: true,
          animationType: ANIMATION_DIRECTION.PREVIOUS,
          currentStep: newCurrentStep,
          history: newHistory,
          context: { ...context, ...newContext },
          values: { ...values, ...newValues },
        };
      }

      const newCurrentStepIndex = config.findIndex(
        ({ name }) => name === nextStep,
      );

      const newHistory = config.slice(0, newCurrentStepIndex);

      return {
        ...state,
        animating: true,
        animationType: ANIMATION_DIRECTION.NEXT,
        currentStep: newCurrentStep,
        history: newHistory,
        context: { ...context, ...newContext },
        values: { ...values, ...newValues },
      };
    }
    case ACTION_TYPES.DONE_ANIMATING: {
      return {
        ...state,
        animating: false,
      };
    }
    case ACTION_TYPES.PREVIOUS_STEP: {
      const { history, context, values } = state;
      const { context: newContext = {}, values: newValues = {} } =
        action.payload;

      return {
        ...state,
        animating: true,
        animationType: ANIMATION_DIRECTION.PREVIOUS,
        currentStep: history[history.length - 1],
        history: history.slice(0, -1),
        context: { ...context, ...newContext },
        values: { ...values, ...newValues },
      };
    }

    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
