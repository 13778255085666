import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const StandardPriceLoginSchema = Yup.object().shape({
  orderReference: Yup.string().required(globalMessages.form.required),
  orderPrice: Yup.string().required(globalMessages.form.required),
});

export default StandardPriceLoginSchema;
