import { Barcode } from 'iconoir-react';
import React from 'react';
import { useTheme } from 'styled-components';

import {
  DeliveryTruckIcon,
  IconoirShopIcon,
  StoreIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { OnboardingActivityTypes } from '../../OnboardingActivities.constant';

import messages from './messages';

export const useGetReturnsActivity = () => {
  const theme = useTheme();

  return {
    returnVolume: [
      {
        value: OnboardingActivityTypes.MIN_RETURN_VOLUME,
        label: messages.minReturnVolume,
      },
      {
        value: OnboardingActivityTypes.LITTLE_RETURN_VOLUME,
        label: messages.littleReturnVolume,
      },
      {
        value: OnboardingActivityTypes.MEDIUM_RETURN_VOLUME,
        label: messages.mediumReturnVolume,
      },
      {
        value: OnboardingActivityTypes.HIGH_RETURN_VOLUME,
        label: messages.highReturnVolume,
      },
      {
        value: OnboardingActivityTypes.MAX_RETURN_VOLUME,
        label: messages.maxReturnVolume,
      },
    ],
    returnMethod: [
      {
        value: OnboardingActivityTypes.STORE,
        label: messages.store,
        icon: <IconoirShopIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.PICKUP_POINT,
        label: messages.pickupPoint,
        icon: <StoreIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.HOME_PICKUP,
        label: messages.homePickup,
        icon: <DeliveryTruckIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.CLIENT,
        label: messages.client,
        icon: <Barcode color={theme.newUI.variationColors?.dark1} />,
      },
    ],
    returnMethodIssue: [
      {
        value: OnboardingActivityTypes.RETRACTATION,
        label: messages.retractation,
      },
      { value: OnboardingActivityTypes.SAV, label: messages.sav },
      {
        value: OnboardingActivityTypes.DELIVERY_INCIDENT,
        label: messages.deliveryIncident,
        description: messages.deliveryIncidentDescription,
      },
    ],
  };
};
