import React from 'react';

interface BedSideIconProps {
  color?: string;
}

 
const BedSideIcon: React.FC<BedSideIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 39.5C10.8284 39.5 11.5 40.1716 11.5 41V44C11.5 44.8284 10.8284 45.5 10 45.5C9.17157 45.5 8.5 44.8284 8.5 44V41C8.5 40.1716 9.17157 39.5 10 39.5ZM38 39.5C38.8284 39.5 39.5 40.1716 39.5 41V44C39.5 44.8284 38.8284 45.5 38 45.5C37.1716 45.5 36.5 44.8284 36.5 44V41C36.5 40.1716 37.1716 39.5 38 39.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4219 5.77962C9.4098 6.05024 8.25087 6.55773 7.40511 7.40421C6.55864 8.24996 6.05024 9.4098 5.77962 11.4219C5.50319 13.4772 5.5 16.1866 5.5 20V24C5.5 27.8134 5.50319 30.5228 5.77962 32.5781C6.05024 34.5902 6.55773 35.7491 7.40421 36.5949C8.24996 37.4414 9.4098 37.9498 11.4219 38.2204C13.4772 38.4968 16.1866 38.5 20 38.5H28C31.8134 38.5 34.5228 38.4968 36.5781 38.2204C38.5902 37.9498 39.7491 37.4423 40.5949 36.5958C41.4414 35.75 41.9498 34.5902 42.2204 32.5781C42.4968 30.5228 42.5 27.8134 42.5 24V20C42.5 16.1866 42.4968 13.4772 42.2204 11.4219C41.9498 9.4098 41.4423 8.25087 40.5958 7.40511C39.75 6.55864 38.5902 6.05024 36.5781 5.77962C34.5228 5.50319 31.8134 5.5 28 5.5H20C16.1866 5.5 13.4772 5.50319 11.4219 5.77962ZM19.8871 2.5H28.1129C31.7881 2.49997 34.6995 2.49994 36.9779 2.80639C39.3223 3.1217 41.2201 3.786 42.7162 5.28289C44.2137 6.77913 44.8782 8.6772 45.1936 11.0221C45.5001 13.3005 45.5 16.2119 45.5 19.8871V24.1129C45.5 27.7881 45.5001 30.6995 45.1936 32.9779C44.8783 35.3226 44.2139 37.2205 42.7167 38.7167C41.2205 40.2139 39.3226 40.8783 36.9779 41.1936C34.6995 41.5001 31.7881 41.5 28.1129 41.5H19.8871C16.2119 41.5 13.3005 41.5001 11.0221 41.1936C8.6772 40.8782 6.77913 40.2137 5.28289 38.7162C3.786 37.2201 3.1217 35.3223 2.80639 32.9779C2.49994 30.6995 2.49997 27.7881 2.5 24.1129V19.8871C2.49997 16.2119 2.49994 13.3005 2.80639 11.0221C3.12173 8.67744 3.78614 6.77951 5.28334 5.28334C6.77951 3.78614 8.67744 3.12173 11.0221 2.80639C13.3005 2.49994 16.2119 2.49997 19.8871 2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 13C16.5 12.1716 17.1716 11.5 18 11.5H30C30.8284 11.5 31.5 12.1716 31.5 13C31.5 13.8284 30.8284 14.5 30 14.5H18C17.1716 14.5 16.5 13.8284 16.5 13ZM2.5 22C2.5 21.1716 3.17157 20.5 4 20.5H44C44.8284 20.5 45.5 21.1716 45.5 22C45.5 22.8284 44.8284 23.5 44 23.5H4C3.17157 23.5 2.5 22.8284 2.5 22ZM16.5 31C16.5 30.1716 17.1716 29.5 18 29.5H30C30.8284 29.5 31.5 30.1716 31.5 31C31.5 31.8284 30.8284 32.5 30 32.5H18C17.1716 32.5 16.5 31.8284 16.5 31Z"
        fill="currentColor"
      />
    </svg>
  );
};

BedSideIcon.displayName = 'BedSideIcon';

export default BedSideIcon;
