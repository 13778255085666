import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';

import CustomerInformationNameSchema from './CustomerInformationName.schema';
import { CustomerInformationNameValues } from './CustomerInformationName.types';

interface UseCustomerInformationNameArgs {
  values: CreateClaimValues;
}

interface UseCustomerInformationNameReturnValues {
  formContext: UseFormReturn<CustomerInformationNameValues>;
  onSubmit: SubmitHandler<CustomerInformationNameValues>;
}

const useCustomerInformationName = ({
  values,
}: UseCustomerInformationNameArgs): UseCustomerInformationNameReturnValues => {
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();

  const customerType = values.activatedSellerPartnersIdSelected?.customerType;
  const isBusiness = customerType === CUSTOMER_TYPE.BUSINESS;

  const formContext = useForm<CustomerInformationNameValues>({
    shouldUnregister: true,
    resolver: yupResolver(CustomerInformationNameSchema({ isBusiness })),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      firstName: values?.owner?.firstName || '',
      lastName: values?.owner?.lastName || '',
      companyName: values?.owner?.companyName || '',
      email: values?.owner?.email || '',
      phoneNumber: values?.owner?.phone || '',
    },
  });

  const onSubmit = useCallback(
    ({
      firstName,
      lastName,
      companyName,
      email,
      phoneNumber,
    }: {
      firstName?: string;
      lastName?: string;
      companyName: string;
      email: string;
      phoneNumber: string;
    }) => {
      return nextStep({
        ...values,
        owner: {
          ...values.owner,
          firstName,
          lastName,
          companyName,
          email,
          phone: phoneNumber,
          ownerAddress: {
            address: values?.owner?.ownerAddress?.address || '',
            postalCode: values?.owner?.ownerAddress?.postalCode || '',
            city: values?.owner?.ownerAddress?.city || '',
            countryCode: values?.owner?.ownerAddress?.countryCode || '',
            firstname: firstName,
            lastname: lastName,
            companyName,
            phone: phoneNumber,
          },
        },
      });
    },
    [nextStep, values],
  );

  return {
    formContext,
    onSubmit,
  };
};

export default useCustomerInformationName;
