import { useCallback } from 'react';

import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { OwnerService } from 'authenticator/api';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';

import prepareAuthenticateWithProductReferencePayload from './adapters/prepareAuthenticateWithProductReferencePayload';

interface UseNewFormContentReturnValues {
  handleSubmit: ({
    values,
    context,
  }: {
    values: CreateClaimValues;
    context: CreateClaimContext;
  }) => Promise<void>;
}

export const useCreateClaimContent = (): UseNewFormContentReturnValues => {
  const handleSubmit = useCallback(
    async ({
      values,
      context,
    }: {
      values: CreateClaimValues;
      context: CreateClaimContext;
    }): Promise<void> => {
      const { hasInvoice, hasPartner } = context;
      const {
        sellerId,
        tenantId,
        genericId,
        brandId,
        owner: { email, ownerAddress },
      } = values;

      if (hasInvoice) {
        const response = await OwnerService.createAnonymousOrder({
          owner: values.owner,
          sellerId,
          tenantId,
          orderReference: values.orderReference as string,
        });

        if (response.failure) {
          throw response.errors;
        }

        window.location = response.value;

        return undefined;
      }

      if (hasPartner && values.productReference) {
        const payload = prepareAuthenticateWithProductReferencePayload({
          tenantId,
          sellerId: values?.productReference?.seller?.id || tenantId,
          customerType:
            values?.productReference?.seller?.customerType ||
            CUSTOMER_TYPE.NOT_SET,
          data: {
            customerInformation: values.owner,
            productReference: {
              model: values.productReference.model,
              seller: values.productReference.seller,
              salesChannel: values.productReference.salesChannel,
              purchaseDate: values.productReference.purchaseDate,
              productPrice: values.productReference.productPrice,
            },
          },
        });

        const response = await OwnerService.createOrderAndShareLink(payload);

        if (response.failure) {
          throw response.errors;
        }

        window.location = response.value;

        return undefined;
      }

      const response = await OwnerService.setCreateOrderGenericProduct({
        sellerId,
        modelTypeId: genericId,
        brandId,
        purchaseDate: new Date(2000, 0, 1),
        ownerEmail: email,
        ownerAddress,
      });

      if (response.failure) {
        throw response.errors;
      }

      window.location.href = response.value;

      return undefined;
    },
    [],
  );

  return {
    handleSubmit,
  };
};
