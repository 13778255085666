import React, { FC } from 'react';
import styled from 'styled-components';

import { IconPropsInterface } from './Icon.types';

interface SparksProps extends IconPropsInterface {
  strokeColor?: string;
}

const $Svg = styled.svg`
  fill: none !important;

  &:hover {
    fill: none !important;
  }
`;

const Sparks: FC<SparksProps> = ({ size = '24px', strokeColor = 'black' }) => (
  <$Svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color={strokeColor}
  >
    <path
      d="M7 14C11.875 14 14 11.949 14 7C14 11.949 16.11 14 21 14C16.11 14 14 16.11 14 21C14 16.11 11.875 14 7 14ZM1 5.5C4.134 5.5 5.5 4.182 5.5 1C5.5 4.182 6.857 5.5 10 5.5C6.857 5.5 5.5 6.857 5.5 10C5.5 6.857 4.134 5.5 1 5.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </$Svg>
);

Sparks.displayName = 'Sparks';

export default Sparks;
