import React, { FunctionComponent } from 'react';

import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';

import { CustomerInformationFormValues } from '../CustomerInformation.types';

import SuggestedAddressModalContent from './SuggestedAddressModalContent';

interface SuggestedAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  addressError: boolean;
  suggestedAddress?: CustomerInformationFormValues;
  onSubmit: () => void;
  isLoading: boolean;
  isVerificationPending?: boolean;
}

const SuggestedAddressModal: FunctionComponent<
  React.PropsWithChildren<SuggestedAddressModalProps>
> = ({
  isOpen,
  onClose,
  addressError,
  suggestedAddress,
  onSubmit,
  isLoading,
  isVerificationPending,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={MODAL_SIZES.TINY}>
      <SuggestedAddressModalContent
        addressError={addressError}
        suggestedAddress={suggestedAddress}
        isLoading={isVerificationPending || isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

SuggestedAddressModal.displayName = 'SuggestedAddressModal';

export default SuggestedAddressModal;
