import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants';

import rootReducer from '../domains/rootReducer';
import rootSaga from '../domains/rootSaga';

const configureStore = (): any => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const devMiddlewares = [...middlewares, logger];

  const composedEnhancer =
    currentAppEnvironment === APP_ENVS.PROD ||
    !import.meta.env.VITE_ENABLE_REDUX_LOGGER
      ? applyMiddleware(...middlewares)
      : composeWithDevTools(applyMiddleware(...(devMiddlewares as any)));

  const store = createStore(rootReducer(), composedEnhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
