const API_COMMON_VALIDATION_ERROR = {
  COUNTRY_CODE_DIFFERS_FROM_STORE_COUNTRY:
    'Reverse.Common.Errors.Validations.CountryCodeDiffersFromStoreCountryCode',
  ARGUMENT_SHOULD_NOT_BE_EMPTY:
    'Reverse.Common.Errors.Validations.ArgumentShouldNotBeEmpty',
  DATA_IS_MISSING: 'Reverse.Common.Errors.Validations.DataIsMissing',
  ARGUMENT_MANDATORY: 'Reverse.Common.Errors.Validations.ArgumentMandatory',
  MIN_VALUE_VALIDATION_ERROR:
    'Reverse.Common.Errors.Validations.MinValueValidationError',
  INVALID_EMAIL_ERROR: 'Reverse.Common.Errors.Validations.InvalidEmailError',
  INVALID_ADDRESS_ERROR:
    'Reverse.Common.Errors.Validations.InvalidAddressError',
  INVALID_ENUM_VALUE_ERROR:
    'Reverse.Common.Errors.Validations.InvalidEnumValueError',
  ARGUMENT_SHOULD_NOT_BE_LOWER_THAN_ZERO:
    'Reverse.Common.Errors.Validations.ArgumentShouldNotBeLowerThanZero',
};

const API_COMMON_UPDLOAD_ERROR = {
  FILE_TOO_BIG: 'Reverse.Common.Errors.Upload.FileTooBig',
  UNSUPPORTED_FILE_TYPE: 'Reverse.Common.Errors.Upload.UnsupportedFileType',
  CONNECTION_ERROR: 'Reverse.Common.Errors.Upload.ConnectionError',
  MORE_THAN_ONE_FILE_PROVIDED:
    'Reverse.Common.Errors.Upload.MoreThanOneFileProvided',
  FILE_NOT_FOUND: 'Reverse.Common.Errors.Upload.FileNotFound',
};

const API_COMMON_DATA_ERROR = {
  DATA_NOT_FOUND: 'Reverse.Common.Errors.Data.NotFound',
  DATA_CANT_BE_CHANGE: 'Reverse.Common.Errors.Data.DataCantBeChange',
  CONFLICT: 'Reverse.Common.Errors.Data.Conflict',
};

const API_COMMON_AUTHENTITCATION_ERROR = {
  FORBIDDEN: 'Reverse.Common.Errors.Authentication.Forbidden',
  UNAUTHORIZED: 'Reverse.Common.Errors.Authentication.Unauthorized',
};

const API_COMMON_TOKEN_ERROR = {
  EXPIRED: 'Reverse.Common.Errors.Token.Expired',
  INCONSISTENT: 'Reverse.Common.Errors.Token.Inconsistent',
};
const API_COMMON_EXEPTIONS_ERROR = {
  FATAL_ERROR: 'Reverse.Common.Errors.Exceptions.FatalError',
};
const API_COMMON_ENDPOINTS_ERROR = {
  THIRD_PARTY_UNAVAILABLE:
    'Reverse.Common.Errors.Endpoints.ThirdPartyUnavailable',
};
const API_COMMON_RAMDOM_ERROR = {
  UNHANDLED: 'Reverse.Common.Errors.Unhandled',
  PROBLEM_WITH_SCHEDULE: 'UPS_9510111',
  READY_TIME_LATER_THAN_ALLOWABLE: 'UPS_9510113',
};

const ERROR_CODE = {
  INVALID_CLAIM_STATE_FOR_DEBUG: 'InvalidClaimStateForDebug',
  EXTENSION_CHANGE_NOT_ALLOWED:
    'Reverse.Workflow.Errors.FileAttachmentValidation.ExtensionChangeNotAllowed',
};

const API_COMMON_ERROR = {
  ...API_COMMON_RAMDOM_ERROR,
  ...API_COMMON_VALIDATION_ERROR,
  ...API_COMMON_ENDPOINTS_ERROR,
  ...API_COMMON_UPDLOAD_ERROR,
  ...API_COMMON_DATA_ERROR,
  ...API_COMMON_TOKEN_ERROR,
  ...API_COMMON_AUTHENTITCATION_ERROR,
  ...API_COMMON_EXEPTIONS_ERROR,
  ...ERROR_CODE,
};

export default API_COMMON_ERROR;
