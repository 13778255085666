import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

interface LoaderIconProps extends IconPropsInterface {
  strokeWidth?: string;
}

const LoaderIcon: FunctionComponent<LoaderIconProps> = ({
  color = 'black',
  size = '24px',
  strokeWidth = '6',
}) => (
  <Icon size={size} viewBox="0 0 80 80" color={color}>
    <g transform="translate(6,6)">
      <g>
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 33 33;270 33 33"
          begin="0s"
          dur="1.4s"
          fill="freeze"
          repeatCount="indefinite"
        />
        <circle
          fill="none"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
          strokeDasharray="187"
          strokeDashoffset="610"
          stroke="currentColor"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0 33 33;135 33 33;450 33 33"
            begin="0s"
            dur="1.4s"
            fill="freeze"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dashoffset"
            values="187;46.75;187"
            begin="0s"
            dur="1.4s"
            fill="freeze"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </Icon>
);

LoaderIcon.displayName = 'LoaderIcon';

export default LoaderIcon;
