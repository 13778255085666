import { all, race, take } from 'redux-saga/effects';

import { Config } from './apiCall';
import { CreateRequestResponse, request } from './request';

function* callAndGetResponse(
  ACTION: CreateRequestResponse,
  url: string,
  config: Config,
  meta?: unknown,
): unknown {
  if (ACTION === undefined) {
    // eslint-disable-next-line no-console
    console.error(`Trying to dispatch undefined ACTION; ${url}`);
  }

  const [{ success, error, cancel }] = yield all([
    race({
      success: take(ACTION.SUCCEEDED),
      error: take(ACTION.ERRORED),
      cancel: take(ACTION.CANCELLED),
    }),
    request(ACTION, { url, config }, meta),
  ]);

  return cancel || error || success;
}

export default callAndGetResponse;
