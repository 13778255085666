import { AutocompleteOption } from '@savgroup-front-common/types';
import { RawModelFromBack } from 'authenticator/api/Catalog/getModelsByFullTextSearch';

import { SearchModel } from '../ChoicePartnerProduct.types';

const adaptRawModelsFromBackToOptions = (
  rawModels: RawModelFromBack[],
  searchTerms: string,
): AutocompleteOption<SearchModel>[] => {
  return rawModels
    .filter(({ label = '', sku = '', ean = '' }) =>
      [label, sku, ean].some((word) => {
        return word.toLowerCase().includes(searchTerms.toLowerCase());
      }),
    )
    .map((rawModel) => {
      return {
        value: rawModel.sku,
        label: rawModel.label,
        data: rawModel,
        description: [rawModel.brandLabel, rawModel.modelType]
          .filter((v) => v)
          .join(' - '),
      };
    });
};

export default adaptRawModelsFromBackToOptions;
