import { darken } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import { NEED_HELP_POSITION_TYPES } from './NeedHelp.types';

interface NeedHelpPropsContainer {
  $position: string;
}

export const $NeedHelpButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & svg {
    color: ${({ theme }) => darken(0.07, theme.colors.primary)};
  }

  @media ${media.minWidth.sm} {
    width: 40px;
    height: 40px;
  }
  & svg {
    width: 45px;
    height: 45px;
  }

  &:hover svg {
    color: ${({ theme }) => darken(0.05, theme.colors.primary)};
  }
  &:active svg {
    transform: ${(props) => `scale(${props.theme.interactions.activeScale})`};
    opacity: 0.8;
  }
`;

export const $NeedHelpContainer = styled.div<NeedHelpPropsContainer>`
  right: 1%;
  bottom: ${({ $position }) => {
    if ($position === NEED_HELP_POSITION_TYPES.TOP) {
      return 'inherit';
    }

    return '1rem';
  }};
  top: ${({ $position }) => {
    if ($position === NEED_HELP_POSITION_TYPES.BOTTOM) {
      return 'inherit';
    }

    return '1rem';
  }};
  position: fixed;

  z-index: 10;

  & > div {
    display: block !important;
  }
`;
