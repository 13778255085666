import styled from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { slideLeftToRight } from '../../../../animations/stateAnimation';
import { decelerateTimingFunctionTransition } from '../../../../animations/timingFunction';
import { rem, styledGetCustomComponentProperty } from '../../../../helpers';

interface InputDepictionProps {
  $show: boolean;
  $isSmall: boolean;
}

export const $InputDepiction = styled.div<InputDepictionProps>`
  color: ${({ theme }) => theme.colors.white};
  min-width: ${({ $show }) => ($show ? rem(28) : 0)};
  width: ${({ $show }) => ($show ? rem(28) : 0)};
  transition-duration: 0.25s;
  height: ${({ $isSmall }) => {
    if ($isSmall) {
      return '24px';
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    });
  }};
  line-height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${decelerateTimingFunctionTransition};
`;
export const $IcoContainer = styled.div`
  ${slideLeftToRight};
  position: absolute;
  max-height: ${rem(16)};
`;
