import React, { useCallback, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, CURRENCIES } from '@savgroup-front-common/constants';
import { FormGroup, Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import {
  formatCurrencyCode,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { formatStringToIntlPrice } from '@savgroup-front-common/core/src/formatters/intl';
import { FLEX_ALIGN } from '@savgroup-front-common/types';
import useSellerSelfcareConfiguration from 'authenticator/hooks/useSellerSelfcareConfiguration';
import { CreateClaimValues } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import messagesForm from './messages';
import useStandardPriceLogin from './StandardPriceLogin.hooks';
import {
  $DivWithMargin,
  $HelperButton,
  $HelperContainerTitle,
  $Img,
} from './StandardPriceLogin.styles';

interface CustomerInvoiceInformationsProps {
  values: CreateClaimValues;
}

const StandardPriceLogin: React.FC<CustomerInvoiceInformationsProps> = ({
  values,
}) => {
  const [isHelperOpen, setIsOpenHelper] = useState<boolean>(false);
  const theme = useTheme();
  const { formContext, isLoading, onSubmit } = useStandardPriceLogin({
    values,
  });
  const { sellerConfiguration } = useSellerSelfcareConfiguration({
    sellerId: values.sellerId,
  });

  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = formContext;

  const handleHelper = useCallback(() => {
    setIsOpenHelper((prev) => !prev);
  }, []);

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CUSTOMER_INVOICE_INFORMATION}
          isDisabled={!isValid}
          isLoading={isLoading}
        >
          <Grid fluid>
            <Row>
              <Col sm={12} xs={12}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="orderReference"
                    render={({ field }) => {
                      return (
                        <Input
                          name={field.name}
                          value={field.value}
                          label={messagesForm.orderReferenceLabel}
                          disableBrowserAutocomplete
                          onChange={field.onChange}
                          errors={errors}
                          postLabel={
                            <$HelperButton
                              hollow
                              naked
                              underline
                              type={BUTTON_TYPES.BUTTON}
                              onClick={handleHelper}
                            >
                              <SafeFormattedMessageWithoutSpread
                                message={messagesForm.orderReferenceHelperLabel}
                              />
                            </$HelperButton>
                          }
                          fullWidthLabel
                          isRequired
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12}>
                <FormGroup>
                  <Controller
                    control={control}
                    name="orderPrice"
                    render={({ field }) => {
                      return (
                        <Input
                          name={field.name}
                          value={field.value}
                          label={messagesForm.orderPriceLabel}
                          disableBrowserAutocomplete
                          onChange={field.onChange}
                          errors={errors}
                          isRequired
                          suffixInsideInput={
                            <SafeFormattedMessageWithoutSpread
                              message={formatCurrencyCode(CURRENCIES.EUR)}
                            />
                          }
                          onBlur={(event) =>
                            field.onChange(
                              formatStringToIntlPrice({
                                amount: event.target.value,
                                currency: CURRENCIES.EUR,
                              }),
                            )
                          }
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </StepLayout>
      </form>
      {sellerConfiguration && (
        <SwipeableDrawer
          isOpen={isHelperOpen}
          size={DRAWER_SIZE.SMALL}
          onClose={() => setIsOpenHelper(false)}
          hasBackdrop
          hasBackdropScroll={false}
        >
          <$HelperContainerTitle>
            <Heading
              level={2}
              align={FLEX_ALIGN.LEFT}
              color={theme.colors.black}
            >
              <SafeFormattedMessageWithoutSpread
                message={messagesForm.orderReferenceHelperTitle}
              />
            </Heading>
          </$HelperContainerTitle>
          <SafeFormattedMessageWithoutSpread
            message={sellerConfiguration?.orderHelpText}
          />
          {sellerConfiguration?.orderHelpImageUrls.map((url) => {
            return (
              <$DivWithMargin key={url}>
                <$Img src={url} alt="helperImage" />
              </$DivWithMargin>
            );
          })}
        </SwipeableDrawer>
      )}
    </FormProvider>
  );
};

StandardPriceLogin.displayName = 'StandardPriceLogin';

export default StandardPriceLogin;
