import { useCallback, useState } from 'react';

import { LOGIN_FORM_TYPE } from '@savgroup-front-common/constants';
import useLocalStorage from '@savgroup-front-common/core/src/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';
import { ClientInformation } from 'authenticator/types';

import { useClientInformationContext } from '../../atoms';

interface GetFirstActiveComponentArgs {
  clientInformation: ClientInformation;
}

export const getFirstActiveComponent = ({
  clientInformation,
}: GetFirstActiveComponentArgs): LOGIN_FORM_TYPE | undefined => {
  if (clientInformation?.phoneNumberLoginEnable) {
    return LOGIN_FORM_TYPE.ORDER_NUMBER;
  }
  if (clientInformation?.isWithTicketNumberLoginEnabled) {
    return LOGIN_FORM_TYPE.RECEIPT_NUMBER;
  }
  if (clientInformation?.productReferenceLoginEnable) {
    return LOGIN_FORM_TYPE.MODEL_REFERENCE;
  }

  return undefined;
};

const useActiveFormComponent = () => {
  const { clientInformation } = useClientInformationContext();

  const [activeComponent, setActiveComponent] = useState<
    LOGIN_FORM_TYPE | undefined
  >(getFirstActiveComponent({ clientInformation }));

  const handleChangeComponent = useCallback(
    (component: LOGIN_FORM_TYPE) => () => {
      setActiveComponent(component);
    },
    [],
  );

  const [isPriceOrderFeatureEnabled] = useLocalStorage(
    LOCAL_STORAGE_KEYS.PRICE_ORDER,
    false,
  );

  return {
    activeComponent: isPriceOrderFeatureEnabled
      ? LOGIN_FORM_TYPE.PRICE_ORDER
      : activeComponent,
    handleChangeComponent,
  };
};

export default useActiveFormComponent;
