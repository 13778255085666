import React from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, LANGUAGES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import {
  ReversioIcon,
  SendMailIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import messages from './messages';
import useResendSalesLeadEmailConfirmationCommand from './OnboardingConfirmation.hooks';
import {
  $AccountActivationTitle,
  $ConfirmationContainer,
  $ConfirmationHeader,
  $EmailActivationMessage,
  $IconWithTitle,
  $ResendButton,
  $SpamMessage,
  $VerificationMessage,
} from './OnboardingConfirmation.styles';

const OnboardingConfirmation = ({ userEmail }: { userEmail: string }) => {
  const theme = useTheme();
  const { handleResendEmail, isLoading } =
    useResendSalesLeadEmailConfirmationCommand({ userEmail });

  return (
    <>
      <$ConfirmationHeader>
        <a href="https://www.revers.io">
          <ReversioIcon color={theme.newUI.defaultColors.menu} />
        </a>
        <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
      </$ConfirmationHeader>
      <$ConfirmationContainer>
        <$IconWithTitle>
          <SendMailIcon
            size="100px"
            color={theme.newUI.variationColors?.dark1}
          />
          <$AccountActivationTitle>
            <SafeFormattedMessageWithoutSpread
              message={messages.accountActivationTitle}
            />
          </$AccountActivationTitle>
        </$IconWithTitle>
        <$VerificationMessage>
          <SafeFormattedMessageWithoutSpread message={messages.verification} />
        </$VerificationMessage>
        <$EmailActivationMessage>
          <SafeFormattedMessageWithoutSpread
            message={messages.emailActivation}
            values={{
              email: userEmail,
            }}
          />
        </$EmailActivationMessage>
        <$SpamMessage>
          <SafeFormattedMessageWithoutSpread message={messages.spam} />
          <$ResendButton
            secondary
            isNewUi
            type={BUTTON_TYPES.BUTTON}
            onClick={() => handleResendEmail()}
            isLoading={isLoading}
          >
            <SafeFormattedMessageWithoutSpread message={messages.resend} />
          </$ResendButton>
        </$SpamMessage>
      </$ConfirmationContainer>
    </>
  );
};

OnboardingConfirmation.displayName = 'OnboardingConfirmation';

export default OnboardingConfirmation;
