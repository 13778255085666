import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { CustomerInformation } from '../common/steps';

import { ReceiptSearch } from './steps';

export enum TICKET_NUMBER_LOGIN_STEP_NAMES {
  RECEIPT_SEARCH = 'receiptSearch',
  CUSTOMER_INFORMATION = 'customerInformation',
}

const searchByReceiptStep: Step<Record<string, unknown>> = {
  name: TICKET_NUMBER_LOGIN_STEP_NAMES.RECEIPT_SEARCH,
  nextStep: TICKET_NUMBER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  Component: ReceiptSearch,
  message: TICKET_NUMBER_LOGIN_STEP_NAMES.RECEIPT_SEARCH,
};

const customInformationStep: Step<Record<string, unknown>> = {
  name: TICKET_NUMBER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: CustomerInformation,
  message: TICKET_NUMBER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
};

export const TICKET_NUMBER_LOGIN_STEPS_CONFIG = [
  searchByReceiptStep,
  customInformationStep,
];
