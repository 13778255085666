import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SellerService } from 'authenticator/api';

import useGetClientInformation from './useGetClientInformation';

const GetSeller = 'getSeller';

const useMyAccountLoginPage = () => {
  const { clientInformation, isLoading } = useGetClientInformation();
  const tenantId = clientInformation?.tenantId || undefined;

  const { data } = useQuery(
    [
      GetSeller,
      {
        tenantId,
      },
    ],
    async () => {
      if (!tenantId) {
        return undefined;
      }
      const response = await SellerService.getSellersByTenantId({
        tenantId,
      });

      if (response.failure) {
        logError(`Got an empty getSellerByTenantId for tenantId: ${tenantId}.`);

        return undefined;
      }

      return response.value;
    },
    {
      enabled: !!tenantId,
    },
  );

  return {
    isLoading,
    clientInformation,
    sellers: data || [],
  };
};

export default useMyAccountLoginPage;
