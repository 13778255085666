import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';

import { COUNTRY_CODES, EMPTY_UUID } from '@savgroup-front-common/constants';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';

import SuggestedAddressModalSchema from './SuggestedAddressModal.schema';
import { SuggestedAddressModalValues } from './SuggestedAddressModal.types';

interface SuggestedAddressModalArgs {
  values: CreateClaimValues;
  addressEntered: AddressInfoDto;
  suggestedAddress: AddressInfoDto;
  onClose: () => void;
}

interface SuggestedAddressModalReturnValues {
  formContext: UseFormReturn<SuggestedAddressModalValues>;
  handleSubmitSuggestedAddress: SubmitHandler<SuggestedAddressModalValues>;
}

const useSuggestedAddressModal = ({
  values,
  addressEntered,
  suggestedAddress,
  onClose,
}: SuggestedAddressModalArgs): SuggestedAddressModalReturnValues => {
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();
  const formContext = useForm<SuggestedAddressModalValues>({
    resolver: yupResolver(SuggestedAddressModalSchema),
    defaultValues: {
      keepSuggestedAddress: false,
    },
  });

  const handleSubmitSuggestedAddress = useCallback(
    ({
      keepSuggestedAddress,
      keepAddressEntered,
    }: {
      keepSuggestedAddress: boolean;
      keepAddressEntered: boolean;
    }) => {
      if (keepSuggestedAddress && !keepAddressEntered) {
        onClose();

        nextStep(
          {
            ...values,
            owner: {
              ...values.owner,
              id:
                values.owner.id && values.owner.id !== EMPTY_UUID
                  ? values.owner.id
                  : v4(),
              ownerAddress: {
                ...values.owner.ownerAddress,
                firstname: values.owner.ownerAddress?.firstname as string,
                lastname: values.owner.ownerAddress?.lastname as string,
                phone: values.owner.ownerAddress?.phone as string,
                address: suggestedAddress.address,
                postalCode: suggestedAddress.postalCode,
                city: suggestedAddress.city,
                countryCode: suggestedAddress.countryCode as COUNTRY_CODES,
              },
            },
          },
          {
            isKeepSuggestedAddress: true,
          },
        );
      }
      if (keepAddressEntered && !keepSuggestedAddress) {
        onClose();

        nextStep(
          {
            ...values,
            owner: {
              ...values.owner,
              id:
                values.owner.id && values.owner.id !== EMPTY_UUID
                  ? values.owner.id
                  : v4(),
              ownerAddress: {
                ...values.owner.ownerAddress,
                firstname: values.owner.ownerAddress?.firstname as string,
                lastname: values.owner.ownerAddress?.lastname as string,
                phone: values.owner.ownerAddress?.phone as string,
                address: addressEntered.address,
                postalCode: addressEntered.postalCode,
                city: addressEntered.city,
                countryCode: addressEntered.countryCode as COUNTRY_CODES,
              },
            },
          },
          {
            isKeepSuggestedAddress: false,
          },
        );
      }

      return onClose();
    },
    [nextStep, onClose, values, addressEntered, suggestedAddress],
  );

  return {
    formContext,
    handleSubmitSuggestedAddress,
  };
};

export default useSuggestedAddressModal;
