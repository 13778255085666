import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';

import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import {
  CUSTOMER_TYPE,
} from '@savgroup-front-common/types';
import {
  CreateClaimContext,
  CreateClaimValues,
  ORDER_LOGIN_CONFIGURATION,
} from 'authenticator/types';

import choiceInvoiceSchema from './ChoiceInvoice.schema';
import {
  CHOICE_INVOICE,
  ChoiceInvoicePartner,
  ChoiceInvoiceValues,
} from './ChoiceInvoice.types';

interface UseChoiceInvoiceReturnAgrs {
  values: CreateClaimValues;
}

interface UseChoiceInvoiceReturnValues {
  formContext: UseFormReturn<ChoiceInvoiceValues>;
  onSubmit: SubmitHandler<ChoiceInvoiceValues>;
  hasPartner: boolean;
  enableGenericProduct: boolean;
  sellerId: string;
  activatedSellerPartners: {
    sellerId: string;
    sellerName: string;
    customerType?: CUSTOMER_TYPE;
  }[];
  sellerName: string;
  orderLoginConfiguration: ORDER_LOGIN_CONFIGURATION;
}

const useChoiceInvoice = ({
  values,
}: UseChoiceInvoiceReturnAgrs): UseChoiceInvoiceReturnValues => {
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();
  const formContext = useForm<ChoiceInvoiceValues>({
    resolver: yupResolver(choiceInvoiceSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });
  const { removeAllNotifications } = useBanners();

  const onSubmit: SubmitHandler<ChoiceInvoiceValues> = useCallback(
    async ({
      choiceInvoice,
    }: {
      choiceInvoice: CHOICE_INVOICE | ChoiceInvoicePartner;
    }) => {
      removeAllNotifications();

      return nextStep(
        {
          ...values,
          activatedSellerPartnersIdSelected:
            values.activatedSellerPartners.find(
              (partner) =>
                partner.sellerId === (<ChoiceInvoicePartner>choiceInvoice).sellerId &&
                partner.customerType === (<ChoiceInvoicePartner>choiceInvoice).customerType,
            ),
        },
        {
          hasInvoice: choiceInvoice === CHOICE_INVOICE.HAS_INVOICE,
          hasPartner: typeof choiceInvoice !== 'string' && !!Object.keys(choiceInvoice).length,
        },
      );
    },
    [nextStep, removeAllNotifications, values],
  );

  return {
    formContext,
    onSubmit,
    hasPartner: values?.hasPartner || false,
    enableGenericProduct: values?.enableGenericProduct || false,
    sellerId: values.sellerId,
    activatedSellerPartners: values.activatedSellerPartners,
    sellerName: values.sellerName,
    orderLoginConfiguration: values.orderLoginConfiguration,
  };
};

export default useChoiceInvoice;
