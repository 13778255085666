import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Grid, Row } from 'react-styled-flexboxgrid';

import {
  FormGroup,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { CreateClaimValues, UniverseModelType } from 'authenticator/types';
import StepLayout from 'authenticator/view/app/layout/NewDesignLayout/StepLayout/StepLayout';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import useChoiceProductUniverseModel from './ChoiceProductUniverseModel.hooks';
import ChoiceProductUniverseModelCard from './ChoiceProductUniverseModelCard';
import { getColumn } from '../../helpers';

interface ChoiceProductUniversModelProps {
  values: CreateClaimValues;
}

const ChoiceProductUniversModel: React.FC<ChoiceProductUniversModelProps> = ({
  values,
}) => {
  const { formContext, modelTypesUniverse, onSubmit } =
    useChoiceProductUniverseModel({
      values,
    });

  const {
    control,
    formState: { isValid },
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <form
        onSubmit={(event) => {
          event.preventDefault();

          return undefined;
        }}
      >
        <StepLayout
          values={values}
          stepName={STEP_CONFIG.CHOICE_UNIVERS_MODEL}
          isDisabled={!isValid}
          noConfirmButton
        >
          <FormGroup>
            <Controller
              control={control}
              name="choiceProductUniverseId"
              render={({ field }) => {
                return (
                  <RadioButtonGroup name="choiceProductUniverseRadioButtonGroup">
                    <Grid fluid>
                      <Row>
                        {modelTypesUniverse?.map(
                          (modelTypeUniverse: UniverseModelType) => {
                            return (
                              <ChoiceProductUniverseModelCard
                                key={modelTypeUniverse.id}
                                modelTypeUniverse={modelTypeUniverse}
                                isChecked={field.value === modelTypeUniverse.id}
                                onChange={field.onChange}
                                column={getColumn(modelTypesUniverse.length)}
                                onClick={() =>
                                  onSubmit({
                                    choiceProductUniverseId:
                                      modelTypeUniverse.id,
                                  })
                                }
                              />
                            );
                          },
                        )}
                      </Row>
                    </Grid>
                  </RadioButtonGroup>
                );
              }}
            />
          </FormGroup>
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ChoiceProductUniversModel.displayName = 'ChoiceProductUniversModel';

export default ChoiceProductUniversModel;
