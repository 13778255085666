import React from 'react';
import { useIntl } from 'react-intl';
import { useMedia } from 'react-use';

import { LANGUAGES, media } from '@savgroup-front-common/constants';
import OnboardingImageEN from '@savgroup-front-common/core/public/images/OnboardingImageEN.svg';
import OnboardingImageFR from '@savgroup-front-common/core/public/images/OnboardingImageFR.svg';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { ReversioIcon } from '@savgroup-front-common/core/src/protons/icons';

import messages from './messages';
import {
  $BackgroundCol,
  $ImgDescription,
  $ImgTitle,
  $LanguageChooser,
  $OnboardingEntry,
  $OnboardingEntryFormContainer,
} from './OnboardingEntry.styles';
import OnboardingForm from './OnboardingForm/OnboardingForm';

const OnboardingEntry = ({
  handleUserEmail,
}: {
  handleUserEmail: (userEmail: string) => void;
}) => {
  const isMobileView = useMedia(media.maxWidth.sm);
  const intl = useIntl();
  const { locale } = intl;

  return (
    <$OnboardingEntry>
      {!isMobileView && (
        <$BackgroundCol xs={6}>
          <a href="https://www.revers.io">
            <ReversioIcon color="#fff" />
          </a>
          <$ImgTitle>
            <SafeFormattedMessageWithoutSpread message={messages.imgTitle} />
          </$ImgTitle>
          <$ImgDescription>
            <SafeFormattedMessageWithoutSpread
              message={messages.imgDescription}
            />
          </$ImgDescription>
          {locale === LANGUAGES.EN && <img src={OnboardingImageEN} alt="" />}
          {locale === LANGUAGES.FR && <img src={OnboardingImageFR} alt="" />}
        </$BackgroundCol>
      )}
      <$OnboardingEntryFormContainer xs={isMobileView ? 12 : 6}>
        <$LanguageChooser>
          <LanguageChooser acceptedLanguages={[LANGUAGES.FR, LANGUAGES.EN]} />
        </$LanguageChooser>

        <OnboardingForm handleUserEmail={handleUserEmail} />
      </$OnboardingEntryFormContainer>
    </$OnboardingEntry>
  );
};

OnboardingEntry.displayName = 'OnboardingEntry';

export default OnboardingEntry;
