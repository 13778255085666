import React, {
  createRef,
  FunctionComponent,
  MutableRefObject,
  Ref,
  useEffect,
  useState,
} from 'react';

import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../../../formatters';
import { $Container, FieldMessage } from '../../common';
import { getFinalFieldState } from '../../common/helpers/getFinalFieldState';
import { FIELD_STATUS } from '../../common/helpers/getFinalFieldState.types';
import Input, { InputProps } from '../../Input';

import { EmailAutocompleteResult } from './components';
import {
  $EmailAutcompleteDropdown,
  $EmailAutcompleteDropdownContainer,
} from './EmailAutocomplete.styles';
import { useEmailAutocomplete } from './hooks';
import messages from './messages';

interface EmailAutocompletePropsWithRef extends InputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const EmailAutocomplete: FunctionComponent<EmailAutocompletePropsWithRef> = ({
  componentThemeName = SUPPORTED_COMPONENTS.DEFAULT_AUTOCOMPLETE,
  name = undefined,
  label = undefined,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  placeholder = messages.placeholder,
  suffix = '',
  prefix = '',
  value = '',
  onChange,
  forwardedRef,
  errors = {},
  warnings = {},
  successes = {},
  isError = false,
  isWarning = false,
  isSuccess = false,
  readOnly,
  postLabel,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  dataTestId,
  icon,
}) => {
  const adaptedValue = String(value);
  const {
    handleKeyDown,
    handleChange,
    handleSelect,
    handleClose,
    handleOpen,
    selectedIndex,
    emailDomains,
    isOpen,
  } = useEmailAutocomplete({
    onChange,
    value: adaptedValue,
  });

  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const arrLength = emailDomains.length;
  const [elRefs, setElRefs] = useState<MutableRefObject<HTMLButtonElement>[]>(
    [],
  );

  useEffect(() => {
    // add or remove refs
    setElRefs((innerRef) =>
      Array(arrLength)
        .fill(null)
        .map((_, index) => innerRef[index] || createRef()),
    );
  }, [arrLength]);

  return (
    <$Container>
      <Input
        icon={icon}
        isDisabled={isDisabled}
        componentThemeName={componentThemeName}
        placeholder={safeFormattedIntlString(placeholder)}
        isLoading={isLoading}
        value={value}
        ref={forwardedRef}
        name={name}
        label={label}
        onChange={handleChange}
        isError={status === FIELD_STATUS.ERROR}
        isWarning={status === FIELD_STATUS.WARNING}
        isSuccess={status === FIELD_STATUS.SUCCESS}
        onKeyDown={handleKeyDown}
        disableBrowserAutocomplete
        suffix={suffix}
        selectAllOnFocus
        readOnly={readOnly}
        prefix={prefix}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }
          if (!readOnly) {
            handleOpen();
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }

          if (
            !readOnly &&
            !elRefs.some(({ current }) => e?.relatedTarget === current)
          ) {
            handleClose();
          }
        }}
        dataTestId={dataTestId}
        isRequired={isRequired}
        postLabel={postLabel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isOpen && (
        <$EmailAutcompleteDropdownContainer>
          <$EmailAutcompleteDropdown
            data-testid={dataTestId ? `${dataTestId}-emailDropdown` : undefined}
          >
            {emailDomains.map((emailDomain, index) => (
              <EmailAutocompleteResult
                key={emailDomain}
                emailDomain={emailDomain}
                value={adaptedValue}
                isSelected={index === selectedIndex}
                onSelect={handleSelect}
                ref={elRefs[index]}
                dataTestId={
                  dataTestId ? `${dataTestId}-emailResult-${index}` : undefined
                }
              />
            ))}
          </$EmailAutcompleteDropdown>
        </$EmailAutcompleteDropdownContainer>
      )}
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

EmailAutocomplete.displayName = 'EmailAutocomplete';

export default React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <EmailAutocomplete forwardedRef={ref} {...props} />
));
