import { tint } from 'polished';
import { DefaultTheme } from 'styled-components';

import { colorBasedOnProps, getColor } from './Button.helpers';

interface GetLoadingColorProps {
  componentThemeName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  hollow?: boolean;
  underline?: boolean;
  naked?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  success?: boolean;
  danger?: boolean;
  alert?: boolean;
}

export const getLoadingColor = (
  props: GetLoadingColorProps,
  theme: DefaultTheme,
) => {
  const { hollow } = props;
  const { interactions } = theme;

  const mergedProps = { theme, ...props };

  if (interactions.buttonHoverAsHollow) {
    return tint(0.1, colorBasedOnProps(mergedProps));
  }

  if (hollow) {
    return colorBasedOnProps(mergedProps);
  }

  return getColor(mergedProps);
};
