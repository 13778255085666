import {
  CIVILITY,
  COUNTRY_CODES,
  HOUSING_TYPES,
} from '@savgroup-front-common/constants';

export enum CUSTOMER_TYPE {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Business',
  ORGANIZATION = 'Organization',
  GOVERNMENT = 'Government',
  NOT_SET = 'NotSet',
  STOCK = 'Stock',
}

export interface AddressInfoDto {
  address: string;
  additionalAddress?: string;
  postalCode: string;
  city: string;
  countryCode: COUNTRY_CODES | string;
  addressId?: string;
  civility?: CIVILITY;
  firstname?: string;
  lastname?: string;
  companyName?: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  phone?: string;
  isDefault?: boolean;
  isValid?: boolean;
  housingType?: HOUSING_TYPES;
  hasElevator?: boolean;
  hasParkingSpace?: boolean;
  customerType?: CUSTOMER_TYPE;
}

export interface AddressInfo {
  address: string;
  additionalAddress?: string;
  postalCode: string;
  city: string;
  countryCode: COUNTRY_CODES;
  addressId?: string;
  civility?: CIVILITY;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  phone?: string;
  isDefault?: boolean;
  isValid?: boolean;
  housingType?: HOUSING_TYPES;
  hasElevator?: boolean;
  hasParkingSpace?: boolean;
}

export interface PickupPointAddress {
  address: string;
  city: string;
  countryCode: COUNTRY_CODES | string;
  firstName: string;
  id: string;
  postalCode: string;
  name: string;
}
