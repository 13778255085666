import React from 'react';

interface WashingMachineIconProps {
  color?: string;
}

 
const WashingMachineIcon: React.FC<WashingMachineIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5.5C9.33696 5.5 8.70107 5.76339 8.23223 6.23223C7.76339 6.70107 7.5 7.33696 7.5 8V40C7.5 40.663 7.76339 41.2989 8.23223 41.7678C8.70107 42.2366 9.33696 42.5 10 42.5H38C38.663 42.5 39.2989 42.2366 39.7678 41.7678C40.2366 41.2989 40.5 40.663 40.5 40V8C40.5 7.33696 40.2366 6.70107 39.7678 6.23223C39.2989 5.76339 38.663 5.5 38 5.5H10ZM6.11091 4.11091C7.14236 3.07946 8.54131 2.5 10 2.5H38C39.4587 2.5 40.8576 3.07946 41.8891 4.11091C42.9205 5.14236 43.5 6.54131 43.5 8V40C43.5 41.4587 42.9205 42.8576 41.8891 43.8891C40.8576 44.9205 39.4587 45.5 38 45.5H10C8.54131 45.5 7.14236 44.9205 6.11091 43.8891C5.07946 42.8576 4.5 41.4587 4.5 40V8C4.5 6.54131 5.07946 5.14236 6.11091 4.11091ZM37.029 8.88809C37.642 9.44536 37.6872 10.394 37.1299 11.007L37.1099 11.029C36.5526 11.642 35.604 11.6872 34.991 11.1299C34.378 10.5726 34.3328 9.62397 34.8901 9.01099L34.9101 8.98899C35.4674 8.376 36.416 8.33083 37.029 8.88809Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4541 16.4541C16.9858 13.9223 20.4196 12.5 24 12.5C27.5804 12.5 31.0142 13.9223 33.5459 16.4541C36.0777 18.9858 37.5 22.4196 37.5 26C37.5 29.5804 36.0777 33.0142 33.5459 35.5459C31.0142 38.0777 27.5804 39.5 24 39.5C20.4196 39.5 16.9858 38.0777 14.4541 35.5459C11.9223 33.0142 10.5 29.5804 10.5 26C10.5 22.4196 11.9223 18.9858 14.4541 16.4541ZM24 15.5C21.2152 15.5 18.5445 16.6062 16.5754 18.5754C14.6062 20.5445 13.5 23.2152 13.5 26C13.5 28.7848 14.6062 31.4555 16.5754 33.4246C18.5445 35.3938 21.2152 36.5 24 36.5C26.7848 36.5 29.4555 35.3938 31.4246 33.4246C33.3938 31.4555 34.5 28.7848 34.5 26C34.5 23.2152 33.3938 20.5445 31.4246 18.5754C29.4555 16.6062 26.7848 15.5 24 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 24.5C18.8284 24.5 19.5 25.1716 19.5 26C19.5 27.1935 19.9741 28.3381 20.818 29.182C21.6619 30.0259 22.8065 30.5 24 30.5C24.8284 30.5 25.5 31.1716 25.5 32C25.5 32.8284 24.8284 33.5 24 33.5C22.0109 33.5 20.1032 32.7098 18.6967 31.3033C17.2902 29.8968 16.5 27.9891 16.5 26C16.5 25.1716 17.1716 24.5 18 24.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

WashingMachineIcon.displayName = 'WashingMachineIcon';

export default WashingMachineIcon;
