import styled from 'styled-components';

export const $InvoiceSvgItem = styled('div')`
  position: relative;
  display: block;
  width: 180px;
`;

export const $InvoiceSvgLogo = styled('div')`
  position: absolute;
  top: 20px;
  left: 30px;
  width: 60px;
`;

export const $HasNotInvoiceIconsContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 80px;
  height: 40px;
`;
