import { transparentize } from 'polished';
import { css } from 'styled-components';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types/src/Theme';

import { getCustomComponentProperty } from '../../../helpers';
import { colorBasedOnProps } from '../Button.helpers';
import { InnerButtonStyledProps } from '../Button.types';

export const handleHollowProps = (props: InnerButtonStyledProps) => {
  const { theme, hollow, disabled, isLoading } = props;

  if (!hollow) {
    return '';
  }

  const color = colorBasedOnProps(props);
  const backgroundColorOnHover =
    disabled && !isLoading ? null : transparentize(0.9, color);
  const backgroundColorOnFocus =
    disabled && !isLoading ? null : transparentize(0.9, color);

  return css`
    color: ${color};
    &:hover {
      background-color: ${backgroundColorOnHover};
    }
    &:focus {
      background-color: ${backgroundColorOnFocus};
    }
    color: ${disabled && !isLoading ? theme.colors.white : null};
    background-color: ${disabled && !isLoading
      ? colorBasedOnProps(props)
      : theme.colors.white};
  `;
};

export const handleHoverAsHollow = (props: InnerButtonStyledProps) => {
  const {
    theme,
    componentThemeName,
    underline,
    disabled,
    hollow,
    naked,
    isLoading,
  } = props;
  const { interactions = { buttonHoverAsHollow: false } } = theme;

  if (
    !interactions.buttonHoverAsHollow ||
    underline ||
    (disabled && !isLoading) ||
    hollow ||
    naked
  ) {
    return '';
  }

  const customOnHoverColor = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
  });
  const customOnHoverBorder = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BORDER,
  });
  const customOnHoverBackgroundColor = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR,
  });

  return css`
    &:hover,
    &:focus {
      background-color: ${customOnHoverBackgroundColor || 'transparent'};
    }
    &:hover {
      color: ${customOnHoverColor || colorBasedOnProps(props)};
      border: ${customOnHoverBorder || `1px solid ${colorBasedOnProps(props)}`};
    }
    &:focus {
      color: ${customOnHoverColor || colorBasedOnProps(props)};
      border: ${customOnHoverBorder || `1px solid ${colorBasedOnProps(props)}`};
    }
  `;
};
