import React, { FunctionComponent } from 'react';

import LOGIN_FOOTER_NETWORK_LINK_TYPES from '@savgroup-front-common/constants/src/myaccount/loginFooterNetworkLinkTypes';

import { NetworkLink } from '../../../types/NetworkLink';

import AppStoreBadge from './AppStoreBadge';
import FacebookIcon from './FacebookIcon';
import GooglePlayBadge from './GooglePlayBadge';
import InstagramIcon from './InstagramIcon';
import { $Link } from './NetworkLinkDisplay.styles';
import PinterestIcon from './PinterestIcon';
import TwitterIcon from './TwitterIcon';

interface NetworkLinkDisplayProps {
  networkLink: NetworkLink;
}

const NetworkLinkDisplay: FunctionComponent<React.PropsWithChildren<NetworkLinkDisplayProps>> = ({
  networkLink,
}) => {
  const { type, label, href } = networkLink;

  return (
    <>
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.LINK && (
        <$Link href={href} target="_blank" rel="noreferrer">
          {label}
        </$Link>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.APP_STORE && (
        <a href={href} target="_blank" rel="noreferrer">
          <AppStoreBadge />
        </a>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.GOOGLE_PLAY && (
        <a href={href} target="_blank" rel="noreferrer">
          <GooglePlayBadge />
        </a>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.FACEBOOK && (
        <a href={href} target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.INSTAGRAM && (
        <a href={href} target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.PINTEREST && (
        <a href={href} target="_blank" rel="noreferrer">
          <PinterestIcon />
        </a>
      )}
      {type === LOGIN_FOOTER_NETWORK_LINK_TYPES.TWITTER && (
        <a href={href} target="_blank" rel="noreferrer">
          <TwitterIcon />
        </a>
      )}
    </>
  );
};

NetworkLinkDisplay.displayName = 'NetworkLink';

export default NetworkLinkDisplay;
