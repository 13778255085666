import styled from 'styled-components';
import { media } from '@savgroup-front-common/constants';

export const $PageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
`;

export const $StepWrapper = styled.div`
  margin-top: 64px;
  @media ${media.minWidth.sm} {
    margin-top: unset;
  }
`;
