import words from 'lodash/words';

const adaptGeocodeToResponse = ({
  result,
  address,
}: {
  result: google.maps.GeocoderResult;
  address: string;
}) => {
  const country = result.address_components?.find((component) =>
    component.types.some((type) => type === 'country'),
  );

  const countryCode = country?.short_name;
  /*
   * According to google docs:
   * In the UK and Sweden the correct component to display the city is postal_town.
   * Brooklyn and other parts of New York City do not include the city as part of the address.
   * They use sublocality_level_1 instead.
   * */
  const cityAddressComponent =
    result.address_components.find((component) =>
      component.types.some((type) => type === 'locality'),
    ) ||
    result.address_components.find((component) =>
      component.types.some((type) => type === 'postal_town'),
    ) ||
    result.address_components.find((component) =>
      component.types.some((type) => type === 'sublocality'),
    );
  const city = cityAddressComponent?.long_name;

  let streetNumber = result.address_components?.find((component) =>
    component.types.some((type) => type === 'street_number'),
  )?.long_name;

  if (!streetNumber) {
    streetNumber = words(address, /\d+/)?.[0];
  }

  const street = result.address_components.find((component) =>
    component.types.some((type) => type === 'route'),
  )?.long_name;

  const postalCode = result.address_components.find((component) =>
    component.types.some((type) => type === 'postal_code'),
  )?.long_name;

  return {
    countryCode,
    streetNumber,
    street,
    city,
    postalCode,
  };
};

export default adaptGeocodeToResponse;
