import { useCallback, useContext } from 'react';

import { Notification } from '@savgroup-front-common/types';

import BannersProviderContext from '../BannersProvider.context';
import { NotificationId } from '../BannersProvider.types';

export type UsePushNotificationReturnType = (
  notification: Notification,
) => NotificationId;

const usePushNotification = (): UsePushNotificationReturnType => {
  const { pushNotifications } = useContext(BannersProviderContext);

  return useCallback(
    (notification: Notification) => {
      const [notificationId] = pushNotifications([notification]);

      return notificationId;
    },
    [pushNotifications],
  );
};

export default usePushNotification;
