import { defineMessages } from 'react-intl';

export default defineMessages({
  'Reverse.Common.Errors.Data.NotFound': {
    id: 'view.MyAccountLoginPage.saleReceipt.notFound',
    defaultMessage: 'Sale receipt not found',
  },
  'Reverse.Owner.Errors.Owner.OrderPriceNotMatch': {
    id: 'view.MyAccountLoginPage.saleReceipt.orderPriceNotMatch',
    defaultMessage: 'Order amount does not match the provided price',
  },
});
