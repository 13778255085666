import styled from 'styled-components';

export const $FullScreenLoader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
`;
