import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $HelperContainerTitle = styled('div')`
  margin-bottom: 1rem;
  h2 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    @media ${media.maxWidth.sm} {
      font-size: 20px;
    }
  }
`;

export const $HelperButton = styled(Button)`
  min-width: unset;
  overflow: unset;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.black};
  &&:hover,
  &&:active,
  &&:focus {
    color: ${({ theme }) => theme.colors.primary};
    background-color: unset;
  }
`;

export const $Img = styled.img`
  width: 100%;
`;
