import { CUSTOMER_TYPE } from '@savgroup-front-common/types';

export interface ChoiceInvoicePartner {
  sellerId: string;
  customerType?: CUSTOMER_TYPE;
}

export enum CHOICE_INVOICE {
  HAS_INVOICE = 'HAS_INVOICE',
  HAS_PARTNER = 'HAS_PARTNER',
  HAS_NOT_INVOICE = 'HAS_NOT_INVOICE',
}

export interface ChoiceInvoiceValues {
  choiceInvoice: CHOICE_INVOICE | ChoiceInvoicePartner;
}
