import get from 'lodash/get';
import { MessageDescriptor } from 'react-intl';

import { MessageType } from '@savgroup-front-common/types';

import { intl } from '../helpers/intl';

export const safeFormattedIntlString = (
  input?: MessageDescriptor | MessageType | string | number,
  values = {},
): string => {
  if (!input) {
    return '';
  }

  const defaultMessage = get(input, 'defaultMessage');
  const id = get(input, 'id');

  const isSafeType = typeof input === 'string' || typeof input === 'number';
  const hasDefaultMessage = typeof defaultMessage !== 'undefined';
  const hasId = typeof id !== 'undefined';

  if (hasId && hasDefaultMessage) {
    return intl?.formatMessage(input as MessageDescriptor, values) as string;
  }

  if (isSafeType) {
    return String(input);
  }

  return '';
};
