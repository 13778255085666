export interface Interactions {
  basicTiming: string;
  transformTiming: string;
  activeScale: string;
  buttonHoverAsHollow: boolean;
  pressedScale?: string;
}

interface PropertyValue {
  value?: string;
  fallback?: string;
}

export interface DefaultStyles {
  color?: PropertyValue;
  highContrastColor?: PropertyValue;
  OnHoverColor?: PropertyValue;
  highContrastOnHoverColor?: PropertyValue;
  backgroundColor?: PropertyValue;
  highContrastBackgroundColor?: PropertyValue;
  onHoverBackgroundColor?: PropertyValue;
  highContrastOnHoverBackgroundColor?: PropertyValue;
}

export type SystemColor = {
  defaultTextColor: string;
};

export interface ThemeColor {
  primary: string;
  primaryHover?: string;
  secondary: string;
  secondaryColor1: string;
  secondaryColor2: string;
  secondaryBackground: string;
  tertiary: string;
  success: string;
  danger: string;
  alert: string;
  neutralBackground: string;
  default: string;
  disabled: string;
  mainTextColor: string;
  headingTextColor: string;
  headerTitleColor: string;
  paragraphTextColor: string;
  black: string;
  white: string;
  iconColor: string;
  lightGrey: string;
  purple: string;
  backgroundColor: string;
  darkBackground: string;
  linkColor: string;
  headerTextColor: string;
  menuItemSeparator?: string;
  datatableRowBackgroundHoverColor: string;
  datatableRowExpandedBackgroundColor: string;
}

export enum SUPPORTED_PROPERTIES {
  COLOR = 'color',
  BORDER = 'border',
  BORDER_RADIUS = 'borderRadius',
  ON_HOVER_COLOR = 'onHoverColor',
  DISABLED_COLOR = 'disabledColor',
  BACKGROUND_COLOR = 'backgroundColor',
  COLORED_BACKGROUND_COLOR = 'coloredBackgroundColor',
  DISABLED_BACKGROUND_COLOR = 'disabledBackgroundColor',
  ON_HOVER_BACKGROUND_COLOR = 'onHoverBackgroundColor',
  ON_FOCUS_BACKGROUND_COLOR = 'onFocusBackgroundColor',
  ICON_POSITION = 'iconPosition',
  TEXT_TRANSFORM = 'textTransform',
  PADDING = 'padding',
  PADDING_BOTTOM = 'paddingBottom',
  PADDING_TOP = 'paddingTop',
  PADDING_RIGHT = 'paddingRight',
  PADDING_LEFT = 'paddingLeft',
  SPACE_BETWEEN_ICON_AND_TEXT = 'spaceBetweenIconAndText',
  MARGIN = 'margin',
  MARGIN_BOTTOM = 'marginBottom',
  MARGIN_TOP = 'marginTop',
  MARGIN_RIGHT = 'marginRight',
  MARGIN_LEFT = 'marginLeft',
  FONT_SIZE = 'fontSize',
  FONT_WEIGHT = 'fontWeight',
  ON_HOVER_BORDER = 'onHoverBorder',
  JUSTIFY_CONTENT = 'justifyContent',
  HEIGHT = 'height',
  MIN_HEIGHT = 'minHeight',
  WIDTH = 'width',
  WHITE_SPACE = 'whiteSpace',
  LINE_HEIGHT = 'lineHeight',
  MAX_WIDTH = 'maxWidth',
  TEXT_ALIGN = 'textAlign',
  POSITION = 'position',
}

export interface NewUITheme {
  borders: {
    smallRadius: string;
    mediumRadius: string;
    bigRadius: string;
  };
  variationColors: {
    light1: string;
    light2: string;
    light3: string;
    light4: string;
    light5: string;
    light6: string;
    light7: string;
    dark1: string;
    dark2: string;
  };
  gradients: {
    blueStart: string;
    blueEnd: string;
    purpleStart: string;
    purpleMiddle: string;
    purpleEnd: string;
    lightStart: string;
    lightMiddle: string;
    lightEnd: string;
  };
  defaultColors: {
    menu: string;
    primary: string;
    secondary: string;
    text: string;
    secondaryText: string;
  };
  shadows: {
    level1: string;
  };
  inputBorderColors: {
    default: string;
  };
  iconColors: {
    primary: {
      color: string;
      bgColor: string;
    };
    secondary: {
      color: string;
      bgColor: string;
    };
    alert: {
      color: string;
      bgColor: string;
    };
    caution: {
      color: string;
      bgColor: string;
    };
    danger: {
      color: string;
      bgColor: string;
    };
    info: {
      color: string;
      bgColor: string;
    };
    success: {
      color: string;
      bgColor: string;
    };
    warning: {
      color: string;
      bgColor: string;
    };
    disabled: {
      color: string;
      bgColor: string;
    };
    gold: {
      color: string;
      bgColor: string;
    };
  };
  tagsColors: {
    primary: {
      color: string;
      bgColor: string;
    };
    secondary: {
      color: string;
      bgColor: string;
    };
    danger: {
      color: string;
      bgColor: string;
    };
    success: {
      color: string;
      bgColor: string;
    };
    alert: {
      color: string;
      bgColor: string;
    };
    disabled: {
      color: string;
      bgColor: string;
    };
    neutral: {
      color: string;
      bgColor: string;
    };
  };
  dividerColors: {
    default: string;
  };
}

export interface Theme {
  colors: ThemeColor;
  systemColors: SystemColor;
  interactions: Interactions;
  fonts: {
    weight: {
      thin: number;
      light: number;
      normal: number;
      mediumFont: number;
      mediumBold: number;
      bold: number;
    };
    size: {
      tiny: string;
      ultraMini?: string;
      mini?: string;
      megaSmall?: string;
      ultraSmall: string;
      small: string;
      medium: string;
      large: string;
      big: string;
      huge: string;
      header: string;
      heading: {
        h1: string;
        h2: string;
        h3: string;
        h4: string;
        h5: string;
        h6: string;
      };
    };
  };
  spacing: {
    none: string;
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl?: string;
    get: ({
      theme,
      top,
      right,
      bottom,
      left,
    }: {
      theme: Theme;
      top: string;
      right?: string;
      bottom?: string;
      left?: string;
    }) => string;
  };
  shadows: {
    elevation__1: string;
    elevation__2: string;
  };
  borders: {
    radius: string;
    thickness: string;
    thicknessSpecialInput: string;
  };

  buttons?: {
    naked: {
      default: DefaultStyles;
      primary: DefaultStyles;
    };
  };
  components: {
    [key in SUPPORTED_COMPONENTS]?: {
      [key in SUPPORTED_PROPERTIES]?: {
        value?:
          | string
          | number
          | (({ theme }: { theme: Theme }) => string | number);
        fallback?: string;
      };
    };
  };

  newUI: NewUITheme;
}

export enum SUPPORTED_COMPONENTS {
  APP_HEADER = 'appHeader',
  DEFAULT_BUTTONS = 'defaultButtons',
  LANGUAGE_CHOOSER = 'languageChooser',
  DEFAULT_NAKED_BUTTONS = 'defaultNakedButtons',
  COPY_TO_CLIPBOARD_BUTTONS = 'copyToClipboardButtons',
  SMALL_DEFAULT_BUTTONS = 'smallDefaultButtons',
  COMBOBOX_BUTTON = 'comboboxButtons',
  FORM_GROUP = 'formGroup',
  DEFAULT_INPUTS = 'defaultInputs',
  TEXT_AREA = 'textAera',
  SKELETON_INPUTS = 'skeletonInputs',
  DEFAULT_LABEL = 'defaultLabel',
  SKELETON_LABEL = 'skeletonLabel',
  DEFAULT_AUTOCOMPLETE = 'defaultAutocomplete',
  VIEW_FILE_DETAILS_BUTTON = 'viewFileDetailsButton',
  FINALIZE_CLAIM_BUTTON = 'finalizeClaimButton',
  CANCEL_CLAIM_BUTTON = 'cancelClaimButton',
  DECLARE_CLAIM_BUTTON = 'declareClaimButton',
  IRSH_ICONS = 'irshIcons',
  LOGO = 'logo',
  LOGOUT_BUTTON = 'logoutButton',
  SMALL_LOGOUT_BUTTON = 'smallLogoutButton',
  BACK_BUTTON = 'backButton',
  UPLOAD_FILES_BUTTON = 'uploadFileButton',
  FILE_DOWNLOADER_BUTTON = 'fileDownloaderButton',
  ISSUE_CARD_BUTTON = 'issueCardButton',
  SEE_DETAILS_BUTTON = 'seeDetailsButton',
  COLLAPSE_DETAILS_BUTTON = 'collapseDetailsButton',
  SOLUTION_CHOICE_BUTTON = 'solutionChoiceButton',
  CREATE_FILE_BUTTON = 'createFileButton',
  SNOOZE_BUTTON = 'snooze',
  MARK_AS_RESOLVED_BUTTON = 'markAsResolved',
  AUTOCOMPLETE_OPTION = 'autocompleteOption',
  QUANTITY_INPUT = 'quantityInput',
  CARD = 'card',
  IMPORT_REPORT_WARNING_BUTTON = 'importReportWarningButton',
  IMPORT_REPORT_ERRORED_BUTTON = 'importReportErroredButton',
  IMPORT_REPORT_DELETED_BUTTON = 'importReportDeletedButton',
  IMPORT_REPORT_MODIFIED_BUTTON = 'importReportModifiedButton',
  IMPORT_REPORT_CREATED_BUTTON = 'importReportCreatedButton',
  ROUTE_TAB_SUB_MENU_CONTENT = 'routeTabSubMenuContent',
  CONFIGURATION_CONSOLE_TAB_MENU_CONTENT = 'configurationConsoleTabMenuContent',
  CONFIGURATION_CONSOLE_SUMMARY_RULE = 'configurationConsoleSummaryRule',
  CONFIGURATION_CONSOLE_DEFAULT = 'configurationConsoleDefault',
  DEBUG_INSPECTOR = 'debugInspector',
  NEW_UI_PRODUCT_ACCORDION = 'newUiProductAccordion',
}

export const BUTTON_COMPONENTS = [
  SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
  SUPPORTED_COMPONENTS.VIEW_FILE_DETAILS_BUTTON,
  SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON,
  SUPPORTED_COMPONENTS.CANCEL_CLAIM_BUTTON,
  SUPPORTED_COMPONENTS.DECLARE_CLAIM_BUTTON,
  SUPPORTED_COMPONENTS.LOGOUT_BUTTON,
  SUPPORTED_COMPONENTS.SMALL_LOGOUT_BUTTON,
  SUPPORTED_COMPONENTS.BACK_BUTTON,
  SUPPORTED_COMPONENTS.UPLOAD_FILES_BUTTON,
  SUPPORTED_COMPONENTS.FILE_DOWNLOADER_BUTTON,
  SUPPORTED_COMPONENTS.SEE_DETAILS_BUTTON,
  SUPPORTED_COMPONENTS.COLLAPSE_DETAILS_BUTTON,
  SUPPORTED_COMPONENTS.SNOOZE_BUTTON,
  SUPPORTED_COMPONENTS.MARK_AS_RESOLVED_BUTTON,
  SUPPORTED_COMPONENTS.IMPORT_REPORT_CREATED_BUTTON,
  SUPPORTED_COMPONENTS.IMPORT_REPORT_DELETED_BUTTON,
  SUPPORTED_COMPONENTS.IMPORT_REPORT_ERRORED_BUTTON,
  SUPPORTED_COMPONENTS.IMPORT_REPORT_MODIFIED_BUTTON,
  SUPPORTED_COMPONENTS.IMPORT_REPORT_WARNING_BUTTON,
];
