import { useLayoutEffect, useState } from 'react';

import { ModalPosition } from './Modal.types';

let mousePosition = { x: 0, y: 0 };
const getClickPosition = (e: MouseEvent) => {
  mousePosition = {
    x: e.pageX - window.innerWidth / 2,
    y: e.pageY - window.innerHeight / 2,
  };

  setTimeout(() => {
    mousePosition = { x: 0, y: 0 };
  }, 10000);
};
const getKeyPosition = () => {
  mousePosition = {
    x: 0,
    y: 0,
  };
};

if (
  typeof window !== 'undefined' &&
  window.document &&
  window.document.documentElement
) {
  document.documentElement.addEventListener('click', getClickPosition, true);
  document.documentElement.addEventListener('keydown', getKeyPosition, true);
}

export const useInitialPosition = (): ModalPosition => {
  const [transformOrigin] = useState<ModalPosition>(mousePosition);

  return transformOrigin;
};

export const useAriaHidden = (): void => {
  useLayoutEffect(() => {
    const root = document.getElementById('root');

    if (root) {
      root.setAttribute('aria-hidden', 'true');
    }

    return () => {
      if (root) {root.removeAttribute('aria-hidden');}
    };
  }, []);
};
