import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getAddress } from '@savgroup-front-common/core/src/helpers/address';
import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import { getCountryOption } from '../AddressInformations.helpers';
import {
  AddressInformationsFormValues,
  ADDRESS_INFORMATIONS_FIELDS as FIELDS,
} from '../AddressInformations.types';

interface HandleSelectAddressArgs {
  countryCode?: string;
  streetNumber?: string;
  street?: string;
  city?: string;
  postalCode?: string;
}
interface UseHandleSelectAddressArgs {
  formContext: UseFormReturn<AddressInformationsFormValues>;
}

const useHandleSelectAddress = ({
  formContext,
}: UseHandleSelectAddressArgs): {
  countryOptions: BaseAutocompleteOption[];
  handleSelectAddress: (address: HandleSelectAddressArgs) => void;
} => {
  const { setValue } = formContext;
  const countryOptions = useSelector(countryList);

  return {
    countryOptions,
    handleSelectAddress: useCallback(
      ({
        countryCode,
        streetNumber,
        street,
        city,
        postalCode,
      }: HandleSelectAddressArgs = {}) => {
        setValue(FIELDS.ADDRESS, getAddress(street, streetNumber));
        setValue(
          FIELDS.COUNTRY_CODE,
          getCountryOption(countryOptions, countryCode),
        );
        setValue(FIELDS.CITY, city || '');
        setValue(FIELDS.POSTAL_CODE, postalCode || '');
      },
      [countryOptions, setValue],
    ),
  };
};

export default useHandleSelectAddress;
