export const getColumn = (count: number) => {
  if (count === 1) {
    return 12;
  }
  if (count === 2) {
    return 6;
  }
  if (count === 3) {
    return 4;
  }
  if (count === 4) {
    return 6;
  }
  if (count >= 4 && count % 2 === 0) {
    return 4;
  }
  if (count >= 4 && count % 2 !== 0) {
    return 3;
  }

  return 12;
};
