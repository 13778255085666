import * as Yup from 'yup';

import {
  globalMessages,
  isPrice,
} from '@savgroup-front-common/core/src/helpers';

import { COMPLEMENTARY_INFORMATION_FIELDS } from './ComplementaryInformation.types';
import messages from './messages';

function testPositive(value?: string) {
  if (value === undefined) {
    return true;
  }

  const parsedValue = parseFloat(value.replace(',', '.'));

  return Boolean(value && parsedValue > 0);
}

function testValid(value?: string) {
  if (value === undefined) {
    return true;
  }

  return isPrice(value);
}

function testIsSafeInteger(value?: string) {
  if (value === undefined) {
    return true;
  }
  const parsedValue = parseFloat(value.replace(',', '.'));
  const int = Math.floor(parsedValue);

  return Number.isSafeInteger(int);
}

const ComplementaryInformationSchema = Yup.object().shape({
  [COMPLEMENTARY_INFORMATION_FIELDS.SALES_CHANNEL]: Yup.object()
    .nullable()
    .required(messages.requiredField),
  [COMPLEMENTARY_INFORMATION_FIELDS.PRODUCT_PRICE]: Yup.string()
    .test(
      'unitPriceShouldBeSafeIntegerOrFloat',
      messages.shouldBeValidNumber,
      testIsSafeInteger,
    )
    .test(
      'unitPriceShouldBeStrictPositive',
      messages.shouldBeStrictPositive,
      testPositive,
    )

    .test('unitPriceShouldBeValid', globalMessages.form.invalidPrice, testValid)
    .required(messages.requiredField),
  [COMPLEMENTARY_INFORMATION_FIELDS.PURCHASE_DATE]: Yup.date()
    .nullable()
    .required(messages.requiredField),
});

export default ComplementaryInformationSchema;
