import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  AdditionalInformationDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/customerfile/${COMMON_QUERY_PARAMS.FILE_ID}/additionalInformation`;

export enum TARGET_ON_TYPE_VALUES {
  Transition = 'Transition',
  State = 'State',
  Expect = 'Expect',
}

export type GetFileAdditionalInformationQueryResponse = {
  claimId: string;
  neededInformation: AdditionalInformationDto[];
}[];

interface GetFileAdditionalInformationQueryArgs {
  claimId?: string;
  fileId: string;
  filterStateName?: string;
  filterModuleDefinitionId?: string;
  targetOnType: TARGET_ON_TYPE_VALUES;
  filterToStateName?: string;
  filterTransportAdequacy?: string;
  filterExpectName?: string;
}

export async function getFileAdditionalInformationQuery({
  claimId,
  fileId,
  filterStateName,
  filterModuleDefinitionId,
  targetOnType,
  filterToStateName,
  filterTransportAdequacy,
  filterExpectName,
}: GetFileAdditionalInformationQueryArgs): Promise<
  BackResponse<GetFileAdditionalInformationQueryResponse> | BackResponseFailure
> {
  try {
    const baseUrl = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    const url = buildUrl(baseUrl, {
      filterClaimId: claimId,
      filterStateName,
      filterModuleDefinitionId,
      targetOnType,
      filterToStateName,
      filterTransportAdequacy,
      filterExpectName,
    });

    const response = await apiCall<
      BackResponse<GetFileAdditionalInformationQueryResponse>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
