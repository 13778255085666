import React from 'react';

import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { CreateClaimValues } from 'authenticator/types';

import SuggestedAddressModalContent from './SuggestedAddressModalContent';

interface SuggestedAddressModalProps {
  values: CreateClaimValues;
  isOpen: boolean;
  addressEntered: AddressInfoDto;
  suggestedAddress: AddressInfoDto;
  onClose: () => void;
}

const SuggestedAddressModal: React.FC<SuggestedAddressModalProps> = ({
  values,
  isOpen,
  addressEntered,
  suggestedAddress,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={MODAL_SIZES.TINY}>
      <SuggestedAddressModalContent
        values={values}
        addressEntered={addressEntered}
        suggestedAddress={suggestedAddress}
        onClose={onClose}
      />
    </Modal>
  );
};

SuggestedAddressModal.displayName = 'SuggestedAddressModal';

export default SuggestedAddressModal;
