import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import { ACTION_TYPES, ReceiptSearchState } from './ReceiptSearch.types';

interface InitReturnValue {
  isLoading: boolean;
}

export type Action = {
  type: ACTION_TYPES.ON_CHECK_RECEIPT_EXIST;
  payload: { isLoading: boolean };
};

export const initReceiptSeachConfigReducer = (): InitReturnValue => {
  return {
    isLoading: false,
  };
};

export const receiptSeachConfigReducer = (
  state: ReceiptSearchState,
  action: Action,
): ReceiptSearchState => {
  switch (action.type) {
    case ACTION_TYPES.ON_CHECK_RECEIPT_EXIST: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
