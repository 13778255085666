import { useEffect } from 'react';

const useChangeHTMLLanguage = (currentLanguage: string): void => {
  useEffect(() => {
    if (currentLanguage) {
      document.documentElement.setAttribute('lang', currentLanguage);
    }
  }, [currentLanguage]);
};

export default useChangeHTMLLanguage;
