import { useEffect } from 'react';

import { EVENT_KEY } from '@savgroup-front-common/constants/src/shared';

const targetIsDatePicker = (target: HTMLElement) => {
  return (
    target.classList.contains('react-datepicker__month-select') ||
    target.classList.contains('react-datepicker__year-select') ||
    target.classList.contains('react-datepicker__day') ||
    target.classList.contains('react-datepicker__navigation')
  );
};

const useFunctionOnEscape = (callback?: () => void): void => {
  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      const target = event.target as HTMLInputElement;

      if (event.key !== EVENT_KEY.ESCAPE) {
        return;
      }

      const shouldNotClose =
        ['autocomplete', 'datepicker'].includes(
          String(target?.dataset?.type),
        ) || targetIsDatePicker(target);

      if (shouldNotClose) {
        target.blur();

        return;
      }

      if (callback) {callback();}
    }
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [callback]);
};

export default useFunctionOnEscape;
