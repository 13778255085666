import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const choicePartnerSchema = Yup.object().shape({
  choicePartnerPurchaseDate: Yup.date()
    .nullable()
    .required(globalMessages.form.required),
  choicePartnerPurchaseStore: Yup.object()
    .test(
      'choicePartnerPurchaseStore',
      globalMessages.form.required,
      function test(option) {
        return option?.value;
      },
    )
    .required(globalMessages.form.required),
});

export default choicePartnerSchema;
