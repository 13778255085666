import React from 'react';
import { useTheme } from 'styled-components';

import {
  CityIcon,
  IconoirShopIcon,
  IconoirUserIcon,
  LaptopIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { OnboardingActivityTypes } from '../../OnboardingActivities.constant';

import messages from './messages';

export const useGetCustomersActivity = () => {
  const theme = useTheme();

  return {
    customers: [
      {
        value: OnboardingActivityTypes.ENTERPRISE,
        label: messages.enterprises,
        icon: <CityIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.PRIVATE,
        label: messages.private,
        icon: <IconoirUserIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.BOTH_CUSTOMER,
        label: messages.both,
        icon: (
          <div>
            <CityIcon color={theme.newUI.variationColors?.dark1} />
            <IconoirUserIcon color={theme.newUI.variationColors?.dark1} />
          </div>
        ),
      },
    ],
    sales: [
      {
        value: OnboardingActivityTypes.ONLINE,
        label: messages.online,
        icon: <LaptopIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.STORE,
        label: messages.store,
        icon: <IconoirShopIcon color={theme.newUI.variationColors?.dark1} />,
      },
      {
        value: OnboardingActivityTypes.BOTH_SALES,
        label: messages.both,
        icon: (
          <div>
            <LaptopIcon color={theme.newUI.variationColors?.dark1} />
            <IconoirShopIcon color={theme.newUI.variationColors?.dark1} />
          </div>
        ),
      },
    ],
  };
};
