import { defineMessages } from 'react-intl';

export default defineMessages({
  seller: {
    id: 'view.MyAccountLoginPage.seller.label',
    defaultMessage: 'Seller',
  },
  sellerTooltip: {
    id: 'view.MyAccountLoginPage.seller.postLabel',
    defaultMessage: 'Seller tooltip',
  },
  product: {
    id: 'view.MyAccountLoginPage.product.label',
    defaultMessage: 'Product',
  },
  salesChannel: {
    id: 'view.MyAccountLoginPage.salesChannel.label',
    defaultMessage: 'Sales channel',
  },
  salesChannelTooltip: {
    id: 'view.MyAccountLoginPage.salesChannel.postLabel',
    defaultMessage: 'Sales channel post label',
  },
  purchaseDate: {
    id: 'view.card.client.purchaseDate',
    defaultMessage: 'Purchase date',
  },
  purchaseDateTooltip: {
    id: 'view.card.client.purchaseDate.postLabel',
    defaultMessage: 'Purchase date post label',
  },
  productPrice: {
    id: 'view.MyAccountLoginPage.productPrice.label',
    defaultMessage: 'Product price',
  },
  productPriceTooltip: {
    id: 'view.MyAccountLoginPage.productPrice.postLabel',
    defaultMessage: 'Product price post label',
  },
  shouldBeStrictPositive: {
    id: 'view.createOrderFromScratch.number.shouldBeStrictPositive',
    defaultMessage: 'This field should be a positive number',
  },
  shouldBeValidNumber: {
    id: 'view.createOrderFromScratch.number.validNumber',
    defaultMessage: 'This field should be a valid number',
  },
  OnlineStore: {
    id: 'global.salesChannel.OnlineStore',
    defaultMessage: 'Online Store',
  },
  Store: {
    id: 'global.salesChannel.Store',
    defaultMessage: 'Store',
  },
  choicePartnerProductInformationPlaceholder: {
    id: 'view.MyAccountLoginPage.choicePartnerProduct.placeholder',
    defaultMessage: 'Veuillez saisir la reference de votre produit',
  },
  choicePartnerProductInformationHelper: {
    id: 'view.MyAccountLoginPage.choicePartnerProduct.helper',
    defaultMessage: 'help',
  },
  productHelperTitle: {
    id: 'view.MyAccountLoginPage.choicePartnerProduct.helper.title',
    defaultMessage: 'Où trouver votre numéro de facture ?',
  },
});
