import { Tab } from '../../core/src/atoms/Tabs/Tabs.types';

import { AppIncident, AppScheduledMaintenance } from './AppStatuses';
import { BaseAutocompleteOption } from './Autocomplete';
import {
  ActiveFilterPreset,
  FilterCategory,
  FilterSASDto,
  SelectedFilters,
} from './FilterList';
import { KEYBOARD_LAYOUT } from './KeyboardLayout';
import { WelcomePageRealizedActions } from './Onboarding';
import { RESULT_TYPE } from './Search';

export enum LOCAL_STORAGE_KEYS {
  KEYBOARD_LAYOUT = 'keyboardLayout',
  DARK_MODE = 'darkMode',
  APP_SCHEDULED_MAINTENANCES = 'appScheduledMaintenances',
  APP_INCIDENTS = 'appIncidents',
  FILTERS_LIST_FILES = 'filtersListFiles',
  FILTERS_LIST_TODOS = 'filtersListTodos',
  FILTERS_LIST_ALERTS = 'filtersListAlerts',
  FILTERS_LIST_SAS_DTO = 'filtersListSASDto',
  FILTERS_LIST_PRESET = 'filtersListPreset',
  SEARCH_RESULT_TYPES = 'search-result-types',
  MASTER_DETAIL = 'master_detail',
  LOCALE = 'locale',
  IS_FILE_STATUS_ACTIONS_DISPLAYED = 'isFileStatusActionsDisplayed',
  FILTERS_FILE_HISTORY = 'filtersFileHistory',
  DEFAULT_SELLER_ID_CONFIGURATION_CONSOLE = 'defaultSellerIdConfigurationConsole',
  DEFAULT_VIEW_CONFIGURATION_CONSOLE = 'defaultViewConfigurationConsole',
  CONFIGURATION_CONSOLE_FILTERS = 'configurationConsoleFilters',
  ON_BOARDING_REALIZED_ACTIONS = 'onBoardingRealizedActions',
  NEW_BO_ENABLED = 'newBoEnabled',
  PRICE_ORDER = 'PRICE_ORDER',
  AUTH_DUAL_LAYOUT = 'AUTH_DUAL_LAYOUT',
  FILES_LIST_FILTERS = 'newUiFilesListFilters',
  TODOS_LIST_FILTERS = 'newUiTodosListFilters',
  SELECTED_SELLER = 'selectedSeller',
  FILES_TAB = 'fileTab',
  ORDERS_TAB = 'ordersTab',
  BILLING_TAB = 'billingTab',
}

export interface LocalStorageValues {
  [LOCAL_STORAGE_KEYS.KEYBOARD_LAYOUT]: KEYBOARD_LAYOUT;
  [LOCAL_STORAGE_KEYS.DARK_MODE]: boolean;
  [LOCAL_STORAGE_KEYS.FILTERS_LIST_FILES]: FilterCategory[];
  [LOCAL_STORAGE_KEYS.FILTERS_LIST_TODOS]: FilterCategory[];
  [LOCAL_STORAGE_KEYS.FILTERS_LIST_ALERTS]: FilterCategory[];
  [LOCAL_STORAGE_KEYS.FILTERS_LIST_SAS_DTO]: FilterSASDto;
  [LOCAL_STORAGE_KEYS.FILTERS_LIST_PRESET]?: ActiveFilterPreset;
  [LOCAL_STORAGE_KEYS.SEARCH_RESULT_TYPES]: RESULT_TYPE[];
  [LOCAL_STORAGE_KEYS.LOCALE]: string;
  [LOCAL_STORAGE_KEYS.IS_FILE_STATUS_ACTIONS_DISPLAYED]: boolean;
  [LOCAL_STORAGE_KEYS.APP_SCHEDULED_MAINTENANCES]: AppScheduledMaintenance[];
  [LOCAL_STORAGE_KEYS.APP_INCIDENTS]: AppIncident[];
  [LOCAL_STORAGE_KEYS.MASTER_DETAIL]: Record<string, boolean>;
  [LOCAL_STORAGE_KEYS.DEFAULT_SELLER_ID_CONFIGURATION_CONSOLE]: string;
  [LOCAL_STORAGE_KEYS.DEFAULT_VIEW_CONFIGURATION_CONSOLE]: number;
  [LOCAL_STORAGE_KEYS.FILTERS_FILE_HISTORY]: {
    isOpen: boolean;
    defaultFilters?: Record<string, BaseAutocompleteOption[] | undefined>;
  };
  [LOCAL_STORAGE_KEYS.CONFIGURATION_CONSOLE_FILTERS]: Record<
    string,
    SelectedFilters[]
  >;
  [LOCAL_STORAGE_KEYS.ON_BOARDING_REALIZED_ACTIONS]: WelcomePageRealizedActions;
  [LOCAL_STORAGE_KEYS.FILES_TAB]: Tab[];
  [LOCAL_STORAGE_KEYS.ORDERS_TAB]: Tab[];
}
