import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $NextButtonModal = styled(Col)`
  margin-left: auto;
`;

export const $AddressRow = styled(Row)`
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: column;
`;

export const $ModalRowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;

export const $SuggestionTitle = styled('div')`
  font-size: ${({ theme }) => theme.fonts.size.medium};
  color: ${({ theme }) => theme.colors.headingTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  display: block;
  margin-bottom: 5px;
`;
