import { OwnerSummary } from '@savgroup-front-common/types';

export enum ORDER_INFORMATIONS_FIELD_NAMES {
  ORDER_REFERENCE = 'orderReference',
  ORDER_PRICE = 'orderPrice',
}
export interface OrderInformationsFieldValues {
  [ORDER_INFORMATIONS_FIELD_NAMES.ORDER_REFERENCE]: string;
  [ORDER_INFORMATIONS_FIELD_NAMES.ORDER_PRICE]: number;
}

export interface OrderInformationsValues {
  orderPrice?: number;
  orderReference?: string;
  owner?: OwnerSummary;
}

export type OrderInformationsContext = any;
