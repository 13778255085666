import { defineMessages } from 'react-intl';

export default defineMessages({
  genericVerificationError: {
    id: 'components.myaccount.claims.handling.genericVerificationError',
    defaultMessage:
      'Failed to validate the address. Please contact your customer support or try again later',
  },
  addressNotFound: {
    id: 'components.myaccount.claims.handling.addressNotFound',
    defaultMessage: 'Address not found',
  },
  houseNumberInvalid: {
    id: 'components.myaccount.claims.handling.houseNumberInvalid',
    defaultMessage: 'House number is invalid',
  },
  missingHouseNumber: {
    id: 'components.myaccount.claims.handling.missingHouseNumber',
    defaultMessage: 'House number is missing',
  },
  streetMissing: {
    id: 'components.myaccount.claims.handling.streetMissing',
    defaultMessage: 'Street is missing',
  },
  streetInvalid: {
    id: 'components.myaccount.claims.handling.streetInvalid',
    defaultMessage: 'Street is invalid',
  },
  addressInvalid: {
    id: 'components.myaccount.claims.handling.addressInvalid',
    defaultMessage: 'Invalid city/state/ZIP',
  },
  zipNotFound: {
    id: 'components.myaccount.claims.handling.zipNotFound',
    defaultMessage: 'Zip not found',
  },
  zipInvalid: {
    id: 'components.myaccount.claims.handling.zipInvalid',
    defaultMessage: 'Zip invalid',
  },
  addressMultiple: {
    id: 'components.myaccount.claims.handling.addressMultiple',
    defaultMessage: 'Multiple addresses were returned with the same zip',
  },
  addressInsufficent: {
    id: 'components.myaccount.claims.handling.addressInsufficent',
    defaultMessage: 'Insufficient/incorrect address data',
  },
  cityStateInvalid: {
    id: 'components.myaccount.claims.handling.cityStateInvalid',
    defaultMessage: 'Unverifiable city / state',
  },
  addressDeliveryInvalid: {
    id: 'components.myaccount.claims.handling.addressDeliveryInvalid',
    defaultMessage: 'Invalid delivery address',
  },
  stateInvalid: {
    id: 'components.myaccount.claims.handling.stateInvalid',
    defaultMessage: 'Invalid State',
  },
  editAddress: {
    id: 'components.myaccount.claims.confirmation.edit',
    defaultMessage: 'Edit',
  },
  suggestionModalTitle: {
    id: 'components.myaccount.claims.handling.suggestionModalTitle',
    defaultMessage: 'Suggested address',
  },
  sureToContinue: {
    id: 'components.myaccount.claims.confirmation.sureToContinue',
    defaultMessage: 'Are you sure to continue ?',
  },
  deliveryAddress: {
    id: 'components.myaccount.claims.handling.addressEntered',
    defaultMessage: 'Address entered',
  },
  next: {
    id: 'components.myaccount.claims.handling.next',
    defaultMessage: 'Suivant',
  },
  suggestedAddress: {
    id: 'components.myaccount.claims.handling.suggestedAddress',
    defaultMessage: 'Suggested address',
  },
});
