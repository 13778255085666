import { Ref, RefObject } from 'react';

const useScrollIntoView = ({
  ref,
  isScrollIntoView,
}: {
  ref:
    | Ref<HTMLInputElement>
    | RefObject<HTMLInputElement | HTMLElement | undefined | null>;
  isScrollIntoView?: boolean;
}): {
  scrollIntoView: () => void;
} => {
  if (!isScrollIntoView || typeof ref === 'function')
    {return { scrollIntoView: () => undefined };}

  const currentRef = ref?.current;
  const scrollIntoView = () =>
    currentRef
      ? currentRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      : undefined;

  return { scrollIntoView };
};

export default useScrollIntoView;
