import md5 from 'md5';
import React, { FunctionComponent, useMemo } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { AuthConfiguration } from 'authenticator/configuration';
import { useUpdateFavicon } from 'authenticator/hooks';
import {
  NetworkLinkDisplay,
  useClientInformationContext,
} from 'authenticator/view/atoms';

import useNetworkLinks from '../hooks/useNetworkLinks';

import {
  $ContentWrapper,
  $FixedImage,
  $Footer,
  $ImageWrapper,
  $PageWrapper,
} from './DualLayout.styles';
import AppHeader from './Header/AppHeader';

const DualLayout: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { clientInformation } = useClientInformationContext();
  const { networkLinks } = useNetworkLinks({
    clientId: clientInformation.clientId,
  });

  const hashedClientId = useMemo(() => md5(AuthConfiguration.clientId), []);

  const sellerId = clientInformation.sellerId || clientInformation.tenantId;

  useUpdateFavicon({ sellerId });

  return (
    <$PageWrapper>
      <$ContentWrapper>
        <AppHeader />
        <div>{children}</div>
        <div>
          {networkLinks.length > 0 && (
            <$Footer>
              {networkLinks.map((networkLink) => {
                return (
                  <NetworkLinkDisplay
                    key={networkLink.href}
                    networkLink={networkLink}
                  />
                );
              })}
            </$Footer>
          )}
        </div>
      </$ContentWrapper>
      <$ImageWrapper>
        <$FixedImage
          $url={`${APIConfiguration.uiStylesCDN}login-images/${hashedClientId}`}
        >
          <img
            alt={AuthConfiguration.clientId}
            src={`${APIConfiguration.uiStylesCDN}login-images/${hashedClientId}`}
          />
        </$FixedImage>
      </$ImageWrapper>
    </$PageWrapper>
  );
};

DualLayout.displayName = 'DualLayout';

export default DualLayout;
