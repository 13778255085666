import validator from 'validator';
import * as Yup from 'yup';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  globalMessages,
  isAddress,
} from '@savgroup-front-common/core/src/helpers';
import { AutocompleteOption } from '@savgroup-front-common/types';

const CustomerInformationAddressSchema = Yup.object().shape({
  address: Yup.string()
    .required(globalMessages.form.required)
    .test('is-address', globalMessages.form.invalidAddress, (value) => {
      return isAddress(value);
    }),
  additionalAddress: Yup.string().nullable(),
  postalCode: Yup.string()
    .required(globalMessages.form.required)
    .when(
      'countryCode',
      (countryCode: AutocompleteOption, schema: Yup.StringSchema) => {
        const countryCodeValue = countryCode?.value || '';

        return schema.test(
          'test-postal-code',
          globalMessages.form.invalidPostalCode,
          (postalCode): boolean => {
            try {
              return (
                !countryCodeValue ||
                !postalCode ||
                validator.isPostalCode(postalCode, countryCodeValue as any)
              );
            } catch (error) {
              logError(error);

              return true;
            }
          },
        );
      },
    ),
  city: Yup.string().required(globalMessages.form.required),
  countryCode: Yup.object()
    .test('countryCode', globalMessages.form.required, function test(option) {
      return option?.value;
    })
    .required(globalMessages.form.required),
});

export default CustomerInformationAddressSchema;
