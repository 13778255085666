import { NetworkLink } from 'authenticator/types';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const CLIENT_ID = 'CLIENT_ID';

const ENDPOINT = `${APIConfiguration.catalogCDN}miscellaneous/login-footer-network-links/${CLIENT_ID}.json`;

interface GetNetworkLinksArgs {
  clientId: string;
}

interface GetNetworkLinksReturnValues extends BaseBackResponse {
  links: NetworkLink[];
}

const getNetworkLinks = async ({
  clientId,
}: GetNetworkLinksArgs): Promise<
  GetNetworkLinksReturnValues | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(CLIENT_ID, clientId);

    const response = await apiCall<GetNetworkLinksReturnValues>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getNetworkLinks;
