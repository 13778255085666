import { Action, ActionMeta } from 'redux-actions';
import { all, call, takeLatest } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';

import { updateLanguage } from '../../helpers';
import { callAndGetResponse } from '../../services';

import {
  ChangeLocaleLanguagePayload,
  SetPreferenceLanguageMeta,
  types,
} from './actions';

function* setLanguagePreferenceWorker(
  action: ActionMeta<ChangeLocaleLanguagePayload, SetPreferenceLanguageMeta>,
): Generator {
  const { payload, meta } = action;
  const { locale } = payload;
  const { setLanguageEndPoint } = meta;

  yield call(
    callAndGetResponse,
    types.SET_LANGUAGE_PREFERENCE,
    setLanguageEndPoint,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        preferences: { language: locale },
      },
    },
  );
}
function* setLanguagePreferenceWatcher() {
  yield takeLatest(
    types.SET_LANGUAGE_PREFERENCE.BASE,
    setLanguagePreferenceWorker,
  );
}

function* updateLocalStorageWorker({
  payload,
}: Action<ChangeLocaleLanguagePayload>) {
  const { locale } = payload;

  yield updateLanguage(locale, true);
}
function* updateLocalStorageWatcher() {
  yield takeLatest(types.CHANGE_LOCALE_LANGUAGE.BASE, updateLocalStorageWorker);
}

export default function* i18n() {
  try {
    yield all([setLanguagePreferenceWatcher(), updateLocalStorageWatcher()]);
  } catch (error) {
    logCritical(error as any);
  }
}
