import { useMutation } from 'react-query';

import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { SellerService } from 'authenticator/api';

import messages from './messages';

const useResendSalesLeadEmailConfirmationCommand = ({
  userEmail,
}: {
  userEmail: string;
}) => {
  const { mutateAsync: handleResendEmail, isLoading } = useMutation(
    [],
    async () => {
      const response =
        await SellerService.resendSalesLeadEmailConfirmationCommand({
          email: userEmail,
        });

      if (response.failure) {
        toast.error(messages.resendEmailError);

        return undefined;
      }

      toast.success(messages.resendEmailSuccess);

      return undefined;
    },
  );

  return { handleResendEmail, isLoading };
};

export default useResendSalesLeadEmailConfirmationCommand;
