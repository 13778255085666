import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { useLayoutContext } from '../../app/layout';
import { LAYOUT_TYPES } from '../../app/layout/Layout.types';
import LoginImage from '../LoginImage';

interface StepLayoutProps {
  showImage?: boolean;
}

const StepLayout: FunctionComponent<React.PropsWithChildren<StepLayoutProps>> = ({
  showImage = false,
  children,
}) => {
  const { layoutType } = useLayoutContext();

  if (layoutType === LAYOUT_TYPES.DUAL) {
    return <>{children}</>;
  }

  return (
    <Row>
      {React.Children.map(children, (child) => (
        <Col xs={12} md={6}>
          {child}
        </Col>
      ))}
      {showImage && (
        <Col xs={false} md={6}>
          <LoginImage />
        </Col>
      )}
    </Row>
  );
};

StepLayout.displayName = 'StepLayout';

export default StepLayout;
