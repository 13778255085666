import { createRequest } from '../../services/request';

export const LOAD_LABELS = createRequest('CARRIERS/LOAD_LABELS');
export const REGENERATE_LABEL_COMMAND = createRequest(
  'CARRIERS/REGENERATE_LABEL_COMMAND',
);
export const RMA_LABEL_DOCUMENT_GENERATION = createRequest(
  'CARRIERS/RMA_LABEL_DOCUMENT_GENERATION',
);
export const LOAD_EXTERNAL_CARRIERS = createRequest(
  'CARRIERS/LOAD_EXTERNAL_CARRIERS',
);
export const LOAD_CARRIERS_PRODUCTS = createRequest('CARRIERS/PRODUCTS');
export const LOAD_TRANSPORT_METHODS = createRequest(
  'CARRIERS/TRANSPORT_METHODS',
);
export const GET_SHIPPING_LABEL = createRequest('CARRIERS/GET_SHIPPING_LABEL');

export const DOWNLOAD_SHIPPING_LABEL = createRequest('DOWNLOAD_SHIPPING_LABEL');
