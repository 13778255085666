export const createPromise = async () => {
  let resolver: (value: boolean) => void = () => {
    return undefined;
  };

  return {
    promise: new Promise<boolean>((resolve) => {
      resolver = resolve;
    }),
    resolve: resolver,
  };
};
