import React from 'react';

interface CreditCardIconProps {
  color?: string;
}

 
const CreditCardIcon: React.FC<CreditCardIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M36.6666 15H9.99992M36.6666 15V30.6667C36.6666 30.798 36.6407 30.928 36.5905 31.0493C36.5402 31.1707 36.4666 31.2809 36.3737 31.3738C36.2808 31.4666 36.1706 31.5403 36.0493 31.5905C35.9279 31.6408 35.7979 31.6667 35.6666 31.6667H4.33325C4.06804 31.6667 3.81368 31.5613 3.62615 31.3738C3.43861 31.1862 3.33325 30.9319 3.33325 30.6667V9.33333C3.33325 9.06812 3.43861 8.81376 3.62615 8.62623C3.81368 8.43869 4.06804 8.33333 4.33325 8.33333H35.6666C35.9318 8.33333 36.1862 8.43869 36.3737 8.62623C36.5612 8.81376 36.6666 9.06812 36.6666 9.33333V15V15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CreditCardIcon.displayName = 'CreditCardIcon';

export default CreditCardIcon;
