import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import { useCallback } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { LANGUAGES } from '@savgroup-front-common/constants';
import { AutocompleteOptionForHookForm } from '@savgroup-front-common/types';

import { CommonSellerService } from '../../../api';
import { changeLocaleLanguage } from '../../../domains/i18n/actions';
import { selectLocale } from '../../../domains/i18n/selectors';
import { safeFormattedIntlString } from '../../../formatters';
import { useBanners } from '../../NotificationsProvider';
import languageChooserSchema from '../LanguageChooser.schema';
import { LanguageChooserValues } from '../LanguageChooser.types';
import messages from '../messages';

const GetSellerAcceptedLanguages = 'getSellerAcceptedLanguages';

interface UseLangageChooserArgs {
  sellerId?: string;
}

interface UseLangageChooserReturnValues {
  formContext: UseFormReturn<LanguageChooserValues>;
  currentLanguage: string;
  acceptedLanguagesBySeller: string[];
  handleChangeLanguage: (locale?: AutocompleteOptionForHookForm | null) => void;
}

const useLangageChooser = ({
  sellerId,
}: UseLangageChooserArgs): UseLangageChooserReturnValues => {
  const { pushErrors } = useBanners();
  const currentLanguage = useSelector(selectLocale);
  const dispatch = useDispatch();

  const formContext = useForm<LanguageChooserValues>({
    resolver: yupResolver(languageChooserSchema),
    defaultValues: {
      language: {
        value: currentLanguage.toLocaleUpperCase(),
        label: safeFormattedIntlString(get(messages, currentLanguage)),
      },
    },
  });

  const { setValue } = formContext;

  const { data } = useQuery(
    [
      GetSellerAcceptedLanguages,
      {
        sellerId,
      },
    ],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      const response = await CommonSellerService.getSellerAcceptedLanguages({
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
  );

  const handleChangeLanguage = useCallback(
    (locale?: AutocompleteOptionForHookForm | null) => {
      if (!locale) {
        return undefined;
      }

      dispatch(
        changeLocaleLanguage({
          locale: locale.value.toLowerCase() as LANGUAGES,
        }),
      );

      return setValue('language', locale);
    },
    [dispatch, setValue],
  );

  return {
    formContext,
    currentLanguage,
    acceptedLanguagesBySeller: data || [],
    handleChangeLanguage,
  };
};

export default useLangageChooser;
