import once from 'lodash/once';
import React, { createContext, ReactElement } from 'react';

import { StepProps } from './StepsOrchestrator.types';

export type StepOrchestratorContextArgs<Values, Context> = StepProps<
  Values,
  Context
>;

export const createStepOrchestratorContextSingleton = once(<
  Values,
  Context,
>() => {
  const StepOrchestratorContext = createContext<StepProps<Values, Context>>({
    config: [],
    onSubmit: () => undefined,
    valuesFromConfig: {},
    context: {} as Context,
    jumpToStep: () => undefined,
    nextStep: () => undefined,
    previousStep: () => undefined,
    values: {} as Values,
    onClose: () => undefined,
    history: [],
    isFirstStep: false,
    valuesFromParent: {},
  });

  return StepOrchestratorContext;
});

function StepOrchestratorProvider<Values, Context>({
  children,
  values,
}: React.PropsWithChildren<{
  values: StepOrchestratorContextArgs<Values, Context>;
}>): ReactElement {
  const StepOrchestratorContext = createStepOrchestratorContextSingleton<
    Values,
    Context
  >();

  return (
    <StepOrchestratorContext.Provider value={values}>
      {children}
    </StepOrchestratorContext.Provider>
  );
}

StepOrchestratorProvider.displayName = 'StepOrchestratorProvider';

export default StepOrchestratorProvider;
