import { defineMessages } from 'react-intl';

export default defineMessages({
  complementaryInformationTitle: {
    id: 'view.MyAccountLoginPage.complementaryInformation.title',
    defaultMessage: 'I fill up information about my order',
  },
  subtitle: {
    id: 'view.MyAccountLoginPage.complementaryInformation.subtitle',
    defaultMessage: 'Informations',
  },
  back: {
    id: 'components.common.addressManagement.back',
    defaultMessage: 'Back',
  },
  next: {
    id: 'view.claimModal.next',
    defaultMessage: 'Next',
  },
  salesChannel: {
    id: 'view.MyAccountLoginPage.salesChannel.label',
    defaultMessage: 'Sales channel',
  },
  salesChannelTooltip: {
    id: 'view.MyAccountLoginPage.salesChannel.postLabel',
    defaultMessage: 'Sales channel post label',
  },
  productPrice: {
    id: 'view.MyAccountLoginPage.productPrice.label',
    defaultMessage: 'Product price',
  },
  productPriceTooltip: {
    id: 'view.MyAccountLoginPage.productPrice.postLabel',
    defaultMessage: 'Product price post label',
  },
  purchaseDate: {
    id: 'view.card.client.purchaseDate',
    defaultMessage: 'Purchase date',
  },
  purchaseDateTooltip: {
    id: 'view.card.client.purchaseDate.postLabel',
    defaultMessage: 'Purchase date post label',
  },
  requiredField: {
    id: 'components.common.breadCrumb.commentRequired',
    defaultMessage: 'This field is mandatory',
  },
  shouldBeStrictPositive: {
    id: 'view.createOrderFromScratch.number.shouldBeStrictPositive',
    defaultMessage: 'This field should be a positive number',
  },
  shouldBeValidNumber: {
    id: 'view.createOrderFromScratch.number.validNumber',
    defaultMessage: 'This field should be a valid number',
  },
  OnlineStore: {
    id: 'global.salesChannel.OnlineStore',
    defaultMessage: 'Online Store',
  },
  Store: {
    id: 'global.salesChannel.Store',
    defaultMessage: 'Store',
  },
});
