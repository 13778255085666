import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import {
  AddressSuggestionStepContext,
  AddressSuggestionStepValues,
} from '../AddressSuggestion.types';

const useAddressSuggestionStepContext = () => {
  return useStepOrchestratorContext<
    AddressSuggestionStepValues,
    AddressSuggestionStepContext
  >();
};

export default useAddressSuggestionStepContext;
