import { css } from 'styled-components';

import { buildStateAnimation } from '../helper';

const enter = css`
  opacity: 0;
`;
const steady = css`
  opacity: 1;
`;
const exit = css`
  opacity: 0;
`;

const fadeInToFadeOut = buildStateAnimation({ enter, steady, exit });

export default fadeInToFadeOut;
