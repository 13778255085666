export * from './BackEnums/addressType';
export * from './Expects';
export * from './actor';
export * from './animation';
export * from './attachmentsPlacements';
export * from './button';
export * from './carrierTypes';
export * from './civility';
export * from './claim';
export * from './claimEditionStates';
export { default as CLIENT_COMMON_ERROR } from './clientCommonError';
export * from './countryCode';
export * from './currency';
export * from './extensions';
export * from './stepsOrchestrator';
export * from './document';
export * from './fileState';
export * from './featureFlags';
export * from './keyCodes';
export * from './input';
export * from './orderState';
export * from './languages';
export * from './media';
export * from './mimeTypes';
export * from './moduleKind';
export * from './notificationTypes';
export * from './orderRegistrationType';
export * from './paymentBehaviour';
export * from './reactHookForm';
export * from './severityLevel';
export * from './supportedMethods';
export * from './sellers';
export * from './staleTime';
export * from './regex';
export * from './quotation';
export * from './target';
export * from './positions';
export * from './countries.fr';
export { default as API_COMMON_ERROR } from './apiCommonError';
export { default as HANDLING_MODES } from './handlingModes';
export { default as HOUSING_TYPES } from './housingTypes';
export * from './services';
export * from './supportedThemes';
export * from './envs';
export * from './roles';
export * from './zIndex';
export * from './General/currencySymbols';
export * from './emptyUuid';
export * from './productHolderTypes';
export { default as COMMON_QUERY_PARAMS } from './commonQueryParams';
export { default as PROFILES_NAME } from './profilesName';
export { default as WEEK_DAY } from './weekDay';
export * from './origin';
export * from './diagnosticTree';

const DEBOUNCE_TIMEOUT = 1000;

const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const STANDARD_DATE_TIME_FORMAT_DATE_FNS = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS =
  "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSXXX";

const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';

export const STANDARD_DATE_FORMAT_DATE_FNS = 'yyyy-MM-dd';

export { DEBOUNCE_TIMEOUT, STANDARD_DATE_TIME_FORMAT, STANDARD_DATE_FORMAT };
