import { ReactElement, Ref } from 'react';

import { safeFormattedIntlString } from '../../formatters';

import messages from './messages';
import useCopy from './useCopy';

interface CopyOnClickProps<Reference = any> {
  children: (args: {
    ref: Ref<Reference>;
    onClick: (
      event: React.MouseEvent<Reference, MouseEvent>,
      textToCopy: string,
    ) => Promise<void>;
    isCopied: boolean;
    textCopiedMessage?: string;
    textCopyMessage: string;
  }) => ReactElement;
  fieldName?: string;
}

export function CopyOnClick<Reference = any>({
  children,
  fieldName = '',
}: CopyOnClickProps<Reference>): ReactElement {
  const { handleClick, copied, childRef } = useCopy<Reference>();

  return children({
    ref: childRef,
    onClick: handleClick,
    isCopied: copied,
    textCopiedMessage: copied
      ? safeFormattedIntlString(messages.textCopied, { fieldName })
      : undefined,
    textCopyMessage: safeFormattedIntlString(messages.textCopy, { fieldName }),
  });
}
