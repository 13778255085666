import React from 'react';
import { Grid } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';

import { $Footer, $RowFooter } from '../StepLayout.styles';

import messages from './messages';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

interface StepFooterProps {
  handlePreviousStep?: () => void;
  noConfirmButton?: boolean;
  isFirstStep?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const StepFooter: React.FC<StepFooterProps> = ({
  handlePreviousStep = () => undefined,
  noConfirmButton = false,
  isFirstStep = false,
  isDisabled = false,
  isLoading = false,
}) => {
  return (
    <$Footer>
      <Grid>
        <$RowFooter between="xs" isFirstStep={isFirstStep}>
          {!isFirstStep && (
            <Button
              type={BUTTON_TYPES.BUTTON}
              hollow
              onClick={handlePreviousStep}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>
          )}
          {!noConfirmButton && (
            <Button
              type={BUTTON_TYPES.SUBMIT}
              primary
              icon={<ArrowNakedRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              disabled={isDisabled}
              isLoading={isLoading}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.nextButton}
              />
            </Button>
          )}
        </$RowFooter>
      </Grid>
    </$Footer>
  );
};

StepFooter.displayName = 'StepFooter';

export default StepFooter;
