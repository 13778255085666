import { CIVILITY } from '@savgroup-front-common/constants';
import {
  AutocompleteOption,
  BaseAutocompleteOption,
} from '@savgroup-front-common/types';

import messages from './messages';

const transformField = (field: string) =>
  field.replace(/[^a-zA-Z]/g, '').toLowerCase();

export const civilityOptions = [
  {
    label: messages.mister,
    value: CIVILITY.MR,
  },
  {
    label: messages.madam,
    value: CIVILITY.MRS,
  },
  {
    label: 'notSet',
    value: CIVILITY.NOT_SET,
  },
];

export const getCountryOption = (
  options: AutocompleteOption[] = [],
  value?: string,
): AutocompleteOption => {
  const firstOption = options.filter((option) => option?.value === value)?.[0];

  return firstOption || ({ value } as AutocompleteOption);
};

export const filterOption = (
  { value = '', label = '' }: BaseAutocompleteOption,
  inputValue = '',
): boolean => {
  const transformedValue = transformField(inputValue);

  return [label, value].some((val) =>
    transformField(val).includes(transformedValue),
  );
};
