import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { BaseAutocompleteOption } from '@savgroup-front-common/types';
import { CatalogService } from 'authenticator/api';
import {
  BrandGenericType,
  CreateClaimContext,
  CreateClaimValues,
  UniverseModelType,
} from 'authenticator/types';

import ChoiceProductGenericModelSchema from './ChoiceProductGenericModel.schema';
import { ChoiceProductGenericModelValues } from './ChoiceProductGenericModel.types';

const GetModelTypeGeneric = 'getModelTypeUnivers';
const GetModelTypeBrand = 'getModelTypeBrand';

interface UseChoiceProductGenericModelArgs {
  values: CreateClaimValues;
}

interface UseChoiceProductGenericModelReturnValues {
  formContext: UseFormReturn<ChoiceProductGenericModelValues>;
  modelTypesGeneric: UniverseModelType[] | undefined;
  modelTypesBrand: BrandGenericType[] | undefined;
  onSubmit: ({
    productGeneric,
    productBrand,
  }: {
    productGeneric: BaseAutocompleteOption;
    productBrand: BaseAutocompleteOption;
  }) => void;
  isLoadingModelTypeGeneric: boolean;
  isLoadingBrandGeneric: boolean;
}

const useChoiceProductGenericModel = ({
  values,
}: UseChoiceProductGenericModelArgs): UseChoiceProductGenericModelReturnValues => {
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();
  const { sellerId, universeId } = values;
  const formContext = useForm<ChoiceProductGenericModelValues>({
    resolver: yupResolver(ChoiceProductGenericModelSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      productGeneric: values.genericId
        ? {
            label: '',
            value: values.genericId || '',
          }
        : undefined,
      productBrand: values.brandId
        ? {
            label: '',
            value: values.brandId || '',
          }
        : undefined,
    },
  });

  const { watch } = formContext;

  const genericId = watch('productGeneric');

  const { data: modelTypesGeneric, isLoading: isLoadingModelTypeGeneric } =
    useQuery(
      [GetModelTypeGeneric, { sellerId, universeId }],
      async () => {
        if (!sellerId || !universeId) {
          return undefined;
        }

        const response = await CatalogService.getModelTypeGeneric({
          sellerId,
          universeId,
        });

        if (response.failure) {
          logError(`Got an empty getModelTypeGeneric`);

          return undefined;
        }

        return response.value;
      },
      {
        staleTime: Infinity,
      },
    );

  const { data: modelTypesBrand, isLoading: isLoadingBrandGeneric } = useQuery(
    [GetModelTypeBrand, { sellerId, genericId }],
    async () => {
      if (!sellerId || !genericId) {
        return undefined;
      }

      const response = await CatalogService.getBrandGeneric({
        sellerId,
        modelTypesGenericId: genericId.value,
      });

      if (response.failure) {
        logError(`Got an empty getModelTypeGeneric`);

        return undefined;
      }

      return response.value;
    },
    {
      enabled: !!genericId?.value,
      staleTime: Infinity,
    },
  );

  const onSubmit = useCallback(
    ({
      productGeneric,
      productBrand,
    }: {
      productGeneric: BaseAutocompleteOption;
      productBrand: BaseAutocompleteOption;
    }) => {
      return nextStep({
        ...values,
        genericId: productGeneric.value,
        brandId: productBrand.value,
      });
    },
    [nextStep, values],
  );

  return {
    formContext,
    modelTypesGeneric,
    modelTypesBrand,
    onSubmit,
    isLoadingModelTypeGeneric,
    isLoadingBrandGeneric,
  };
};

export default useChoiceProductGenericModel;
