import React from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { AddressInfoDto, MessageType } from '@savgroup-front-common/types';

import {
  $AddressRow,
  $SuggestionContent,
  $SuggestionTitle,
} from './SuggestedAddressModal.styles';

interface SuggestedAddressModalContentProps {
  title: MessageType;
  checked: boolean;
  address: AddressInfoDto;
}

const SuggestedAddressModalRadioButtonInformation: React.FC<
  SuggestedAddressModalContentProps
> = ({ title, checked, address }) => {
  return (
    <$AddressRow>
      <$SuggestionTitle $isChecked={checked}>
        <SafeFormattedMessageWithoutSpread message={title} />
      </$SuggestionTitle>
      <$SuggestionContent $isChecked={checked}>
        <span>
          {address.firstname} {address.lastname}
        </span>
        <span>{`${address.address}, ${address.additionalAddress || ''}`}</span>
        <span>
          {address.postalCode} {address.city}
        </span>
      </$SuggestionContent>
    </$AddressRow>
  );
};

SuggestedAddressModalRadioButtonInformation.displayName =
  'SuggestedAddressModalContent';

export default SuggestedAddressModalRadioButtonInformation;
