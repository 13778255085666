import { Notification } from '@savgroup-front-common/types';

export enum TOASTS_PROVIDER_ACTION_TYPES {
  ADD_TOASTS = 'ADD_TOASTS',
  CLOSE_TOAST = 'CLOSE_TOAST',
  CLOSE_ALL_TOASTS = 'CLOSE_ALL_TOASTS',
}

export type NotificationId = string;
export type TOASTS_PROVIDER_ACTIONS =
  | {
      type: TOASTS_PROVIDER_ACTION_TYPES.CLOSE_TOAST;
      payload: { notificationId: NotificationId };
    }
  | {
      type: TOASTS_PROVIDER_ACTION_TYPES.CLOSE_ALL_TOASTS;
    }
  | {
      type: TOASTS_PROVIDER_ACTION_TYPES.ADD_TOASTS;
      payload: { notifications: Notification[] };
    };
