import size from 'lodash/size';
import React, { FunctionComponent, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { Row } from 'react-styled-flexboxgrid';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { BUTTON_TYPES } from '@savgroup-front-common/constants/src';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { InputHookForm } from '@savgroup-front-common/core/src/molecules/Form';
import {
  BannersConsumer,
  BannersProviderContext,
} from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { StepProps } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { AuthConfiguration } from 'authenticator/configuration';

import {
  PostLabelTooltip,
  StepLayout,
  useClientInformationContext,
} from '../../../../atoms';
import { TicketNumberLoginContext } from '../../TicketNumberLogin.types';

import { useReceiptSearch } from './hooks';
import messages from './messages';
import {
  $LinkButton,
  $ReceiptDidacticImg,
  $StartButtonContainer,
  $SwitchLoginModeLink,
} from './ReceiptSearch.styles';
import { RECEIPT_SEARCH_FIELDS } from './ReceiptSearch.types';

type ReceiptSearchProps = React.PropsWithChildren<
  StepProps<
    {
      [RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID]: string;
    },
    TicketNumberLoginContext
  >
>;

const ReceiptSearch: FunctionComponent<ReceiptSearchProps> = ({
  nextStep,
  context,
}) => {
  const { notifications } = useContext(BannersProviderContext);
  const { clientInformation, sellers } = useClientInformationContext();

  const { productReferenceLoginEnable } = clientInformation;
  const { changeActiveComponent } = context;
  const { formContext, handleCheckExistingReceiptSubmit, state } =
    useReceiptSearch({
      sellers,
      nextStep,
    });
  const { handleSubmit, register, formState } = formContext;
  const { isSubmitting, isValidating, errors } = formState;
  const { isLoading } = state;

  return (
    <StepLayout showImage>
      <FormProvider {...formContext}>
        <BannersConsumer />
        <form onSubmit={handleSubmit(handleCheckExistingReceiptSubmit)}>
          <FormGroup>
            <InputHookForm
              label={messages.receiptNumber}
              name={RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID}
              register={register}
              disableBrowserAutocomplete
              errors={errors}
              placeholder={messages.receiptSearchPlaceholder}
              isRequired
              postLabel={
                <PostLabelTooltip>
                  <$ReceiptDidacticImg
                    alt="login"
                    // eslint-disable-next-line max-len
                    src={`${APIConfiguration.catalogCDN}miscellaneous/didactics/form-price-order/receipt-ref-input/${AuthConfiguration.clientId}.png`}
                  />
                  <SafeFormattedMessageWithoutSpread
                    message={messages.receiptSearchTooltip}
                  />
                </PostLabelTooltip>
              }
            />
          </FormGroup>

          {productReferenceLoginEnable && (
            <$SwitchLoginModeLink>
              <SafeFormattedMessageWithoutSpread
                message={messages.goToProductReferenceLogin}
              />
              <$LinkButton
                onClick={changeActiveComponent}
                type={BUTTON_TYPES.BUTTON}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.clickHere}
                />
              </$LinkButton>
            </$SwitchLoginModeLink>
          )}

          <FormGroup>
            <Row between="xs">
              <$StartButtonContainer>
                <Button
                  type={BUTTON_TYPES.SUBMIT}
                  isLoading={(isSubmitting && isValidating) || isLoading}
                  isError={size(errors) > 0 || size(notifications) > 0}
                  primary
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.startButton}
                  />
                </Button>
              </$StartButtonContainer>
            </Row>
          </FormGroup>
        </form>
      </FormProvider>
    </StepLayout>
  );
};

ReceiptSearch.displayName = 'ReceiptSearch';

export default ReceiptSearch;
