import React, { FunctionComponent, Ref } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Loader } from '../../../../../loader';
import { $OptionDescription, $OptionName } from '../../../Autocomplete.styles';
import {
  $PlaceAutcompleteButton,
  $PlaceAutcompleteItem,
} from '../../PlacesAutocomplete.styles';
import { Place } from '../../PlacesAutocomplete.types';

interface PlaceAutocompleteResultProps {
  place: Place;
  onSelect: (place: Place) => void;
  isSelected: boolean;
  isLoading: boolean;
  dataTestId?: string;
}
interface PlaceAutocompleteResultPropsWithRef
  extends PlaceAutocompleteResultProps {
  forwardedRef: Ref<HTMLButtonElement>;
}

const PlaceAutocompleteResult: FunctionComponent<
  PlaceAutocompleteResultPropsWithRef
> = ({ place, onSelect, isSelected, isLoading, forwardedRef, dataTestId }) => {
  return (
    <$PlaceAutcompleteItem>
      <$PlaceAutcompleteButton
        title={place.description}
        type={BUTTON_TYPES.BUTTON}
        onClick={() => onSelect(place)}
        $isSelected={isSelected}
        tabIndex={-1}
        ref={forwardedRef}
        data-testid={dataTestId}
      >
        <Row middle="xs">
          <Col>
            <$OptionName>{place.structured_formatting.main_text}</$OptionName>
            <$OptionDescription>
              {place.structured_formatting.secondary_text}
            </$OptionDescription>
          </Col>
          {isLoading && (
            <Col>
              <Loader />
            </Col>
          )}
        </Row>
      </$PlaceAutcompleteButton>
    </$PlaceAutcompleteItem>
  );
};

PlaceAutocompleteResult.displayName = 'PlaceAutocompleteResult';

export default React.forwardRef<
  HTMLButtonElement,
  PlaceAutocompleteResultProps
>((props, ref) => <PlaceAutocompleteResult forwardedRef={ref} {...props} />);
