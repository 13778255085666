import { useContext } from 'react';

import { createStepOrchestratorContextSingleton } from '../StepsOrchestrator.context';
import { StepProps } from '../StepsOrchestrator.types';

const useStepOrchestratorContext = <
  Values,
  Context = Record<string, unknown>,
>(): StepProps<Values, Context> =>
  useContext<StepProps<Values, Context>>(
    createStepOrchestratorContextSingleton<Values, Context>(),
  );

export default useStepOrchestratorContext;
