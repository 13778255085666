import get from 'lodash/get';
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { GroupBase } from 'react-select';
import SelectType from 'react-select/base';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';

import {
  BaseAutocompleteOption,
  isOptionType,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../../formatters';
import { $Container, FieldMessage, Wrapper } from '../../common';
import { getFinalFieldState } from '../../common/helpers/getFinalFieldState';
import { Label } from '../../common/Label/Label';
import { useGetAutocompleteStyle } from '../Autocomplete.hooks';
import { $SelectWrapper } from '../Autocomplete.styles';
import * as customAutocompleteComponents from '../CustomAucompleteComponents';

import {
  getSelectedOption,
  sortOptions,
} from './CreatableAutocomplete.helpers';
import {
  AUTOCOMPLETE_ACTION_TYPES,
  CreatableAutocompleteProps,
} from './CreatableAutocomplete.types';
import messages from './messages';

const CreatableAutocomplete = <
  Option extends BaseAutocompleteOption & {
    __isNew__?: boolean;
  },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: PropsWithChildren<CreatableAutocompleteProps<Option, IsMulti, Group>>,
  ref: React.ForwardedRef<SelectType<Option, IsMulti, Group>>,
): ReactElement => {
  const {
    name,
    label = null,
    postLabel,
    isRequired = false,
    value = null,
    options = [],
    isLoading = undefined,

    noOptionsMessage = messages.noOptionsMessage,
    loadingMessage = messages.loadingMessage,
    placeholder = messages.placeholder,
    createLabel = messages.createLabel,

    isDisabled = false,
    styles,
    defaultValue = null,
    componentThemeName = SUPPORTED_COMPONENTS.DEFAULT_AUTOCOMPLETE,
    isClearable,

    errors = {},
    warnings = {},
    successes = {},

    isError = false,
    isWarning = false,
    isSuccess = false,

    dataTestId,
    dataTestIdForCypress,

    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    onChange,

    prefix = '',
    suffix = '',
    suffixButton,

    filterOption,
    withoutOptionSort = false,
    isAcceptDuplicateValuesMode = false,
  } = props;

  const theme = useTheme();

  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const defaultStyles = useGetAutocompleteStyle({ status });

  const selectedValue = getSelectedOption(value, options);
  const defaultSelectedValue = getSelectedOption(defaultValue, options);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <$Container>
      {label && (
        <Label
          status={status}
          isRequired={isRequired}
          htmlFor={name}
          postLabel={postLabel}
        >
          {SafeFormattedMessage(label)}
        </Label>
      )}
      <Wrapper
        name={name}
        status={status}
        prefix={prefix}
        suffix={suffix}
        isHovered={isHovered}
        isFocused={isFocused}
        dataTestId={dataTestIdForCypress}
        suffixButton={suffixButton}
      >
        <$SelectWrapper
          data-testid={dataTestId}
          onMouseEnter={(e) => {
            if (onMouseEnter) {
              onMouseEnter(e);
            }
            setIsHovered(true);
          }}
          onMouseLeave={(e) => {
            if (onMouseLeave) {
              onMouseLeave(e);
            }
            setIsHovered(false);
          }}
        >
          <CreatableSelect
            {...props}
            onChange={(option, action) => {
              if (option && onChange) {
                if (isOptionType(option)) {
                  onChange(
                    {
                      ...option,
                      __isNew__: undefined,
                      isNew: get(option, '__isNew__'),
                    },
                    action,
                  );
                } else {
                  if (
                    action.action === AUTOCOMPLETE_ACTION_TYPES.REMOVE_VALUE &&
                    isAcceptDuplicateValuesMode
                  ) {
                    const removedValueIndex = options
                      .map((item) => item.label)
                      .indexOf(action.removedValue.label);
                    const newOptions = options.reduce(
                      (acc, item, index): any => {
                        if (index === removedValueIndex) {
                          return acc;
                        }

                        return [...acc, item];
                      },
                      [],
                    );

                    onChange(newOptions as any, action);

                    return;
                  }
                  const newOptions = option.map((item) => ({
                    ...item,
                    __isNew__: undefined,
                    isNew: get(item, '__isNew__', item.isNew),
                  }));

                  onChange(newOptions as any, action);
                }
              } else if (onChange) {
                onChange(option, action);
              }
            }}
            isLoading={isLoading}
            isDisabled={isDisabled}
            options={
              options && options.length > 0 && !withoutOptionSort
                ? sortOptions(options)
                : options
            }
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }
              setIsFocused(true);
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              setIsFocused(false);
              setIsHovered(false);
            }}
            componentThemeName={componentThemeName}
            inputId={name}
            name={name}
            noOptionsMessage={() => safeFormattedIntlString(noOptionsMessage)}
            loadingMessage={() => safeFormattedIntlString(loadingMessage)}
            placeholder={SafeFormattedMessageWithoutSpread({
              message: placeholder,
            })}
            status={status}
            themeStyled={theme}
            styles={styles || defaultStyles}
            value={selectedValue}
            defaultValue={defaultSelectedValue}
            ref={ref}
            components={customAutocompleteComponents}
            filterOption={filterOption}
            isClearable={isClearable}
            formatCreateLabel={(inputValue) => {
              return SafeFormattedMessageWithoutSpread({
                message: createLabel,
                values: { inputValue },
              });
            }}
            isValidNewOption={
              isAcceptDuplicateValuesMode
                ? (inputValue) => {
                    if (!inputValue) {
                      return false;
                    }

                    return true;
                  }
                : undefined
            }
            getNewOptionData={
              isAcceptDuplicateValuesMode
                ? (_, optionLabel): any => {
                    return {
                      value: optionLabel,
                      label: optionLabel,
                      __isNew__: true,
                    };
                  }
                : undefined
            }
          />
        </$SelectWrapper>
      </Wrapper>
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

CreatableAutocomplete.displayName = 'CreatableAutocomplete';

export default React.forwardRef(CreatableAutocomplete);
