import { Tools } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const SparePartIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Tools width={size} height={size} color={color} />;

SparePartIcon.displayName = 'SparePartIcon';

export default SparePartIcon;
