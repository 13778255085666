import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  Exception,
  logError,
} from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

interface IntlManagerResponseSuccess {
  value: Record<string, string>;
  failure: false;
}
interface IntlManagerResponseFailure {
  failure: true;
}

type IntlManagerResponse =
  | IntlManagerResponseSuccess
  | IntlManagerResponseFailure;

async function fetchIntlFallback(locale: string): Promise<IntlManagerResponse> {
  try {
    const response = await fetch(
      `${window.location.origin}/static/${locale}.json`,
    );

    return { failure: false, value: await response.json() };
  } catch (e) {
    logError(e as Exception);

    return { failure: true };
  }
}
export async function fetchIntl(locale: string): Promise<IntlManagerResponse> {
  try {
    const response = await fetch(
      `${APIConfiguration.catalogCDN}translations/${locale}.json`,
    );

    return { failure: false, value: await response.json() };
  } catch (e) {
    logError(e as Exception);

    return fetchIntlFallback(locale);
  }
}

async function fetchDynamicIntlFallback(
  locale: string,
): Promise<IntlManagerResponse> {
  try {
    const response = await fetch(
      `${window.location.origin}/static/dynamic/${locale}.json`,
    );

    return { failure: false, value: await response.json() };
  } catch (e) {
    logError(e as Exception);

    return { failure: true };
  }
}
export async function fetchDynamicIntl(
  locale: string,
): Promise<IntlManagerResponse> {
  try {
    const response = await fetch(
      `${APIConfiguration.catalogCDN}translations/dynamic/${locale}.json`,
    );

    return { failure: false, value: await response.json() };
  } catch (e) {
    logError(e as Exception);

    return fetchDynamicIntlFallback(locale);
  }
}
