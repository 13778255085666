import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { CreateClaimContext } from 'authenticator/types';

import {
  ChoiceInvoice,
  ChoicePartnerStore,
  ChoicePartnerProduct,
  ChoiceProductGenericModel,
  ChoiceProductUniversModel,
  CustomerInformationAddress,
  CustomerInformationName,
  CustomerInvoiceInformations,
} from './steps';

export enum CREATE_CLAIM_PAGE_NAME {
  CHOICE_PARTNER_STORE = 'choicePartnerStore',
  CHOICE_PARTNER_PRODUCT = 'choicePartnerProduct',
  CHOICE_INVOICE = 'choiceInvoice',
  CHOICE_UNIVERS_MODEL = 'choiceUniversModel',
  CHOICE_MODEL_GENERIC = 'choiceModelGeneric',
  CUSTOMER_INFORMATION_NAME = 'customerInformationName',
  CUSTOMER_INFORMATION_ADDRESS = 'customerInformationAddress',
  CUSTOMER_INVOICE_INFORMATION = 'customerInvoiceInformation',
}

const CREATE_CLAIM_CONFIG_MAP: Record<string, Step<CreateClaimContext>> = {
  CHOICE_INVOICE: {
    name: CREATE_CLAIM_PAGE_NAME.CHOICE_INVOICE,
    nextStep: ({ hasInvoice, hasPartner }): CREATE_CLAIM_PAGE_NAME => {
      if (hasInvoice) {
        return CREATE_CLAIM_PAGE_NAME.CUSTOMER_INVOICE_INFORMATION;
      }
      if (hasPartner) {
        return CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_PRODUCT;
      }

      return CREATE_CLAIM_PAGE_NAME.CHOICE_UNIVERS_MODEL;
    },
    Component: ChoiceInvoice,
    message: CREATE_CLAIM_PAGE_NAME.CHOICE_INVOICE,
  },
  CUSTOMER_INVOICE_INFORMATION: {
    name: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INVOICE_INFORMATION,
    nextStep: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_NAME,
    Component: CustomerInvoiceInformations,
    message: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INVOICE_INFORMATION,
  },
  CHOICE_PARTNER_PRODUCT: {
    name: CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_PRODUCT,
    nextStep: CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_STORE,
    Component: ChoicePartnerProduct,
    message: CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_PRODUCT,
  },
  CHOICE_PARTNER_STORE: {
    name: CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_STORE,
    nextStep: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_NAME,
    Component: ChoicePartnerStore,
    message: CREATE_CLAIM_PAGE_NAME.CHOICE_PARTNER_STORE,
  },
  CHOICE_UNIVERS_MODEL: {
    name: CREATE_CLAIM_PAGE_NAME.CHOICE_UNIVERS_MODEL,
    nextStep: CREATE_CLAIM_PAGE_NAME.CHOICE_MODEL_GENERIC,
    Component: ChoiceProductUniversModel,
    message: CREATE_CLAIM_PAGE_NAME.CHOICE_UNIVERS_MODEL,
  },
  CHOICE_MODEL_GENERIC: {
    name: CREATE_CLAIM_PAGE_NAME.CHOICE_MODEL_GENERIC,
    nextStep: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_NAME,
    Component: ChoiceProductGenericModel,
    message: CREATE_CLAIM_PAGE_NAME.CHOICE_MODEL_GENERIC,
  },
  CUSTOMER_INFORMATION_NAME: {
    name: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_NAME,
    nextStep: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_ADDRESS,
    Component: CustomerInformationName,
    message: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_NAME,
  },
  CUSTOMER_INFORMATION_ADDRESS: {
    name: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_ADDRESS,
    nextStep: COMMON_STEPS.SUBMIT,
    Component: CustomerInformationAddress,
    message: CREATE_CLAIM_PAGE_NAME.CUSTOMER_INFORMATION_ADDRESS,
  },
};

export const CREATE_CLAIM_CONFIG = [
  CREATE_CLAIM_CONFIG_MAP.CHOICE_INVOICE,
  CREATE_CLAIM_CONFIG_MAP.CHOICE_PARTNER_STORE,
  CREATE_CLAIM_CONFIG_MAP.CHOICE_PARTNER_PRODUCT,
  CREATE_CLAIM_CONFIG_MAP.CUSTOMER_INVOICE_INFORMATION,
  CREATE_CLAIM_CONFIG_MAP.CHOICE_UNIVERS_MODEL,
  CREATE_CLAIM_CONFIG_MAP.CHOICE_MODEL_GENERIC,
  CREATE_CLAIM_CONFIG_MAP.CUSTOMER_INFORMATION_NAME,
  CREATE_CLAIM_CONFIG_MAP.CUSTOMER_INFORMATION_ADDRESS,
];
