import isEqual from 'lodash/isEqual';

import {
  CUSTOMER_INFORMATION_FIELDS,
  CustomerInformationFormValues,
} from '../CustomerInformation.types';

interface IsAddressValidArgs {
  suggestedAddress?: CustomerInformationFormValues;
  address: CustomerInformationFormValues;
}

const isAddressValid = ({
  suggestedAddress,
  address,
}: IsAddressValidArgs): boolean => {
  if (!suggestedAddress) {
    return false;
  }

  const addressToCheck = {
    address: address[CUSTOMER_INFORMATION_FIELDS.ADDRESS].toLowerCase(),
    postalCode: address[CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE].toLowerCase(),
    city: address[CUSTOMER_INFORMATION_FIELDS.CITY].toLowerCase(),
  };

  const suggestedAddressToCheck = {
    address: suggestedAddress[
      CUSTOMER_INFORMATION_FIELDS.ADDRESS
    ].toLowerCase(),
    postalCode: suggestedAddress[
      CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE
    ].toLowerCase(),
    city: suggestedAddress[CUSTOMER_INFORMATION_FIELDS.CITY].toLowerCase(),
  };

  return isEqual(addressToCheck, suggestedAddressToCheck);
};

export default isAddressValid;
