import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ColContainer = styled(Col)`
  margin-top: ${rem(80)};
`;

export const $RowContainer = styled(Row)`
  display: flex;
  align-items: stretch;
`;

export const $ColSvgItem = styled(Col)`
  padding-left: unset;
  padding-right: unset;
  svg {
    width: 64px !important;
    height: 64px !important;
  }
`;

export const $IconSvgItem = styled('div')`
  display: block;
  width: 40px;
  height: 40px;
`;
