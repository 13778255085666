import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

import { PLACEHOLDER_TO_DISABLE_NATIVE_AUTOCOMPLETE } from '@savgroup-front-common/constants';

const Input: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  return (
    <components.Input
      {...props}
      autoComplete={PLACEHOLDER_TO_DISABLE_NATIVE_AUTOCOMPLETE}
      data-type="autocomplete"
    />
  );
};

Input.displayName = 'Input';

export default Input;
