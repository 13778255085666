import { css } from 'styled-components';

import { buildStateAnimation } from '../helper';

const enter = css`
  opacity: 0;
  transform: translateY(3rem);
`;

const steady = css`
  opacity: 1;
  transform: translateY(0);
`;

const exit = css`
  opacity: 0;
  transform: translateY(0);
`;

const slideBottomToFadeOut = buildStateAnimation({ enter, steady, exit });

export default slideBottomToFadeOut;
