import React, { FunctionComponent } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { BaseLoader } from '../BaseLoader';

import { $FullScreenLoader } from './FullScreenLoader.styles';

interface FullScreenLoaderProps {
  message?: MessageType | string;
  size?: string;
  hasMargin?: boolean;
  align?: 'center' | 'start' | 'end';
  dataTestId?: string;
  isOpen?: boolean;
  isIntlInitialized?: boolean;
}

const FullScreenLoader: FunctionComponent<FullScreenLoaderProps> = ({
  message,
  size = '32px',
  hasMargin = true,
  align = 'center',
  dataTestId,
  isOpen = false,
  isIntlInitialized = true,
}) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <$FullScreenLoader>
      <BaseLoader
        messageList={message ? [message] : undefined}
        size={size}
        hasMargin={hasMargin}
        align={align}
        dataTestId={dataTestId}
        isIntlInitialized={isIntlInitialized}
      />
    </$FullScreenLoader>
  );
};

FullScreenLoader.displayName = 'FullScreenLoader';

export default FullScreenLoader;
