import { defineMessages } from 'react-intl';

export default defineMessages({
  mainTitle: {
    id: 'authenticator.onboarding.domains.mainTitle',
    defaultMessage: 'Select your activity domains',
  },
  mainDescription: {
    id: 'authenticator.onboarding.domains.mainDescription',
    defaultMessage:
      'You have the possibility to select multiple domains to qualify your activity',
  },
  next: {
    id: 'authenticator.onboarding.button.next',
    defaultMessage: 'Next',
  },
  highTech: {
    id: 'authenticator.onboarding.domains.highTech',
    defaultMessage: 'High Tech',
  },
  homeAppliances: {
    id: 'authenticator.onboarding.domains.homeAppliances',
    defaultMessage: 'Home Appliances',
  },
  handiwork: {
    id: 'authenticator.onboarding.domains.handiwork',
    defaultMessage: 'Handiwork',
  },
  sport: {
    id: 'authenticator.onboarding.domains.sport',
    defaultMessage: 'Sport',
  },
  mobility: {
    id: 'authenticator.onboarding.domains.mobility',
    defaultMessage: 'Mobility',
  },
  furnishing: {
    id: 'authenticator.onboarding.domains.furnishing',
    defaultMessage: 'Furnishing',
  },
  beauty: {
    id: 'authenticator.onboarding.domains.beauty',
    defaultMessage: 'Beauty',
  },
  mode: {
    id: 'authenticator.onboarding.domains.mode',
    defaultMessage: 'Mode',
  },
  jewelry: {
    id: 'authenticator.onboarding.domains.jewelry',
    defaultMessage: 'Jewelry',
  },
  childcare: {
    id: 'authenticator.onboarding.domains.childcare',
    defaultMessage: 'Childcare',
  },
  games: {
    id: 'authenticator.onboarding.domains.games',
    defaultMessage: 'Games',
  },
  other: {
    id: 'authenticator.onboarding.domains.other',
    defaultMessage: 'Other',
  },
});
