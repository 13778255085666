import styled from 'styled-components';

export const $FlagImageOption = styled.img`
  display: inline-block;
  max-width: 0.9rem;
  margin-right: 0.5rem;
`;

export const $FlagImageValue = styled.img`
  display: inline-block;
  max-width: 0.9rem;
  margin-right: 0.4rem;
  margin-bottom: -1px;
`;

export const $DisplayValue = styled.div`
  margin-right: 0.9rem;
`;
