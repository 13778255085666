import React, { FunctionComponent } from 'react';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { AuthConfiguration } from '../../../configuration';
import { useClientInformationContext } from '../../atoms';

import messages from './messages';

const ExternalLogin: FunctionComponent = () => {
  const { clientInformation } = useClientInformationContext();

  const isEnableLocalLogin =
    clientInformation?.phoneNumberLoginEnable ||
    clientInformation?.productReferenceLoginEnable;

  const visibleExternalProviders =
    clientInformation?.externalProviders.filter(
      (externalProvider) => externalProvider.displayName,
    ) || [];

  const isBannerOpen = !visibleExternalProviders.length && !isEnableLocalLogin;

  return (
    <>
      <Banner isOpen={isBannerOpen} notificationType={NOTIFICATION_TYPES.ERROR}>
        <SafeFormattedMessageWithoutSpread
          message={messages.invalidLoginRequest}
        />
      </Banner>

      {visibleExternalProviders.length > 0 && !isEnableLocalLogin && (
        <>
          <Heading level={3}>External Login</Heading>
          <ul>
            {visibleExternalProviders.map((provider) => {
              const queryParams = `?provider=${provider.authenticationScheme}`;
              const href = `${AuthConfiguration.authority}/ExternalLogin${queryParams}`;

              return (
                <li key={provider.displayName}>
                  <ButtonLink href={href}>{provider.displayName}</ButtonLink>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

ExternalLogin.displayName = 'ExternalLogin';

export default ExternalLogin;
