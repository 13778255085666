import { useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import {
  ADDRESS_SUGGESTION_ORIGIN,
  AddressSuggestionFormValues,
} from '../AddressSuggestion.types';

import useAddressSuggestionStepContext from './useAddressSuggestionStepContext';

const useAddressSuggestionSubmit = () => {
  const { values, onSubmit } = useAddressSuggestionStepContext();
  const [isLoading, setIsLoading] = useState(false);
  const { pushErrors } = useBanners();
  const handleAddressSuggestionSubmit: SubmitHandler<AddressSuggestionFormValues> =
    useCallback(
      async ({ origin }) => {
        try {
          setIsLoading(true);

          if (origin === ADDRESS_SUGGESTION_ORIGIN.SUGGESTION) {
            await onSubmit({
              owner: values.suggestedOwner,
            });
          }

          if (origin === ADDRESS_SUGGESTION_ORIGIN.USER_VALUES) {
            await onSubmit();
          }
        } catch (errors) {
          pushErrors(errors as Error[]);
          setIsLoading(false);
        }
      },
      [onSubmit, pushErrors, values.suggestedOwner],
    );

  return { handleAddressSuggestionSubmit, isLoading };
};

export default useAddressSuggestionSubmit;
