/// <reference types="@types/googlemaps" />

export interface PlaceAutocompleteOnSelectArgs {
  city?: string;
  countryCode?: string;
  postalCode?: string;
  street?: string;
  streetNumber?: string;
}

export enum PLACE_AUTOCOMPLETE_ACTION_TYPES {
  LOAD_PREDICTION_END = 'LOAD_PREDICTION_END',
  LOAD_PREDICTION_FAIL = 'LOAD_PREDICTION_FAIL',
  LOAD_PLACE_ID_START = 'LOAD_PLACE_ID_START',
  LOAD_PLACE_ID_END = 'LOAD_PLACE_ID_END',
  CHANGE_TEXT_INPUT = 'CHANGE_TEXT_INPUT',
  OPEN_DROPDOWN = 'OPEN_DROPDOWN',
  CLOSE_DROPDOWN = 'CLOSE_DROPDOWN',
  ARROW_NAVIGATION = 'ARROW_NAVIGATION',
  CLEAR_INPUT = 'CLEAR_INPUT',
  ON_SELECT = 'ON_SELECT',
}

export enum PLACE_TYPE {
  GOUV = 'GOUV',
  GOOGLE = 'GOOGLE',
}

export interface GouvPlace extends google.maps.places.AutocompletePrediction {
  originType: PLACE_TYPE.GOUV;
  data: PlaceAutocompleteOnSelectArgs;
}
export interface GooglePlace extends google.maps.places.AutocompletePrediction {
  originType: PLACE_TYPE.GOOGLE;
}
export type Place = GouvPlace | GooglePlace;
