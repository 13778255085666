import { COMMON_STEPS } from '@savgroup-front-common/constants/src/shared';

import { WizardStep } from './Wizard.types';

const byName =
  <Context, Step extends WizardStep<Context>>(name: string) =>
  (item: Step) =>
    item.name === name;

interface GetOrderedWizardStepsArgs<Context, Step extends WizardStep<Context>> {
  config: Step[];
  context: Partial<Context>;
}

const getNextStepNameFactory =
  <Context, Step extends WizardStep<Context>>(context: Partial<Context>) =>
  (step: Step): string | undefined => {
    const nextStep = step?.nextStep;

    if (typeof nextStep === 'function') {
      return nextStep(context);
    }

    return nextStep;
  };
const getStepFactory =
  <Context, Step extends WizardStep<Context>>(config: Step[]) =>
  (stepName: string): Step => {
    const step = config.find(byName(stepName)) || null;

    if (!step) {
      throw new Error(`[Wizard] Can't find the step: ${stepName}`);
    }

    return step;
  };

export function getOrderedWizardSteps<
  Context,
  Step extends WizardStep<Context>,
>({ config, context }: GetOrderedWizardStepsArgs<Context, Step>): Step[] {
  const getNextStepName = getNextStepNameFactory(context);
  const getStep = getStepFactory(config);
  let step = getStep(config[0].name);

  let nextStepName = getNextStepName(step);

  const orderedWizardSteps: Step[] = [];

  do {
    nextStepName = getNextStepName(step);
    orderedWizardSteps.push(step);
    if (nextStepName && nextStepName !== COMMON_STEPS.SUBMIT) {
      step = getStep(nextStepName);
    }
  } while (
    typeof nextStepName === 'string' &&
    nextStepName !== COMMON_STEPS.SUBMIT
  );

  return orderedWizardSteps;
}
