import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
  CustomerFileSummary,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/shortInfo`;

interface GetShortFileInfoQueryPayload {
  fileId: string;
  withAddInfoLabel?: boolean;
}

type GetShortFileInfoQuery = (
  payload: GetShortFileInfoQueryPayload,
) => Promise<BackResponse<CustomerFileSummary> | BackResponseFailure>;

export const getShortFileInfoQuery: GetShortFileInfoQuery = async ({
  fileId,
  withAddInfoLabel = false,
}) => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BackResponse<CustomerFileSummary>>(
      buildUrl(url, {
        withAddInfoLabel,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
