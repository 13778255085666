import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

const STATUS_PAGE_URL = ((): string => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return 'https://reversiodemo.statuspage.io';
    case APP_ENVS.DEV:
    case APP_ENVS.LOCAL:
    case APP_ENVS.DEFAULT:
      return 'https://reversiodev.statuspage.io';
    case APP_ENVS.PROD:
    default:
      return 'https://status.revers.io';
  }
})();

export default STATUS_PAGE_URL;
