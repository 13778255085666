import React from 'react';
import { useTheme } from 'styled-components';

import {
  BykeIcon,
  DishWasherIcon,
  GymIcon,
  LaptopIcon,
  PuzzleIcon,
  RefreshDoubleIcon,
  ShirtIcon,
  SofaIcon,
  SparePartIcon,
  StrollerIcon,
  TreeStarsIcon,
  WatchIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { OnboardingActivityTypes } from '../../OnboardingActivities.constant';

import messages from './messages';

export const useGetDomainsActivity = () => {
  const theme = useTheme();

  return [
    {
      value: OnboardingActivityTypes.HIGH_TECH,
      label: messages.highTech,
      icon: <LaptopIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.HOME_APPLIANCES,
      label: messages.homeAppliances,
      icon: <DishWasherIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.HANDIWORK,
      label: messages.handiwork,
      icon: <SparePartIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.SPORT,
      label: messages.sport,
      icon: <GymIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.MOBILITY,
      label: messages.mobility,
      icon: <BykeIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.FURNISHING,
      label: messages.furnishing,
      icon: <SofaIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.BEAUTY,
      label: messages.beauty,
      icon: <TreeStarsIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.MODE,
      label: messages.mode,
      icon: <ShirtIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.JEWELRY,
      label: messages.jewelry,
      icon: <WatchIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.CHILDCARE,
      label: messages.childcare,
      icon: <StrollerIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.GAMES,
      label: messages.games,
      icon: <PuzzleIcon color={theme.newUI.variationColors?.dark1} />,
    },
    {
      value: OnboardingActivityTypes.OTHER,
      label: messages.other,
      icon: <RefreshDoubleIcon color={theme.newUI.variationColors?.dark1} />,
    },
  ];
};
