import { defineMessages } from 'react-intl';

export default defineMessages({
  copyToClipboard: {
    id: 'view.card.product.copyToClipboard',
    defaultMessage: 'Copy to clipboard',
  },
  textCopied: {
    id: 'view.orders.textCopied',
    defaultMessage: 'Copied!',
  },
});
