import { defineMessages } from 'react-intl';

export default defineMessages({
  firstNameLabel: {
    id: 'view.card.client.firstName',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'view.card.client.lastName',
    defaultMessage: 'Last Name',
  },
  companyNameLabel: {
    id: 'view.createOrderFromScratch.companyName',
    defaultMessage: 'Company name',
  },
  emailLabel: {
    id: 'view.card.client.email',
    defaultMessage: 'Your email',
  },
  phoneNumberLabel: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone Number',
  },
});
