import React, { FunctionComponent } from 'react';

import { Loader } from '../../../loader';

import { FieldLoaderStyled } from './FieldLoader.styles';

const FieldLoader: FunctionComponent<
  React.PropsWithChildren<{ name?: string }>
> = ({ name = undefined }) => {
  return (
    <FieldLoaderStyled>
      <Loader dataTestId={`loader-${name}`} />
    </FieldLoaderStyled>
  );
};

FieldLoader.displayName = 'FieldLoader';

export default FieldLoader;
