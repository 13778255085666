import styled, { css } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types/src/Theme';

import { simpleShakeAnimation } from '../../animations/simpleAnimation';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import {
  getCustomComponentProperty,
  rem,
  styledGetCustomComponentProperty,
  SUPPORTED_ICON_POSITIONS,
} from '../../helpers/theme';

import {
  getBackgroundColor,
  getBorder,
  getBorderRadius,
  getButtonPadding,
  getButtonsTextTransform,
  getColor,
  getFontSize,
  getFontWeight,
  getHeight,
  getIconColor,
  getMinHeight,
  getOnHoverBackgroundColor,
  getOnHoverBorder,
  getOnHoverColor,
  getWhiteSpace,
  getWidth,
  handleUnderlineProps,
} from './Button.helpers';
import { InnerButtonStyledProps } from './Button.types';
import {
  handleHollowProps,
  handleHoverAsHollow,
} from './helpers/getHollowButtonStylesFromProps';
import getNakedButtonStylesFromProps from './helpers/getNakedButtonStylesFromProps';

const handleSquareIconButton = (props: InnerButtonStyledProps) => {
  const { $isSquareIconButton, theme, small } = props;

  if (!$isSquareIconButton) {
    return null;
  }

  const height = small
    ? getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS,
        property: SUPPORTED_PROPERTIES.HEIGHT,
      })
    : getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
        property: SUPPORTED_PROPERTIES.HEIGHT,
      });

  const color = getIconColor(props);
  const onHoverColor = getOnHoverColor(props);

  return css<InnerButtonStyledProps>`
    & svg {
      color: ${color};
      width: 20px;
      height: 20px;
    }

    &:hover svg {
      color: ${onHoverColor};
    }

    width: ${height};
    padding: 0;
    height: ${height};
    max-height: ${height};
    flex-direction: column;
    justify-content: center;
    & > span {
      width: 100%;
      height: 100%;
    }
  `;
};

const handleMobileView = () => {
  return css<InnerButtonStyledProps>`
    @media screen and (max-width: 576px) {
      width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
      & > button {
        width: 100%;
      }
    }
  `;
};

const handleIconButton = ({
  $isSquareIconButton,
  icon,
  position,
  $isNewUiEnabled,
}: InnerButtonStyledProps) => {
  if ($isSquareIconButton || icon) {
    return null;
  }

  const defaultStyle = css`
    & svg {
      color: ${getIconColor};
      flex-shrink: 0;
      max-height: ${rem(20)};
      max-width: ${rem(20)};
      transition-property: fill;
      transition-duration: 0.3s;
      ${decelerateTimingFunctionTransition};
    }

    &:focus,
    &:active,
    &:hover {
      & svg {
        color: ${$isNewUiEnabled ? getIconColor : getOnHoverColor};
      }
    }
  `;

  if (!position) {
    return css`
      ${defaultStyle};
    `;
  }

  if (position === SUPPORTED_ICON_POSITIONS.RIGHT) {
    return css`
      & > span > svg,
      & > svg {
        margin-left: 8px;
      }

      ${defaultStyle};
    `;
  }

  return css`
    & > span > svg,
    & > svg {
      margin-right: 8px;
    }

    ${defaultStyle};
  `;
};

export const buttonStyleMixin = css<InnerButtonStyledProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  box-sizing: border-box;
  font-size: ${getFontSize};
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  outline: 0;

  padding: ${getButtonPadding};
  font-weight: ${getFontWeight};
  text-transform: ${getButtonsTextTransform};

  height: ${getHeight};
  width: ${getWidth};

  min-width: ${({ $isIcon, $isOnlyIcon, $isMinimal, small }) => {
    if ($isMinimal || small) {
      return '0';
    }

    if (!$isIcon) {
      return '85px';
    }

    if ($isOnlyIcon) {
      return undefined;
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    });
  }};
  min-height: ${getMinHeight};

  position: relative;
  overflow: hidden;

  cursor: ${(props: InnerButtonStyledProps) => {
    if (props.isLoading) {
      return 'progress';
    }
    if (props.disabled) {
      return 'not-allowed';
    }

    return 'pointer';
  }};

  border-radius: ${(props: InnerButtonStyledProps) =>
    props.rounded ? '100px' : null};
  border-radius: ${getBorderRadius};

  border-top-left-radius: ${({ $isSuffix }) => ($isSuffix ? 0 : null)};
  border-bottom-left-radius: ${({ $isSuffix }) => ($isSuffix ? 0 : null)};

  color: ${getColor};

  & > span {
    display: flex;
    flex-direction: row;
  }

  background-color: ${getBackgroundColor};
  border: ${getBorder};

  white-space: ${getWhiteSpace};

  &:active {
    transform: ${(props: InnerButtonStyledProps) =>
      props.disabled ? null : `scale(${props.theme.interactions.activeScale})`};
    opacity: ${({ disabled }: InnerButtonStyledProps) =>
      disabled ? null : 0.8};
  }
  &:hover {
    color: ${getOnHoverColor};
    border: ${getOnHoverBorder};
    background-color: ${({ naked }) =>
      naked ? 'transparent' : getOnHoverBackgroundColor};
  }
  &:focus {
    color: ${getOnHoverColor};
    border: ${getOnHoverBorder};
    background-color: ${({ naked }) =>
      naked ? 'transparent' : getOnHoverBackgroundColor};
  }

  ${handleMobileView};

  ${handleSquareIconButton};
  ${handleHoverAsHollow};
  ${handleHollowProps};
  ${handleUnderlineProps};
  ${getNakedButtonStylesFromProps};
  ${handleIconButton};

  transition: border 500ms;
  transition-property: background-color, transform, opacity, color, padding-left,
    border-color;
  transition-duration: 0.5s;
  ${({ startShake }: InnerButtonStyledProps) =>
    startShake ? simpleShakeAnimation : null};
  ${decelerateTimingFunctionTransition};

  & span {
    width: ${({ $isIcon }) => ($isIcon ? 'auto' : '100%')};
    margin: ${({ $isIcon }) => ($isIcon ? 'auto' : null)};
  }

  scale: ${({ $isPressed }) => ($isPressed ? 0.95 : 1)};
`;

export const $InnerButtonStyled = styled.button<InnerButtonStyledProps>`
  ${buttonStyleMixin};
`;

export const $Count = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${rem(18)};
  height: ${rem(18)};
  display: inline-block;
  border-radius: ${rem(3)};
  background: ${({ theme }) => theme.colors.darkBackground};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(18)};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
`;
