import { useContext, useMemo } from 'react';

import { Notification } from '@savgroup-front-common/types';

import { MessageDescriptorMap } from '../../../../helpers';
import BannersProviderContext from '../BannersProvider.context';
import { NotificationId } from '../BannersProvider.types';

import usePushError, { UsePushErrorReturnType } from './usePushError';
import usePushErrors, { UsePushErrorsReturnType } from './usePushErrors';
import usePushNotification, {
  UsePushNotificationReturnType,
} from './usePushNotification';

interface UseBannersReturnValue {
  removeAllNotifications: () => void;
  removeNotification: (notificationId: string) => void;
  pushNotification: UsePushNotificationReturnType;
  pushNotifications: (notifications: Notification[]) => NotificationId[];
  pushError: UsePushErrorReturnType;
  pushErrors: UsePushErrorsReturnType;
}

interface UseBannersArgs {
  extendedMessages?: MessageDescriptorMap;
}

const useBanners = ({
  extendedMessages = undefined,
}: UseBannersArgs = {}): UseBannersReturnValue => {
  const { removeAllNotifications, pushNotifications, removeNotification } =
    useContext(BannersProviderContext);
  const pushNotification = usePushNotification();
  const pushError = usePushError({ extendedMessages });
  const pushErrors = usePushErrors({ extendedMessages });

  return useMemo(
    () => ({
      removeAllNotifications,
      pushNotifications,
      pushNotification,
      pushError,
      pushErrors,
      removeNotification,
    }),
    [
      removeAllNotifications,
      pushNotifications,
      pushNotification,
      pushError,
      pushErrors,
      removeNotification,
    ],
  );
};

export default useBanners;
