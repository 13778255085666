import { defineMessages } from 'react-intl';

export default defineMessages({
  orderSearchError: {
    id: 'view.card.client.orderSearchError',
    defaultMessage: 'An error has occurred',
  },
  orderReferenceLabel: {
    id: 'view.card.client.orderReferenceLabel',
    defaultMessage: 'Order Reference Label',
  },
  phoneNumberLabel: {
    id: 'view.MyAccountLoginPage.phoneNumber.label',
    defaultMessage: 'Phone number',
  },
  phoneNumberPlaceholder: {
    id: 'view.MyAccountLoginPage.phoneNumber.placeholder',
    defaultMessage: 'Fill phone number 4 last digits',
  },
  phoneNumberIsRequired: {
    id: 'view.MyAccountLoginPage.schema.phoneNumberIsRequired',
    defaultMessage: 'Phone number is required',
  },
  orderNumberIsRequired: {
    id: 'view.MyAccountLoginPage.schema.orderNumberIsRequired',
    defaultMessage: 'Order number is required',
  },
  digitsOnlyAreAuthorized: {
    id: 'view.MyAccountLoginPage.schema.digitsOnlyAreAuthorized',
    defaultMessage: 'Please fill phone number 4 last digits',
  },
});
