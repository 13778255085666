import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared/envs';

interface APIConfigurationInterface {
  actor: string;
  authorization: string;
  base: string;
  baseBlobStorage: string;
  carrier: string;
  catalog: string;
  catalogCDN: string;
  cdn: string;
  claim: string;
  config: string;
  control: string;
  document: string;
  geographical: string;
  ids: string;
  imageCDN: string;
  import: string;
  marketplace: string;
  notification: string;
  owner: string;
  payment: string;
  repairer: string;
  search: string;
  seller: string;
  sparePart: string;
  uiStylesCDN: string;
  warranty: string;
  workflow: string;
  enterpriseAuchan: string;
  businessIntelligence: string;
  graphQlGateway: string;
}

const API: Record<APP_ENVS, APIConfigurationInterface> = {
  LOCAL: {
    actor: '//localhost/ReverseActorService/',
    authorization: '//localhost/ReverseAuthorizationService/',
    base: '//localhost/',
    baseBlobStorage: 'https://reversedevstorage.blob.core.windows.net/',
    carrier: '//localhost/ReverseCarrierService/',
    catalog: '//localhost/ReverseCatalogService/',
    catalogCDN: 'https://reversedevstorage.blob.core.windows.net/catalog/',
    cdn: 'https://reversedevstorage.blob.core.windows.net/',
    claim: '//localhost/ReverseClaimService/',
    config: '//localhost/ReverseSellerService/',
    control: '//localhost/ReverseControlService/',
    document: '//localhost/ReverseDocumentService/',
    geographical: '//localhost/ReverseGeographicalService/',
    ids: '//localhost:5000/',
    imageCDN:
      'https://reversedevstorage.blob.core.windows.net/publicdocuments/',
    import: '//localhost/ReverseImportService/',
    marketplace: '//localhost/ReverseMarketplaceService/',
    notification: '//localhost/ReverseNotificationService/',
    owner: '//localhost/ReverseOwnerService/',
    payment: '//localhost/ReversePaymentService/',
    repairer: '//localhost/ReverseRepairerService/',
    search: '//localhost/ReverseSearchService/',
    seller: '//localhost/ReverseSellerService/',
    sparePart: '//localhost/ReverseSparePartsService/',
    uiStylesCDN: 'https://reversedevstorage.blob.core.windows.net/ui-styles/',
    warranty: '//localhost/ReverseWarrantyService/',
    workflow: '//localhost/ReverseWorkflowService/',
    enterpriseAuchan: '//localhost/ReverseEnterpriseAuchanService/',
    businessIntelligence: '//localhost/ReverseBusinessIntelligenceService/',
    graphQlGateway: '//localhost/ReverseGraphQLGatewayService/graphql',
  },
  DEFAULT: {
    actor: 'https://dev-api.revers.io/ReverseActorService/',
    authorization: 'https://dev-api.revers.io/ReverseAuthorizationService/',
    base: 'https://dev-api.revers.io/',
    baseBlobStorage: 'https://reversedevstorage.blob.core.windows.net/',
    carrier: 'https://dev-api.revers.io/ReverseCarrierService/',
    catalog: 'https://dev-api.revers.io/ReverseCatalogService/',
    catalogCDN: 'https://reversedevstorage.blob.core.windows.net/catalog/',
    cdn: 'https://reversedevstorage.blob.core.windows.net/',
    claim: 'https://dev-api.revers.io/ReverseClaimService/',
    config: 'https://dev-api.revers.io/ReverseSellerService/',
    control: 'https://dev-api.revers.io/ReverseControlService/',
    document: 'https://dev-api.revers.io/ReverseDocumentService/',
    geographical: 'https://dev-api.revers.io/ReverseGeographicalService/',
    ids: 'https://dev-api.revers.io:5000/',
    imageCDN:
      'https://reversedevstorage.blob.core.windows.net/publicdocuments/',
    import: 'https://dev-api.revers.io/ReverseImportService/',
    marketplace: 'https://dev-api.revers.io/ReverseMarketplaceService/',
    notification: 'https://dev-api.revers.io/ReverseNotificationService/',
    owner: 'https://dev-api.revers.io/ReverseOwnerService/',
    payment: 'https://dev-api.revers.io/ReversePaymentService/',
    repairer: 'https://dev-api.revers.io/ReverseRepairerService/',
    search: 'https://dev-api.revers.io/ReverseSearchService/',
    seller: 'https://dev-api.revers.io/ReverseSellerService/',
    sparePart: 'https://dev-api.revers.io/ReverseSparePartsService/',
    uiStylesCDN: 'https://reversedevstorage.blob.core.windows.net/ui-styles/',
    warranty: 'https://dev-api.revers.io/ReverseWarrantyService/',
    workflow: 'https://dev-api.revers.io/ReverseWorkflowService/',
    enterpriseAuchan:
      'https://dev-api.revers.io/ReverseEnterpriseAuchanService/',
    businessIntelligence:
      'https://dev-api.revers.io/ReverseBusinessIntelligenceService/',
    graphQlGateway:
      'https://dev-api.revers.io/ReverseGraphQLGatewayService/graphql',
  },
  DEV: {
    actor: 'https://dev-api.revers.io/ReverseActorService/',
    authorization: 'https://dev-api.revers.io/ReverseAuthorizationService/',
    base: 'https://dev-api.revers.io/',
    baseBlobStorage: 'https://reversedevstorage.blob.core.windows.net/',
    carrier: 'https://dev-api.revers.io/ReverseCarrierService/',
    catalog: 'https://dev-api.revers.io/ReverseCatalogService/',
    catalogCDN: 'https://reversedevstorage.blob.core.windows.net/catalog/',
    cdn: 'https://reversedevstorage.blob.core.windows.net/',
    claim: 'https://dev-api.revers.io/ReverseClaimService/',
    config: 'https://dev-api.revers.io/ReverseSellerService/',
    control: 'https://dev-api.revers.io/ReverseControlService/',
    document: 'https://dev-api.revers.io/ReverseDocumentService/',
    geographical: 'https://dev-api.revers.io/ReverseGeographicalService/',
    ids: 'https://dev-api.revers.io:5000/',
    imageCDN:
      'https://reversedevstorage.blob.core.windows.net/publicdocuments/',
    import: 'https://dev-api.revers.io/ReverseImportService/',
    marketplace: 'https://dev-api.revers.io/ReverseMarketplaceService/',
    notification: 'https://dev-api.revers.io/ReverseNotificationService/',
    owner: 'https://dev-api.revers.io/ReverseOwnerService/',
    payment: 'https://dev-api.revers.io/ReversePaymentService/',
    repairer: 'https://dev-api.revers.io/ReverseRepairerService/',
    search: 'https://dev-api.revers.io/ReverseSearchService/',
    seller: 'https://dev-api.revers.io/ReverseSellerService/',
    sparePart: 'https://dev-api.revers.io/ReverseSparePartsService/',
    uiStylesCDN: 'https://reversedevstorage.blob.core.windows.net/ui-styles/',
    warranty: 'https://dev-api.revers.io/ReverseWarrantyService/',
    workflow: 'https://dev-api.revers.io/ReverseWorkflowService/',
    enterpriseAuchan:
      'https://dev-api.revers.io/ReverseEnterpriseAuchanService/',
    businessIntelligence:
      'https://dev-api.revers.io/ReverseBusinessIntelligenceService/',
    graphQlGateway:
      'https://dev-api.revers.io/ReverseGraphQLGatewayService/graphql',
  },
  DEMO: {
    actor: 'https://demo-api.revers.io/ReverseActorService/',
    authorization: 'https://demo-api.revers.io/ReverseAuthorizationService/',
    base: 'https://demo-api.revers.io/',
    baseBlobStorage: 'https://demoreversestorage.blob.core.windows.net/',
    carrier: 'https://demo-api.revers.io/ReverseCarrierService/',
    catalog: 'https://demo-api.revers.io/ReverseCatalogService/',
    catalogCDN: 'https://demoreversestorage.blob.core.windows.net/catalog/',
    cdn: 'https://demoreversestorage.blob.core.windows.net/',
    claim: 'https://demo-api.revers.io/ReverseClaimService/',
    config: 'https://demo-api.revers.io/ReverseSellerService/',
    control: 'https://demo-api.revers.io/ReverseControlService/',
    document: 'https://demo-api.revers.io/ReverseDocumentService/',
    geographical: 'https://demo-api.revers.io/ReverseGeographicalService/',
    ids: 'https://demo-api.revers.io:5000/',
    imageCDN:
      'https://demoreversestorage.blob.core.windows.net/publicdocuments/',
    import: 'https://demo-api.revers.io/ReverseImportService/',
    marketplace: 'https://demo-api.revers.io/ReverseMarketplaceService/',
    notification: 'https://demo-api.revers.io/ReverseNotificationService/',
    owner: 'https://demo-api.revers.io/ReverseOwnerService/',
    payment: 'https://demo-api.revers.io/ReversePaymentService/',
    repairer: 'https://demo-api.revers.io/ReverseRepairerService/',
    search: 'https://demo-api.revers.io/ReverseSearchService/',
    seller: 'https://demo-api.revers.io/ReverseSellerService/',
    sparePart: 'https://demo-api.revers.io/ReverseSparePartsService/',
    uiStylesCDN: 'https://demoreversestorage.blob.core.windows.net/ui-styles/',
    warranty: 'https://demo-api.revers.io/ReverseWarrantyService/',
    workflow: 'https://demo-api.revers.io/ReverseWorkflowService/',
    enterpriseAuchan:
      'https://demo-api.revers.io/ReverseEnterpriseAuchanService/',
    businessIntelligence:
      'https://demo-api.revers.io/ReverseBusinessIntelligenceService/',
    graphQlGateway:
      'https://demo-api.revers.io/ReverseGraphQLGatewayService/graphql',
  },
  PREPROD: {
    actor: 'https://preprod-api.revers.io/ReverseActorService/',
    authorization: 'https://preprod-api.revers.io/ReverseAuthorizationService/',
    base: 'https://preprod-api.revers.io/',
    baseBlobStorage: 'https://preprodreversestorage.blob.core.windows.net/',
    carrier: 'https://preprod-api.revers.io/ReverseCarrierService/',
    catalog: 'https://preprod-api.revers.io/ReverseCatalogService/',
    catalogCDN: 'https://preprodreversestorage.blob.core.windows.net/catalog/',
    cdn: 'https://preprodreversestorage.blob.core.windows.net/',
    claim: 'https://preprod-api.revers.io/ReverseClaimService/',
    config: 'https://preprod-api.revers.io/ReverseSellerService/',
    control: 'https://preprod-api.revers.io/ReverseControlService/',
    document: 'https://preprod-api.revers.io/ReverseDocumentService/',
    geographical: 'https://preprod-api.revers.io/ReverseGeographicalService/',
    ids: 'https://preprod-api.revers.io:5000/',
    imageCDN:
      'https://preprodreversestorage.blob.core.windows.net/publicdocuments/',
    import: 'https://preprod-api.revers.io/ReverseImportService/',
    marketplace: 'https://preprod-api.revers.io/ReverseMarketplaceService/',
    notification: 'https://preprod-api.revers.io/ReverseNotificationService/',
    owner: 'https://preprod-api.revers.io/ReverseOwnerService/',
    payment: 'https://preprod-api.revers.io/ReversePaymentService/',
    repairer: 'https://preprod-api.revers.io/ReverseRepairerService/',
    search: 'https://preprod-api.revers.io/ReverseSearchService/',
    seller: 'https://preprod-api.revers.io/ReverseSellerService/',
    sparePart: 'https://preprod-api.revers.io/ReverseSparePartsService/',
    uiStylesCDN:
      'https://preprodreversestorage.blob.core.windows.net/ui-styles/',
    warranty: 'https://preprod-api.revers.io/ReverseWarrantyService/',
    workflow: 'https://preprod-api.revers.io/ReverseWorkflowService/',
    enterpriseAuchan:
      'https://preprod-api.revers.io/ReverseEnterpriseAuchanService/',
    businessIntelligence:
      'https://preprod-api.revers.io/ReverseBusinessIntelligenceService/',
    graphQlGateway:
      'https://preprod-api.revers.io/ReverseGraphQLGatewayService/graphql',
  },
  PROD: {
    actor: 'https://api.revers.io/ReverseActorService/',
    authorization: 'https://api.revers.io/ReverseAuthorizationService/',
    base: 'https://api.revers.io/',
    baseBlobStorage: 'https://prodreversestorage.blob.core.windows.net/',
    carrier: 'https://api.revers.io/ReverseCarrierService/',
    catalog: 'https://api.revers.io/ReverseCatalogService/',
    catalogCDN: 'https://prodreversestorage.blob.core.windows.net/catalog/',
    cdn: 'https://prodreversestorage.blob.core.windows.net/',
    claim: 'https://api.revers.io/ReverseClaimService/',
    config: 'https://api.revers.io/ReverseSellerService/',
    control: 'https://api.revers.io/ReverseControlService/',
    document: 'https://api.revers.io/ReverseDocumentService/',
    geographical: 'https://api.revers.io/ReverseGeographicalService/',
    ids: 'https://api.revers.io:5000/',
    imageCDN:
      'https://prodreversestorage.blob.core.windows.net/publicdocuments/',
    import: 'https://api.revers.io/ReverseImportService/',
    marketplace: 'https://api.revers.io/ReverseMarketplaceService/',
    notification: 'https://api.revers.io/ReverseNotificationService/',
    owner: 'https://api.revers.io/ReverseOwnerService/',
    payment: 'https://api.revers.io/ReversePaymentService/',
    repairer: 'https://api.revers.io/ReverseRepairerService/',
    search: 'https://api.revers.io/ReverseSearchService/',
    seller: 'https://api.revers.io/ReverseSellerService/',
    sparePart: 'https://api.revers.io/ReverseSparePartsService/',
    uiStylesCDN: 'https://prodreversestorage.blob.core.windows.net/ui-styles/',
    warranty: 'https://api.revers.io/ReverseWarrantyService/',
    workflow: 'https://api.revers.io/ReverseWorkflowService/',
    enterpriseAuchan: 'https://api.revers.io/ReverseEnterpriseAuchanService/',
    businessIntelligence:
      'https://api.revers.io/ReverseBusinessIntelligenceService/',
    graphQlGateway:
      'https://api.revers.io/ReverseGraphQLGatewayService/graphql',
  },
};

const getCurrentApiConfigs = (): APIConfigurationInterface => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return API.DEMO;
    case APP_ENVS.DEV:
      return API.DEV;
    case APP_ENVS.LOCAL:
      return API.LOCAL;
    case APP_ENVS.PROD:
      return API.PROD;
    case APP_ENVS.PREPROD:
      return API.PREPROD;
    case APP_ENVS.DEFAULT:
    default:
      return API.DEV;
  }
};

export default getCurrentApiConfigs();
