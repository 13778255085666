import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.catalog}models/fullTextSearch`;

interface GetModelsByFullTextSearchPayload {
  maxResult?: number;
  searchTerms?: string;
  sellerId: string;
}

export interface RawModelFromBack {
  brandLabel: string;
  label: string;
  modelType: string;
  sku: string;
  ean: string;
}

async function getModelsByFullTextSearch({
  maxResult = 20,
  searchTerms = '',
  sellerId,
}: GetModelsByFullTextSearchPayload): Promise<
  BackResponse<RawModelFromBack[]> | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT, {
      maxResult,
      searchTerms,
      sellerId,
    });

    const response = await apiCall<BackResponse<RawModelFromBack[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getModelsByFullTextSearch;
