import { defineMessages } from 'react-intl';

export default defineMessages({
  addressLabel: {
    id: 'view.card.transport.address',
    defaultMessage: 'Address',
  },
  additionalAddressLabel: {
    id: 'view.DeliveryManagement.additionalAddress',
    defaultMessage: 'Additional address',
  },
  postalCodeLabel: {
    id: 'view.createOrderFromScratch.postalCode',
    defaultMessage: 'Zipcode',
  },
  cityLabel: {
    id: 'view.DeliveryManagement.city',
    defaultMessage: 'City',
  },
  countryLabel: {
    id: 'view.component.block.client.country',
    defaultMessage: 'Country',
  },
});
