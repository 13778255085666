import { useSelector } from 'react-redux';

import { ROLES } from '@savgroup-front-common/constants';
import { selectRoles } from '../domains/login/selectors';

const useActiveRole = () => {
  return useSelector(selectRoles);
};

const hasRole = (roles?: ROLES[], check?: ROLES) =>
  (roles || []).some((role) => check === role);

export function useHasRole(role: ROLES) {
  const accesses = useActiveRole();

  return hasRole(accesses, role);
}

export function useHasEveryRoles(roles: ROLES[]) {
  const accesses = useActiveRole();

  return roles.every((role) => hasRole(accesses, role));
}

export function useHasSomeRoles(roles: ROLES[]) {
  const accesses = useActiveRole();

  return roles.some((role) => hasRole(accesses, role));
}

export const useIsSuperAdmin = () => {
  return useHasRole(ROLES.SUPER_ADMIN);
};

export const useIsAdmin = () => {
  return useHasSomeRoles([ROLES.SUPER_ADMIN, ROLES.ACCOUNT_MANAGER]);
};
