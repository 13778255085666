import {
  GroupStrictAutocompleteOption,
  SalesChannel,
} from '@savgroup-front-common/types';
import { MinimalSeller } from 'authenticator/types';

import { CustomerInformationValues } from '../../../common/steps/CustomerInformation/CustomerInformation.types';
import { SearchModel } from '../ModelSearch/ModelSearch.types';

export enum COMPLEMENTARY_INFORMATION_FIELDS {
  SALES_CHANNEL = 'salesChannel',
  PURCHASE_DATE = 'purchaseDate',
  PRODUCT_PRICE = 'productPrice',
}

export type SalesChannelOption = GroupStrictAutocompleteOption<SalesChannel>;

export interface ComplementaryInformationValues
  extends CustomerInformationValues {
  model: SearchModel;
  seller: MinimalSeller;
  salesChannel: SalesChannel;
  purchaseDate: Date;
  productPrice: number;
}
