import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingMessage1: {
    id: 'authenticator.onboarding.setup.waitingMessage1',
    defaultMessage:
      'Your Reversio account is being configured, please wait a little',
  },
  waitingMessage2: {
    id: 'authenticator.onboarding.setup.waitingMessage2',
    defaultMessage:
      'Your Reversio experience is being prepared, thanks to wait while we configure your account',
  },
  waitingMessage3: {
    id: 'authenticator.onboarding.setup.waitingMessage3',
    defaultMessage:
      'Did you know ? 72% of consomators stay loyal to a brand that offers a good client experience',
  },
  waitingMessage4: {
    id: 'authenticator.onboarding.setup.waitingMessage4',
    defaultMessage:
      'Did you know ? A good client experience can increase the client payment by 16%',
  },
  waitingMessage5: {
    id: 'authenticator.onboarding.setup.waitingMessage5',
    defaultMessage: 'Your Reversio account is ready, enjoy your experience !',
  },
  waitingMessage6: {
    id: 'authenticator.onboarding.setup.waitingMessage6',
    defaultMessage:
      "'Never complain about difficult clients because they are the reason of your progress. Treat them better, they are the reason of your benefice.' Auguste Detoeuf",
  },
  salesLeadConfirmEmailCommandError: {
    id: 'authenticator.onboarding.setup.salesLeadConfirmEmailCommandError',
    defaultMessage: 'An error occured while confirming the email',
  },
});
