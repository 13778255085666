enum STEP_CONFIG {
  CHOICE_INVOICE = 'choiceInvoice',
  CHOICE_PARTNER_STORE = 'choicePartnerStore',
  CHOICE_PARTNER_PRODUCT = 'choicePartnerProduct',
  CHOICE_UNIVERS_MODEL = 'choiceUniversModel',
  CHOICE_MODEL_GENERIC = 'choiceModelGeneric',
  CUSTOMER_INFORMATION_NAME = 'customerInformationName',
  CUSTOMER_INFORMATION_ADDRESS = 'customerInformationAddress',
  CUSTOMER_INVOICE_INFORMATION = 'customerInvoiceInformation',
}

export default STEP_CONFIG;
