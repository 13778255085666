import { COMMON_STEPS } from '@savgroup-front-common/constants';

import messages from './messages';
import { AddressInformations, AddressSuggestion } from './steps';

export enum OWNER_INFORMATIONS_STEP_NAMES {
  ADDRESS_INFORMATIONS = 'ADDRESS_INFORMATIONS',
  ADDRESS_SUGGESTION = 'ADDRESS_SUGGESTION',
}

export const OWNER_INFORMATIONS_STEPS_CONFIG = [
  {
    name: OWNER_INFORMATIONS_STEP_NAMES.ADDRESS_INFORMATIONS,
    Component: AddressInformations,
    nextStep: OWNER_INFORMATIONS_STEP_NAMES.ADDRESS_SUGGESTION,
    message: messages.addressInformations,
  },
  {
    name: OWNER_INFORMATIONS_STEP_NAMES.ADDRESS_SUGGESTION,
    Component: AddressSuggestion,
    nextStep: COMMON_STEPS.SUBMIT,
    message: messages.addressSuggestion,
  },
];
