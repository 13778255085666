import { all } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { saga as i18nSaga } from '@savgroup-front-common/core/src/domains/i18n';
import { sellerSaga } from '@savgroup-front-common/core/src/domains/sellers';

export default function* rootSaga(): Generator {
  try {
    yield all([i18nSaga(), sellerSaga()]);
  } catch (error) {
    logCritical(error);
  }
}
