/* eslint-disable import/no-extraneous-dependencies */
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import { IntlShape, useIntl } from 'react-intl';

import {
  APP_ENVS,
  availableNamedLanguages,
  CURRENCIES,
  currentAppEnvironment,
  LANGUAGES,
} from '@savgroup-front-common/constants/src/shared';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import { getFromLocalStorage, setToLocalStorage } from './localStorage';

export const defaultLanguage = LANGUAGES.FR;

const formats = {
  number: {
    USD: {
      style: 'currency' as any,
      currency: CURRENCIES.USD,
    },
    EUR: {
      style: 'currency' as any,
      currency: CURRENCIES.EUR,
    },
  },
};

let currentLanguage: string = defaultLanguage;

export const miniLanguageCode = (language: string) => {
  return language.toLowerCase().split(/[_-]+/)[0] as LANGUAGES;
};
export const normalizeLanguage = (language: string) => {
  const normalizedLanguage = miniLanguageCode(language);

  if (!availableNamedLanguages.includes(normalizedLanguage)) {
    return defaultLanguage;
  }

  return normalizedLanguage;
};

export const guessNavigatorLanguages = () => {
  const languages =
    navigator.languages && navigator.languages.length
      ? navigator.languages.map(miniLanguageCode)
      : undefined;

  if (languages) {
    return languages;
  }

  const language = navigator.language || get(navigator, 'userLanguage');

  return [normalizeLanguage(language || defaultLanguage)];
};

export const sortLanguageByPrefered = (
  languages: string[] = availableNamedLanguages,
): string[] => {
  const prefered = guessNavigatorLanguages();

  return prefered
    .filter((language) => languages.includes(language))
    .sort((a, b) => {
      const aIndex = prefered.indexOf(a);
      const bIndex = prefered.indexOf(b);

      if (aIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    });
};

export function getInitialLanguage() {
  const [firstNavigatorLanguage] = sortLanguageByPrefered();

  const locale = getFromLocalStorage({
    key: LOCAL_STORAGE_KEYS.LOCALE,
    defaultValue: firstNavigatorLanguage,
  });

  return locale;
}

export function getCurrentLanguage() {
  return currentLanguage;
}

export const setLangueLocalStorage = (language: string) => {
  const normalizedLanguage = normalizeLanguage(language);

  if (window.localStorage) {
    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.LOCALE,
      value: normalizedLanguage,
    });
  }
};

export function getConfigLanguage(locale: string) {
  const normalizedLanguage = normalizeLanguage(locale);

  return {
    locale: normalizedLanguage,
    formats,
  };
}

export const setMomentLanguage = (language: string) => {
  const normalizedLanguage = normalizeLanguage(language);

  moment.locale(normalizedLanguage);
};

export function updateLanguage(language: string, storeInlocalStorage = false) {
  const normalizedLanguage = normalizeLanguage(language);

  currentLanguage = normalizedLanguage;

  if (storeInlocalStorage) {
    setLangueLocalStorage(normalizedLanguage);
  }

  setMomentLanguage(normalizedLanguage);

  return getConfigLanguage(normalizedLanguage);
}

export function getInitialLocale() {
  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  return getInitialLanguage();
}

export let intl: IntlShape | undefined;

export let showMessageId = false;

export const IntlGlobalProvider = (props: any = {}) => {
  intl = useIntl();
  showMessageId = currentAppEnvironment === APP_ENVS.DEV || props.isSuperAdmin;

  return props.children;
};
export const IntlGlobalProviderMocker = (props: any = {}) => {
  intl = useIntl();

  return props.children;
};
