import { defineMessages } from 'react-intl';

export default defineMessages({
  noOptionsMessage: {
    id: 'components.atom.select.noOptionsMessage',
    defaultMessage: 'No options',
  },
  loadingMessage: {
    id: 'components.atom.select.loadingMessage',
    defaultMessage: 'Loading',
  },
  placeholder: {
    id: 'components.atom.cretableAutocomplete.placeholder',
    defaultMessage: 'Select or type a new entry',
  },
  createLabel: {
    id: 'components.atom.cretableAutocomplete.createLabel',
    defaultMessage: 'Create new "{inputValue}"',
  },
});
