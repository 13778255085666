import { defineMessages } from 'react-intl';

export default defineMessages({
  informationTitle: {
    id: 'components.common.addressManagement.addressForm.information',
    defaultMessage: 'Information',
  },
  addressTitle: {
    id: 'components.common.addressManagement.addressForm.address',
    defaultMessage: 'Address',
  },
  mister: {
    id: 'components.common.addressManagement.addressForm.mister',
    defaultMessage: 'M.',
  },
  madam: {
    id: 'components.common.addressManagement.addressForm.madam',
    defaultMessage: 'Mme',
  },
  emailLabel: {
    id: 'view.card.client.email',
    defaultMessage: 'Your email',
  },
  postalCodeLabel: {
    id: 'view.createOrderFromScratch.postalCode',
    defaultMessage: 'Zipcode',
  },
  countryLabel: {
    id: 'view.component.block.client.country',
    defaultMessage: 'Country',
  },
  cityLabel: {
    id: 'view.DeliveryManagement.city',
    defaultMessage: 'City',
  },
  firstNameLabel: {
    id: 'view.card.client.firstName',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'view.card.client.lastName',
    defaultMessage: 'Last Name',
  },
  phoneLabel: {
    id: 'view.card.client.phone',
    defaultMessage: 'Phone Number',
  },
  companyNameLabel: {
    id: 'view.createOrderFromScratch.companyName',
    defaultMessage: 'Company name',
  },
  addressLabel: {
    id: 'view.card.transport.address',
    defaultMessage: 'Address',
  },
  additionalAddressLabel: {
    id: 'view.DeliveryManagement.additionalAddress',
    defaultMessage: 'Additional address',
  },

  personalInformation: {
    id: 'view.createOrderFromScratch.personalInformation',
    defaultMessage: 'Personal information',
  },
  contactDetails: {
    id: 'view.createOrderFromScratch.contactDetails',
    defaultMessage: 'Contact details',
  },
});
