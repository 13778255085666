import validator from 'validator';
import * as Yup from 'yup';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  globalMessages,
  isAddress,
  isEmail,
  isValidPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';
import { AutocompleteOption } from '@savgroup-front-common/types';

import { ADDRESS_INFORMATIONS_FIELDS as FIELDS } from './AddressInformations.types';

const AddressInformationsSchema = Yup.object().shape({
  [FIELDS.FIRST_NAME]: Yup.string().required(globalMessages.form.required),
  [FIELDS.LAST_NAME]: Yup.string().required(globalMessages.form.required),
  [FIELDS.COMPANY_NAME]: Yup.string(),
  [FIELDS.ADDITIONAL_ADDRESS]: Yup.string(),

  [FIELDS.EMAIL]: Yup.string()
    .required(globalMessages.form.required)
    .test('is-email', globalMessages.form.invalidEmail, (value) => {
      return isEmail(value);
    }),
  [FIELDS.PHONE_NUMBER]: Yup.string()
    .required(globalMessages.form.required)
    .when(
      FIELDS.COUNTRY_CODE,
      (countryCode: AutocompleteOption, schema: Yup.StringSchema) => {
        const countryCodeValue = countryCode?.value || '';

        return schema.test(
          'test-country-phone',
          globalMessages.form.invalidPhoneNumber,
          (phone): boolean => {
            return isValidPhoneNumber(phone, countryCodeValue);
          },
        );
      },
    ),
  [FIELDS.ADDRESS]: Yup.string()
    .required(globalMessages.form.required)
    .test('is-address', globalMessages.form.invalidAddress, (value) => {
      return isAddress(value);
    }),
  [FIELDS.POSTAL_CODE]: Yup.string()
    .required(globalMessages.form.required)
    .when(
      FIELDS.COUNTRY_CODE,
      (countryCode: AutocompleteOption, schema: Yup.StringSchema) => {
        const countryCodeValue = countryCode?.value || '';

        return schema.test(
          'test-postal-code',
          globalMessages.form.invalidPostalCode,
          (postalCode): boolean => {
            try {
              return (
                !countryCodeValue ||
                !postalCode ||
                validator.isPostalCode(postalCode, countryCodeValue as any)
              );
            } catch (error: any) {
              logError(error);

              return true;
            }
          },
        );
      },
    ),
  [FIELDS.CITY]: Yup.string().required(globalMessages.form.required),
  [FIELDS.COUNTRY_CODE]: Yup.object().required(globalMessages.form.required),
});

export default AddressInformationsSchema;
