import React, { FunctionComponent, ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { useTheme } from 'styled-components';

import { Loader } from '../loader';

import { BUTTON_HUMAN_LOADING } from './Button.constants';
import { getLoadingColor } from './LoadingBasedOnProps.helpers';
import {
  $ButtonChildContainer,
  $LoaderContainer,
} from './LoadingBasedOnProps.styles';

interface LoadingBasedOnPropsInterface {
  disabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  success?: boolean;
  danger?: boolean;
  alert?: boolean;
}

const LoadingBasedOnProps: FunctionComponent<
  React.PropsWithChildren<LoadingBasedOnPropsInterface>
> = (props) => {
  const theme = useTheme();

  const {
    disabled,
    isLoading,
    children,
    success,
    primary,
    secondary,
    tertiary,
    danger,
    alert,
  } = props;

  const shouldShowLoading = !disabled && isLoading;

  return (
    <>
      <Transition
        in={shouldShowLoading}
        timeout={BUTTON_HUMAN_LOADING}
        unmountOnExit
      >
        {(animationState) => (
          <$LoaderContainer
            animationState={animationState}
            animationDuration={BUTTON_HUMAN_LOADING}
          >
            <Loader
              size="16px"
              color={getLoadingColor(
                {
                  disabled,
                  isLoading,
                  success,
                  primary,
                  secondary,
                  tertiary,
                  danger,
                  alert,
                },
                theme,
              )}
              strokeWidth="10"
            />
          </$LoaderContainer>
        )}
      </Transition>
      <Transition in={!shouldShowLoading} timeout={BUTTON_HUMAN_LOADING}>
        {(animationState) => (
          <$ButtonChildContainer
            animationState={animationState}
            animationDuration={BUTTON_HUMAN_LOADING}
          >
            {children}
          </$ButtonChildContainer>
        )}
      </Transition>
    </>
  );
};

LoadingBasedOnProps.displayName = 'LoadingBasedOnProps';

export default LoadingBasedOnProps;
