import React, { FunctionComponent, ReactNode } from 'react';
import { Tooltip, TooltipProps } from 'react-tippy';

interface CustomTooltipProps extends TooltipProps {
  children: ReactNode;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = (props) => (
  <Tooltip {...props} />
);

CustomTooltip.displayName = 'CustomTooltip';

export default CustomTooltip;
