import * as Yup from 'yup';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import {
  globalMessages,
  isPrice,
} from '@savgroup-front-common/core/src/helpers';

import { ORDER_INFORMATIONS_FIELD_NAMES } from './OrderInformations.types';

const MIN_ORDER_SIZE = 4;

const orderInformationsSchema = () =>
  Yup.object().shape({
    [ORDER_INFORMATIONS_FIELD_NAMES.ORDER_REFERENCE]: Yup.string()
      .required(globalMessages.form.required)
      .min(
        MIN_ORDER_SIZE,
        safeFormattedIntlString(globalMessages.form.minStringLength, {
          min: MIN_ORDER_SIZE,
        }),
      ),
    [ORDER_INFORMATIONS_FIELD_NAMES.ORDER_PRICE]: Yup.string()
      .required(globalMessages.form.required)
      .test('isPrice', globalMessages.form.invalidPrice, (value) => {
        if (!value) {
          return false;
        }

        return isPrice(value);
      }),
  });

export default orderInformationsSchema;
