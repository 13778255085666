import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
  OutcomeHistory,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.owner}owners/${COMMON_QUERY_PARAMS.OWNER_ID}/products/${COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID}/full`;

interface GetFullOwnerProductQueryPayload {
  ownerId: string;
  ownerProductId: string;
}

export interface FullProductDto {
  warrantyEndDateUtc: string;
  soldWarrantyEndDateUtc: string;
  ownerId: string;
  ownerProductId: string;
  sellerId: string;
  sellerProductId: string;
  sellerBrand: string;
  buyDate: string;
  orderDeliveryDateUtc: string;
  marketPlace: string;
  soldWarrantyId: string;
  soldWarrantyPurchaseDate: string;
  claimId: string;
  claimGroupId: string;
  fileId: string;
  hasOpenFile: boolean;
  orderId: string;
  orderReference: string;
  orderLineReference: string;
  externalIdentifier: string;
  notOpenForClaims: boolean;
  isLowValue: boolean;
  currencyCode: string;
  price: number;
  shippingPrice: number;
  soldWarrantyPrice: number;
  state?: string;
  imei: string[];
  serialNumber: string;
  outcomesHistory?: OutcomeHistory[];
  deliveryTransportCarrier: {
    deliveryCarrierName: string;
    deliveryCarrierCode: string;
  };
}

const getFullOwnerProductQuery = async ({
  ownerId,
  ownerProductId,
}: GetFullOwnerProductQueryPayload): Promise<
  BackResponse<FullProductDto> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.OWNER_ID, ownerId).replace(
      COMMON_QUERY_PARAMS.OWNER_PRODUCT_ID,
      ownerProductId,
    );

    const response = await apiCall<BackResponse<FullProductDto>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getFullOwnerProductQuery;
