import { GeocodeAddressFromDataGouvResponse } from '@savgroup-front-common/core/src/api/DataGouv';

import {
  CUSTOMER_INFORMATION_FIELDS,
  CustomerInformationFormValues,
} from '../CustomerInformation.types';

interface AdaptSuggestedAddressArgs {
  suggestedAddress: GeocodeAddressFromDataGouvResponse;
  customerInformation: CustomerInformationFormValues;
  keepSuggestedAddress: string;
}

const adaptSuggestedAddress = ({
  suggestedAddress,
  customerInformation,
  keepSuggestedAddress,
}: AdaptSuggestedAddressArgs): CustomerInformationFormValues | undefined => {
  const addresses = suggestedAddress?.features;

  if (addresses.length === 0) {
    return undefined;
  }

  const addressToSuggest = addresses[0];

  return {
    [CUSTOMER_INFORMATION_FIELDS.ADDRESS]: addressToSuggest.properties.name,
    [CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE]:
      addressToSuggest.properties.postcode,
    [CUSTOMER_INFORMATION_FIELDS.CITY]: addressToSuggest.properties.city,
    [CUSTOMER_INFORMATION_FIELDS.FIRST_NAME]: customerInformation.firstName,
    [CUSTOMER_INFORMATION_FIELDS.LAST_NAME]: customerInformation.lastName,
    [CUSTOMER_INFORMATION_FIELDS.ADDITIONAL_ADDRESS]:
      customerInformation?.additionalAddress,
    [CUSTOMER_INFORMATION_FIELDS.CIVILITY]: customerInformation?.civility,
    [CUSTOMER_INFORMATION_FIELDS.EMAIL]: customerInformation.email,
    [CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE]: customerInformation.countryCode,
    [CUSTOMER_INFORMATION_FIELDS.COMPANY_NAME]:
      customerInformation?.companyName,
    [CUSTOMER_INFORMATION_FIELDS.PHONE]: customerInformation?.phone,
    [CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS]: keepSuggestedAddress,
  };
};

export default adaptSuggestedAddress;
