import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $NextButtonModal = styled(Col)`
  margin-left: auto;
`;

export const $AddressRow = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${rem(1)};
`;

export const $ModalRowWithMargin = styled(Row)`
  margin-bottom: 1rem;
`;

interface SuggestionAddressProps {
  $isChecked: boolean;
}

export const $SuggestionTitle = styled.div<SuggestionAddressProps>`
  font-size: ${({ theme }) => theme.fonts.size.small};
  color: ${({ theme, $isChecked }) =>
    $isChecked ? theme.colors.primary : theme.colors.paragraphTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
`;

export const $SuggestionContent = styled.div<SuggestionAddressProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  span {
    color: ${({ theme, $isChecked }) =>
      $isChecked ? theme.colors.black : theme.colors.paragraphTextColor};
  }
`;

export const $ModalContent = styled.div`
  h3 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }
`;
