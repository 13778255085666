import md5 from 'md5';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';

import { AuthConfiguration } from '../../../../configuration';
import { useUpdateFavicon } from '../../../../hooks';
import { Grid, useClientInformationContext } from '../../../atoms';
import { BannerImage, NetworkLinks } from '../../../molecules';
import useNetworkLinks from '../hooks/useNetworkLinks';

import {
  $Footer,
  $FooterImageContainer,
  PageWrapper,
} from './ClassicLayout.styles';
import AppHeader from './Header/AppHeader';

const ClassicLayout: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { clientInformation } = useClientInformationContext();
  const { networkLinks } = useNetworkLinks({
    clientId: clientInformation?.clientId,
  });

  const hashedClientId = useMemo(() => md5(AuthConfiguration.clientId), []);
  const imgRef = useRef<HTMLImageElement>(null);
  const [isLoginFooterImageFound, setIsLoginFooterImageFound] =
    useState<boolean>(true);

  const sellerId = clientInformation?.sellerId || clientInformation?.tenantId;

  const onError = useCallback(() => {
    if (imgRef && imgRef.current) {
      imgRef.current.style.visibility = 'hidden';
      setIsLoginFooterImageFound(false);
    }
  }, []);

  useUpdateFavicon({ sellerId });

  return (
    <PageWrapper>
      <AppHeader />
      <BannerImage />
      <Grid>{children}</Grid>

      {(isLoginFooterImageFound || networkLinks.length > 0) && (
        <$Footer $isLoginFooterImageFound={isLoginFooterImageFound}>
          {isLoginFooterImageFound && (
            <$FooterImageContainer>
              <img
                ref={imgRef}
                alt="login-footer-images"
                src={`${APIConfiguration.uiStylesCDN}login-footer-images/${hashedClientId}`}
                onError={onError}
              />
            </$FooterImageContainer>
          )}
          <NetworkLinks links={networkLinks} />
        </$Footer>
      )}
    </PageWrapper>
  );
};

ClassicLayout.displayName = 'ClassicLayout';

export default ClassicLayout;
