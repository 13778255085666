import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const SofaIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} fill="none">
    <path
      d="M2 16L2 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 9V7C4 5.89543 4.89543 5 6 5L18 5C19.1046 5 20 5.89543 20 7V9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 9C18.8954 9 18 9.89543 18 11V13H6V11C6 9.89543 5.10457 9 4 9C2.89543 9 2 9.89543 2 11V17H22V11C22 9.89543 21.1046 9 20 9Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 16L22 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

SofaIcon.displayName = 'SofaIcon';

export default SofaIcon;
