import { useCallback, useContext } from 'react';

import { Notification } from '@savgroup-front-common/types';

import ToastsContext from '../ToastsProvider.context';
import { NotificationId } from '../ToastsProvider.types';

export type UsePushNotificationReturnType = (
  toast: Notification,
) => NotificationId;

const usePushNotification = (): UsePushNotificationReturnType => {
  const { pushNotifications } = useContext(ToastsContext);

  return useCallback(
    (toast: Notification) => {
      const [id] = pushNotifications([toast]);

      return id;
    },
    [pushNotifications],
  );
};

export default usePushNotification;
