import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import { Tooltip } from '@savgroup-front-common/core/src/atoms/Tooltip';
import { InformationIcon } from '@savgroup-front-common/core/src/protons/icons';

import {
  $ToolTipContainer,
  $ToolTipIconContainer,
} from './PostLabelTooltip.styles';

const PostLabelTooltip: FunctionComponent<
  React.PropsWithChildren<{ isNewUi?: boolean }>
> = ({ children, isNewUi }) => {
  const theme = useTheme();

  return (
    <$ToolTipIconContainer>
      <Tooltip
        useContext
        html={<$ToolTipContainer>{children}</$ToolTipContainer>}
      >
        <InformationIcon
          color={
            isNewUi ? theme.newUI.defaultColors.primary : theme.colors.primary
          }
          size="20px"
        />
      </Tooltip>
    </$ToolTipIconContainer>
  );
};

PostLabelTooltip.displayName = 'PostLabelTooltip';

export default PostLabelTooltip;
