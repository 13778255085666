import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import apiCall from '../../services/apiCall';

interface GetFileAttachmentUploadLinkFromControlCommandPayload {
  fileId?: string;
  claimId?: string;
  additionalInformationId?: string;
}

interface GetFileAttachmentUploadLinkFromControlCommandResponse {
  uploadQrCode: string;
  uploadUrl: string;
}

const ENDPOINT = `${APIConfiguration.workflow}files/control-attachments/uploadLink`;

export const getFileAttachmentUploadLinkFromControlCommand = async ({
  fileId,
  claimId,
  additionalInformationId,
}: GetFileAttachmentUploadLinkFromControlCommandPayload): Promise<
  | BackResponse<GetFileAttachmentUploadLinkFromControlCommandResponse>
  | BackResponseFailure
> => {
  try {
    return await apiCall<
      BackResponse<GetFileAttachmentUploadLinkFromControlCommandResponse>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        additionalInformationId,
        claimId,
        fileId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
