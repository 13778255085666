import { get } from 'lodash';

import { rem } from '../../helpers';

export enum MODAL_SIZES {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  DEFAULT = 'default',
  LARGE = 'large',
  FULL_SCREEN = 'fullscreen',
}
const SIZES_MAPPED = {
  [MODAL_SIZES.TINY]: rem(360),
  [MODAL_SIZES.SMALL]: rem(500),
  [MODAL_SIZES.MEDIUM]: rem(720),
  [MODAL_SIZES.DEFAULT]: rem(900),
  [MODAL_SIZES.LARGE]: rem(1080),
  [MODAL_SIZES.FULL_SCREEN]: rem(1920),
};

const NEW_UI_SIZES_MAPPED = {
  [MODAL_SIZES.TINY]: rem(360),
  [MODAL_SIZES.SMALL]: rem(446),
  [MODAL_SIZES.MEDIUM]: rem(612),
  [MODAL_SIZES.DEFAULT]: rem(900),
  [MODAL_SIZES.LARGE]: rem(1080),
  [MODAL_SIZES.FULL_SCREEN]: rem(1920),
};

export const getModalSize = ({
  $size,
  $isNewUi,
}: {
  $size: MODAL_SIZES;
  $isNewUi?: boolean;
}): string => {
  if ($isNewUi) {
    return get(SIZES_MAPPED, $size, NEW_UI_SIZES_MAPPED[MODAL_SIZES.DEFAULT]);
  }

  return get(SIZES_MAPPED, $size, SIZES_MAPPED[MODAL_SIZES.DEFAULT]);
};
