enum LOGIN_FOOTER_NETWORK_LINK_TYPES {
  APP_STORE = 'appStore',
  GOOGLE_PLAY = 'googlePlay',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  PINTEREST = 'pinterest',
  LINK = 'link',
}

export default LOGIN_FOOTER_NETWORK_LINK_TYPES;
