import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.workflow}files/salesforceContext`;

export interface SalesforceContextSummary {
  id: string;
  fileId: string;
  isLegacyContext: boolean;
}

interface GetSalesforceContextQueryPayload {
  fileId: string;
}

async function getSalesforceContextQuery({
  fileId,
}: GetSalesforceContextQueryPayload): Promise<
  BackResponse<SalesforceContextSummary> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<SalesforceContextSummary>>(
      buildUrl(ENDPOINT, { fileId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getSalesforceContextQuery;
