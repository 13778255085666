import React, { FunctionComponent, useCallback } from 'react';

import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { EnterpriseAuchan } from 'authenticator/api';

import { prepareAuthenticateWithTicketNumberPayload } from './TicketNumberLogin.adapters';
import {
  TICKET_NUMBER_LOGIN_STEP_NAMES,
  TICKET_NUMBER_LOGIN_STEPS_CONFIG,
} from './TicketNumberLogin.steps';
import {
  TicketNumberLoginContext,
  TicketNumberLoginValues,
} from './TicketNumberLogin.types';

interface TicketNumberLoginProps {
  changeActiveComponent: () => void;
}

const TicketNumberLogin: FunctionComponent<
  React.PropsWithChildren<TicketNumberLoginProps>
> = ({ changeActiveComponent }) => {
  const handleSubmit = useCallback(async (data: TicketNumberLoginValues) => {
    const payload = prepareAuthenticateWithTicketNumberPayload(data);

    const redirectUrl = await EnterpriseAuchan.createOrderBySaleReceiptId(
      payload,
    );

    window.location.href = redirectUrl;
  }, []);

  return (
    <BannersProvider>
      <StepsOrchestrator<TicketNumberLoginValues, TicketNumberLoginContext>
        initialStep={TICKET_NUMBER_LOGIN_STEP_NAMES.RECEIPT_SEARCH}
        initialContext={{
          changeActiveComponent,
        }}
        config={TICKET_NUMBER_LOGIN_STEPS_CONFIG}
        onSubmit={handleSubmit}
        withWizard={false}
      />
    </BannersProvider>
  );
};

TicketNumberLogin.displayName = 'TicketNumberLogin';

export default TicketNumberLogin;
