import { defineMessages } from 'react-intl';

export default defineMessages({
  textCopied: {
    id: 'view.orders.textCopied',
    defaultMessage: '{fieldName} copied!',
  },
  textCopy: {
    id: 'view.orders.textCopy',
    defaultMessage: 'Copy',
  },
});
