export enum ROLES {
  ACCOUNT_MANAGER = 'AccountManager',
  SUPER_ADMIN = 'SuperAdmin',
  DELIVERY_MANAGER = 'DeliveryManager',
  SELLER_OPERATOR = 'SellerOperator',
  SELLER_SUPERVISOR = 'SellerSupervisor',
  CUSTOMER_SUPPORT_SUPERVISOR = 'CustomerSupportSupervisor',
  CUSTOMER_SUPPORT_OPERATOR = 'CustomerSupportOperator',
  CREATE_ORDER_OPERATOR = 'CreateOrderOperator',
  CUSTOM_PROFILE = 'CustomProfile',
  DEMO_ADMINISTRATOR = 'DemoAdministrator',
  ADMINISTRATOR = 'Administrator',
}
