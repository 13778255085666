import { v4 } from 'uuid';

import {
  ACTION_STATE,
  ActionType,
  DATA_STATE,
  SagaRequestMetadataState,
  SEPARATOR,
} from './constants';

export const defaultIndexer = `__${v4()}`;

type Err = any;

const isAction = (
  actions: ACTION_STATE[],
  type: ActionType,
): RegExpExecArray | null => {
  const reg = new RegExp(`(.*)${SEPARATOR}(${actions.join('|')})`);

  return reg.exec(type);
};

function initialize(
  state: SagaRequestMetadataState,
  [, requestName]: RegExpExecArray,
  indexer: string,
) {
  const oldRequestState = state[requestName] || {};
  const oldRequestStateWithIndex = oldRequestState[indexer] || {};

  return {
    ...state,
    [requestName]: {
      ...oldRequestState,
      [indexer]: {
        ...oldRequestStateWithIndex,
        [DATA_STATE.IS_LOADING]: true,
        [DATA_STATE.HAS_ERRORS]: null,
        [DATA_STATE.ERRORS]: [],
      },
    },
  };
}

function success(
  state: SagaRequestMetadataState,
  [, requestName]: RegExpExecArray,
  indexer: string,
) {
  const oldRequestState = state[requestName] || {};
  const oldRequestStateWithIndex = oldRequestState[indexer] || {};

  return {
    ...state,
    [requestName]: {
      ...oldRequestState,
      [indexer]: {
        ...oldRequestStateWithIndex,
        [DATA_STATE.IS_LOADING]: false,
        [DATA_STATE.HAS_ERRORS]: null,
        [DATA_STATE.ERRORS]: [],
        [DATA_STATE.WAS_LOADED]: true,
      },
    },
  };
}

function error(
  state: SagaRequestMetadataState,
  [, requestName]: RegExpExecArray,
  indexer: string,
  errors: Err[],
) {
  const oldRequestState = state[requestName] || {};
  const oldRequestStateWithIndex = oldRequestState[indexer] || {};

  return {
    ...state,
    [requestName]: {
      ...oldRequestState,
      [indexer]: {
        ...oldRequestStateWithIndex,
        [DATA_STATE.IS_LOADING]: false,
        [DATA_STATE.HAS_ERRORS]: true,
        [DATA_STATE.ERRORS]: errors,
      },
    },
  };
}

function rest(
  state: SagaRequestMetadataState,
  [, requestName]: RegExpExecArray,
  indexer: string,
) {
  const oldRequestState = state[requestName] || {};
  const oldRequestStateWithIndex = oldRequestState[indexer] || {};

  return {
    ...state,
    [requestName]: {
      ...oldRequestState,
      [indexer]: {
        ...oldRequestStateWithIndex,
        [DATA_STATE.IS_LOADING]: false,
      },
    },
  };
}

interface ReducerPayload {
  type: ActionType;
  errors: Err[];
  meta: { indexer: string };
}

export default function reducer(
  state: SagaRequestMetadataState = {},
  payload: ReducerPayload,
) {
  const { type, errors = [], meta = { indexer: defaultIndexer } } = payload;
  const { indexer = defaultIndexer } = meta;

  const allMatches = isAction(
    [
      ACTION_STATE.STARTED,
      ACTION_STATE.SUCCEEDED,
      ACTION_STATE.ERRORED,
      ACTION_STATE.CANCELLED,
      ACTION_STATE.PROGRESS,
      ACTION_STATE.END,
    ],
    type,
  );

  const startMatches = isAction([ACTION_STATE.STARTED], type);
  const errorMatches = isAction([ACTION_STATE.ERRORED], type);
  const successMatches = isAction([ACTION_STATE.SUCCEEDED], type);
  const restMatches = isAction(
    [ACTION_STATE.CANCELLED, ACTION_STATE.PROGRESS, ACTION_STATE.END],
    type,
  );

  if (!allMatches) {
    return state;
  }

  if (startMatches) {
    return initialize(state, startMatches, indexer);
  }

  if (errorMatches) {
    return error(state, errorMatches, indexer, errors);
  }

  if (successMatches) {
    return success(state, successMatches, indexer);
  }

  if (restMatches) {
    return rest(state, restMatches, indexer);
  }

  return state;
}
