import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $HeaderContainer = styled.div`
  width: 100%;
  height: 72px;
  margin-bottom: ${rem(24)};
`;

export const $MenuContainer = styled.div`
  align-items: center;
  height: ${rem(72)};
  display: flex;
  justify-content: space-between;
`;

export const $ButtonCol = styled(Col)`
  @media (min-width: 670px) {
    display: flex !important;
    align-items: center !important;
  }
  @media (max-width: 670px) {
    padding: 0;
  }
`;

export const $RightSide = styled.div`
  display: flex;
  margin-left: 2rem;
`;
