import { defineMessages } from 'react-intl';

export default defineMessages({
  currencyWithVatExcluded: {
    id: 'global.price.currencyWithVatExcluded',
    defaultMessage: '{currency} VAT excluded',
  },
  currencyWithVatIncluded: {
    id: 'global.price.currencyWithVatIncluded',
    defaultMessage: '{currency} VAT included',
  },
});
