import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useReducer } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';

import { NextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { MinimalSeller } from 'authenticator/types';

import { TicketNumberLoginContext } from '../../../TicketNumberLogin.types';
import {
  initReceiptSeachConfigReducer,
  receiptSeachConfigReducer,
} from '../ReceiptSearch.reducers';
import ReceiptSearchSchema from '../ReceiptSearch.schema';
import {
  ACTION_TYPES,
  RECEIPT_SEARCH_FIELDS,
  ReceiptSearchState,
  SubmitReceiptSearchFormValues,
} from '../ReceiptSearch.types';

import useCheckExistingReceipt from './useCheckExistingReceipt';

interface ReceiptSearchFormValues {
  [RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID]: string;
}

interface UseReceiptSearchReturnValue {
  formContext: UseFormReturn<ReceiptSearchFormValues>;
  handleCheckExistingReceiptSubmit: () => void;
  state: ReceiptSearchState;
}

interface UseReceiptSearchArgs {
  sellers: MinimalSeller[];
  nextStep: NextStep<SubmitReceiptSearchFormValues, TicketNumberLoginContext>;
}

const useReceiptSearch = ({
  sellers,
  nextStep,
}: UseReceiptSearchArgs): UseReceiptSearchReturnValue => {
  const [state, dispatch] = useReducer(
    receiptSeachConfigReducer,
    null,
    initReceiptSeachConfigReducer,
  );

  const formContext = useForm<ReceiptSearchFormValues>({
    defaultValues: {
      [RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID]: '',
    },
    resolver: yupResolver(ReceiptSearchSchema),
  });

  const { control } = formContext;
  const saleReceiptId = useWatch({
    control,
    name: RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID,
  });

  const onCheckReceiptExist = useCallback(
    ({ isLoading }: { isLoading: boolean }) => {
      dispatch({
        type: ACTION_TYPES.ON_CHECK_RECEIPT_EXIST,
        payload: { isLoading },
      });
    },
    [dispatch],
  );

  const handleCheckExistingReceiptSubmit = useCheckExistingReceipt({
    sellers,
    saleReceiptId,
    nextStep,
    onCheckReceiptExist,
  });

  return {
    formContext,
    handleCheckExistingReceiptSubmit,
    state,
  };
};

export default useReceiptSearch;
