import { useCallback } from 'react';

import { COUNTRY_CODES } from '@savgroup-front-common/constants/src';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { AddressInfoDto, OwnerSummary } from '@savgroup-front-common/types';

const useHandleSuggestion = () => {
  const { pushError } = useBanners();

  const handleSuggestion = useCallback(
    async (formValues: OwnerSummary): Promise<OwnerSummary> => {
      if (formValues.countryCode === COUNTRY_CODES.FR) {
        try {
          const response = await DataGouvService.geoCodeAddressFromDataGouv({
            query: [
              formValues.ownerAddress.address,
              formValues.ownerAddress.postalCode,
              formValues.ownerAddress.city,
            ]
              .filter((v) => v)
              .join(' '),
          });

          if (response.features.length > 0) {
            const [feature] = response.features;
            const addresss: AddressInfoDto = {
              ...formValues.ownerAddress,
              address: feature.properties.name,
              city: feature.properties.city,
              postalCode: feature.properties.postcode,
            };

            const suggestedAddress: OwnerSummary = {
              ...formValues,
              ownerAddress: addresss,
              addresses: [addresss],
            };

            return suggestedAddress;
          }
        } catch (error) {
          pushError(error as Error);
        }
      }

      return formValues;
    },
    [pushError],
  );

  return {
    handleSuggestion,
  };
};

export default useHandleSuggestion;
