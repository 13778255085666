import { defineMessages } from 'react-intl';

export default defineMessages({
  actorId: {
    id: 'view.filterAside.actorId',
    defaultMessage: 'Actor Id',
  },
  actorName: {
    id: 'view.filterAside.actorName',
    defaultMessage: 'Actor name',
  },
  actorType: {
    id: 'view.filterAside.actorType',
    defaultMessage: 'Actor type',
  },
  assignedTo: {
    id: 'view.filterAside.assignedTo',
    defaultMessage: 'Assigned to',
  },
  assignment: {
    id: 'view.filterAside.assignment',
    defaultMessage: 'Assignment',
  },
  alertType: {
    id: 'view.filterAside.alertType',
    defaultMessage: 'Alert type',
  },
  priorities: {
    id: 'view.filterAside.priorities',
    defaultMessage: 'Priorities',
  },
  assignedType: {
    id: 'view.filterAside.assignedType',
    defaultMessage: 'Assigned type',
  },
  solutionGroupType: {
    id: 'view.filterAside.solutionGroupType',
    defaultMessage: 'Solution group type',
  },
  brands: {
    id: 'view.filterAside.brands',
    defaultMessage: 'Brands',
  },
  statusId: {
    id: 'view.filterAside.statusId',
    defaultMessage: 'Status Id',
  },
  warrantyType: {
    id: 'view.filterAside.warrantyType',
    defaultMessage: 'Warranty type',
  },
  siteActorId: {
    id: 'view.filterAside.siteActorId',
    defaultMessage: 'Site actor',
  },
  store: {
    id: 'view.filterAside.store',
    defaultMessage: 'Store',
  },
  fileLanguage: {
    id: 'view.filterAside.fileLanguge',
    defaultMessage: 'File language',
  },
  partnerScenarioType: {
    id: 'view.files.filter.partnerScenarioType',
    defaultMessage: 'Partner Scenario Type',
  },
  seller: {
    id: 'view.messagesFilters.Sellers',
    defaultMessage: 'Sellers',
  },
  category: {
    id: 'view.messagesFilters.category',
    defaultMessage: 'Category level {depth}',
  },
  workflowVersion: {
    id: 'view.messagesFilters.workflowVersion',
    defaultMessage: 'Workflow version',
  },
  buyerType: {
    id: 'view.messagesFilters.buyerType',
    defaultMessage: 'Buyer type',
  },
});
