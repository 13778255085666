import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { BackResponseFailure, Theme } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.imageCDN}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/theme.json`;

interface GetSellerThemePayload {
  sellerId: string;
}

const getSellerTheme = async ({
  sellerId,
}: GetSellerThemePayload): Promise<Theme | BackResponseFailure> => {
  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

  try {
    const response = await fetch(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return await response.json();
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellerTheme;
