import { BackResponseFailure } from '@savgroup-front-common/types';

const prepareResponseFailure = (exception: any): BackResponseFailure => {
  return {
    failure: true,
    isHandledError: false,
    hasWarnings: false,
    isNotFound: false,
    ...exception,
    errors: exception.errors || [exception],
  };
};

export default prepareResponseFailure;
