import React, { FunctionComponent, useMemo } from 'react';

import useLocalStorage from '@savgroup-front-common/core/src/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import ClassicLayout from './ClassicLayout';
import DualLayout from './DualLayout';
import { LayoutContext } from './Layout.provider';
import { LAYOUT_TYPES } from './Layout.types';

const Layout: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isFeatureFlagEnabled] = useLocalStorage(
    LOCAL_STORAGE_KEYS.AUTH_DUAL_LAYOUT,
    false,
  );

  const layoutType = useMemo<LAYOUT_TYPES>(() => {
    if (isFeatureFlagEnabled) {
      return LAYOUT_TYPES.DUAL;
    }

    return LAYOUT_TYPES.CLASSIC;
  }, [isFeatureFlagEnabled]);

  return (
    <LayoutContext.Provider value={{ layoutType }}>
      {layoutType === LAYOUT_TYPES.CLASSIC && (
        <ClassicLayout>{children}</ClassicLayout>
      )}
      {layoutType === LAYOUT_TYPES.DUAL && <DualLayout>{children}</DualLayout>}
    </LayoutContext.Provider>
  );
};

Layout.displayName = 'Layout';

export default Layout;
