import styled from 'styled-components';

import { DefaultStyledProps } from '@savgroup-front-common/types';

interface LogoContainerProps extends DefaultStyledProps {
  componentThemeName?: string;
}

export const $LoginImageContainer = styled.div<LogoContainerProps>`
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  position: relative;
  z-index: 2;
`;

export const $LoginImg = styled.img`
  width: auto;
  display: block;
  max-height: 30rem;
  max-width: 80%;
  transform: translateY(-3rem);
`;
