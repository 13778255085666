import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem } from '../helpers';

export const newControlDefaultTheme: DefaultTheme = {
  colors: {
    primary: '#5369EB',
    primaryHover: undefined,
    secondary: '#5A72FF',
    secondaryColor1: '#ebebeb',
    secondaryColor2: '#EEF0FD',
    secondaryBackground: '#E5F1FA',
    tertiary: '#8C9FAA',
    success: '#0BB07B',
    danger: '#E15554',
    alert: '#f07300',
    neutralBackground: '#F3F5F6',
    white: '#FFFFFF',
    black: '#000000',
    darkBackground: '#2a2833',
    lightGrey: '#E6EAEE',
    default: '#C9CED6',
    disabled: '#E1E4E8',
    mainTextColor: '#343434',
    headingTextColor: '#5369EB',
    paragraphTextColor: '#8A94A6',
    headerTitleColor: '#EEF0FD',
    linkColor: '#5369EB',
    headerTextColor: '#54617a',
    purple: '#9c36f2',
    iconColor: '#000000',
    backgroundColor: '#F8F9FB',
    menuItemSeparator: '#666666',
    datatableRowBackgroundHoverColor: '#689ABA0D',
    datatableRowExpandedBackgroundColor: '#689ABA0D',
  },
  systemColors: {
    defaultTextColor: '#2a2833',
  },
  interactions: {
    basicTiming: '.2s',
    transformTiming: '.25s',
    activeScale: '.96',
    buttonHoverAsHollow: false,
  },
  fonts: {
    weight: {
      thin: 100,
      light: 300,
      normal: 400,
      mediumFont: 500,
      mediumBold: 600,
      bold: 700,
    },
    size: {
      tiny: rem(7),
      ultraMini: rem(8),
      mini: rem(11),
      megaSmall: rem(12),
      ultraSmall: rem(13),
      small: rem(14),
      medium: rem(16),
      large: rem(18),
      big: rem(25),
      huge: rem(30),
      header: rem(32),
      heading: {
        h1: rem(32),
        h2: rem(24),
        h3: rem(21),
        h4: rem(18),
        h5: rem(16),
        h6: rem(15),
      },
    },
  },
  spacing: {
    none: '0',
    xs: '.125rem',
    sm: '.25rem',
    md: '.5rem',
    lg: '1rem',
    xl: '1.5rem',
    xxl: '2rem',
    get: ({
      theme,
      top,
      right,
      bottom,
      left,
    }: {
      theme: DefaultTheme;
      top: string;
      right?: string;
      bottom?: string;
      left?: string;
    }) => {
      if (theme.spacing) {
        const $1 = (theme.spacing as any)[top];
        const $2 = right ? (theme.spacing as any)[right] : undefined;
        const $3 = bottom ? (theme.spacing as any)[bottom] : undefined;
        const $4 = left ? (theme.spacing as any)[left] : undefined;
        const result = `${$1}${$2 ? ` ${$2}` : ''}${$3 ? ` ${$3}` : ''}${
          $4 ? ` ${$4}` : ''
        }`;

        return result;
      }

      return '';
    },
  },
  shadows: {
    // eslint-disable-next-line camelcase
    elevation__1: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '1px',
    )} ${rem('1px')} 0 rgba(10,31,68,0.08);`,
    // eslint-disable-next-line camelcase
    elevation__2: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '3px',
    )} ${rem('4px')} 0 rgba(10,31,68,0.1);`,
  },
  borders: {
    radius: '4px',
    thickness: '1px',
    thicknessSpecialInput: '2px',
  },

  buttons: {
    naked: {
      default: {
        highContrastColor: {
          value: '#FFFFFF',
        },
        highContrastOnHoverColor: {
          value: '#FFFFFF',
        },
        onHoverBackgroundColor: {
          value: 'rgba(0, 0, 0, 0.1)',
        },
        highContrastOnHoverBackgroundColor: {
          value: 'rgba(255, 255, 255, 0.08)',
        },
      },
      primary: {
        color: {
          value: 'colors.primary',
        },
        highContrastColor: {
          value: '#FFFFFF',
        },
        onHoverBackgroundColor: {
          value: rgba('#5369EB', 0.1),
        },
        highContrastOnHoverBackgroundColor: {
          value: 'rgba(255, 255, 255, 0.08)',
        },
      },
    },
  },
  components: {
    [SUPPORTED_COMPONENTS.DEFAULT_BUTTONS]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.headingTextColor',
      },
      [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
        fallback: 'colors.disabled',
      },
      [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
        fallback: 'colors.disabled',
      },
      [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
        value: 'inherit',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem(9)} ${rem(9)}`,
      },
      [SUPPORTED_PROPERTIES.SPACE_BETWEEN_ICON_AND_TEXT]: {
        value: `${rem(5)}`,
      },
      [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
        value: 600,
      },
      [SUPPORTED_PROPERTIES.BORDER_RADIUS]: {
        value: '4px',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_NAKED_BUTTONS]: {
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem(8)} ${rem(5)}`,
      },
    },
    [SUPPORTED_COMPONENTS.COPY_TO_CLIPBOARD_BUTTONS]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: `1px solid transparent`,
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        value: 'transparent',
      },
      [SUPPORTED_PROPERTIES.ON_FOCUS_BACKGROUND_COLOR]: {
        value: 'transparent',
      },
    },
    [SUPPORTED_COMPONENTS.SMALL_DEFAULT_BUTTONS]: {
      [SUPPORTED_PROPERTIES.FONT_SIZE]: {
        value: '14px',
      },
      [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
        value: 600,
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: rem(8),
      },
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '32px',
      },
      [SUPPORTED_PROPERTIES.MIN_HEIGHT]: {
        value: '32px',
      },
      [SUPPORTED_PROPERTIES.SPACE_BETWEEN_ICON_AND_TEXT]: {
        value: `${rem(5)}`,
      },
    },
    [SUPPORTED_COMPONENTS.COMBOBOX_BUTTON]: {
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `0.498rem 0.8rem`,
      },
    },
    [SUPPORTED_COMPONENTS.CREATE_FILE_BUTTON]: {
      [SUPPORTED_PROPERTIES.BORDER_RADIUS]: {
        value: '4px',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '8px 10px 8px 14px',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_INPUTS]: {
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '0 1rem',
      },
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '2.858rem',
      },
      [SUPPORTED_PROPERTIES.MIN_HEIGHT]: {
        value: '2.858rem',
      },
      [SUPPORTED_PROPERTIES.BORDER_RADIUS]: { value: '6px' },
    },
    [SUPPORTED_COMPONENTS.TEXT_AREA]: {
      [SUPPORTED_PROPERTIES.PADDING_BOTTOM]: { value: '13px' },
      [SUPPORTED_PROPERTIES.PADDING_TOP]: { value: '13px' },
      [SUPPORTED_PROPERTIES.PADDING_LEFT]: {
        value: '1rem',
      },
      [SUPPORTED_PROPERTIES.PADDING_RIGHT]: {
        value: '1rem',
      },
      [SUPPORTED_PROPERTIES.HEIGHT]: { value: '41px' },
    },
    [SUPPORTED_COMPONENTS.SKELETON_INPUTS]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '40px',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_LABEL]: {
      [SUPPORTED_PROPERTIES.MIN_HEIGHT]: {
        value: '16px',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.paragraphTextColor',
      },
      [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
        fallback: 'fonts.weight.mediumFont',
      },
      [SUPPORTED_PROPERTIES.FONT_SIZE]: {
        fallback: 'fonts.size.megaSmall',
      },
      [SUPPORTED_PROPERTIES.LINE_HEIGHT]: {
        fallback: 'fonts.size.ultraSmall',
      },
      [SUPPORTED_PROPERTIES.MARGIN]: {
        value: '0 0 4px 0',
      },
    },
    [SUPPORTED_COMPONENTS.FORM_GROUP]: {
      [SUPPORTED_PROPERTIES.MARGIN_BOTTOM]: {
        value: '1rem',
      },
      [SUPPORTED_PROPERTIES.MARGIN_TOP]: {
        value: '0.25rem',
      },
    },
    [SUPPORTED_COMPONENTS.SKELETON_LABEL]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '1rem',
      },
      [SUPPORTED_PROPERTIES.MARGIN]: {
        value: '0 0 .5rem',
      },
    },
    [SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#d9b120',
      },
    },
    [SUPPORTED_COMPONENTS.VIEW_FILE_DETAILS_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#32c264',
      },
    },
    [SUPPORTED_COMPONENTS.SNOOZE_BUTTON]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid white',
      },
    },
    [SUPPORTED_COMPONENTS.MARK_AS_RESOLVED_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
    },
    [SUPPORTED_COMPONENTS.BACK_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.mainTextColor',
      },
    },
    [SUPPORTED_COMPONENTS.AUTOCOMPLETE_OPTION]: {
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.secondaryColor1',
      },
      [SUPPORTED_PROPERTIES.ON_FOCUS_BACKGROUND_COLOR]: {
        fallback: 'colors.secondaryColor1',
      },
    },
    [SUPPORTED_COMPONENTS.QUANTITY_INPUT]: {
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '1.125rem 1.25rem',
      },
    },
    [SUPPORTED_COMPONENTS.CARD]: {
      coloredBackgroundColor: {
        value: '#EEF0FD',
      },
      padding: {
        value: rem(28),
      },
      marginTop: {
        value: rem(24),
      },
      marginBottom: {
        value: rem(32),
      },
      borderRadius: {
        value: rem(4),
      },
    },
    [SUPPORTED_COMPONENTS.IMPORT_REPORT_DELETED_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        value: '#636161',
      },
    },
    [SUPPORTED_COMPONENTS.ROUTE_TAB_SUB_MENU_CONTENT]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.darkBackground',
      },
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid #E1E4E8',
      },
      [SUPPORTED_PROPERTIES.BORDER_RADIUS]: {
        value: '8px',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: '8px',
      },
    },
    [SUPPORTED_COMPONENTS.CONFIGURATION_CONSOLE_TAB_MENU_CONTENT]: {
      [SUPPORTED_PROPERTIES.MARGIN]: {
        value: 50,
      },
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '69px',
      },
      [SUPPORTED_PROPERTIES.MARGIN_BOTTOM]: {
        value: '1.4rem',
      },
    },
    [SUPPORTED_COMPONENTS.CONFIGURATION_CONSOLE_SUMMARY_RULE]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: 'none',
      },
      [SUPPORTED_PROPERTIES.BORDER_RADIUS]: {
        value: '8px',
      },
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
    },
    [SUPPORTED_COMPONENTS.CONFIGURATION_CONSOLE_DEFAULT]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.MAX_WIDTH]: {
        value: '30%',
      },
    },
    [SUPPORTED_COMPONENTS.DEBUG_INSPECTOR]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
    },
    [SUPPORTED_COMPONENTS.NEW_UI_PRODUCT_ACCORDION]: {
      [SUPPORTED_PROPERTIES.HEIGHT]: {
        value: '80px',
      },
    },
  },

  newUI: {
    borders: {
      smallRadius: '4px',
      mediumRadius: '6px',
      bigRadius: '8px',
    },
    shadows: {
      level1: `0px 1px 4px 0px ${rgba(0, 0, 0, 0.1)}`,
    },
    gradients: {
      blueStart: '#010B35',
      blueEnd: '#002E74',
      purpleStart: '#151F4C',
      purpleMiddle: '#482F99',
      purpleEnd: '#4F56D1',
      lightStart: '#EEEFFE',
      lightMiddle: '#EEEFFE99',
      lightEnd: '#E1E5FD',
    },
    defaultColors: {
      menu: '#002E74',
      primary: '#5369EB',
      secondary: '#5A72FF',
      text: '#000000',
      secondaryText: '#515969',
    },
    inputBorderColors: {
      default: '#CCD2F9',
    },
    dividerColors: {
      default: '#DDE1FB',
    },
    iconColors: {
      primary: {
        color: '#5369EB',
        bgColor: '#EEF0FD',
      },
      secondary: {
        color: '#A661FF',
        bgColor: '#F1E5FF',
      },
      alert: {
        color: '#F36C6C',
        bgColor: '#FEF0F0',
      },
      caution: {
        color: '#FB6D86',
        bgColor: '#FFE9ED',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#FEF0F0',
      },
      info: {
        color: '#3AACC6',
        bgColor: '#E2F5FF',
      },
      success: {
        color: '#31BF95',
        bgColor: '#E7FAEB',
      },
      warning: {
        color: '#FF7B1A',
        bgColor: '#FFE9DA',
      },
      disabled: {
        color: '#515969',
        bgColor: '#F1F2F5',
      },
      gold: {
        color: '#CC9300',
        bgColor: '#FFF9E5',
      },
    },
    tagsColors: {
      primary: {
        color: '#3F51BD',
        bgColor: '#EEF0FD',
      },
      secondary: {
        color: '#FFFFFF',
        bgColor: '#002E74',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#FEF0F0',
      },
      success: {
        color: '#07875D',
        bgColor: '#E7FAEB',
      },
      alert: {
        color: '#FF7B1A',
        bgColor: '#FFF3EB',
      },
      disabled: {
        color: '#8A94A6',
        bgColor: '#F7F7FA',
      },
      neutral: {
        color: '#000000',
        bgColor: '#E8E9ED',
      },
    },
    variationColors: {
      light1: '#5A72FF',
      light2: '#A2ABE9',
      light3: '#CCD2F9',
      light4: '#DDE1FB',
      light5: '#EEF0FD',
      light6: '#F4F6FF',
      light7: '#FBFBFF',
      dark1: '#465AD3',
      dark2: '#3F51BD',
    },
  },
};
