import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}salesleads/${COMMON_QUERY_PARAMS.EMAIL}/configure`;

interface SavePersonalizedDemoContextCommandArgs {
  personalizationKeys: string[];
  email: string;
}

const savePersonalizedDemoContextCommand = async ({
  email,
  personalizationKeys,
}: SavePersonalizedDemoContextCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.EMAIL, email);

    const response = await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        personalizationKeys,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default savePersonalizedDemoContextCommand;
