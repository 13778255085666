import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { MinimalSeller } from 'authenticator/types';

const ENDPOINT = `${APIConfiguration.seller}sellers/bytenantid/${COMMON_QUERY_PARAMS.TENANT_ID}`;

interface GetSellersByTenantIdArgs {
  tenantId: string;
}

const getSellersByTenantId = async ({
  tenantId,
}: GetSellersByTenantIdArgs): Promise<
  BackResponse<MinimalSeller[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.TENANT_ID, tenantId);

    const response = await apiCall<BackResponse<MinimalSeller[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellersByTenantId;
