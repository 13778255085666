import styled from 'styled-components';

export const $Link = styled.a`
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.size.medium};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
