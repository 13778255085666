import {
  DefaultStyledProps,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { getCustomComponentProperty } from '../../../helpers';

interface GetBorderRadiusProps extends DefaultStyledProps {
  componentThemeName?: string;
}

export const getBorderRadius = (
  props: GetBorderRadiusProps,
): string | undefined => {
  const { theme, componentThemeName } = props;

  const customBorderRadius = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
  });

  const defaultValue = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
  });

  return customBorderRadius || defaultValue;
};
