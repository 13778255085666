import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import React from 'react';

import { formatPhoneNumber, formatPhoneNumberAsYouType } from '../formatters';

import { updateCaretPosition } from './DOM';

function computeNewtCaretPosition(initialPosition, oldValue, newValue) {
   
  const leadingDoubleZeros = startsWith(oldValue, '00');

  let indexOld = leadingDoubleZeros ? 2 : 0;

  let indexNew = leadingDoubleZeros ? 1 : 0;

  while (indexOld < initialPosition && indexNew < newValue.length) {
    indexOld += get(
      oldValue.slice(indexOld).match(/[0-9]/),
      'index',
      oldValue.length - indexOld,
    );
    indexNew += get(
      newValue.slice(indexNew).match(/[0-9]/),
      'index',
      newValue.length - indexNew,
    );
    if (
      oldValue[indexOld] !== newValue[indexNew] ||
      indexOld >= initialPosition
    ) {
      return indexNew;
    }
    indexOld++;
    indexNew++;
  }

  return indexNew;
}

export class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleValueChange = (e, data) => {
      e.preventDefault();
      const { onChange, countryCode } = this.props;
      const element = e.target;
      const phoneNumber = e.target.value;
      const formattedValue = formatPhoneNumberAsYouType(
        phoneNumber,
        countryCode,
      );
      const caretPosition = computeNewtCaretPosition(
        element.selectionStart,
        phoneNumber,
        formattedValue,
      );

      e.target.value = formattedValue;
      onChange(e, { ...data, value: formattedValue });
      window.setTimeout(() => {
        updateCaretPosition(element, caretPosition);
      });
    };

    this.handleBlur = (e) => {
      e.preventDefault();
      const { onBlur, onChange, value, countryCode } = this.props;
      const formattedValue = formatPhoneNumber(value, countryCode);

      if (formattedValue !== value) {
        e.target.value = formattedValue;
        onChange(e, { name: e.target.name, value: formattedValue });
      }
      if (onBlur) {
        onBlur();
      }
    };
  }

  render() {
    const { children, value } = this.props;

    return children(value, this.handleValueChange, this.handleBlur);
  }
}
