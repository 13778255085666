import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { hotjar } from 'react-hotjar';

export interface HotjarConfigurationEnabled {
  enabled: true;
  hjid: number;
  hjsv: number;
}
export interface HotjarConfigurationDisabled {
  enabled: false;
}

interface HotjarContextReturnValue {
  isHotjarEnabled: boolean;
  hotjar: typeof hotjar;
}

const HotjarProviderContext = React.createContext<HotjarContextReturnValue>({
  isHotjarEnabled: false,
  hotjar,
});

export const useHotjar = (): HotjarContextReturnValue => {
  return useContext(HotjarProviderContext);
};

export const useOnRegisterHotjarEventTracking = (): ((
  event: string,
) => boolean) => {
  const { isHotjarEnabled } = useHotjar();

  return useCallback(
    (event: string) => {
      if (isHotjarEnabled) {
        hotjar.event(event);

        return true;
      }

      return false;
    },
    [isHotjarEnabled],
  );
};

export const useHotjarEventTrackingOnMount = (
  event: string,
): HotjarContextReturnValue => {
  const { isHotjarEnabled } = useHotjar();

  useEffect(() => {
    if (isHotjarEnabled) {
      hotjar.event(event);
    }
  }, [event, isHotjarEnabled]);

  return useContext(HotjarProviderContext);
};

interface HotjarProviderProps {
  config: HotjarConfigurationEnabled | HotjarConfigurationDisabled;
}

const HotjarProvider: FunctionComponent<React.PropsWithChildren<HotjarProviderProps>> = ({
  children,
  config,
}) => {
  const [isHotjarEnabled, setIsHotjarEnabled] = useState(false);

  useLayoutEffect(() => {
    if (config.enabled) {
      hotjar.initialize(config.hjid, config.hjsv);
      setIsHotjarEnabled(true);
    }
  }, [config]);

  return (
    <HotjarProviderContext.Provider value={{ isHotjarEnabled, hotjar }}>
      {children}
    </HotjarProviderContext.Provider>
  );
};

HotjarProvider.displayName = 'HotjarProvider';

export default HotjarProvider;
