import React, { FunctionComponent, useCallback } from 'react';

import {
  OnSubmit,
  StepsOrchestrator,
} from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { OwnerService } from 'authenticator/api';
import { useClientInformationContext } from 'authenticator/view/atoms';

import {
  PRICE_ORDER_LOGIN_STEP_NAMES,
  PRICE_ORDER_LOGIN_STEPS_CONFIG,
} from './PriceOrderLogin.steps';
import { PriceOrdersContext, PriceOrdersValues } from './PriceOrderLogin.types';

const PriceOrderLogin: FunctionComponent = () => {
  const {
    clientInformation: { sellerId, tenantId },
  } = useClientInformationContext();

  const handleSubmit: OnSubmit<PriceOrdersValues, PriceOrdersContext> =
    useCallback(
      async (value) => {
        if (!sellerId || !tenantId) {
          throw new Error('SellerId or TenantId is not defined');
        }

        const response = await OwnerService.createAnonymousOrder({
          owner: value.owner,
          sellerId,
          tenantId,
          orderReference: value.orderReference,
        });

        if (!response.failure) {
          window.location = response.value;

          return;
        }

        throw response.errors;
      },
      [sellerId, tenantId],
    );

  return (
    <StepsOrchestrator<PriceOrdersValues, PriceOrdersContext>
      initialStep={PRICE_ORDER_LOGIN_STEP_NAMES.ORDER_INFORMATIONS}
      config={PRICE_ORDER_LOGIN_STEPS_CONFIG}
      onSubmit={handleSubmit}
      withWizard={false}
    />
  );
};

PriceOrderLogin.displayName = 'PriceOrderLogin';

export default PriceOrderLogin;
