import flatMap from 'lodash/flatMap';
import sum from 'lodash/sum';

import { REGEX_RULES } from '@savgroup-front-common/constants/src/shared';

import {
  numberFormatter,
  transformStringPriceToRoundedFloatPrice,
} from '../formatters';

import { toFloat } from './numericConverter';

export const isValidIMEI = (value?: string | number) => {
  if (
    value === undefined ||
    (typeof value === 'string' && value.length === 0)
  ) {
    return true;
  }

  const reg = new RegExp(REGEX_RULES.IMEI);

  const isImei = reg.test(String(value));

  if (!isImei) {
    return false;
  }
  const baseValue =
    typeof value === 'string'
      ? value.toString().split('').map(Number)
      : numberFormatter.toDigits(Number(value));

  return (
    sum(
      flatMap(baseValue, (v, i) => numberFormatter.toDigits(((i % 2) + 1) * v)),
    ) %
      10 ===
    0
  );
};

export const isPrice = (
  value?: string | number,
  reg = new RegExp(REGEX_RULES.PRICE),
) => {
  if (value === undefined) {
    return false;
  }

  if (!reg.test(String(value))) {
    return false;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  if (numberFormatter.transformToValidNumber(value) < 0) {
    return false;
  }

  return true;
};
export const isNegativePrice = (
  value?: string | number,
  reg = new RegExp(REGEX_RULES.NEGATIVE_PRICE),
) => {
  if (value === undefined) {
    return false;
  }

  if (!reg.test(String(value))) {
    return false;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  if (numberFormatter.transformToValidNumber(value) > 0) {
    return false;
  }

  return true;
};

export const isPositivePrice = (value?: string | number) => {
  if (value === undefined || !isPrice(value, REGEX_RULES.PRICE_WITH_SPACE)) {
    return false;
  }

  const price = transformStringPriceToRoundedFloatPrice(String(value));

  return !Number.isNaN(price) && Number(price) > 0;
};

export const isUrl = (value?: string) => {
  if (value === undefined) {
    return false;
  }
  const reg = new RegExp(REGEX_RULES.URL);

  return reg.test(String(value));
};

export const isRoundNumber = (value?: string | number) => {
  if (value === undefined) {
    return false;
  }

  const reg = new RegExp(/^-{0,1}[0-9]*$/);

  if (!reg.test(String(value))) {
    return false;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  return true;
};

export const isFloat = (value?: string | number) => {
  if (value === undefined) {
    return false;
  }

  const reg = new RegExp(/^-{0,1}[0-9]*([.,][0-9]*)?$/);

  if (!reg.test(String(value))) {
    return false;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  return true;
};

export const isRoundedPercent = (value?: string | number) => {
  if (value === undefined) {
    return false;
  }

  if (!isRoundNumber(value)) {
    return false;
  }

  const number = numberFormatter.transformToValidNumber(value);

  if (number < 0) {
    return false;
  }

  if (number > 100) {
    return false;
  }

  return true;
};

export const isEmail = (value?: string) => {
  if (value === undefined) {
    return false;
  }

  const reg = new RegExp(REGEX_RULES.EMAIL);

  return reg.test(value);
};

export const isPhoneNumber = (value?: string) => {
  if (value === undefined) {
    return false;
  }

  const reg = new RegExp(REGEX_RULES.PHONE);

  return reg.test(String(value));
};

export const isSafeNumber = (value?: number | string) => {
  if (value === undefined) {
    return false;
  }

  return (
    (isRoundNumber(value) || isFloat(value)) &&
    toFloat(value) <= Number.MAX_SAFE_INTEGER
  );
};

export const isSafePrice = (value?: number | string) => {
  if (value === undefined) {
    return false;
  }

  return isPrice(value) && isSafeNumber(value);
};

export const isUnderPercentageMax = (value?: number | string) => {
  if (value === undefined) {
    return false;
  }

  return Number(value) <= 100;
};

export const isSafeFractionalNumber = (value: string | number) => {
  if (value === undefined) {
    return false;
  }

  return (
    (isPrice(value, new RegExp(REGEX_RULES.FRACTIONAL_PRICE)) ||
      isNegativePrice(value, new RegExp(REGEX_RULES.NEGATIVE_PRICE))) &&
    isSafeNumber(value)
  );
};

export const isAddress = (value: string | undefined) => {
  if (value === undefined) {
    return false;
  }

  const reg = new RegExp(REGEX_RULES.ADDRESS);

  return reg.test(value);
};
