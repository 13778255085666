import getSalesLeadOnboardingStatusQuery from './getSalesLeadOnboardingStatusQuery';
import getSellerAcceptedLanguages from './getSellerAcceptedLanguages';
import getSellersByTenantId from './getSellersByTenantId';
import getSellerSelfcareConfigurationQuery from './getSellerSelfcareConfigurationQuery';
import resendSalesLeadEmailConfirmationCommand from './resendSalesLeadEmailConfirmationCommand';
import salesLeadConfirmEmailCommand from './salesLeadConfirmEmailCommand';
import savePersonalizedDemoContextCommand from './savePersonalizedDemoContextCommand';
import upsertSalesLeadCommand from './upsertSalesLeadCommand';

export const SellerService = {
  getSellersByTenantId,
  getSellerAcceptedLanguages,
  getSellerSelfcareConfigurationQuery,
  upsertSalesLeadCommand,
  savePersonalizedDemoContextCommand,
  resendSalesLeadEmailConfirmationCommand,
  salesLeadConfirmEmailCommand,
  getSalesLeadOnboardingStatusQuery,
};
