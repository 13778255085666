import { combineReducers } from 'redux';

import { reducer as i18nReducer } from '@savgroup-front-common/core/src/domains/i18n';
import { sellerReducer } from '@savgroup-front-common/core/src/domains/sellers';

const rootReducer = () =>
  combineReducers({
    sellers: sellerReducer,
    i18n: i18nReducer,
    login: (state = { profiles: [], roles: [], permissions: [] }) => {
      return state;
    },
  });

export default rootReducer;
