import React, { FunctionComponent, ReactElement } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src/shared';

import { getAnimationDirectionClassNames } from '../../animations/helper';

import {
  $StepsOrchestratorAnimation,
  $StepsOrchestratorContainer,
} from './StepsOrchestrator.styles';

interface StepsOrchestratorAnimationProps {
  onAnimationEntered: () => void;
  animating: boolean;
  animationType: ANIMATION_DIRECTION;
  name: string;
  parentPadding: number;
}

const StepsOrchestratorAnimation: FunctionComponent<
  React.PropsWithChildren<StepsOrchestratorAnimationProps>
> = ({
  name,
  animationType,
  animating,
  onAnimationEntered,
  children,
  parentPadding,
}) => {
  const classNames = getAnimationDirectionClassNames(animationType);

  return (
    <$StepsOrchestratorContainer
      $isAnimating={animating}
      $parentPadding={parentPadding}
    >
      <TransitionGroup
        component={null}
        childFactory={(child: ReactElement) =>
          React.cloneElement(child, { classNames })
        }
      >
        <CSSTransition
          key={name}
          timeout={400}
          classNames={classNames}
          onEntered={onAnimationEntered}
          unmountOnExit
        >
          {(animationState) => (
            <$StepsOrchestratorAnimation
              animationDuration={400}
              animationState={animationState}
              $parentPadding={parentPadding}
            >
              {children}
            </$StepsOrchestratorAnimation>
          )}
        </CSSTransition>
      </TransitionGroup>
    </$StepsOrchestratorContainer>
  );
};

StepsOrchestratorAnimation.displayName = 'StepsOrchestratorAnimation';

export default StepsOrchestratorAnimation;
