import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import messages from './messages';
import CustomersPage from './steps/CustomersPage/CustomersPage';
import DomainsPage from './steps/DomainsPage/DomainsPage';
import ReturnsPage from './steps/ReturnsPage/ReturnsPage';

export enum ACTIVITIES_STEPS_NAMES {
  DOMAINS_PAGE = 'domainsPage',
  CUSTOMERS_PAGE = 'customersPage',
  RETURNS_PAGE = 'returnsPage',
}

const selectDomainsStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.DOMAINS_PAGE,
  nextStep: ACTIVITIES_STEPS_NAMES.CUSTOMERS_PAGE,
  Component: DomainsPage,
  message: messages.domainsWizard,
};

const selectCustomersStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.CUSTOMERS_PAGE,
  nextStep: ACTIVITIES_STEPS_NAMES.RETURNS_PAGE,
  Component: CustomersPage,
  message: messages.customersWizard,
};

const selectReturnsStep: Step<Record<string, unknown>> = {
  name: ACTIVITIES_STEPS_NAMES.RETURNS_PAGE,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: ReturnsPage,
  message: messages.returnsWizard,
};

export const ACTIVITIES_STEPS = [
  selectDomainsStep,
  selectCustomersStep,
  selectReturnsStep,
];
