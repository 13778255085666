import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import orderInformationsSchema from '../OrderInformations.schema';
import {
  ORDER_INFORMATIONS_FIELD_NAMES as FIELDS,
  OrderInformationsFieldValues,
} from '../OrderInformations.types';

import useOrderInformationsStepContext from './useOrderInformationsStepContext';

const useOrderInformationsFormContext = () => {
  const { values } = useOrderInformationsStepContext();

  return useForm<OrderInformationsFieldValues>({
    resolver: yupResolver(orderInformationsSchema()),
    defaultValues: {
      [FIELDS.ORDER_REFERENCE]: values.orderReference,
      [FIELDS.ORDER_PRICE]: values.orderPrice,
    },
  });
};

export default useOrderInformationsFormContext;
