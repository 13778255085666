import * as Yup from 'yup';

import {
  globalMessages,
  isEmail,
  isPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';

import messages from './messages';

const OnboardingSchema = Yup.object().shape({
  email: Yup.string()
    .required(globalMessages.form.required)
    .test('is-email', globalMessages.form.invalidEmail, (value) => {
      return isEmail(value);
    }),
  lastName: Yup.string().required(globalMessages.form.required),
  firstName: Yup.string().required(globalMessages.form.required),
  companyName: Yup.string().required(globalMessages.form.required),
  jobTitle: Yup.object().nullable().required(globalMessages.form.required),
  country: Yup.object().nullable().required(globalMessages.form.required),
  phone: Yup.string()
    .required(globalMessages.form.required)
    .test(
      'isFrenchPhoneNumber',
      globalMessages.form.invalidPhoneNumber,
      (value) => {
        if (value?.length !== 10) {
          return false;
        }

        return isPhoneNumber(value);
      },
    ),
  cgv: Yup.boolean().test('isCgvAccepted', messages.cgvRequired, (value) => {
    if (!value) {
      return false;
    }

    return value;
  }),
});

export default OnboardingSchema;
