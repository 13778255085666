import padStart from 'lodash/padStart';
import replace from 'lodash/replace';
import round from 'lodash/round';
import toInteger from 'lodash/toInteger';
import trim from 'lodash/trim';
import { IntlShape } from 'react-intl';

import { CURRENCIES } from '@savgroup-front-common/constants/src/shared';
import {
  ReverseMoney,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

import {
  intl,
  isNegativePrice,
  isPrice,
  isSafeFractionalNumber,
  isSafePrice,
} from '../helpers';

import { formatCurrencyCode } from './currency';

export const formatStripeAmount = (
  amount: number,
  currency: CURRENCIES = CURRENCIES.EUR,
  isFractional = false,
): string | undefined => {
   
   
  if (!Number.isFinite(amount) && isFractional) {return '0';}
  if (!Number.isFinite(amount)) {return '-';}

  const integralPart = (Math.round(amount) / 100) | 0;
  const fractionalPart = Math.round(amount) % 100 | 0;
  const fractionalPartWithoutNegative = replace(
    String(fractionalPart),
    '-',
    '',
  );
  const formattedAmount = `${String(integralPart)}.${padStart(
    fractionalPartWithoutNegative,
    2,
    '0',
  )}`;

  if (isFractional) {
    return formattedAmount;
  }

  return intl?.formatNumber(Number(formattedAmount), {
    style: 'currency',
    currency,
  });
};
export const formatStripeReverseMoneyNumberAmount = (price: {
  amount: number;
  currency: CURRENCIES;
}): string | undefined => {
  const { amount, currency } = price;

  return intl?.formatNumber(amount, {
    style: 'currency',
    currency,
  });
};

export const formatAmountWithCurrency = (
  amount: number,
  currency = CURRENCIES.EUR,
): IntlShape | string | undefined => {
  if (!Number.isFinite(amount)) {
    return '-';
  }

  return intl?.formatNumber(amount, {
    style: 'currency',
    currency,
  });
};

export const formatStripeAmountWithoutCurrency = ({
  amount,
  currency = CURRENCIES.EUR,
  isFractional = false,
}: {
  amount: number;
  currency?: CURRENCIES;
  isFractional?: boolean;
}): string => {
  const formattedAmount = formatStripeAmount(amount, currency, isFractional);
  const formattedCurrency = formatCurrencyCode(currency);

  if (formattedAmount === undefined) {
    return '0';
  }

  const replaceFormattedAmount = replace(
    formattedAmount.toString(),
    `${formattedCurrency}`,
    '',
  );

  return trim(replaceFormattedAmount);
};

export const cleanIntlNumber = ({ amount }: { amount: string }): string => {
  // eslint-disable-next-line no-irregular-whitespace
  const removeWhiteSpaceReg = new RegExp(/[ ]/gi);
  const reg = new RegExp('([0-9,.]*)([,.][0-9]{1,2})');
  const formattedAmount = replace(amount, removeWhiteSpaceReg, '');

  const matches = formattedAmount.match(reg);

  if (!matches) {
    return amount;
  }

  const number = matches[1].replace(',', '').replace('.', '');
  const decimal = matches[2];

  return `${number}${decimal}`;
};

export const formatStringToIntlPrice = ({
  amount,
  currency = CURRENCIES.EUR,
}: {
  amount: string;
  currency?: CURRENCIES;
}): string => {
  if (amount === '' || !isSafePrice(amount)) {
    return amount;
  }

  const floatAmount = round(Number(replace(amount, ',', '.')), 2).toFixed(2);

  if (floatAmount === '0.00') {
    return '0';
  }

  const formattedStripeValue = formatStripeAmountWithoutCurrency({
    amount: toInteger(Math.round(Number(floatAmount) * 100)),
    currency,
  });

  return cleanIntlNumber({ amount: formattedStripeValue });
};

export const formatStringToNegativeIntlPrice = ({
  amount,
  currency = CURRENCIES.EUR,
}: {
  amount: string;
  currency?: CURRENCIES;
}): string => {
  if (amount === '' || !(isNegativePrice(amount) || isPrice(amount))) {
    return amount;
  }

  const floatAmount = round(
    Math.abs(Number(replace(amount, ',', '.'))),
    2,
  ).toFixed(2);

  if (floatAmount === '0.00') {
    return '0';
  }

  const formattedStripeValue = formatStripeAmountWithoutCurrency({
    amount: toInteger(Math.round(Number(floatAmount) * 100)),
    currency,
  });

  return `-${cleanIntlNumber({ amount: formattedStripeValue })}`;
};

export const formatReverseMoneyToString = (
  reverseMoney?: ReverseMoney | ReverseMoneyNumberAmount,
): string => {
  if (!reverseMoney) {
    return '';
  }

  const { amount } = reverseMoney;
  const formattedCurrency = formatCurrencyCode(reverseMoney.currency);

  if (!isSafeFractionalNumber(amount)) {
    return `${amount} ${formattedCurrency}`;
  }

  const floatAmount = round(
    Number(replace(amount.toString(), ',', '.')),
    2,
  ).toFixed(2);
  const formattedStripeValue = formatStripeAmountWithoutCurrency({
    amount: toInteger(Math.round(Number(floatAmount) * 100)),
    currency: reverseMoney.currency,
  });

  if (floatAmount === round(0, 2).toFixed(2)) {
    return `${formattedStripeValue} ${formattedCurrency}`;
  }

  const formattedAmount = cleanIntlNumber({ amount: formattedStripeValue });

  if (isNegativePrice(amount)) {
    return `-${formattedAmount} ${formattedCurrency}`;
  }

  return `${formattedAmount} ${formattedCurrency}`;
};
