import React, { FunctionComponent } from 'react';

import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import AddressSuggestionContent from './AddressSuggestionContent';

const AddressSuggestion: FunctionComponent = () => {
  return (
    <BannersProvider>
      <AddressSuggestionContent />
    </BannersProvider>
  );
};

AddressSuggestion.displayName = 'AddressSuggestion';

export default AddressSuggestion;
