export type AnimationDirection = 'next' | 'previous';

export const ANIMATION_STATE = {
  ENTERING: 'entering',
  ENTERED: 'entered',
  EXITING: 'exiting',
  EXITED: 'exited',
  UNMOUNTED: 'unmounted',
};

export enum ANIMATION_DIRECTION {
  NEXT = 'next',
  PREVIOUS = 'previous',
}
