import React from 'react';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { Redirect } from 'react-router-dom';

const Callback = () => {
  const query = useSearchParams();
  const redirectUri = query.get('redirectUri') || '';

  return <Redirect to={redirectUri} />;
};

Callback.displayName = 'Callback';

export default Callback;
