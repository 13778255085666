import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const ChoiceProductGenericModelSchema = Yup.object().shape({
  productGeneric: Yup.object()
    .test(
      'productGeneric',
      globalMessages.form.required,
      function test(option) {
        return option?.value;
      },
    )
    .required(globalMessages.form.required),
  productBrand: Yup.object()
    .test('productBrand', globalMessages.form.required, function test(option) {
      return option?.value;
    })
    .required(globalMessages.form.required),
});

export default ChoiceProductGenericModelSchema;
