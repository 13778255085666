import React from 'react';

interface SparksProps {
  color?: string;
}

 
const SparksIcon: React.FC<SparksProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <g clipPath="url(#clip0_1196_2943)">
        <path
          d="M6.66699 12.5C10.7295 12.5 12.5003 10.7908 12.5003 6.66663C12.5003 10.7908 14.2587 12.5 18.3337 12.5C14.2587 12.5 12.5003 14.2583 12.5003 18.3333C12.5003 14.2583 10.7295 12.5 6.66699 12.5ZM1.66699 5.41663C4.27866 5.41663 5.41699 4.31829 5.41699 1.66663C5.41699 4.31829 6.54783 5.41663 9.16699 5.41663C6.54783 5.41663 5.41699 6.54746 5.41699 9.16663C5.41699 6.54746 4.27866 5.41663 1.66699 5.41663Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1196_2943">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SparksIcon.displayName = 'SparksIcon';

export default SparksIcon;
