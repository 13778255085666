import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}anonymous/orders/${COMMON_QUERY_PARAMS.REFERENCE}`;

interface GetOrderByOrderReferenceAndOrderPriceArgs {
  sellerId: string;
  totalPrice?: number | string;
  PhoneNumberLastFourDigits?: string;
  orderReference: string;
}

export enum ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE {
  ORDER_ALREADY_IMPORT = 'OrderAlreadyImport',
  ANONYMOUS_CUSTOMER = 'AnonymousCustomer',
  CUSTOMER_IDENTIFIED = 'CustomerIdentified',
}

export type GetOrderByOrderReferenceAndOrderPriceResponseCustomerIdentified = {
  orderReference: string;
  owner: OwnerSummary;
  importStatus: ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.CUSTOMER_IDENTIFIED;
};

export type GetOrderByOrderReferenceAndOrderPriceResponseAnonymousCustomer = {
  orderReference: string;
  importStatus: ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.ANONYMOUS_CUSTOMER;
};

export type GetOrderByOrderReferenceAndOrderPriceResponseOrderAlreadyImport = {
  irshUrl: Location;
  importStatus: ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.ORDER_ALREADY_IMPORT;
};

export type GetOrderByOrderReferenceAndOrderPriceResponse =
  | GetOrderByOrderReferenceAndOrderPriceResponseCustomerIdentified
  | GetOrderByOrderReferenceAndOrderPriceResponseAnonymousCustomer
  | GetOrderByOrderReferenceAndOrderPriceResponseOrderAlreadyImport;

async function getOrderByOrderReferenceAndOrderPrice({
  sellerId,
  orderReference,
  totalPrice,
  PhoneNumberLastFourDigits,
}: GetOrderByOrderReferenceAndOrderPriceArgs): Promise<
  | BackResponse<GetOrderByOrderReferenceAndOrderPriceResponse>
  | BackResponseFailure
> {
  const url = buildUrl(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.REFERENCE, orderReference),
    {
      sellerId,
      orderReference,
      totalPrice,
      PhoneNumberLastFourDigits,
    },
  );

  try {
    const response = await apiCall<
      BackResponse<GetOrderByOrderReferenceAndOrderPriceResponse>
    >(url, { method: SUPPORTED_METHODS.GET });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getOrderByOrderReferenceAndOrderPrice;
