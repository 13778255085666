import React, { FunctionComponent } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button } from '../../atoms/button';
import { SafeFormattedMessage } from '../../formatters';
import { ConnectionErrorIcon, RefreshIcon } from '../../protons/icons';

import BaseErrorBoundary from './BaseErrorBoundary';
import messages from './messages';

const ChunkLoadErrorBoundary: FunctionComponent<unknown> = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <BaseErrorBoundary
      icon={<ConnectionErrorIcon color="#fff" size="80px" />}
      titleMessage={messages.chunkErrorBoundaryTitle}
      bodyMessage={messages.chunkErrorBoundaryDidactic}
      isFullScreen
    >
      <Button
        type={BUTTON_TYPES.BUTTON}
        onClick={refreshPage}
        icon={<RefreshIcon />}
      >
        <SafeFormattedMessage {...messages.refresh} />
      </Button>
    </BaseErrorBoundary>
  );
};

ChunkLoadErrorBoundary.displayName = 'ChunkLoadErrorBoundary';

export default ChunkLoadErrorBoundary;
