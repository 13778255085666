import { css } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import {
  getCustomComponentProperty,
  rem,
  SUPPORTED_ICON_POSITIONS,
} from '../../../helpers';
import { getColor } from '../Button.helpers';

export const getDefaultColor = (props: any) => {
  const { theme } = props;

  if (props.disabled) {
    return theme.colors.disabled;
  }
  if (props.primary) {
    return theme.colors.primary;
  }
  if (props.secondary) {
    return theme.colors.primary;
  }
  if (props.clear) {
    return theme.colors.white;
  }
  if (props.success) {
    return theme.colors.success;
  }
  if (props.alert) {
    return theme.colors.alert;
  }
  if (props.danger) {
    return theme.colors.danger;
  }
  if (props.naked) {
    return theme.colors.headingTextColor;
  }

  return theme.colors.headingTextColor;
};

export const getOnHoverColor = (props: any) => {
  const { componentThemeName, naked, hollow, disabled, secondary } = props;
  const { theme } = props;
  const { interactions = {} } = theme;

  const themeValue = getCustomComponentProperty({
    theme,
    property: SUPPORTED_PROPERTIES.ON_HOVER_COLOR,
    componentName: componentThemeName,
  });

  if (themeValue) {
    return themeValue;
  }

  if (secondary) {
    return theme.colors.white;
  }

  if (interactions.buttonHoverAsHollow && !naked && !hollow && !disabled) {
    return getDefaultColor(props);
  }

  return getColor(props);
};

export const handleMargin = (props: any) => {
  if (!props.haschildren) {
    return '';
  }

  const { theme, position, componentThemeName } = props;

  let iconPositionToBeUsed = position;
  const customIconPosition = getCustomComponentProperty({
    theme,
    property: SUPPORTED_PROPERTIES.ICON_POSITION,
    componentName: componentThemeName,
  });

  if (customIconPosition) {
    iconPositionToBeUsed = customIconPosition;
  }

  if (
    iconPositionToBeUsed !== null &&
    iconPositionToBeUsed === SUPPORTED_ICON_POSITIONS.LEFT
  ) {
    return css`
      & .children {
        margin-left: ${rem('14px')};
      }
    `;
  }
  if (
    iconPositionToBeUsed !== null &&
    iconPositionToBeUsed === SUPPORTED_ICON_POSITIONS.RIGHT
  ) {
    return css`
      & .children {
        margin-right: ${rem('14px')};
      }
    `;
  }

  return css`
    & .children {
      margin-right: ${rem('0px')};
    }
  `;
};
export const handlePadding = (props: any) => {
  const { theme, naked } = props;

  if (naked) {
    return '';
  }

  const defaultPadding = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.PADDING,
  });

  if (defaultPadding) {
    return css`
      padding: ${defaultPadding};
    `;
  }

  if (props.rounded && props.haschildren) {
    return css`
      padding: ${rem('14px')} ${rem('18px')};
    `;
  }

  return css`
    padding: ${rem('14px')};
  `;
};
export const handleJustifyContent = (props: any) => {
  if (props.$isSquareIconButton) {
    return null;
  }

  if (props.isLoading) {
    return css`
      justify-content: center;
    `;
  }

  const { theme, componentThemeName, icon } = props;
  const customJustifyContent = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.JUSTIFY_CONTENT,
  });

  if (customJustifyContent) {
    return css`
      justify-content: ${customJustifyContent};
    `;
  }

  if (props.position === SUPPORTED_ICON_POSITIONS.RIGHT && icon) {
    return css`
      justify-content: space-between;
    `;
  }

  if (!icon) {
    return undefined;
  }

  return css`
    justify-content: start;
  `;
};
export const getHeight = (props: any) => {
  const { theme, componentThemeName } = props;

  const defaultValue = getCustomComponentProperty({
    theme,
    property: SUPPORTED_PROPERTIES.HEIGHT,
    componentName: componentThemeName,
    fallback: 'auto',
  });

  return getCustomComponentProperty({
    theme,
    property: SUPPORTED_PROPERTIES.HEIGHT,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
    fallback: defaultValue,
  });
};
export const getButtonsTextTransform = (props: any) => {
  const { theme, componentThemeName } = props;
  const customValue = getCustomComponentProperty({
    theme,
    componentName: componentThemeName,
    property: SUPPORTED_PROPERTIES.TEXT_TRANSFORM,
  });

  if (customValue) {
    return customValue;
  }

  return getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.DEFAULT_BUTTONS,
    property: SUPPORTED_PROPERTIES.TEXT_TRANSFORM,
  });
};
