import { css, FlattenSimpleInterpolation } from 'styled-components';

import { ANIMATION_DIRECTION } from '@savgroup-front-common/constants/src/shared';

import { isAnimationExiting } from '../../helpers';
import {
  slideLeftToRight,
  slideRightToLeft,
  StateAnimationProps,
} from '../stateAnimation';

const defaultExiting = css`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
`;
const defaultEntering = css`
  z-index: 1;
`;

interface TransitionRightOrLeft {
  entering?: FlattenSimpleInterpolation;
  exiting?: FlattenSimpleInterpolation;
}

const transitionRightOrLeft = ({
  entering = defaultEntering,
  exiting = defaultExiting,
}: TransitionRightOrLeft = {}) => css<StateAnimationProps>`
  ${({ animationState }) => {
    if (isAnimationExiting(animationState)) {
      return exiting;
    }

    return entering;
  }}
  &.${ANIMATION_DIRECTION.NEXT} {
    ${slideRightToLeft}
  }
  &.${ANIMATION_DIRECTION.PREVIOUS} {
    ${slideLeftToRight}
  }
`;

export default transitionRightOrLeft;
