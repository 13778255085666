import React, { FunctionComponent } from 'react';

import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import ModelSearchContent from './ModelSearchContent';

const ModelSearch: FunctionComponent = () => {
  return (
    <BannersProvider>
      <ModelSearchContent />
    </BannersProvider>
  );
};

ModelSearch.displayName = 'ModelSearch';

export default ModelSearch;
