export enum RECEIPT_SEARCH_FIELDS {
  SALE_RECEIPT_ID = 'saleReceiptId',
}

export interface SubmitReceiptSearchFormValues {
  [RECEIPT_SEARCH_FIELDS.SALE_RECEIPT_ID]: string;
  sellerId: string;
}

export enum ACTION_TYPES {
  ON_CHECK_RECEIPT_EXIST = 'ON_CHECK_RECEIPT_EXIST',
}

export interface ReceiptSearchState {
  isLoading: boolean;
}
