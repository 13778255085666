import { useEffect, useState } from 'react';

import { DEBOUNCE_TIMEOUT } from '@savgroup-front-common/constants/src/shared';

export default function useDebounce(
  callback: () => void,
  delay = DEBOUNCE_TIMEOUT,
): boolean {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const id = setTimeout(() => {
      callback();
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(id);
  }, [callback, delay]);

  return isLoading;
}
