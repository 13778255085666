import styled from 'styled-components';

export const $ToolTipIconContainer = styled.div`
  && {
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
  }

  > div {
    display: flex !important;
  }
`;

export const $ToolTipContainer = styled.div`
  white-space: break-spaces;
  text-align: left;
`;
