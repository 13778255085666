import { cancelled as cancelledSaga, put } from 'redux-saga/effects';

import apiCall, { Config } from './apiCall';

export type ApiCall<T> = (url: string, config: Config) => Promise<T>;

export interface CreateRequestResponse {
  BASE: string;
  STARTED: string;
  SUCCEEDED: string;
  ERRORED: string;
  CANCELLED: string;
  END: string;
  PROGRESS: string;
  base: (payload: unknown) => {
    type: string;
    payload: unknown;
  };
  start: (meta?: unknown) => {
    type: string;
    meta: unknown;
  };
  success: (
    payload: unknown,
    meta?: unknown,
  ) => {
    type: string;
    payload: unknown;
    meta: unknown;
  };
  error: (
    errors: any,
    meta: unknown,
  ) => {
    type: string;
    meta: unknown;
  };
  cancel: (meta: unknown) => {
    type: string;
    meta: unknown;
  };
  progress: (
    progressInfo: number,
    meta: unknown,
  ) => {
    type: string;
    payload: number;
    meta: unknown;
  };
  end: (meta?: unknown) => {
    type: string;
    meta: unknown;
  };
}

export function createRequest(type: string): CreateRequestResponse {
  const ACTION_PREFIX = '@REVERS.IO';

  const regexp = /^@REVERS.IO|REVERS.IO|@@SAV|@SAV|SAV/;

  const internalType = regexp.test(type) ? type : `${ACTION_PREFIX}/${type}`;

  const BASE = internalType;
  const STARTED = `${internalType}_STARTED`;
  const SUCCEEDED = `${internalType}_SUCCEEDED`;
  const ERRORED = `${internalType}_ERRORED`;
  const CANCELLED = `${internalType}_CANCELLED`;
  const END = `${internalType}_END`;
  const PROGRESS = `${internalType}_PROGRESS`;

  return {
    BASE,
    STARTED,
    SUCCEEDED,
    ERRORED,
    CANCELLED,
    END,
    PROGRESS,
    base: (payload) => ({
      type: BASE,
      payload,
    }),
    start: (meta) => ({
      type: STARTED,
      meta,
    }),
    success: (payload, meta) => ({
      type: SUCCEEDED,
      payload,
      meta,
    }),
    error: (errors, meta) => ({
      type: ERRORED,
      ...errors,
      meta,
    }),
    cancel: (meta) => ({
      type: CANCELLED,
      meta,
    }),
    progress: (progressInfo, meta) => ({
      type: PROGRESS,
      payload: progressInfo,
      meta,
    }),
    end: (meta) => ({
      type: END,
      meta,
    }),
  };
}

export interface RequestSagaCallEffect {
  url: string;
  config: Config;
}

// Calls a promise. Puts events for start, success, error, and cancelled.
export function* request<T>(
  type: CreateRequestResponse,
  func: RequestSagaCallEffect,
  meta: unknown,
): any {
  // Get the request event types for this type.
  const { start, success, error, cancel } = type;

  // Put the started type.
  yield put(start(meta));

  try {
    const { url, config } = func;

    // Attempt to call the promise.
    const payload = yield apiCall<T>(url, config);

    // If it's successful put the succeeded type.
    return yield put(success(payload, meta));
  } catch (e) {
    // If it's unsuccessful put the errored type.
    return yield put(error(e, meta));
  } finally {
    if (yield cancelledSaga()) {
      // If this saga is cancelled put the cancelled type.
      // eslint-disable-next-line no-unsafe-finally
      return yield put(cancel(meta));
    }
  }
}
