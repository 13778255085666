import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';
import { BaseBackResponse } from '@savgroup-front-common/types';

import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/documents`;

interface GetClaimDocumentsPayload {
  claimId: string;
}

interface Document {
  extension: string;
  id: string;
  name: string;
  originalFileName: string;
  url: string;
}

interface DocumentValues {
  documents: Document[];
}

interface GetClaimDocumentsResponse extends BaseBackResponse {
  value: DocumentValues;
}

async function getClaimDocuments({
  claimId,
}: GetClaimDocumentsPayload): Promise<DocumentValues> {
  const functionName = 'getClaimDocuments';

  if (!claimId) {
    throw new RequiredPayloadFieldIsNotProvided(claimId, `${functionName}`);
  }

  const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

  const response = await apiCall<GetClaimDocumentsResponse>(url, {
    method: SUPPORTED_METHODS.GET,
    timeout: 30000,
  });

  return response.value;
}

export default getClaimDocuments;
