import styled from 'styled-components';

import { rem } from '../../../../helpers';

const $Container = styled.div<{ $isHorizontal?: boolean }>`
  display: flex;
  flex-direction: ${({ $isHorizontal }) => ($isHorizontal ? 'row' : 'column')};
  width: 100%;
  position: relative;
  align-items: ${({ $isHorizontal }) => ($isHorizontal ? 'center' : undefined)};
  gap: ${({ $isHorizontal }) => ($isHorizontal ? '1rem' : undefined)};

  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }
`;

export default $Container;
