import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.component.confirmModal.title',
    defaultMessage: 'Are you sure?',
  },
  body: {
    id: 'view.component.confirmModal.body',
    defaultMessage: 'this action is irreversible.',
  },
  buttonCancel: {
    id: 'view.component.confirmModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  buttonDelete: {
    id: 'view.component.confirmModal.confirmButton',
    defaultMessage: 'Ok',
  },
});
