import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
/* eslint-disable max-len */
const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/selfcareConfiguration/${COMMON_QUERY_PARAMS.CULTURE_CODE}`;

interface GetSellerSelfcareConfigurationQueryReturnValues {
  generalConditionOfServiceUrl: string;
  homeLoginOrderLogoUrl: string;
  homeLoginProductLogoUrl: string;
  orderLoginTypeHelpImageUrls: string[];
  productHelpText: string;
  productHelpImageUrls: string[];
  orderHelpText: string;
  orderHelpImageUrls: string[];
  customerPrivacyCondition: string;
  orderLoginTypeHelpText: string;
  contactSupportMessage: string;
  defaultOwnerOrderUrl?: string;
}

interface GetSellerSelfcareConfigurationQueryArgs {
  sellerId: string;
  cultureCode: string;
}

const getSellerSelfcareConfigurationQuery = async ({
  sellerId,
  cultureCode,
}: GetSellerSelfcareConfigurationQueryArgs): Promise<
  | BackResponse<GetSellerSelfcareConfigurationQueryReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(COMMON_QUERY_PARAMS.CULTURE_CODE, cultureCode);

    const response = await apiCall<
      BackResponse<GetSellerSelfcareConfigurationQueryReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getSellerSelfcareConfigurationQuery;
