import { CountryCode, parseNumber } from 'libphonenumber-js';
import get from 'lodash/get';
import toUpper from 'lodash/toUpper';

import { isPhoneNumber } from './validators';

export const isValidPhoneNumber = (
  phoneNumber: string | undefined,
  countryCode: string,
) => {
  if (!phoneNumber) {
    return false;
  }

  const parsedPhoneNumber = parseNumber(
    phoneNumber.replace(/^00/, '+'),
    toUpper(countryCode) as CountryCode,
  );

  return isPhoneNumber(phoneNumber) && Boolean(get(parsedPhoneNumber, 'phone'));
};
