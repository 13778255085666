import { CURRENCIES } from '@savgroup-front-common/constants/src/shared';

export const formatCurrencyCode = (currencyCode = CURRENCIES.EUR) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return '€';

    case CURRENCIES.USD:
      return '$';

    default:
      return '€';
  }
};
