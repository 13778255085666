import styled, { css } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { standardTimingFunctionTransition } from '../../../animations/timingFunction';
import { rem, styledGetCustomComponentProperty } from '../../../helpers';
import { Button } from '../../button';
import { InputStatus } from '../common/helpers/getFinalFieldState.types';
import { getWrapperHoverBackgroundFromStatus } from '../common/Wrapper/Wrapper.helpers';
import { getBorderColor } from '../Input/Input.helpers';

interface QuantityContainerProps {
  $status: InputStatus;
  $isFocused?: boolean;
  $isHovered?: boolean;
  fullWidth?: boolean;
  $isSmall?: boolean;
}

export const $QuantityContainer = styled.div<QuantityContainerProps>`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'inherit')};
  height: ${({ $isSmall }) => {
    if ($isSmall) {
      return 'fit-content';
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    });
  }};

  transition-property: border;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};

  & input:hover,
  & input:focus {
    border-radius: 0;
    border-width: ${rem(1)};
    border-style: solid;
    border-color: ${({ theme, $status }) => {
      return getWrapperHoverBackgroundFromStatus({ theme, $status });
    }};
  }

  ${standardTimingFunctionTransition};
`;

const buttonStyle = css`
  border: none;
  min-height: 0;
  &:hover,
  &:focus {
    border: none;
  }
`;

interface ButtonQuantityProps {
  $isSmall?: boolean;
}

export const $PlusButtonQuantity = styled(Button)<ButtonQuantityProps>`
  ${buttonStyle};
  min-width: ${({ $isSmall }) => {
    if ($isSmall) {
      return '1.8rem';
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    });
  }};
  height: ${({ $isSmall }) => ($isSmall ? '1.7rem' : 'unset')};
  border-radius: 0 ${rem(4)} ${rem(4)} 0;
`;

export const $MinusButtonQuantity = styled(Button)<ButtonQuantityProps>`
  ${buttonStyle};
  min-width: ${({ $isSmall }) => {
    if ($isSmall) {
      return '1.8rem';
    }

    return styledGetCustomComponentProperty({
      componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
      property: SUPPORTED_PROPERTIES.HEIGHT,
    });
  }};
  height: ${({ $isSmall }) => ($isSmall ? '1.7rem' : 'unset')};
  border-radius: ${rem(4)} 0 0 ${rem(4)};
`;

interface QuantityDisplayProps {
  $currentValue: string;
  $isFocused: boolean;
  $maxValue?: number;
  $status: InputStatus;
  $disabled?: boolean;
  $fullWidth?: boolean;
  $isSmall?: boolean;
}

export const $QuantityDisplay = styled.div<QuantityDisplayProps>`
  background-color: ${({ theme }) => theme.colors.white};
  transition-duration: ${({ theme }) => theme.interactions.transformTiming};
  position: relative;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : null)};
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
  min-width: 50px;

  flex: ${({ $fullWidth }) => ($fullWidth ? '1 100%' : null)};
  &:before {
    content: '${({ $currentValue, $maxValue }) => {
      if ($maxValue) {
        return `${$currentValue} / ${$maxValue}`;
      }

      return `${$currentValue}`;
    }}';
    position: absolute;
    text-align: center;
    top: 0px;
    left: 0px;
    display: ${({ $isFocused }) => ($isFocused ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${({ $isSmall }) => ($isSmall ? '24px' : '100%')};
    color: black;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid
      ${({ theme, $status, $disabled }) =>
        getBorderColor({ theme, status: $status, disabled: $disabled })};
  }

  && input {
    border-radius: 0px !important;
    opacity: ${({ $isFocused }) => ($isFocused ? '1' : '0')};
  }
`;
