import { defineMessages } from 'react-intl';

export default defineMessages({
  accountActivationTitle: {
    id: 'authenticator.onboarding.confirmation.accountActivationTitle',
    defaultMessage: 'Activate your account',
  },
  verification: {
    id: 'authenticator.onboarding.confirmation.verification',
    defaultMessage:
      'We need to verify your email address to activate your account',
  },
  emailActivation: {
    id: 'authenticator.onboarding.confirmation.emailActivation',
    defaultMessage:
      'A mail has been sent to your email address {email}. Please click on the link in the email to activate your account',
  },
  spam: {
    id: 'authenticator.onboarding.confirmation.spam',
    defaultMessage:
      'If you do not receive the email, please check your spam folder',
  },
  resend: {
    id: 'authenticator.onboarding.confirmation.resend',
    defaultMessage: 'Resend the mail',
  },
  resendEmailError: {
    id: 'authenticator.onboarding.confirmation.resendEmailError',
    defaultMessage: 'An error occurred while resending the email',
  },
  resendEmailSuccess: {
    id: 'authenticator.onboarding.confirmation.resendEmailSuccess',
    defaultMessage: 'The email has been successfully resent',
  },
});
