 
import styled from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem, styledGetCustomComponentProperty } from '../../../../helpers';
import { ProgressBar } from '../../../ProgressBar';

export const StyledProgressBar = styled(ProgressBar)`
  height: ${styledGetCustomComponentProperty({
    componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
    property: SUPPORTED_PROPERTIES.HEIGHT,
  })};
  border-width: 1px;
  border-color: ${({ isSuccess, isError, theme }) => {
    if (isError) {
      return theme.colors.danger;
    }

    if (isSuccess) {
      return theme.colors.success;
    }

    return theme.colors.lightGrey;
  }};
  border-style: solid;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borders.radius};
  & > * :last-child {
    white-space: nowrap;
  }
`;

interface AttachmentContentProps {
  $fixed?: boolean;
}

export const AttachmentContent = styled.div<AttachmentContentProps>`
  flex: ${({ $fixed }) => (!$fixed ? 1 : null)};
  min-width: ${({ $fixed }) => ($fixed ? null : 0)};
  max-width: ${({ $fixed }) => ($fixed ? null : rem(300))};
  white-space: ${({ $fixed }) => ($fixed ? 'nowrap' : null)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const AttachmentsListFileName = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: calc(
    ${styledGetCustomComponentProperty({
        componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
        property: SUPPORTED_PROPERTIES.HEIGHT,
      })} - ${rem(2)}
  );
  height: 100%;
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.mainTextColor};

  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
