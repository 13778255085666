import React, {
  createRef,
  FunctionComponent,
  MutableRefObject,
  Ref,
  useEffect,
  useState,
} from 'react';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../../../formatters';
import { $Container, FieldMessage } from '../../common';
import { getFinalFieldState } from '../../common/helpers/getFinalFieldState';
import { FIELD_STATUS } from '../../common/helpers/getFinalFieldState.types';
import Input, { InputProps } from '../../Input';

import {
  PlaceAutocompleteLoading,
  PlaceAutocompleteResult,
} from './components';
import { usePlacesAutocomplete } from './hooks';
import messages from './messages';
import {
  $PlaceAutcompleteDropdown,
  $PlaceAutcompleteDropdownContainer,
} from './PlacesAutocomplete.styles';
import { PlaceAutocompleteOnSelectArgs } from './PlacesAutocomplete.types';

interface PlacesAutocompleteProps extends InputProps {
  countryCode?: string;
  onSelect: (value: PlaceAutocompleteOnSelectArgs) => void;
  addSecondaryInformationOnSelect?: boolean;
}

interface PlacesAutocompletePropsWithRef extends PlacesAutocompleteProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const PlacesAutocomplete: FunctionComponent<PlacesAutocompletePropsWithRef> = ({
  componentThemeName = SUPPORTED_COMPONENTS.DEFAULT_AUTOCOMPLETE,
  name = undefined,
  label = undefined,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  placeholder = messages.placeholder,
  suffix = '',
  prefix = '',
  value = '',
  onChange,
  forwardedRef,
  addSecondaryInformationOnSelect = false,
  errors = {},
  warnings = {},
  successes = {},
  isError = false,
  isWarning = false,
  isSuccess = false,
  readOnly,
  postLabel,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  onSelect,
  countryCode = COUNTRY_CODES.FR,
  dataTestId,
}) => {
  const adaptedValue = String(value);
  const {
    isLoading: innerLoading,
    handleKeyDown,
    handleChange,
    handleSelect,
    handleClose,
    handleOpen,
    selectedIndex,
    currentGooglePlaceIdLoading,
    places,
    isOpen,
    showLoadingDropdown,
    showPlacesDropdown,
  } = usePlacesAutocomplete({
    onChange,
    onSelect,
    countryCode,
    value: adaptedValue,
    addSecondaryInformationOnSelect,
  });
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const arrLength = places.length;
  const [elRefs, setElRefs] = useState<MutableRefObject<HTMLButtonElement>[]>(
    [],
  );

  useEffect(() => {
    // add or remove refs
    setElRefs((innerRef) =>
      Array(arrLength)
        .fill(null)
        .map((_, index) => innerRef[index] || createRef()),
    );
  }, [arrLength]);

  return (
    <$Container>
      <Input
        isDisabled={isDisabled}
        componentThemeName={componentThemeName}
        placeholder={safeFormattedIntlString(placeholder)}
        isLoading={innerLoading || isLoading}
        value={value}
        ref={forwardedRef}
        name={name}
        label={label}
        onChange={handleChange}
        isError={status === FIELD_STATUS.ERROR}
        isWarning={status === FIELD_STATUS.WARNING}
        isSuccess={status === FIELD_STATUS.SUCCESS}
        onKeyDown={handleKeyDown}
        disableBrowserAutocomplete
        suffix={suffix}
        selectAllOnFocus
        readOnly={readOnly}
        prefix={prefix}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }
          if (!readOnly) {
            handleOpen();
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }

          if (
            !readOnly &&
            !elRefs.some(({ current }) => e?.relatedTarget === current)
          ) {
            handleClose();
          }
        }}
        dataTestId={dataTestId}
        isRequired={isRequired}
        postLabel={postLabel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isOpen && (
        <$PlaceAutcompleteDropdownContainer>
          <$PlaceAutcompleteDropdown
            data-testid={dataTestId ? `${dataTestId}-dropdown` : undefined}
          >
            {showPlacesDropdown && (
              <>
                {places.map((place, index) => (
                  <PlaceAutocompleteResult
                    key={place.description}
                    place={place}
                    isSelected={index === selectedIndex}
                    onSelect={handleSelect}
                    isLoading={currentGooglePlaceIdLoading === place.place_id}
                    ref={elRefs[index]}
                    dataTestId={
                      dataTestId ? `${dataTestId}-result-${index}` : undefined
                    }
                  />
                ))}
              </>
            )}
            {showLoadingDropdown && (
              <PlaceAutocompleteLoading
                dataTestId={dataTestId ? `${dataTestId}-loading` : undefined}
              />
            )}
          </$PlaceAutcompleteDropdown>
        </$PlaceAutcompleteDropdownContainer>
      )}
      <FieldMessage message={message} status={status} dataTestId={dataTestId} />
    </$Container>
  );
};

PlacesAutocomplete.displayName = 'PlacesAutocomplete';

export default React.forwardRef<HTMLInputElement, PlacesAutocompleteProps>(
  (props, ref) => <PlacesAutocomplete forwardedRef={ref} {...props} />,
);
