import styled, { DefaultTheme } from 'styled-components';

import { rem } from '../../../../helpers';
import { FIELD_STATUS, InputStatus } from '../helpers/getFinalFieldState.types';
import { simpleSlideFromTopAnimation } from '../../../../animations/simpleAnimation';

interface GetColor {
  theme: DefaultTheme;
  $status?: InputStatus;
}

const getColor = ({ theme, $status }: GetColor) => {
  if ($status === FIELD_STATUS.ERROR) {
    return theme.colors.danger;
  }

  if ($status === FIELD_STATUS.WARNING) {
    return theme.colors.alert;
  }

  if ($status === FIELD_STATUS.SUCCESS) {
    return theme.colors.success;
  }

  return theme.colors.paragraphTextColor;
};

export const $FieldMessage = styled.div<GetColor>`
  font-size: ${rem(12)};
  margin-top: ${rem(4)};
  padding: 0;
  line-height: ${rem(12)};
  color: ${getColor};
  ${simpleSlideFromTopAnimation};
`;
