import { allEmailDomains } from '../EmailAutocomplete.constants';

const getPossibleEmailDomains = (email: string): string[] => {
  const indexOfAt = email.indexOf('@');
  const emailDomain = email.substring(indexOfAt);
  const isEmailDomainMatch = allEmailDomains.includes(emailDomain);

  if (isEmailDomainMatch) {
    return [];
  }
  const possibleEmailDomains = allEmailDomains
    .filter((possibleEmailDomain) =>
      possibleEmailDomain.startsWith(emailDomain),
    )
    .slice(0, 5);

  return possibleEmailDomains;
};

export default getPossibleEmailDomains;
