import React from 'react';

interface NewspaperIconProps {
  color?: string;
}

 
const NewspaperIcon: React.FC<NewspaperIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" color={color}
    >
      <path
        d="M9.99992 10H29.9999M9.99992 16.6667H29.9999M21.6666 23.3333H29.9999M21.6666 30H29.9999M3.33325 35.6667V4.33333C3.33325 4.06812 3.43861 3.81376 3.62615 3.62623C3.81368 3.43869 4.06804 3.33333 4.33325 3.33333H35.6666C35.9318 3.33333 36.1862 3.43869 36.3737 3.62623C36.5612 3.81376 36.6666 4.06812 36.6666 4.33333V35.6667C36.6666 35.9319 36.5612 36.1862 36.3737 36.3738C36.1862 36.5613 35.9318 36.6667 35.6666 36.6667H4.33325C4.06804 36.6667 3.81368 36.5613 3.62615 36.3738C3.43861 36.1862 3.33325 35.9319 3.33325 35.6667V35.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 30V23.3333H15V30H10Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

NewspaperIcon.displayName = 'NewspaperIcon';

export default NewspaperIcon;
