import { Reducer, useCallback, useMemo, useReducer } from 'react';

import { COMMON_STEPS } from '@savgroup-front-common/constants/src/shared';

import {
  StepsOrchestratorAction,
  stepsOrchestratorInit,
  StepsOrchestratorInitArgs,
  stepsOrchestratorReducer,
  StepsOrchestratorState,
} from '../StepsOrchestrator.reducer';
import {
  CurrentStepOnSubmit,
  JumpToStep,
  NextStep,
  OnPreviousClick,
  OnSubmit,
  PreviousStep,
  STEPS_ORCHESTRATOR_ACTION_TYPES,
  StepsOrchestratorConfig,
  StepsOrchestratorHistory,
} from '../StepsOrchestrator.types';

interface UseStepsOrchestratorArgs<Values, Context> {
  initialStep: string;
  config: StepsOrchestratorConfig<Context>;
  onSubmit: OnSubmit<Values, Context>;
  onPreviousClick?: OnPreviousClick<Values, Context>;
  initialContext: Partial<Context>;
  initialHistory: StepsOrchestratorHistory<Context>;
  initialValues: Partial<Values>;
}

interface UseStepsOrchestratorReturnValue<Values, Context> {
  state: StepsOrchestratorState<Values, Context>;
  handleAnimationEntered: () => void;
  handlePreviousStep: PreviousStep<Values, Context>;
  handleNextStep: NextStep<Values, Context>;
  handleSubmit: CurrentStepOnSubmit<Values, Context>;
  handleJumpToStep: JumpToStep<Values, Context>;
}

const useStepsOrchestrator = <Values, Context>({
  initialStep,
  config,
  onSubmit,
  onPreviousClick,
  initialContext,
  initialHistory,
  initialValues,
}: UseStepsOrchestratorArgs<Values, Context>): UseStepsOrchestratorReturnValue<
  Values,
  Context
> => {
  const [state, dispatch] = useReducer<
    Reducer<
      StepsOrchestratorState<Values, Context>,
      StepsOrchestratorAction<Values, Context>
    >,
    StepsOrchestratorInitArgs<Values, Context>
  >(
    stepsOrchestratorReducer,
    {
      initialStep,
      config,
      initialContext,
      initialHistory,
      initialValues,
    },
    stepsOrchestratorInit,
  );

  const { currentStep, history, context, values } = state;

  if (currentStep === null) {
    throw new Error(
      `Current Step is trying to render a step with a undefined Component ${JSON.stringify(
        { currentStep, history, context },
        null,
        2,
      )}.`,
    );
  }

  const moveToNextStep = useCallback(
    (
      newStepName: string,
      newValues?: Partial<Values>,
      newContext?: Partial<Context>,
    ) => {
      dispatch({
        type: STEPS_ORCHESTRATOR_ACTION_TYPES.NEXT_STEP,
        payload: {
          step: newStepName,
          context: newContext,
          values: newValues,
        },
      });

      return null;
    },
    [],
  );
  const handleAnimationEntered = useCallback(
    () => dispatch({ type: STEPS_ORCHESTRATOR_ACTION_TYPES.DONE_ANIMATING }),
    [],
  );

  const handleJumpToStep = useCallback(
    (
      newStepName: string,
      newValues?: Partial<Values>,
      newContext?: Partial<Context>,
    ) => {
      dispatch({
        type: STEPS_ORCHESTRATOR_ACTION_TYPES.JUMP_TO_STEP,
        payload: {
          step: newStepName,
          context: newContext,
          values: newValues,
        },
      });
    },
    [],
  );

  const { nextStep } = currentStep;

  const handleNextStep = useMemo(() => {
    if (typeof nextStep === 'string') {
      if (nextStep === COMMON_STEPS.SUBMIT) {
        return (
          newValues: Partial<Values> = {},
          newContext: Partial<Context> = {},
        ) =>
          onSubmit(
            { ...values, ...newValues } as Values,
            { ...context, ...newContext } as Context,
          );
      }

      return (newValues?: Partial<Values>, newContext?: Partial<Context>) =>
        moveToNextStep(nextStep, newValues, newContext);
    }

    if (typeof nextStep === 'function') {
      return (
        newValues: Partial<Values> = {},
        newContext: Partial<Context> = {},
      ) => {
        const newStep = nextStep(newContext, { ...values, ...newValues });

        if (newStep === COMMON_STEPS.SUBMIT) {
          return onSubmit(
            { ...values, ...newValues } as Values,
            { ...context, ...newContext } as Context,
          );
        }

        return moveToNextStep(newStep, newValues, newContext);
      };
    }

    return () => undefined;
  }, [context, moveToNextStep, nextStep, onSubmit, values]);

  const handlePreviousStep = useMemo(() => {
    const previousStep = history[history.length - 1];

    if (previousStep) {
      return (newValues?: Partial<Values>, newContext?: Partial<Context>) => {
        dispatch({
          type: STEPS_ORCHESTRATOR_ACTION_TYPES.PREVIOUS_STEP,
          payload: {
            context: newContext,
            values: newValues,
          },
        });
      };
    }

    return onPreviousClick || (() => undefined);
  }, [history, onPreviousClick]);

  const handleSubmit: CurrentStepOnSubmit<Values, Context> = useCallback(
    (newValues: Partial<Values> = {}, newContext: Partial<Context> = {}) => {
      return onSubmit(
        { ...values, ...newValues } as Values,
        { ...context, ...newContext } as Context,
      );
    },
    [onSubmit, values, context],
  );

  return {
    state,
    handleAnimationEntered,
    handlePreviousStep,
    handleNextStep,
    handleJumpToStep,
    handleSubmit,
  };
};

export default useStepsOrchestrator;
