import { UniverseModelType } from 'authenticator/types';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const SELLER_ID = 'SELLER_ID';

const ENDPOINT = `${APIConfiguration.catalog}modeltypes/universe?SellerId=${SELLER_ID}`;

interface GetModelTypeUniverseArgs {
  sellerId: string;
}

const getModelTypeUniverse = async ({
  sellerId,
}: GetModelTypeUniverseArgs): Promise<
  BackResponse<UniverseModelType[]> | BackResponseFailure
> => {
  const url = ENDPOINT.replace(SELLER_ID, sellerId);

  try {
    const response = await apiCall<BackResponse<UniverseModelType[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getModelTypeUniverse;
