import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

const SingleValue: FunctionComponent<React.PropsWithChildren<any>> = (
  props,
) => {
  const { data, innerProps } = props;

  return (
    <components.SingleValue
      {...props}
      innerProps={{ ...innerProps, 'data-value': data?.value }}
    />
  );
};

SingleValue.displayName = 'SingleValue';

export default SingleValue;
