import getModelsByFullTextSearch from './getModelsByFullTextSearch';
import getModelTypeUniverse from './getModelTypeUniverse';
import getModelTypeGeneric from './getModelTypeGeneric';
import getBrandGeneric from './getBrandGeneric';

export const CatalogService = {
  getModelsByFullTextSearch,
  getModelTypeUniverse,
  getModelTypeGeneric,
  getBrandGeneric,
};
