import { defineMessages } from 'react-intl';

export default defineMessages({
  orderRefLabel: {
    id: 'view.MyAccountLoginPage.priceOrder.orderRef.label',
    defaultMessage: 'Order reference',
  },
  orderRefPlaceholder: {
    id: 'view.MyAccountLoginPage.priceOrder.orderRef.placeholder',
    defaultMessage: 'FXXX-XXXX',
  },
  orderPriceLabel: {
    id: 'view.MyAccountLoginPage.priceOrder.orderPrice.label',
    defaultMessage: 'Order price',
  },
  orderPricePlaceholder: {
    id: 'view.MyAccountLoginPage.priceOrder.orderPrice.placeholder',
    defaultMessage: '0.00',
  },
  sellerIsUndefined: {
    id: 'view.MyAccountLoginPage.priceOrder.sellerIsUndefined',
    defaultMessage: 'Seller is not defined',
  },
  orderNotFound: {
    id: 'view.MyAccountLoginPage.priceOrder.orderNotFound',
    defaultMessage: 'Order not found',
  },
  priceDoesNotMatch: {
    id: 'view.MyAccountLoginPage.priceOrder.priceDoesNotMatch',
    defaultMessage: 'Price does not match',
  },
  orderPricePostLabel: {
    id: 'view.MyAccountLoginPage.priceOrder.orderPricePostLabel',
    defaultMessage: 'Order price post label',
  },
  orderReferencePostLabel: {
    id: 'view.MyAccountLoginPage.priceOrder.orderReferencePostLabel',
    defaultMessage: 'Order reference post label',
  },
});
