import get from 'lodash/get';

const { hostname } = window.location;

const clientIdByHostname: Record<string, string> = {
  'auth.myaccount-login.revers.io': 'myaccount.revers.io',
  'auth.ekinsport-login.revers.io': 'ekinsport.revers.io',
  'auth.sav-login.auchan.fr': 'auchan.revers.io',
};

const authorityByHostname: Record<string, string> = {
  'auth.auchan-login.revers.io': 'https://auchan-login.revers.io',
  'auth.sav-login.auchan.fr': 'https://sav-login.auchan.fr',
};

const authorityByClientId: Record<string, string> = {
  'myaccount.revers.io': 'https://myaccount-login.revers.io',
  'ekinsport.revers.io': 'https://ekinsport-login.revers.io',
  'revers.io': 'https://myaccount-login.revers.io',
  'retours.carrefour.fr': 'https://retours-login.carrefour.fr',
  'auchan.revers.io': 'https://sav-login.auchan.fr',
};

const defaultClientId = 'myaccount.revers.io';

function computeAuthority({ clientId }: { clientId: string }) {
  const defaultAuthority = get(authorityByClientId, clientId);
  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    return defaultAuthorityFromHostname;
  }

  if (defaultAuthority) {
    return defaultAuthority;
  }

  const builtAuthorityFromClientId = clientId.replace(
    '.revers.io',
    '-login.revers.io',
  );

  return builtAuthorityFromClientId.includes('https://')
    ? builtAuthorityFromClientId
    : `https://${builtAuthorityFromClientId}`;
}

function setAuthority(authority = null) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || computeAuthority({ clientId });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

function computeClientId() {
  if (import.meta.env.VITE_CLIENTID) {
    return import.meta.env.VITE_CLIENTID;
  }

  if (hostname === 'localhost') {
    return defaultClientId;
  }

  if (clientIdByHostname[hostname]) {
    return clientIdByHostname[hostname];
  }

  if (window.location.hostname.includes('-auth')) {
    return window.location.hostname.replace('-auth', '');
  }

  if (window.location.hostname.includes('auth.')) {
    return window.location.hostname.replace('-login', '').replace('auth.', '');
  }

  return window.location.hostname;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: computeAuthority({ clientId }),
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
