import { createContext, useContext } from 'react';

import { LAYOUT_TYPES } from './Layout.types';

interface LayoutReturnValue {
  layoutType: LAYOUT_TYPES;
}

export const LayoutContext = createContext<LayoutReturnValue>({
  layoutType: LAYOUT_TYPES.CLASSIC,
});

const useLayoutContext = () => useContext(LayoutContext);

export default useLayoutContext;
