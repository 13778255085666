import { useCallback } from 'react';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';

import { DataGouvService } from '../../../../../api';
import {
  GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES,
  GeocodeAddressFromDataGouvResponse,
} from '../../../../../api/DataGouv';
import { adaptGeocodeToResponse, geocodeByPlaceId } from '../helpers';
import { getSearchOptions } from '../PlacesAutocomplete.constants';
import {
  GooglePlace,
  GouvPlace,
  Place,
  PLACE_TYPE,
  PlaceAutocompleteOnSelectArgs,
} from '../PlacesAutocomplete.types';

const adaptToGoogleMapResponse = (
  response: GeocodeAddressFromDataGouvResponse,
): GouvPlace[] => {
  return response.features
    .filter(
      (feature) =>
        ![
          GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES.STREET,
          GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES.MUNICIPALITY,
        ].includes(feature.properties.type),
    )
    .map((feature) => {
      const { properties } = feature;

      return {
        originType: PLACE_TYPE.GOUV,
        description: `${properties.housenumber} ${properties.street}, ${properties.city}, France`,
        structured_formatting: {
          main_text: properties.name,
          secondary_text: `${properties.city}, France`,
          main_text_matched_substrings: [],
        },
        data: {
          countryCode: COUNTRY_CODES.FR,
          streetNumber: properties.housenumber,
          street: properties.street,
          city: properties.city,
          postalCode: properties.postcode,
        },
        matched_substrings: [],
        place_id: '',
        reference: '',
        terms: [],
        types: [],
      };
    });
};

const useGoogleMapApi = ({ countryCode }: { countryCode: COUNTRY_CODES }) => {
  const getPlacesPredictions = useCallback(
    async ({
      input,
      ...searchOptions
    }: google.maps.places.AutocompletionRequest): Promise<Place[]> => {
      const predictionService = window.google
        ? new window.google.maps.places.AutocompleteService()
        : null;

      if (countryCode === COUNTRY_CODES.FR) {
        if (!input) {
          throw new Error('Null input is provided');
        }

        const response = await DataGouvService.geoCodeAddressFromDataGouv({
          query: input,
        });

        return adaptToGoogleMapResponse(response);
      }

      const response = await new Promise<GooglePlace[]>((resolve, reject) => {
        if (!window.google) {
          throw new Error(`Google map api is not available.`);
        }

        if (!input) {
          throw new Error('Null input is provided');
        }

        if (predictionService) {
          const sessionToken =
            new window.google.maps.places.AutocompleteSessionToken();

          predictionService.getPlacePredictions(
            {
              ...getSearchOptions(),
              ...searchOptions,
              input,
              sessionToken,
            },
            function callback(places, status) {
              switch (status) {
                case window.google.maps.places.PlacesServiceStatus.NOT_FOUND:
                case window.google.maps.places.PlacesServiceStatus
                  .INVALID_REQUEST:
                case window.google.maps.places.PlacesServiceStatus
                  .OVER_QUERY_LIMIT:
                case window.google.maps.places.PlacesServiceStatus
                  .REQUEST_DENIED:
                case window.google.maps.places.PlacesServiceStatus
                  .UNKNOWN_ERROR: {
                  reject(new Error(`Request failed: ${status}`));
                  break;
                }

                case window.google.maps.places.PlacesServiceStatus
                  .ZERO_RESULTS: {
                  resolve([]);
                  break;
                }

                default: {
                  const googlePlaces =
                    places?.map<GooglePlace>((place) => ({
                      ...place,
                      originType: PLACE_TYPE.GOOGLE,
                    })) || [];

                  resolve(googlePlaces);
                }
              }
            },
          );
        }
      });

      return response;
    },
    [countryCode],
  );

  const getGeocodeByPlaceId = useCallback(
    async ({
      placeId,
      address,
    }: {
      placeId: string;
      address: string;
    }): Promise<PlaceAutocompleteOnSelectArgs> => {
      try {
        const response = await geocodeByPlaceId({ placeId });

        if (response && response.length > 0) {
          const result = response[0];

          return adaptGeocodeToResponse({ result, address });
        }

        return {};
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);

        return {};
      }
    },
    [],
  );

  return {
    getPlacesPredictions,
    getGeocodeByPlaceId,
  };
};

export default useGoogleMapApi;
