import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDeepCompareEffect } from 'react-use';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import messages from '../messages';

const useGetJobTitleOptions = () => {
  const intl = useIntl();
  const [options, setOptions] = useState<BaseAutocompleteOption[]>([]);

  useDeepCompareEffect(() => {
    setOptions([
      {
        value: 'Direction générale',
        label: safeFormattedIntlString(messages.generalManagement),
      },
      {
        value: 'Service client',
        label: safeFormattedIntlString(messages.customerService),
      },
      {
        value: 'SAV',
        label: safeFormattedIntlString(messages.afterSalesService),
      },
      {
        value: 'Logistique',
        label: safeFormattedIntlString(messages.logistics),
      },
      {
        value: 'Supply Chain',
        label: safeFormattedIntlString(messages.supplyChain),
      },
      {
        value: 'Transfo Digitale',
        label: safeFormattedIntlString(messages.digitalTransformation),
      },
      {
        value: 'IT',
        label: safeFormattedIntlString(messages.it),
      },
      {
        value: 'Finance',
        label: safeFormattedIntlString(messages.finance),
      },
      {
        value: 'Achats',
        label: safeFormattedIntlString(messages.purchasing),
      },
      {
        value: 'Autre',
        label: safeFormattedIntlString(messages.other),
      },
    ]);
  }, [intl]);

  return options;
};

export default useGetJobTitleOptions;
