import get from 'lodash/get';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { SalesChannel } from '@savgroup-front-common/types';

import { SalesChannelOption } from '../ChoicePartnerStore.types';
import messages from '../messages';

const adaptSalesChannelToOptions = (
  accumulatedSalesChannelOptions: SalesChannelOption[],
  salesChannel: SalesChannel,
): SalesChannelOption[] => {
  const salesChannelTypeMessage = get(messages, salesChannel.salesChannelType);

  const option = {
    value: salesChannel.id,
    label: salesChannel.name,
    data: salesChannel,
  };

  const isOptionGroupExist = accumulatedSalesChannelOptions.some(
    (accumulatedSalesChannelOption) => {
      return (
        accumulatedSalesChannelOption.label ===
        safeFormattedIntlString(salesChannelTypeMessage)
      );
    },
  );

  if (isOptionGroupExist) {
    return accumulatedSalesChannelOptions.map(
      (accumulatedSalesChannelOption) => {
        if (
          accumulatedSalesChannelOption.label ===
          safeFormattedIntlString(salesChannelTypeMessage)
        ) {
          return {
            ...accumulatedSalesChannelOption,
            options: [...accumulatedSalesChannelOption.options, option],
          };
        }

        return accumulatedSalesChannelOption;
      },
    );
  }

  return [
    ...accumulatedSalesChannelOptions,
    {
      label: safeFormattedIntlString(salesChannelTypeMessage),
      options: [option],
    },
  ];
};

export default adaptSalesChannelToOptions;
