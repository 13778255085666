import { isPrice } from '@savgroup-front-common/core/src/helpers';

const testPositive = (value?: string) => {
  if (value === undefined || !value.length) {
    return true;
  }

  const parsedValue = parseFloat(value.replace(',', '.'));

  return Boolean(value && parsedValue > 0);
};

const testValid = (value?: string) => {
  if (value === undefined || !value.length) {
    return true;
  }

  return isPrice(value);
};

const testIsSafeInteger = (value?: string) => {
  if (value === undefined || !value.length) {
    return true;
  }
  const parsedValue = parseFloat(value.replace(',', '.'));
  const int = Math.floor(parsedValue);

  return Number.isSafeInteger(int);
};

export const priceHelpers = {
  testPositive,
  testValid,
  testIsSafeInteger,
};
