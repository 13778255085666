export const toFloat = (number: number | string = ''): number => {
  if (typeof number === 'number') {
    return number;
  }

  if (number === '') {
    return 0;
  }

  return parseFloat(number.replace(/[^0-9,.]/g, '').replace(',', '.'));
};
