import { EVENT_KEY } from '@savgroup-front-common/constants';
import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import { EMAIL_AUTOCOMPLETE_ACTION_TYPES } from './EmailAutocomplete.types';

interface EmailAutocompleteState {
  isOpen: boolean;
  selectedIndex: number | undefined;
  emailDomains: string[];
}

type EmailAutocompleteAction =
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN;
    }
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN;
    }
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT;
    }
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT;
    }
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION;
      payload: { key: EVENT_KEY.ARROW_UP | EVENT_KEY.ARROW_DOWN };
    }
  | {
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT;
      payload: { emailDomains: string[] };
    };

export const emailAutocompleteInit = (): EmailAutocompleteState => ({
  isOpen: false,
  selectedIndex: undefined,
  emailDomains: [],
});

export const emailAutocompleteReducer = (
  state: EmailAutocompleteState,
  action: EmailAutocompleteAction,
): EmailAutocompleteState => {
  switch (action.type) {
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT: {
      return {
        ...state,
        emailDomains: [],
      };
    }
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT: {
      return {
        ...state,
        emailDomains: action.payload.emailDomains,
      };
    }
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT: {
      return {
        ...state,
        emailDomains: [],
        selectedIndex: undefined,
      };
    }
    case EMAIL_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION: {
      if (state.emailDomains.length > 0 && state.isOpen === false) {
        return {
          ...state,
          isOpen: true,
        };
      }
      if (state.selectedIndex === undefined) {
        return {
          ...state,
          selectedIndex: 0,
        };
      }

      const lastIndex = state.emailDomains.length - 1;

      if (action.payload.key === EVENT_KEY.ARROW_DOWN) {
        return {
          ...state,
          selectedIndex:
            lastIndex === state.selectedIndex ? 0 : state.selectedIndex + 1,
        };
      }

      return {
        ...state,
        selectedIndex:
          state.selectedIndex === 0 ? lastIndex : state.selectedIndex - 1,
      };
    }

    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
