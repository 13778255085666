import { toast as toastComponent } from 'react-toastify';

import { ToastClose } from './Components';
import { ReversToastOptions } from './Toast.types';

export const toastOptions: ReversToastOptions = {
  position: toastComponent.POSITION.TOP_RIGHT,
  draggable: false,
  autoClose: 5000,
  hideProgressBar: true,
  closeButton: ToastClose,
  pauseOnFocusLoss: false,
  showIcon: true,
  className: 'toast-message',
};

export const noticeOptions: ReversToastOptions = {
  position: toastComponent.POSITION.BOTTOM_CENTER,

  autoClose: 2000,
  draggable: false,
  hideProgressBar: true,
  className: 'notice-message',

  closeButton: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  showIcon: false,
};
