import { defineMessages } from 'react-intl';

export default defineMessages({
  noOptionsMessage: {
    id: 'components.atom.select.noOptionsMessage',
    defaultMessage: 'No options',
  },
  loadingMessage: {
    id: 'components.atom.select.loadingMessage',
    defaultMessage: 'Loading',
  },
  placeholder: {
    id: 'components.atom.select.placeholder',
    defaultMessage: 'Select...',
  },
});
