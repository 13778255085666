import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { LinkButton } from '@savgroup-front-common/core/src/atoms/link';
import { rem } from '@savgroup-front-common/core/src/helpers/theme';

export const $LinkButton = styled(LinkButton)`
  text-decoration: underline;
`;

export const $StartButtonContainer = styled(Col)`
  margin-bottom: 1rem;
`;

export const $SwitchLoginModeLink = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 1rem;

  & > * {
    margin-right: 0.25rem;
  }
`;

export const $ReceiptSearch = styled.div`
  margin-bottom: 3rem;
`;

export const $FormContainer = styled(Col)`
  @media ${media.minWidth.md} {
    padding: 1rem 3rem 0 3rem;
  }
`;

export const $ReceiptDidacticImg = styled.img`
  display: block;
  max-width: ${rem(330)};
  margin-bottom: 0.5rem;
`;
