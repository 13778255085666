import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import {
  AddressInformationsStepContext,
  AddressInformationsStepValues,
} from '../AddressInformations.types';

const useAddressInformationStepContext = () => {
  return useStepOrchestratorContext<
    AddressInformationsStepValues,
    AddressInformationsStepContext
  >();
};

export default useAddressInformationStepContext;
