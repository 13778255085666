import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $HelperContainerButtonClose = styled('div')`
  display: flex;
  justify-content: end;
`;

export const $HelperContainerTitle = styled('div')`
  margin-bottom: 1rem;
  h2 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    @media ${media.maxWidth.sm} {
      font-size: 20px;
    }
  }
`;

export const $CloseButton = styled(Button)`
  padding: 0;

  &&:hover,
  &&:active,
  &&:focus {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};

    & svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  && svg {
    width: 2rem;
    height: 2rem;
    max-height: none;
    max-width: none;
    margin-right: unset;
  }
`;

export const $HelperButton = styled(Button)`
  min-width: unset;
  overflow: unset;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.black};
  &&:hover,
  &&:active,
  &&:focus {
    color: ${({ theme }) => theme.colors.primary};
    background-color: unset;
  }
`;

export const $Img = styled.img`
  width: 100%;
`;
