import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $ActionRow = styled(Row)<{
  $withoutMarginBottom?: boolean;
  $withoutPadding?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  padding: ${({ $withoutPadding }) => ($withoutPadding ? null : '0 1rem')};

  > * {
    margin-left: 0.5rem;
    margin-bottom: ${({ $withoutMarginBottom }) =>
      $withoutMarginBottom ? null : '1rem'};
    flex-grow: initial;
  }

  @media ${media.maxWidth.sm} {
    margin-top: 1rem;

    flex-direction: column;
    gap: 0.5rem;
  }
`;
