import { defineMessages } from 'react-intl';

export default defineMessages({
  availableInStock: {
    id: 'sparePartRequest.requestLines.status.availableInStock',
    defaultMessage: 'Available in stock',
  },
  cancelled: {
    id: 'sparePartRequest.requestLines.status.cancelled',
    defaultMessage: 'Cancelled',
  },
  missing: {
    id: 'sparePartRequest.requestLines.status.missing',
    defaultMessage: 'Missing',
  },
  orderCollected: {
    id: 'sparePartRequest.requestLines.status.orderCollected',
    defaultMessage: 'Order collected',
  },
  orderDelivered: {
    id: 'sparePartRequest.requestLines.status.orderDelivered',
    defaultMessage: 'Order delivered',
  },
  orderSubmitted: {
    id: 'sparePartRequest.requestLines.status.orderSubmitted',
    defaultMessage: 'Order submitted',
  },
  rejected: {
    id: 'sparePartRequest.requestLines.status.rejected',
    defaultMessage: 'Rejected',
  },
  toProcess: {
    id: 'sparePartRequest.requestLines.status.toProcess',
    defaultMessage: 'To process',
  },
  orderShipped: {
    id: 'sparePartRequest.requestLines.status.orderShipped',
    defaultMessage: 'Order shipped',
  },
});
