import { defineMessages } from 'react-intl';

export default defineMessages({
  information: {
    id: 'components.common.addressManagement.addressForm.information',
    defaultMessage: 'Information',
  },
  label: {
    id: 'components.common.addressManagement.addressForm.label',
    defaultMessage: 'Donner un nom à cette adresse',
  },
  labelPlaceHolder: {
    id: 'components.common.addressManagement.addressForm.labelPlaceHolder',
    defaultMessage: 'Ici vous pouvez donner un nom à votre adresse',
  },
  firstName: {
    id: 'components.common.addressManagement.addressForm.firstName',
    defaultMessage: 'Prénom',
  },
  lastName: {
    id: 'components.common.addressManagement.addressForm.lastName',
    defaultMessage: 'Nom',
  },
  company: {
    id: 'components.common.addressManagement.addressForm.company',
    defaultMessage: 'Société',
  },
  address: {
    id: 'components.common.addressManagement.addressForm.address',
    defaultMessage: 'Address',
  },
  additionalAddress: {
    id: 'components.common.addressManagement.addressForm.additionalAddress',
    defaultMessage: "Complément d'adresse",
  },
  postalCode: {
    id: 'components.common.addressManagement.addressForm.postalCode',
    defaultMessage: 'Code Postal',
  },
  city: {
    id: 'components.common.addressManagement.addressForm.city',
    defaultMessage: 'Ville',
  },
  country: {
    id: 'components.common.addressManagement.addressForm.country',
    defaultMessage: 'Pays',
  },
  additionalInformation: {
    id: 'components.common.addressManagement.addressForm.additionalInformation',
    defaultMessage: 'Informations pour la livraison',
  },
  additionalInformationsPlaceHolder: {
    id: 'components.common.addressManagement.addressForm.additionalInformationsPlaceHolder',
    defaultMessage:
      'Toute information utilie pour la livraison : étage, code de la porte, ...',
  },
  civility: {
    id: 'components.common.addressManagement.addressForm.civility',
    defaultMessage: 'Civilité',
  },
  mister: {
    id: 'components.common.addressManagement.addressForm.mister',
    defaultMessage: 'M.',
  },
  madam: {
    id: 'components.common.addressManagement.addressForm.madam',
    defaultMessage: 'Mme',
  },
  phone: {
    id: 'components.common.addressManagement.addressForm.phone',
    defaultMessage: 'Téléphone',
  },
  companyName: {
    id: 'components.common.addressManagement.addressForm.companyName',
    defaultMessage: 'Raison sociale',
  },
  email: {
    id: 'view.card.client.email',
    defaultMessage: 'E-mail',
  },
  chooseOption: {
    id: 'components.common.addressManagement.addressForm.chooseAnOption',
    defaultMessage: 'Choose an option',
  },
});
