import { defineMessages } from 'react-intl';

import { API_COMMON_ERROR } from '@savgroup-front-common/constants/src/shared';

export default defineMessages({
  [API_COMMON_ERROR.FORBIDDEN]: {
    id: 'Reverse.Common.Errors.Authentication.Forbidden',
    defaultMessage: "You don't have access",
  },
  [API_COMMON_ERROR.UNAUTHORIZED]: {
    id: 'Reverse.Common.Errors.Authentication.Unauthorized',
    defaultMessage: "You don't have access",
  },
  [API_COMMON_ERROR.UNHANDLED]: {
    id: 'Reverse.Common.Errors.Unhandled',
    defaultMessage: 'An unknown error happened',
  },
  [API_COMMON_ERROR.COUNTRY_CODE_DIFFERS_FROM_STORE_COUNTRY]: {
    id: 'Reverse.Common.Errors.Validations.CountryCodeDiffersFromStoreCountryCode',
    defaultMessage: 'The user address country does not match the store country',
  },

  [API_COMMON_ERROR.ARGUMENT_SHOULD_NOT_BE_EMPTY]: {
    id: 'Reverse.Common.Errors.Validations.ArgumentShouldNotBeEmpty',
    defaultMessage: 'The input should not be empty',
  },
  [API_COMMON_ERROR.DATA_IS_MISSING]: {
    id: 'Reverse.Common.Errors.Validations.DataIsMissing',
    defaultMessage: 'Data is missing',
  },
  [API_COMMON_ERROR.ARGUMENT_MANDATORY]: {
    id: 'Reverse.Common.Errors.Validations.ArgumentMandatory',
    defaultMessage: 'The input is mandatory',
  },

  [API_COMMON_ERROR.MIN_VALUE_VALIDATION_ERROR]: {
    id: 'Reverse.Common.Errors.Validations.MinValueValidationError',
    defaultMessage: 'Value should be greater',
  },
  [API_COMMON_ERROR.INVALID_EMAIL_ERROR]: {
    id: 'Reverse.Common.Errors.Validations.InvalidEmailError',
    defaultMessage: "Email isn't valid",
  },
  [API_COMMON_ERROR.INVALID_ADDRESS_ERROR]: {
    id: 'Reverse.Common.Errors.Validations.InvalidAddressError',
    defaultMessage: "Address isn't valid",
  },
  [API_COMMON_ERROR.INVALID_ENUM_VALUE_ERROR]: {
    id: 'Reverse.Common.Errors.Validations.InvalidEnumValueError',
    defaultMessage: 'Invalide value',
  },
  [API_COMMON_ERROR.ARGUMENT_SHOULD_NOT_BE_LOWER_THAN_ZERO]: {
    id: 'Reverse.Common.Errors.Validations.ArgumentShouldNotBeLowerThanZero',
    defaultMessage: 'Value should not be lower than zero',
  },

  [API_COMMON_ERROR.FILE_TOO_BIG]: {
    id: 'Reverse.Common.Errors.Upload.FileTooBig',
    defaultMessage: 'File is too big',
  },
  [API_COMMON_ERROR.UNSUPPORTED_FILE_TYPE]: {
    id: 'Reverse.Common.Errors.Upload.UnsupportedFileType',
    defaultMessage: 'Unsupported file type',
  },
  [API_COMMON_ERROR.CONNECTION_ERROR]: {
    id: 'Reverse.Common.Errors.Upload.ConnectionError',
    defaultMessage: 'Connection error',
  },
  [API_COMMON_ERROR.MORE_THAN_ONE_FILE_PROVIDED]: {
    id: 'Reverse.Common.Errors.Upload.MoreThanOneFileProvided',
    defaultMessage: 'More than one file provided',
  },
  [API_COMMON_ERROR.FILE_NOT_FOUND]: {
    id: 'Reverse.Common.Errors.Upload.FileNotFound',
    defaultMessage: 'File not found',
  },

  [API_COMMON_ERROR.DATA_NOT_FOUND]: {
    id: 'Reverse.Common.Errors.Data.NotFound',
    defaultMessage: 'Missing data',
  },
  [API_COMMON_ERROR.DATA_CANT_BE_CHANGE]: {
    id: 'Reverse.Common.Errors.Data.DataCantBeChange',
    defaultMessage: 'Data can not be change',
  },
  [API_COMMON_ERROR.CONFLICT]: {
    id: 'Reverse.Common.Errors.Data.Conflict',
    defaultMessage: 'Conflict',
  },

  [API_COMMON_ERROR.EXPIRED]: {
    id: 'Reverse.Common.Errors.Token.Expired',
    defaultMessage: 'Token has expired',
  },
  [API_COMMON_ERROR.INCONSISTENT]: {
    id: 'Reverse.Common.Errors.Token.Inconsistent',
    defaultMessage: 'Token inconsistant',
  },

  [API_COMMON_ERROR.FATAL_ERROR]: {
    id: 'Reverse.Common.Errors.Exceptions.FatalError',
    defaultMessage: 'Fatal error',
  },
  [API_COMMON_ERROR.THIRD_PARTY_UNAVAILABLE]: {
    id: 'Reverse.Common.Errors.Endpoints.ThirdPartyUnavailable',
    defaultMessage: 'Third party unvalaible',
  },
});
