import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Autocomplete,
  DescriptionOptionFormatter,
} from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import PostLabelTooltip from '../../../../../../atoms/PostLabelTooltip';
import useGetModelOptions from '../../hooks/useGetModelOptions';
import { ModelSearchFormValues } from '../../hooks/useModelSearch';
import messages from '../../messages';
import { productPostLabelMessage } from '../../ModelSearch.helpers';
import { MODEL_SEARCH_FIELDS } from '../../ModelSearch.types';

const ModelInput: FunctionComponent = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<ModelSearchFormValues>();
  const seller = watch(MODEL_SEARCH_FIELDS.SELLER)?.data;
  const { options, isLoading, setSearchTerms, searchTerms } =
    useGetModelOptions({
      seller,
    });

  const modelToolTipMessage = productPostLabelMessage({ seller });

  return (
    <Controller
      control={control}
      name={MODEL_SEARCH_FIELDS.MODEL}
      render={({ field }) => (
        <Autocomplete
          isLoading={isLoading}
          options={options}
          onInputChange={(input) => setSearchTerms(input)}
          ref={field.ref}
          name={field.name}
          errors={errors}
          label={messages.product}
          formatOptionLabel={DescriptionOptionFormatter}
          placeholder={messages.productPlaceholder}
          noOptionsMessage={
            searchTerms.length > 0
              ? messages.noOptionsMessage
              : messages.startTyping
          }
          onChange={field.onChange}
          value={field.value}
          postLabel={
            <PostLabelTooltip>
              <SafeFormattedMessageWithoutSpread
                message={modelToolTipMessage}
              />
            </PostLabelTooltip>
          }
          isRequired
          filterOption={null}
        />
      )}
    />
  );
};

ModelInput.displayName = 'ModelInput';

export default ModelInput;
