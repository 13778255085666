import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import {
  AlertIcon,
  CheckCircleIcon,
  WarningIcon,
} from '../../../../protons/icons';
import { FIELD_STATUS } from '../helpers/getFinalFieldState.types';

interface DepictionSvgProps {
  status: FIELD_STATUS;
}

const DepictionSvg: FunctionComponent<
  React.PropsWithChildren<DepictionSvgProps>
> = ({ status }) => {
  const theme = useTheme();

  switch (status) {
    case FIELD_STATUS.ERROR:
      return <AlertIcon color={theme.colors.danger} size="16px" />;
    case FIELD_STATUS.WARNING:
      return <WarningIcon color={theme.colors.alert} size="16px" />;
    case FIELD_STATUS.SUCCESS:
      return <CheckCircleIcon color={theme.colors.success} size="16px" />;

    default:
      return null;
  }
};

DepictionSvg.displayName = 'DepictionSvg';

export default DepictionSvg;
