import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.payment}files/${COMMON_QUERY_PARAMS.FILE_ID}/deductibleAndPaid`;

export interface FileAccountingSummaryDto {
  deductibleAmountFromClaim: ReverseMoneyNumberAmount;
  alreadyPaidAmountInPreviousQuotation: ReverseMoneyNumberAmount;
}

export const getOwnerDeductibleAndAlreadyPaidAmountQuery = async ({
  fileId,
}: {
  fileId: string;
}): Promise<BackResponseFailure | BackResponse<FileAccountingSummaryDto>> => {
  try {
    return await apiCall<BackResponse<FileAccountingSummaryDto>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
