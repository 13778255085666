import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { CatalogService } from 'authenticator/api';
import {
  CreateClaimContext,
  CreateClaimValues,
  UniverseModelType,
} from 'authenticator/types';

import ChoiceProductUniverseModelSchema from './ChoiceProductUniverseModel.schema';
import { ChoiceProductUniverseModelValues } from './ChoiceProductUniverseModel.types';

const GetModelTypeUnivers = 'getModelTypeUnivers';

interface UseChoiceProductUniverseModelArgs {
  values: CreateClaimValues;
}

interface UseChoiceProductUniverseModelReturnValues {
  formContext: UseFormReturn<ChoiceProductUniverseModelValues>;
  modelTypesUniverse: UniverseModelType[] | undefined;
  onSubmit: SubmitHandler<ChoiceProductUniverseModelValues>;
}

const useChoiceProductUniverseModel = ({
  values,
}: UseChoiceProductUniverseModelArgs): UseChoiceProductUniverseModelReturnValues => {
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();
  const { sellerId } = values;
  const formContext = useForm<ChoiceProductUniverseModelValues>({
    resolver: yupResolver(ChoiceProductUniverseModelSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      choiceProductUniverseId: values.universeId || undefined,
    },
  });

  const { data } = useQuery(
    [GetModelTypeUnivers, { sellerId }],
    async () => {
      const response = await CatalogService.getModelTypeUniverse({ sellerId });

      if (response.failure) {
        logError(`Got an empty getModelTypeGeneric`);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  const onSubmit: SubmitHandler<ChoiceProductUniverseModelValues> = useCallback(
    ({ choiceProductUniverseId }: { choiceProductUniverseId: string }) => {
      return nextStep({
        ...values,
        universeId: choiceProductUniverseId,
      });
    },
    [nextStep, values],
  );

  return {
    formContext,
    modelTypesUniverse: data,
    onSubmit,
  };
};

export default useChoiceProductUniverseModel;
