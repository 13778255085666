import { defineMessages } from 'react-intl';

export default defineMessages({
  addressInformations: {
    id: 'view.modal.editAddress.steps.addressInformations.title',
    defaultMessage: 'Address informations',
  },
  addressSuggestion: {
    id: 'view.modal.editAddress.steps.addressSuggestion.title',
    defaultMessage: 'Address suggestion',
  },
});
