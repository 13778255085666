import { FunctionComponent } from 'react';

import { COMMON_STEPS } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

export type NextStep<Values, Context = Record<string, unknown>> = (
  newValues?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;
export type StrictNextStep<Values, Context = Record<string, unknown>> = (
  newValues: Values,
  newContext?: Partial<Context>,
) => void;
export type JumpToStep<Values, Context> = (
  newStepName: string,
  newValues?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;

export type PreviousStep<Values, Context> = (
  newValues?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;

export type CurrentStepMoveToStep<Values, Context = Record<string, unknown>> = (
  newValue?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;

export type CurrentStepOnSubmit<Values, Context = Record<string, unknown>> = (
  newValue?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;

export enum STEPS_ORCHESTRATOR_ACTION_TYPES {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  JUMP_TO_STEP = 'JUMP_TO_STEP',
  DONE_ANIMATING = 'DONE_ANIMATING',
}

export interface Step<
  Context,
  StepNames = string,
  Values = Record<string, unknown>,
> {
  nextStep:
    | string
    | ((
        context: Partial<Context>,
        values?: Partial<Values>,
      ) => StepNames | COMMON_STEPS);
  name: string;
  message: MessageType | string;
  Component: FunctionComponent<React.PropsWithChildren<any>>;
  valuesFromConfig?: Record<string, unknown>;
}

export type StepsOrchestratorHistory<Context, StepNames = string> = Step<
  Context,
  StepNames
>[];
export type StepsOrchestratorConfig<
  Context,
  StepNames = string,
  Values = Record<string, unknown>,
> = Step<Context, StepNames, Values>[];

export type OnSubmit<Values, Context> = (
  newValue: Values,
  newContext: Context,
) => void;

export type OnPreviousClick<Values, Context> = (
  newValue?: Partial<Values>,
  newContext?: Partial<Context>,
) => void;

export interface StepProps<
  Values,
  Context = Record<string, unknown>,
  NewValues = void,
> {
  nextStep: NewValues extends void
    ? NextStep<Values, Context>
    : StrictNextStep<NewValues, Context>;
  previousStep: PreviousStep<Values, Context>;
  values: Values;
  context: Context;
  config: StepsOrchestratorConfig<Context>;
  isFirstStep: boolean;
  valuesFromConfig?: Record<string, unknown>;
  onSubmit: CurrentStepOnSubmit<Values, Context>;
  jumpToStep: JumpToStep<Values, Context>;
  onClose: () => void;
  history: StepsOrchestratorHistory<Context>;
  valuesFromParent?: Record<string, unknown>;
}
