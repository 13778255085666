import { yupResolver } from '@hookform/resolvers/yup';
import { MinimalSeller } from 'authenticator/types';
import { useCallback } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { NextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { sortBy } from '@savgroup-front-common/core/src/helpers';
import { StrictAutocompleteOptionForHookForm } from '@savgroup-front-common/types';

import { ProductReferenceLoginContext } from '../../../ProductReferenceLogin.types';
import { formatModelOption, formatSellerOption } from '../ModelSearch.helpers';
import ModelSearchSchema from '../ModelSearch.schema';
import {
  MODEL_SEARCH_FIELDS,
  ModelSearchValues,
  SearchModel,
  SubmitModelSearchFormValues,
} from '../ModelSearch.types';

export interface ModelSearchFormValues {
  [MODEL_SEARCH_FIELDS.MODEL]: StrictAutocompleteOptionForHookForm<SearchModel>;
  [MODEL_SEARCH_FIELDS.SELLER]: StrictAutocompleteOptionForHookForm<MinimalSeller>;
}

interface UseModelSearchArgs {
  nextStep: NextStep<SubmitModelSearchFormValues, ProductReferenceLoginContext>;
  values: ModelSearchValues;
  sellers: MinimalSeller[];
}
interface UseModelSearchReturnValue {
  formContext: UseFormReturn<ModelSearchFormValues>;
  handleModelSearchSubmit: (data: ModelSearchFormValues) => void;
}

const useModelSearch = ({
  nextStep,
  values,
  sellers,
}: UseModelSearchArgs): UseModelSearchReturnValue => {
  const defaultModel = values[MODEL_SEARCH_FIELDS.MODEL];
  const defaultSeller = values[MODEL_SEARCH_FIELDS.SELLER];
  const [firstSeller] = sellers.sort(sortBy({ fieldName: 'name' }));
  const firstSellerOption = formatSellerOption(firstSeller);

  const formContext = useForm<ModelSearchFormValues>({
    mode: REVALIDATE_MODES.ON_CHANGE,
    resolver: yupResolver(ModelSearchSchema),
    defaultValues: {
      [MODEL_SEARCH_FIELDS.MODEL]:
        defaultModel !== undefined
          ? formatModelOption(defaultModel)
          : undefined,
      [MODEL_SEARCH_FIELDS.SELLER]: defaultSeller
        ? formatSellerOption(defaultSeller)
        : firstSellerOption,
    },
  });

  const handleModelSearchSubmit = useCallback(
    (data: ModelSearchFormValues) => {
      return nextStep({
        [MODEL_SEARCH_FIELDS.MODEL]: data.model.data,
        [MODEL_SEARCH_FIELDS.SELLER]: data.seller.data,
      });
    },
    [nextStep],
  );

  return {
    formContext,
    handleModelSearchSubmit,
  };
};

export default useModelSearch;
