import { useCallback, useContext } from 'react';

import {
  ErrorFromBack,
  getUniqueBuiltErrorNotifications,
  MessageDescriptorMap,
} from '../../../../helpers/errorsMessage';
import BannersProviderContext from '../BannersProvider.context';
import { NotificationId } from '../BannersProvider.types';

export type UsePushErrorReturnType = (
  error: Error | ErrorFromBack,
) => NotificationId;

interface UsePushErrorArgs {
  extendedMessages?: MessageDescriptorMap;
}

const usePushError = ({
  extendedMessages,
}: UsePushErrorArgs = {}): UsePushErrorReturnType => {
  const { pushNotifications } = useContext(BannersProviderContext);

  return useCallback(
    (error: Error | ErrorFromBack) => {
      const notifications = getUniqueBuiltErrorNotifications({
        errors: [error],
        extendedMessages,
      });

      const [notificationId] = pushNotifications(notifications);

      return notificationId;
    },
    [extendedMessages, pushNotifications],
  );
};

export default usePushError;
