import React, { FunctionComponent } from 'react';

import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { CustomerInformations } from '../../../../molecules';

import {
  PriceOrderCustomerInformationContext,
  PriceOrderCustomerInformationValues,
} from './PriceOrderCustomerInformations.types';

const PriceOrderCustomerInformations: FunctionComponent = () => {
  const { previousStep, values, onSubmit } = useStepOrchestratorContext<
    PriceOrderCustomerInformationValues,
    PriceOrderCustomerInformationContext
  >();

  return (
    <CustomerInformations
      owner={values.owner}
      onSubmit={onSubmit}
      onPreviousStep={previousStep}
    />
  );
};

PriceOrderCustomerInformations.displayName = 'PriceOrderCustomerInformations';

export default PriceOrderCustomerInformations;
