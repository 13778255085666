import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { MessageType } from '@savgroup-front-common/types';

import { Loader } from '../../atoms/loader';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import { useInterval } from '../../hooks';

import { $Container, $LoaderContainer, $LoaderText } from './BaseLoader.styles';
import { BASE_LOADER_DIRECTION } from './BaseLoader.types';
import messages from './messages';

interface BaseLoaderProps {
  align?: 'center' | 'start' | 'end';
  direction?: BASE_LOADER_DIRECTION;
  dataTestId?: string;
  size?: string;
  hasMargin?: boolean;
  hasHeightAuto?: boolean;
  messageList?: (MessageType | string)[];
  customLoader?: ReactElement;
  isIntlInitialized?: boolean;
  isLargeFontSize?: boolean;
  messageInterval?: number;
}

const BaseLoader: FunctionComponent<BaseLoaderProps> = ({
  size = '32px',
  align = 'center',
  direction = BASE_LOADER_DIRECTION.ROW,
  dataTestId,
  hasMargin = true,
  hasHeightAuto = false,
  isLargeFontSize = false,
  customLoader,
  messageList,
  isIntlInitialized = true,
  messageInterval = 4000,
}) => {
  const localMessages = useMemo(() => {
    if (!isIntlInitialized) {
      return undefined;
    }
    if (messageList) {
      return messageList;
    }

    return [messages.waitMessage];
  }, [isIntlInitialized, messageList]);

  const [index, setIndex] = useState(0);

  const message = localMessages?.[index];

  const intervalCallBack = useCallback(() => {
    if (localMessages) {
      setIndex(index + 1 < localMessages.length ? index + 1 : 0);
    }
  }, [index, localMessages]);

  useInterval(intervalCallBack, messageInterval);

  return (
    <$Container
      data-testid={dataTestId}
      animationDuration={2500}
      $hasMargin={hasMargin}
      $size={size}
      $hasMessage={!!message}
      $hasHeightAuto={hasHeightAuto}
    >
      <TransitionGroup component={null}>
        <Transition key={String(index)} timeout={1000}>
          {(animationState) => (
            <$LoaderText
              animationDuration={1000}
              animationState={animationState}
              $align={align}
              $direction={direction}
              $hasMargin={hasMargin}
              $hasHeightAuto={hasHeightAuto}
              $isLargeFontSize={isLargeFontSize}
            >
              <$LoaderContainer $size={size}>
                {customLoader || <Loader size={size} />}
              </$LoaderContainer>
              {message && (
                <div>
                  <SafeFormattedMessageWithoutSpread message={message} />
                </div>
              )}
            </$LoaderText>
          )}
        </Transition>
      </TransitionGroup>
    </$Container>
  );
};

BaseLoader.displayName = 'BaseLoader';

export default BaseLoader;
