import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { LOGIN_FORM_TYPE } from '@savgroup-front-common/constants';
import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';

import { AuthConfiguration } from '../../../configuration';
import { Layout } from '../../app/layout';
import { Grid } from '../../atoms';
import {
  OrderNumberLogin,
  PriceOrderLogin,
  ProductReferenceLogin,
  TicketNumberLogin,
} from '../../components';
import { ExternalLogin } from '../../molecules';

import useActiveFormComponent from './useActiveFormComponent';

interface ActiveComponentFactoryProps {
  handleChangeComponent: (type: LOGIN_FORM_TYPE) => () => void;
  activeComponent?: LOGIN_FORM_TYPE;
}

const ActiveComponentFactory = ({
  handleChangeComponent,
  activeComponent,
}: ActiveComponentFactoryProps) => {
  switch (activeComponent) {
    case LOGIN_FORM_TYPE.RECEIPT_NUMBER:
      return (
        <TicketNumberLogin
          changeActiveComponent={handleChangeComponent(
            LOGIN_FORM_TYPE.MODEL_REFERENCE,
          )}
        />
      );
    case LOGIN_FORM_TYPE.MODEL_REFERENCE:
      return (
        <ProductReferenceLogin
          changeActiveComponent={handleChangeComponent(
            LOGIN_FORM_TYPE.RECEIPT_NUMBER,
          )}
        />
      );
    case LOGIN_FORM_TYPE.ORDER_NUMBER:
      return <OrderNumberLogin />;
    case LOGIN_FORM_TYPE.PRICE_ORDER:
      return <PriceOrderLogin />;
    default:
      return <></>;
  }
};

const MyAccountLoginPage: React.FC = () => {
  const { handleChangeComponent, activeComponent } = useActiveFormComponent();

  return (
    <>
      <Grid>
        <Row>
          <Col xs={12} sm={6}>
            <ExternalLogin />
          </Col>
        </Row>
      </Grid>

      <Layout>
        <ActiveComponentFactory
          handleChangeComponent={handleChangeComponent}
          activeComponent={activeComponent}
        />
      </Layout>
      <NeedHelp clientId={AuthConfiguration.clientId} />
    </>
  );
};

ActiveComponentFactory.displayName = 'ActiveComponentFactory';
MyAccountLoginPage.displayName = 'MyAccountLoginPage';

export default MyAccountLoginPage;
