import { darken, rgba, setLightness } from 'polished';
import styled from 'styled-components';

import { isAnimationExiting } from '../../helpers';
import {
  fadeInToFadeOut,
  StateAnimationProps,
} from '../../animations/stateAnimation';

interface BackgroundDimmerNakedProps extends StateAnimationProps {
  $opacity: number;
  $zIndex: number;
}

export const BackgroundDimmerNaked = styled.div<BackgroundDimmerNakedProps>`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme, $opacity }) =>
    rgba(darken(0.47, setLightness(0.5, theme.colors.primary)), $opacity)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ $zIndex }) => $zIndex};
  ${fadeInToFadeOut};
  pointer-events: ${({ animationState }) =>
    isAnimationExiting(animationState) ? 'none' : null};
`;
