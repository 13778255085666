import { format } from 'date-fns';

import {
  CURRENCIES,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import { formatAmountToNumber } from '@savgroup-front-common/core/src/formatters';
import { CreateOrderAndShareLinkPayload } from 'authenticator/api/Owner/createOrderAndShareLink';

import { ComplementaryInformationValues } from '../steps/ComplementaryInformation/ComplementaryInformation.types';

interface PrepareAuthenticateWithProductReferencePayloadArgs {
  tenantId?: string;
  data: ComplementaryInformationValues;
}

const prepareAuthenticateWithProductReferencePayload = ({
  tenantId,
  data,
}: PrepareAuthenticateWithProductReferencePayloadArgs): CreateOrderAndShareLinkPayload => {
  const { customerInformation } = data;

  return {
    tenantId,
    payload: {
      civility: customerInformation.civility,
      product: {
        price: {
          amount: formatAmountToNumber(data.productPrice).toString(),
          currency: CURRENCIES.EUR,
        },
        modelType: data.model.modelType,
        brandName: data.model.brandLabel,
        sku: data.model.sku,
        label: data.model.label,
        id: data.model?.id,
      },
      address: {
        additionalAddress: customerInformation.additionalAddress,
        address: customerInformation.address,
        addressId: undefined,
        additionalInformation: undefined,
        city: customerInformation.city,
        civility: customerInformation.civility,
        companyName: customerInformation.companyName,
        countryCode: customerInformation.countryCode,
        doorCode: undefined,
        floor: undefined,
        firstname: customerInformation.firstName,
        isDefault: true,
        lastname: customerInformation.lastName,
        phone: customerInformation.phone,
        postalCode: customerInformation.postalCode,
      },
      customerFirstName: customerInformation.firstName,
      customerLastName: customerInformation.lastName,
      customerMail: customerInformation.email,
      isOutOfWarranty: false,
      phoneNumber: customerInformation.phone,
      purchaseDateUtc: format(
        data.purchaseDate,
        STANDARD_DATE_TIME_FORMAT_DATE_FNS,
      ),
      salesChannelId: data.salesChannel.id,
      salesChannelType: data.salesChannel.salesChannelType,
      sellerId: data.seller.id,
    },
  };
};

export default prepareAuthenticateWithProductReferencePayload;
