import React, { FunctionComponent } from 'react';

import messages from '../../atoms/CopyField/messages';
import { safeFormattedIntlString } from '../../formatters';
import { CopyIcon } from '../../protons/icons';

import { $Icon } from './Field.styles';

interface IconCopyProps {
  handleIconClick: React.MouseEventHandler<HTMLDivElement>;
}

const IconCopy: FunctionComponent<IconCopyProps> = ({ handleIconClick }) => {
  return (
    <$Icon
      onClick={handleIconClick}
      title={safeFormattedIntlString(messages.copyToClipboard)}
    >
      <CopyIcon size="12px" />
    </$Icon>
  );
};

IconCopy.displayName = 'IconCopy';

export default IconCopy;
