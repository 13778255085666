import React from 'react';

interface StrollerIconProps {
  color?: string;
}

 
const StrollerIcon: React.FC<StrollerIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M29.9998 43.6008C26.9998 43.6008 24.3998 41.2008 24.3998 38.0008C24.3998 34.8008 26.7998 32.4008 29.9998 32.4008C33.1998 32.4008 35.5998 34.8008 35.5998 38.0008C35.5998 41.2008 32.9998 43.6008 29.9998 43.6008ZM29.9998 35.6008C28.5998 35.6008 27.5998 36.8008 27.5998 38.0008C27.5998 39.2008 28.7998 40.4008 29.9998 40.4008C31.1998 40.4008 32.3998 39.2008 32.3998 38.0008C32.3998 36.8008 31.3998 35.6008 29.9998 35.6008ZM15.9998 43.6008C12.9998 43.6008 10.3998 41.2008 10.3998 38.0008C10.3998 34.8008 12.7998 32.4008 15.9998 32.4008C18.9998 32.4008 21.5998 34.8008 21.5998 38.0008C21.5998 41.2008 18.9998 43.6008 15.9998 43.6008ZM15.9998 35.6008C14.5998 35.6008 13.5998 36.8008 13.5998 38.0008C13.5998 39.2008 14.7998 40.4008 15.9998 40.4008C17.1998 40.4008 18.3998 39.2008 18.3998 38.0008C18.3998 36.8008 17.3998 35.6008 15.9998 35.6008ZM37.3998 33.6008C37.1998 33.6008 36.7998 33.6008 36.5998 33.4008C35.7998 33.0008 35.5998 32.0008 36.1998 31.4008C37.1998 29.6008 37.9998 27.8008 38.3998 25.6008H7.7998C8.1998 27.6008 8.7998 29.6008 9.9998 31.4008C10.3998 32.2008 10.1998 33.0008 9.5998 33.4008C8.7998 33.8008 7.9998 33.6008 7.5998 33.0008C5.7998 30.0008 4.7998 26.6008 4.7998 23.2008C4.7998 13.0008 13.1998 4.80078 23.1998 4.80078C23.7998 4.80078 24.3998 5.20078 24.5998 5.80078C24.5998 6.00078 24.5998 6.00078 24.5998 6.20078V22.6008H38.5998V19.0008C38.5998 18.2008 39.1998 17.4008 40.1998 17.4008H45.1998C45.9998 17.4008 46.7998 18.0008 46.7998 19.0008C46.7998 20.0008 46.1998 20.6008 45.1998 20.6008H41.5998V23.0008C41.5998 26.4008 40.5998 29.8008 38.7998 32.8008C38.3998 33.2008 37.9998 33.6008 37.3998 33.6008ZM7.5998 22.4008H21.5998V7.60078C13.7998 8.40078 7.7998 14.6008 7.5998 22.4008Z"
        fill="currentColor"
      />
    </svg>
  );
};

StrollerIcon.displayName = 'StrollerIcon';

export default StrollerIcon;
