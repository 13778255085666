import React from 'react';

interface HeadsetHelpIconProps {
  color?: string;
}

 
const HeadsetHelpIcon: React.FC<HeadsetHelpIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81282 4.81282C7.45376 3.17187 9.67936 2.25 12 2.25C14.3206 2.25 16.5462 3.17187 18.1872 4.81282C19.8281 6.45376 20.75 8.67936 20.75 11C20.75 11.4142 20.4142 11.75 20 11.75C19.5858 11.75 19.25 11.4142 19.25 11C19.25 9.07718 18.4862 7.23311 17.1265 5.87348C15.7669 4.51384 13.9228 3.75 12 3.75C10.0772 3.75 8.23311 4.51384 6.87348 5.87348C5.51384 7.23311 4.75 9.07718 4.75 11C4.75 11.4142 4.41421 11.75 4 11.75C3.58579 11.75 3.25 11.4142 3.25 11C3.25 8.67936 4.17187 6.45376 5.81282 4.81282Z"
        fill="currentColor"
      />
      <path
        d="M20 18V18.5C20 19.0304 19.7893 19.5391 19.4142 19.9142C19.0391 20.2893 18.5304 20.5 18 20.5H14.5M2 15.438V13.562C2.00007 13.116 2.1492 12.6828 2.4237 12.3313C2.69821 11.9798 3.08232 11.7301 3.515 11.622L5.255 11.186C5.34342 11.164 5.43569 11.1624 5.52481 11.1813C5.61393 11.2003 5.69757 11.2393 5.76937 11.2954C5.84118 11.3515 5.89926 11.4232 5.93922 11.5051C5.97918 11.587 5.99997 11.6769 6 11.768V17.231C6.00016 17.3223 5.97947 17.4124 5.93952 17.4946C5.89957 17.5767 5.84141 17.6486 5.76946 17.7048C5.69752 17.761 5.61369 17.8001 5.52436 17.819C5.43503 17.8379 5.34257 17.8362 5.254 17.814L3.514 17.379C3.08151 17.2707 2.69762 17.0209 2.42331 16.6694C2.149 16.3179 2.00001 15.8849 2 15.439V15.438ZM22 15.438V13.562C21.9999 13.116 21.8508 12.6828 21.5763 12.3313C21.3018 11.9798 20.9177 11.7301 20.485 11.622L18.745 11.186C18.6566 11.164 18.5643 11.1624 18.4752 11.1813C18.3861 11.2003 18.3024 11.2393 18.2306 11.2954C18.1588 11.3515 18.1007 11.4232 18.0608 11.5051C18.0208 11.587 18 11.6769 18 11.768V17.231C17.9999 17.3222 18.0206 17.4122 18.0604 17.4942C18.1003 17.5763 18.1584 17.6481 18.2302 17.7043C18.3021 17.7605 18.3857 17.7996 18.4749 17.8186C18.5641 17.8376 18.6565 17.836 18.745 17.814L20.485 17.379C20.9177 17.2708 21.3018 17.0212 21.5763 16.6697C21.8508 16.3182 21.9999 15.885 22 15.439V15.438Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 19.75C10.3011 19.75 10.1103 19.829 9.96967 19.9697C9.82902 20.1103 9.75 20.3011 9.75 20.5C9.75 20.6989 9.82902 20.8897 9.96967 21.0303C10.1103 21.171 10.3011 21.25 10.5 21.25H13.5C13.6989 21.25 13.8897 21.171 14.0303 21.0303C14.171 20.8897 14.25 20.6989 14.25 20.5C14.25 20.3011 14.171 20.1103 14.0303 19.9697C13.8897 19.829 13.6989 19.75 13.5 19.75H10.5ZM8.90901 18.909C9.33097 18.4871 9.90326 18.25 10.5 18.25H13.5C14.0967 18.25 14.669 18.4871 15.091 18.909C15.5129 19.331 15.75 19.9033 15.75 20.5C15.75 21.0967 15.5129 21.669 15.091 22.091C14.669 22.5129 14.0967 22.75 13.5 22.75H10.5C9.90326 22.75 9.33097 22.5129 8.90901 22.091C8.48705 21.669 8.25 21.0967 8.25 20.5C8.25 19.9033 8.48705 19.331 8.90901 18.909Z"
        fill="currentColor"
      />
    </svg>
  );
};

HeadsetHelpIcon.displayName = 'HeadsetHelpIcon';

export default HeadsetHelpIcon;
