import React, { FunctionComponent } from 'react';

import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../../../formatters';
import { $OptionDescription, $OptionName } from '../Autocomplete.styles';

const DescriptionOptionFormatter: FunctionComponent<BaseAutocompleteOption> = ({
  description,
  label,
}) => {
  return (
    <>
      <$OptionName>{safeFormattedIntlString(label)}</$OptionName>
      {description && (
        <$OptionDescription>
          {safeFormattedIntlString(description)}
        </$OptionDescription>
      )}
    </>
  );
};

DescriptionOptionFormatter.displayName = 'DescriptionOptionFormatter';

export default DescriptionOptionFormatter;
