import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getAddress } from '@savgroup-front-common/core/src/helpers/address';
import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { BaseAutocompleteOption } from '@savgroup-front-common/types';

import {
  CustomerInformationAddressValues,
  HandleSelectAddressValues,
} from '../CustomerInformationAddress.types';

interface UseHandleSelectAddressArgs {
  formContext: UseFormReturn<CustomerInformationAddressValues>;
}

interface UseHandleSelectAddressReturnValues {
  countryOptions: BaseAutocompleteOption[];
  handleSelectAddress: (address: HandleSelectAddressValues) => void;
}

const useHandleSelectAddress = ({
  formContext,
}: UseHandleSelectAddressArgs): UseHandleSelectAddressReturnValues => {
  const { setValue } = formContext;
  const countryOptions = useSelector(countryList);

  const handleSelectAddress = useCallback(
    ({
      countryCode,
      streetNumber,
      street,
      city,
      postalCode,
    }: HandleSelectAddressValues = {}) => {
      setValue('address', getAddress(street, streetNumber));
      setValue(
        'countryCode',
        countryOptions.filter((country) => country.value === countryCode)[0],
      );
      setValue('city', city || '');
      setValue('postalCode', postalCode || '');
    },
    [countryOptions, setValue],
  );

  return {
    countryOptions,
    handleSelectAddress,
  };
};

export default useHandleSelectAddress;
