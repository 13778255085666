 
import React from 'react';

const GooglePlayBadge = () => {
  return (
    <img
      style={{ height: '40px' }}
      alt=""
      src="/images/Google_Play_Store_badge_EN.svg"
    />
  );
};

GooglePlayBadge.displayName = 'GooglePlayBadge';

export default GooglePlayBadge;
