import { AuthConfiguration } from 'authenticator/configuration';
import { ClientInformation } from 'authenticator/types';

import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const CLIENT_ID = 'CLIENT_ID';
const IDP = 'IDP';

const ENDPOINT = `${AuthConfiguration.authority}/api/client/information?clientId=${CLIENT_ID}&idP=${IDP}`;

interface GetClientInformationsAgrs {
  clientId: string;
  idP: string;
}

const getClientInformations = async ({
  clientId,
  idP,
}: GetClientInformationsAgrs): Promise<
  BackResponse<ClientInformation> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(CLIENT_ID, clientId).replace(IDP, idP);

    const response = await apiCall<BackResponse<ClientInformation>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getClientInformations;
