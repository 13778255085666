import React, { useRef } from 'react';
import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { DOCUMENT_TYPES } from '@savgroup-front-common/constants';

interface InvoiceLogo {
  sellerId: string;
}

const InvoiceLogo: React.FC<InvoiceLogo> = ({ sellerId }) => {
  const imgRef = useRef(null);

  const logoUrl = `${
    APIConfiguration.imageCDN
  }sellers/${sellerId}/${DOCUMENT_TYPES.LOGO.toLocaleLowerCase()}`;

  if (!logoUrl) {
    return <></>;
  }

  return (
    <img ref={imgRef} alt="logo" src={logoUrl} style={{ width: 'inherit' }} />
  );
};

InvoiceLogo.displayName = 'InvoiceLogo';

export default InvoiceLogo;
