import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}anonymous/order/genericProduct`;

interface SetCreateorderGenericProductArgs {
  sellerId?: string;
  modelTypeId?: string;
  brandId?: string;
  ownerEmail?: string;
  purchaseDate?: Date;
  ownerAddress?: Partial<AddressInfoDto>;
}

const setCreateOrderGenericProduct = async ({
  sellerId,
  modelTypeId,
  brandId,
  purchaseDate,
  ownerEmail,
  ownerAddress,
}: SetCreateorderGenericProductArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        modelTypeId,
        brandId,
        purchaseDate,
        ownerEmail,
        ownerAddress,
        sellerId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default setCreateOrderGenericProduct;
