import { ActorService } from 'authenticator/api';
import { useQuery } from 'react-query';

import { sortBy } from '@savgroup-front-common/core/src/helpers';

import adaptSalesChannelToOptions from '../adapters/adaptSalesChannelToOptions';
import { SalesChannelOption } from '../ChoicePartnerStore.types';

const GetSalesChannelOptions = 'getSalesChannelOtions';

interface UseGetSalesChannelOptionsArgs {
  sellerId?: string;
}

interface UseGetSalesChannelOptionsReturnValue {
  salesChannelOptions: SalesChannelOption[];
  isLoading: boolean;
}

const useGetSalesChannelOptions = ({
  sellerId,
}: UseGetSalesChannelOptionsArgs): UseGetSalesChannelOptionsReturnValue => {
  const {
    data: salesChannelsOptions,
    isLoading: isLoadingSalesChannelsOptions,
  } = useQuery(
    [GetSalesChannelOptions, { sellerId }],
    async () => {
      if (!sellerId) {
        return undefined;
      }

      const response = await ActorService.getSalesChannelsBySellerId({
        sellerId,
      });

      return response;
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    salesChannelOptions:
      salesChannelsOptions
        ?.sort(sortBy({ fieldName: 'name' }))
        ?.reduce(adaptSalesChannelToOptions, []) || [],
    isLoading: isLoadingSalesChannelsOptions,
  };
};

export default useGetSalesChannelOptions;
