import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';

import OrderNumberLoginSchema from '../OrderNumberLogin.schema';
import { OrderNumberLoginFormValues } from '../OrderNumberLogin.types';

const useOrderNumberLogin = () => {
  const [backErrors, setBackErrors] = useState<ErrorFromBack[]>([]);
  const query = useSearchParams();
  const errorIdQuery = query.get('errorid') || undefined;

  const formContext = useForm<OrderNumberLoginFormValues>({
    mode: REVALIDATE_MODES.ON_CHANGE,
    resolver: yupResolver(OrderNumberLoginSchema),
  });

  const [loginError, setLoginError] = useState<string | undefined>(undefined);

  return {
    formContext,
    loginError,
    setLoginError,
    errorIdQuery,
    backErrors,
    setBackErrors,
  };
};

export default useOrderNumberLogin;
