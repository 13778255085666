import { CIVILITY } from '@savgroup-front-common/constants';
import {
  AutocompleteOptionForHookForm,
  OwnerSummary,
} from '@savgroup-front-common/types';

export enum ADDRESS_INFORMATIONS_FIELDS {
  CIVILITY = 'civility',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_NAME = 'companyName',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address',
  EMAIL = 'email',
  ADDITIONAL_ADDRESS = 'additionalAddress',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  COUNTRY_CODE = 'countryCode',
}
export interface AddressInformationsFormValues {
  [ADDRESS_INFORMATIONS_FIELDS.CIVILITY]: CIVILITY;
  [ADDRESS_INFORMATIONS_FIELDS.FIRST_NAME]: string;
  [ADDRESS_INFORMATIONS_FIELDS.EMAIL]: string;
  [ADDRESS_INFORMATIONS_FIELDS.LAST_NAME]: string;
  [ADDRESS_INFORMATIONS_FIELDS.COMPANY_NAME]: string;
  [ADDRESS_INFORMATIONS_FIELDS.PHONE_NUMBER]: string;
  [ADDRESS_INFORMATIONS_FIELDS.ADDRESS]: string;
  [ADDRESS_INFORMATIONS_FIELDS.ADDITIONAL_ADDRESS]: string;
  [ADDRESS_INFORMATIONS_FIELDS.POSTAL_CODE]: string;
  [ADDRESS_INFORMATIONS_FIELDS.CITY]: string;
  [ADDRESS_INFORMATIONS_FIELDS.COUNTRY_CODE]: AutocompleteOptionForHookForm;
}

export interface AddressInformationsStepValues {
  owner: OwnerSummary;
  suggestedOwner?: OwnerSummary;
}
export interface AddressInformationsStepContext {
  hasSuggestedAddress: boolean;
  unmatchingFields: string[];
  onPreviousStep?: () => void;
}
