import { defineMessages } from 'react-intl';

export default defineMessages({
  descriptionLabel: {
    id: 'view.modal.quote.form.description.label',
    defaultMessage: 'Description',
  },
  service: {
    id: 'components.myaccount.quoteInformation.service',
    defaultMessage: 'Service',
  },
  recipient: {
    id: 'view.quotation.recipient.label',
    defaultMessage: 'Recipient',
  },
  optionalSpareParts: {
    id: 'view.quotation.optionalSpareParts.label',
    defaultMessage: 'Optional spare parts',
  },
  requiredSpareParts: {
    id: 'view.quotation.requiredSpareParts.label',
    defaultMessage: 'Required spare parts',
  },
  total: {
    id: 'view.quotation.total.label',
    defaultMessage: 'Total TTC',
  },
  statusSpareParts: {
    id: 'view.quotation.status.label',
    defaultMessage: 'Status',
  },
  sparePartConditionNoMoreAvailable: {
    id: 'view.issueAQuotation.sparepartQuotationLines.sparePartConditionNoMoreAvailable.errorMessage',
    defaultMessage: 'Selected spare part is not available',
  },
  buttonCancel: {
    id: 'view.component.confirmModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  buttonValidate: {
    id: 'view.quotation.buttonValidate',
    defaultMessage: 'Validate',
  },
  buttonReject: {
    id: 'view.quotation.buttonReject',
    defaultMessage: 'Reject',
  },
  bonus: {
    id: 'view.quotationTab.bonus.label',
    defaultMessage: 'Bonus',
  },
  congratulation: {
    id: 'view.quotationTab.bonus.congratulation',
    defaultMessage: 'Congratulation',
  },
  bonusDidactic: {
    id: 'view.quotationTab.bonus.didactic',
    defaultMessage: 'You have all requirements to get bonus',
  },
  learnMore: {
    id: 'view.quotationTab.bonus.learnMore',
    defaultMessage: 'Learn more',
  },

  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel',
  },
  confirmActionButton: {
    id: 'view.quotationTab.confirmActionButton',
    defaultMessage: 'Confirm',
  },
  confirmActionTitle: {
    id: 'view.quotationTab.confirmActionTitle',
    defaultMessage: 'Are you sure ?',
  },
  thisActionIsNoReversible: {
    id: 'view.quotationTab.thisActionIsNoReversible',
    defaultMessage: 'This action is irreversible.',
  },
  paymentTypeModalTitle: {
    id: 'view.quotationTab.paymentTypeModalTitle',
    defaultMessage: 'Payment type title',
  },
  paymentTypeModalDidactics: {
    id: 'view.quotationTab.paymentTypeModalDidactics',
    defaultMessage: 'Payment type didactics',
  },
  paymentTypeModalFormLabel: {
    id: 'view.quotationTab.paymentTypeModalFormLabel',
    defaultMessage: 'Payment type label',
  },
  paymentTypeModalLabelImmediate: {
    id: 'view.quotationTab.paymentTypeModalLabelImmediate',
    defaultMessage: 'Payment Immediate',
  },
  paymentTypeModalLabelEndOfMonth: {
    id: 'view.quotationTab.paymentTypeModalLabelEndOfMonth',
    defaultMessage: 'Payment end of month',
  },
});
