import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/actions`;

interface GetWorkTypeBySellerQueryPayload {
  fileId: string;
}

interface StateSummaryDto {
  fileId: string;
  module: {
    id: string;
    definitionId: string;
    referencedModuleType: string;
    wave: number;
    parameters: {
      additionalProp1: string;
      additionalProp2: string;
      additionalProp3: string;
    };
  };
  stateDate: string;
  priority: number;
  currentState: {
    name: string;
    shortLabel: string;
    label: string;
  };
  transitions: [
    {
      label: string;
      targetState: {
        name: string;
        shortLabel: string;
        label: string;
      };
      required: boolean;
      expects: {
        mandatories: string[];
        optionals: string[];
      };
      isCommentMandatory: boolean;
    },
  ];
}

export const getUserActionsQuery = async ({
  fileId,
}: GetWorkTypeBySellerQueryPayload): Promise<
  BackResponse<StateSummaryDto[]> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<StateSummaryDto[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
