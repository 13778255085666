import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, POSITIONS } from '@savgroup-front-common/constants';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { AddressInfoDto } from '@savgroup-front-common/types';
import { CreateClaimValues } from 'authenticator/types';

import RadioButton from './components/RadioButton';
import messages from './messages';
import useSuggestedAddressModal from './SuggestedAddressModal.hooks';
import { $ModalContent } from './SuggestedAddressModal.styles';
import SuggestedAddressModalRadioButtonInformation from './SuggestedAddressModalRadioButtonInformation';
import { ModalFooter } from '@savgroup-front-common/core/src/atoms/modal';

interface SuggestedAddressModalContentProps {
  values: CreateClaimValues;
  addressEntered: AddressInfoDto;
  suggestedAddress: AddressInfoDto;
  onClose: () => void;
}

const SuggestedAddressModalContent: React.FC<
  SuggestedAddressModalContentProps
> = ({ values, addressEntered, suggestedAddress, onClose }) => {
  const theme = useTheme();
  const { formContext, handleSubmitSuggestedAddress } =
    useSuggestedAddressModal({
      values,
      addressEntered,
      suggestedAddress,
      onClose,
    });
  const { setValue, watch, handleSubmit } = formContext;

  const keepSuggestedAddressValue = watch('keepSuggestedAddress');
  const keepAddressEnteredValue = watch('keepAddressEntered');

  const handleChangeKeepSuggestedAddress = () => {
    setValue('keepSuggestedAddress', true);
    setValue('keepAddressEntered', false);
  };

  const handleChangeKeepAddressEntered = () => {
    setValue('keepSuggestedAddress', false);
    setValue('keepAddressEntered', true);
  };

  return (
    <$ModalContent>
      <Heading level={3} color={theme.colors.black}>
        <SafeFormattedMessageWithoutSpread
          message={messages.suggestionModalTitle}
        />
      </Heading>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(handleSubmitSuggestedAddress)}>
          <RadioButton
            name="keepSuggestedAddress"
            checked={keepSuggestedAddressValue}
            onChange={handleChangeKeepSuggestedAddress}
            isFluid
            label={
              <SuggestedAddressModalRadioButtonInformation
                title={messages.suggestedAddress}
                checked={keepSuggestedAddressValue}
                address={suggestedAddress}
              />
            }
            labelPosition={POSITIONS.LEFT}
          />
          <RadioButton
            name="keepAddressEntered"
            checked={keepAddressEnteredValue}
            onChange={handleChangeKeepAddressEntered}
            isFluid
            label={
              <SuggestedAddressModalRadioButtonInformation
                title={messages.addressEntered}
                checked={keepAddressEnteredValue}
                address={addressEntered}
              />
            }
            labelPosition={POSITIONS.LEFT}
          />
          <ModalFooter>
            <Button
              type={BUTTON_TYPES.SUBMIT}
              disabled={!keepSuggestedAddressValue && !keepAddressEnteredValue}
              primary
              dataTestId="nextstepsuggestionmodal"
            >
              <SafeFormattedMessageWithoutSpread message={messages.next} />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </$ModalContent>
  );
};

SuggestedAddressModalContent.displayName = 'SuggestedAddressModalContent';

export default SuggestedAddressModalContent;
