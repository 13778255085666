export interface UserManagerDataCache {
  userManager: Record<string, any>;
  userManagerConfig: VoidFunction;
  changePassword: Record<string, any>;
  resetPassword: Record<string, any>;
}

export class UserManagerConfig {
  protected static _cache?: UserManagerDataCache;

  public static get cache(): UserManagerDataCache | undefined {
    return this._cache;
  }

  public static set cache(newValue: UserManagerDataCache | undefined) {
    this._cache = newValue;
  }
}
