import { defineMessages } from 'react-intl';

export default defineMessages({
  space: {
    id: 'shortcuts.space',
    defaultMessage: 'Space',
  },
  tab: {
    id: 'shortcuts.tab',
    defaultMessage: 'Tab',
  },
  controlLeft: {
    id: 'shortcuts.controlLeft',
    defaultMessage: 'Ctrl',
  },
  shiftLeft: {
    id: 'shortcuts.shiftLeft',
    defaultMessage: 'Shift',
  },
  altLeft: {
    id: 'shortcuts.altLeft',
    defaultMessage: 'Alt',
  },
  escape: {
    id: 'shortcuts.escape',
    defaultMessage: 'Esc',
  },
  enter: {
    id: 'shortcuts.enter',
    defaultMessage: 'Enter',
  },
  arrowUp: {
    id: 'shortcuts.arrowUp',
    defaultMessage: 'Up',
  },
  arrowDown: {
    id: 'shortcuts.arrowDown',
    defaultMessage: 'Down',
  },
  arrowRight: {
    id: 'shortcuts.arrowRight',
    defaultMessage: 'Right',
  },
  arrowLeft: {
    id: 'shortcuts.arrowLeft',
    defaultMessage: 'Left',
  },
  metaLeft: {
    id: 'shortcuts.metaLeft',
    defaultMessage: 'Meta',
  },
});
