import React, { FunctionComponent } from 'react';

import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import useHandleSubmitProductReferenceLogin from './hooks/useHandleSubmitProductReferenceLogin';
import { PRODUCT_REFERENCE_LOGIN_STEPS_CONFIG } from './ProductReferenceLogin.config';
import {
  PRODUCT_REFERENCE_LOGIN_STEP_NAMES,
  ProductReferenceLoginContext,
  ProductReferenceLoginValues,
} from './ProductReferenceLogin.types';

interface ProductReferenceLoginProps {
  changeActiveComponent: () => void;
}

const ProductReferenceLogin: FunctionComponent<
  React.PropsWithChildren<ProductReferenceLoginProps>
> = ({ changeActiveComponent }) => {
  const handleSubmit = useHandleSubmitProductReferenceLogin();

  return (
    <StepsOrchestrator<
      ProductReferenceLoginValues,
      ProductReferenceLoginContext
    >
      initialStep={PRODUCT_REFERENCE_LOGIN_STEP_NAMES.SEARCH_MODEL}
      initialContext={{
        changeActiveComponent,
      }}
      config={PRODUCT_REFERENCE_LOGIN_STEPS_CONFIG}
      onSubmit={handleSubmit}
      withWizard={false}
    />
  );
};

ProductReferenceLogin.displayName = 'ProductReferenceLogin';

export default ProductReferenceLogin;
