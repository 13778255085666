import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';

import { INPUT_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import CheckboxNaked from '../../atoms/checkbox/CheckboxNaked';
import { getFinalFieldState } from '../../atoms/Form/common/helpers/getFinalFieldState';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../../atoms/Form/common/helpers/getFinalFieldState.types';
import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../formatters';

import {
  $ImageContainer,
  $PostTitle,
  $RadioCardBody,
  $RadioCardContainer,
  $RadioCardInfo,
  $RadioCardLabel,
  $RadioCardLabelContainer,
  $RadioCardTitle,
  $TextContainer,
} from './RadioCard.styles';

export interface RadioCardProps {
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  isChecked?: boolean;
  title: ReactElement | string | number | MessageType;
  image?: ReactNode;
  name: string;
  label?: string | number | MessageType;
  dataTestId?: string;
  value?: string;
  children?: ReactNode;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  isDisabled?: boolean;
  postTitle?: ReactNode;
}

interface RadioCardWithRefProps extends RadioCardProps {
  forwardedRef?: Ref<HTMLInputElement>;
}

const RadioCard: FunctionComponent<
  React.PropsWithChildren<RadioCardWithRefProps>
> = ({
  isChecked = undefined,
  onChange = undefined,
  forwardedRef = null,
  title,
  label,
  children = null,
  isError = false,
  isWarning = false,
  isSuccess = false,
  errors = {},
  warnings = {},
  successes = {},
  image,
  name,
  value,
  dataTestId,
  postTitle,
  isDisabled = false,
}) => {
  const [status] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$RadioCardLabelContainer $status={status} $isDisabled={isDisabled}>
      <$RadioCardContainer $status={status} $isDisabled={isDisabled}>
        <$RadioCardInfo>
          {image && <$ImageContainer $status={status}>{image}</$ImageContainer>}

          <$TextContainer
            title={label ? safeFormattedIntlString(label) : undefined}
          >
            <$RadioCardTitle>
              <div>
                {SafeFormattedMessage(title)}
                {label && (
                  <$RadioCardLabel>
                    {SafeFormattedMessage(label)}
                  </$RadioCardLabel>
                )}
              </div>
              {postTitle && <$PostTitle>{postTitle}</$PostTitle>}
            </$RadioCardTitle>
          </$TextContainer>

          <CheckboxNaked
            checked={isChecked}
            onChange={onChange}
            ref={forwardedRef}
            name={name}
            danger={status === FIELD_STATUS.ERROR}
            value={value}
            type={INPUT_TYPES.RADIO}
            dataTestId={dataTestId}
            disabled={isDisabled}
          />
        </$RadioCardInfo>
        {children && <$RadioCardBody>{children}</$RadioCardBody>}
      </$RadioCardContainer>
    </$RadioCardLabelContainer>
  );
};

RadioCard.displayName = 'RadioCard';

export default React.forwardRef<HTMLInputElement, RadioCardProps>(
  (props, ref) => <RadioCard forwardedRef={ref} {...props} />,
);
