import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $ActivitiesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 48px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
`;

export const $ActivitiesContainer = styled.div`
  padding: 24px 208px 32px 208px;

  @media ${media.maxWidth.md} {
    padding: 24px 104px 32px 104px;
  }

  @media ${media.maxWidth.sm} {
    padding: 24px;
  }
`;
