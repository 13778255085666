import { createContext } from 'react';

import {
  ClientInformation,
  MinimalSeller,
  ORDER_LOGIN_CONFIGURATION,
} from 'authenticator/types';

interface ClientInformationProviderReturnValue {
  clientInformation: ClientInformation;
  sellers: MinimalSeller[];
  isLoading: boolean;
  logoutId?: string;
}

const ClientInformationContext =
  createContext<ClientInformationProviderReturnValue>({
    isLoading: false,
    clientInformation: {
      activatedSellerPartners: [],
      id: '',
      tenantId: '',
      externalProviders: [],
      providers: [],
      phoneNumberLoginEnable: false,
      productReferenceLoginEnable: false,
      isWithTicketNumberLoginEnabled: false,
      orderLoginConfiguration: ORDER_LOGIN_CONFIGURATION.STANDARD_PRICE_LOGIN,
    },
    sellers: [],
  });

export default ClientInformationContext;
