import { useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  API_COMMON_ERROR,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { formatAmountToNumber } from '@savgroup-front-common/core/src/formatters';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { OwnerService } from 'authenticator/api';
import { ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE } from 'authenticator/api/Owner/getOrderByOrderReferenceAndOrderPrice';
import { useClientInformationContext } from 'authenticator/view/atoms';

import messages from '../messages';
import { OrderInformationsFieldValues } from '../OrderInformations.types';

import useOrderInformationsStepContext from './useOrderInformationsStepContext';


const useOrderInformationsSubmit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { nextStep } = useOrderInformationsStepContext();

  const {
    clientInformation: { sellerId },
  } = useClientInformationContext();

  const { pushNotification, pushErrors, removeAllNotifications } = useBanners();

  const onSubmit: SubmitHandler<OrderInformationsFieldValues> = useCallback(
    async ({ orderPrice, orderReference }) => {
      removeAllNotifications();

      if (!sellerId) {
        pushNotification(
          buildNotification({
            message: messages.sellerIsUndefined,
            notificationType: NOTIFICATION_TYPES.ALERT,
          }),
        );

        return;
      }

      setIsLoading(true);

      const response = await OwnerService.getOrderByOrderReferenceAndOrderPrice(
        {
          totalPrice: Math.round(formatAmountToNumber(orderPrice) * 100),
          orderReference,
          sellerId,
        },
      );

      if (response.failure) {
        setIsLoading(false);

        const error = response.errors?.[0];

        if (error?.code === API_COMMON_ERROR.DATA_NOT_FOUND) {
          pushNotification(
            buildNotification({
              message: messages.orderNotFound,
              notificationType: NOTIFICATION_TYPES.ERROR,
            }),
          );

          return;
        }
        if (error?.code === 'Reverse.Owner.Errors.Owner.OrderPriceNotMatch') {
          pushNotification(
            buildNotification({
              message: messages.priceDoesNotMatch,
              notificationType: NOTIFICATION_TYPES.ERROR,
            }),
          );

          return;
        }

        pushErrors(response.errors);

        return;
      }

      if (
        response.value.importStatus ===
        ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.ORDER_ALREADY_IMPORT
      ) {
        window.location = response.value.irshUrl;

        return;
      }
      if (
        response.value.importStatus ===
        ORDER_BY_REF_AND_PRICE_PAYLOAD_TYPE.CUSTOMER_IDENTIFIED
      ) {
        const { owner } = response.value;

        nextStep({
          orderPrice,
          orderReference,
          owner,
        });

        return;
      }

      nextStep({ orderPrice, orderReference });
    },
    [nextStep, pushErrors, pushNotification, sellerId, removeAllNotifications],
  );

  return { onSubmit, isLoading };
};

export default useOrderInformationsSubmit;
