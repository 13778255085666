import { css } from 'styled-components';

import { buildStateAnimation } from '../helper';

const enter = css`
  opacity: 0;
  transform: scale3d(0, 0, 1);
`;

const steady = css`
  opacity: 1;
  transform: scale3d(1, 1, 1);
`;

const exit = css`
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
`;

const scaleInToScaleDown = buildStateAnimation({ enter, steady, exit });

export default scaleInToScaleDown;
