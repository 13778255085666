import { MessageType } from '@savgroup-front-common/types';
import STEP_CONFIG from 'authenticator/view/app/Routes/StepConfig';

import messages from './messages';

export const getStepHeaderSubtitle = (
  stepName: STEP_CONFIG,
): ReturnType<MessageType | any> => {
  let message;

  switch (stepName) {
    case STEP_CONFIG.CHOICE_INVOICE: {
      message = messages.choiceInvoice;
      break;
    }
    case STEP_CONFIG.CHOICE_PARTNER_STORE: {
      message = messages.choicePartnerStore;
      break;
    }
    case STEP_CONFIG.CHOICE_PARTNER_PRODUCT: {
      message = messages.choicePartnerProduct;
      break;
    }
    case STEP_CONFIG.CHOICE_UNIVERS_MODEL: {
      message = messages.choiceUniversModel;
      break;
    }
    case STEP_CONFIG.CHOICE_MODEL_GENERIC: {
      message = messages.choiceModelGeneric;
      break;
    }
    case STEP_CONFIG.CUSTOMER_INFORMATION_NAME: {
      message = messages.customerInformationName;
      break;
    }
    case STEP_CONFIG.CUSTOMER_INFORMATION_ADDRESS: {
      message = messages.customerInformationAddress;
      break;
    }
    case STEP_CONFIG.CUSTOMER_INVOICE_INFORMATION: {
      message = messages.customerInvoiceInformation;
      break;
    }
    default:
      return new Error('stepName scenario type does not exist');
  }

  return message;
};
