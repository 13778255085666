import get from 'lodash/get';

export const sortBy =
  <ObjectType>({
    fieldName,
    ascending = true,
  }: {
    fieldName: string;
    ascending?: boolean;
  }) =>
  (firstObject: ObjectType, secondObject: ObjectType) => {
    const firstValue = get(firstObject, fieldName);
    const secondValue = get(secondObject, fieldName);

    const sortDirection = ascending ? -1 : 1;

    if (firstValue < secondValue) {
      return sortDirection;
    }

    if (firstValue > secondValue) {
      return sortDirection * -1;
    }

    return 0;
  };

export const sortByDate =
  <ObjectType>({
    fieldName,
    ascending = true,
  }: {
    fieldName: string;
    ascending?: boolean;
  }) =>
  (firstObject: ObjectType, secondObject: ObjectType) => {
    const firstValue = get(firstObject, fieldName);
    const secondValue = get(secondObject, fieldName);

    const sortDirection = ascending ? -1 : 1;

    if (firstValue.getTime() < secondValue.getTime()) {
      return sortDirection;
    }

    if (firstValue.getTime() > secondValue.getTime()) {
      return sortDirection * -1;
    }

    return 0;
  };
