import {
  GetFinalFieldStateArgs,
  GetFinalFieldStateResponse,
} from './getFinalFieldState.types';
import { chooseState, getFieldState } from './index';

export function getFinalFieldState({
  name,
  errors,
  warnings = { isStatus: false },
  successes = { isStatus: false },
}: GetFinalFieldStateArgs): GetFinalFieldStateResponse {
  const errorEnhancedStates = getFieldState({
    messages: errors.messages,
    name,
    isStatus: errors.isStatus,
  });
  const warningEnhancedStates = getFieldState({
    messages: warnings.messages,
    name,
    isStatus: warnings.isStatus,
  });

  const successEnhancedStates = getFieldState({
    messages: successes.messages,
    name,
    isStatus: successes.isStatus,
  });

  const [returnStatus, returnStatusMessages] = chooseState({
    error: errorEnhancedStates,
    warning: warningEnhancedStates,
    success: successEnhancedStates,
  });

  return [returnStatus, returnStatusMessages];
}
