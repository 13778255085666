import get from 'lodash/get';

const { hostname } = window.location;

const authorityByClientId: Record<string, string> = {
  'preprod-selfcare.revers.io': 'https://preprod-selfcare-login.revers.io',
};

const defaultClientId = 'preprod-selfcare.revers.io';

function computeAuthority({ clientId }: { clientId: string }) {
  const defaultAuthority = get(authorityByClientId, clientId);

  if (defaultAuthority) {
    return defaultAuthority;
  }

  const builtAuthorityFromClientId = clientId.replace(
    '.revers.io',
    '-login.revers.io',
  );

  return builtAuthorityFromClientId.includes('https://')
    ? builtAuthorityFromClientId
    : `https://${builtAuthorityFromClientId}`;
}

function setAuthority(authority = null) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || computeAuthority({ clientId });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

function computeClientId() {
  if (import.meta.env.VITE_CLIENTID) {
    return import.meta.env.VITE_CLIENTID;
  }

  if (hostname === 'localhost') {
    return defaultClientId;
  }

  if (window.location.hostname.includes('-auth')) {
    return window.location.hostname.replace('-auth', '');
  }

  if (window.location.hostname.includes('auth.')) {
    return window.location.hostname.replace('-login', '').replace('auth.', '');
  }

  return window.location.hostname;
}

const clientId = computeClientId();

const AuthConfiguration = {
  clientId,
  authority: computeAuthority({ clientId }),
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
