import styled, { DefaultTheme } from 'styled-components';

import { rem } from '../../../helpers';
import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';

const size = 30;
const border = 3;
const handlerHeight = size - border * 2;

const colorBasedOnProps = (props: {
  theme: DefaultTheme;
  isDisabled: boolean;
  isError: boolean;
}) => {
  const { theme } = props;

  if (props.isDisabled) {
    return theme.colors.disabled;
  }
  if (props.isError) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

export const $SliderTrack = styled.div`
  height: ${rem(5)};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${colorBasedOnProps};
  margin-top: ${rem(handlerHeight / 2)};
  margin-bottom: ${rem(handlerHeight / 2)};
`;

export const $SliderHandler = styled.button`
  border: none;
  display: block;
  position: relative;
  border-radius: 50%;
  width: ${rem(handlerHeight)};
  height: ${rem(handlerHeight)};
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 ${rem(4)} ${rem(4)} 0 rgba(10, 31, 68, 0.16);

  transition-duration: 0.4s;
  ${decelerateTimingFunctionTransition};

  &:hover,
  &:focus,
  &:active {
    cursor: grab;
    width: ${rem(size - border * 0.5)};
    height: ${rem(size - border * 0.5)};
  }

  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline-color: ${({ theme }) => theme.colors.primary};
  }
`;
