import { EVENT_KEY } from '@savgroup-front-common/constants';
import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import {
  GooglePlace,
  Place,
  PLACE_AUTOCOMPLETE_ACTION_TYPES,
} from './PlacesAutocomplete.types';

interface PlaceAutocompleteState {
  isOpen: boolean;

  lastValue: string;
  currentGooglePlaceIdLoading: undefined | string;
  selectedIndex: number | undefined;
  places: Place[];
}

export type PlaceAutocompleteAction =
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN;
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN;
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_END;
      payload: { places: Place[]; value: string };
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT;
      payload: { value: string };
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_FAIL;
      payload: { value: string };
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_START;
      payload: { place: GooglePlace };
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_END;
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT;
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION;
      payload: { key: EVENT_KEY.ARROW_UP | EVENT_KEY.ARROW_DOWN };
    }
  | {
      type: PLACE_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT;
    };

export const placeAutocompleteInit = (): PlaceAutocompleteState => ({
  isOpen: false,
  lastValue: '',
  currentGooglePlaceIdLoading: undefined,
  selectedIndex: undefined,
  places: [],
});

export const placeAutocompleteReducer = (
  state: PlaceAutocompleteState,
  action: PlaceAutocompleteAction,
): PlaceAutocompleteState => {
  switch (action.type) {
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_END: {
      return {
        ...state,
        lastValue: action.payload.value,
        selectedIndex: undefined,
        places: action.payload.places,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT: {
      return {
        ...state,
        lastValue: action.payload.value,
        isOpen: false,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PREDICTION_FAIL: {
      return {
        ...state,
        lastValue: action.payload.value,
        selectedIndex: undefined,
        places: [],
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT: {
      return {
        ...state,
        places: !state.isOpen ? [] : state.places,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_START: {
      return {
        ...state,
        currentGooglePlaceIdLoading: action.payload.place.place_id,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.LOAD_PLACE_ID_END: {
      return {
        ...state,
        currentGooglePlaceIdLoading: undefined,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT: {
      return {
        ...state,
        places: [],
        selectedIndex: undefined,
        lastValue: '',
      };
    }
    case PLACE_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION: {
      if (state.places.length > 0 && state.isOpen === false) {
        return {
          ...state,
          isOpen: true,
        };
      }
      if (state.selectedIndex === undefined) {
        return {
          ...state,
          selectedIndex: 0,
        };
      }

      const lastIndex = state.places.length - 1;

      if (action.payload.key === EVENT_KEY.ARROW_DOWN) {
        return {
          ...state,
          selectedIndex:
            lastIndex === state.selectedIndex ? 0 : state.selectedIndex + 1,
        };
      }

      return {
        ...state,
        selectedIndex:
          state.selectedIndex === 0 ? lastIndex : state.selectedIndex - 1,
      };
    }

    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
