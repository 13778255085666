import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src';
import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  GotAnErrorFromEndpoint,
  RequiredPayloadFieldIsNotProvided,
} from '@savgroup-front-common/exceptions/src';
import { BaseBackResponse } from '@savgroup-front-common/types';

const SALE_RECEIPT_ID = ':saleReceiptId';

const ENDPOINT = `${APIConfiguration.enterpriseAuchan}saleReceipts/${SALE_RECEIPT_ID}`;

interface GetSaleReceiptByIdArgs {
  saleReceiptId: string;
  sellerId?: string;
}

interface GetSaleReceiptByIdResponse extends BaseBackResponse {
  value: string;
}

async function getSaleReceiptById(
  payload: GetSaleReceiptByIdArgs,
): Promise<string | null> {
  const { saleReceiptId, sellerId } = payload;
  const functionName = 'getSaleReceiptById';

  if (!sellerId) {
    throw new RequiredPayloadFieldIsNotProvided('sellerId', `${functionName}`);
  }

  const url = ENDPOINT.replace(SALE_RECEIPT_ID, saleReceiptId);

  const response = await apiCall<GetSaleReceiptByIdResponse>(
    buildUrl(url, { sellerId }),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(functionName, response.errors);
  }

  return response.value;
}

export default getSaleReceiptById;
