import _ from 'lodash';
import { em as polishedEm, rem as polishedRem } from 'polished';
import { DefaultTheme } from 'styled-components';

import { capitalize } from '../formatters/capitalize';

export const COMPONENTS = 'components';

export enum SUPPORTED_ICON_POSITIONS {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
}

export const getProperty = ({
  theme,
  path,
}: {
  theme: DefaultTheme;
  path: string;
}): string | undefined => {
  const value: any = _.get(theme, `${path}.value`, null);
  const fallback: string | null = _.get(theme, `${path}.fallback`, null);
  const nativeValue: string | null = _.get(theme, `${path}`, null);

  let result: string | undefined;

  if (path.includes('undefined')) {
    // eslint-disable-next-line no-console
    console.error(`Got Undefined in path ${path}.`);

    return undefined;
  }

  if (value) {
    result = typeof value === 'function' ? value({ theme }) : value;
  } else if (fallback) {
    result = getProperty({ theme, path: fallback });
  } else if (nativeValue && typeof nativeValue !== 'object') {
    result = nativeValue;
  }

  return result;
};

interface GetCustomComponentPropertyProps {
  theme: DefaultTheme;
  componentName?: string;
  property: string;
  fallback?: string;
}
export const getCustomComponentProperty = ({
  theme,
  componentName,
  property,
  fallback = undefined,
}: GetCustomComponentPropertyProps): string | undefined => {
  if (!componentName) {
    return fallback;
  }

  return (
    getProperty({
      theme,
      path: `${COMPONENTS}.${componentName}.${property}`,
    }) || fallback
  );
};

interface StyledGetCustomComponentPropertyProps {
  componentName?: string;
  property?: string;
  fallback?: string | number;
}
export const styledGetCustomComponentProperty =
  ({
    componentName,
    property,
    fallback,
  }: StyledGetCustomComponentPropertyProps) =>
  ({ theme }: { theme: DefaultTheme }) => {
    if (!componentName) {
      return fallback || null;
    }

    return (
      getProperty({
        theme,
        path: `${COMPONENTS}.${componentName}.${property}`,
      }) || fallback
    );
  };

interface GetThemePropertyArgs {
  theme: DefaultTheme;
  highContrast?: boolean;
  path: string;
  property: string;
  defaultValue?: string;
}

export const getThemeProperty = ({
  theme,
  highContrast = false,
  path,
  property,
  defaultValue,
}: GetThemePropertyArgs) => {
  if (!theme || !path || !property) {
    return null;
  }

  const transformedPropertyName = highContrast
    ? `highContrast${capitalize(property)}`
    : property;

  return (
    getProperty({
      theme,
      path: `${path}.${transformedPropertyName}`,
    }) || defaultValue
  );
};

export const rem = (px: string | number, base = 14) => polishedRem(px, base);
export const em = (px: string | number, base = 14) => polishedEm(px, base);
