import React, { FunctionComponent } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { Didactic } from '../../../atoms/Didactic';
import { Heading } from '../../../atoms/Heading';
import { PhoneIcon } from '../../../protons/icons';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';

import messages from './messages';
import {
  $CustomerSupportPhoneNumber,
  $NeedHelpModalContent,
  $PhoneIconCoCol,
  $Row,
} from './NeedHelpModalContent.styles';
import { Link } from '../../../atoms/link';

const NeedHelpModalContent: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <$NeedHelpModalContent>
      <Heading level={2}>
        <SafeFormattedMessageWithoutSpread
          message={messages.needHelpModalTitle}
        />
      </Heading>

      <$Row>
        <$PhoneIconCoCol xs={2}>
          <PhoneIcon color={theme.colors.paragraphTextColor} size="35px" />
        </$PhoneIconCoCol>

        <Col xs={10}>
          <Didactic>
            <SafeFormattedMessageWithoutSpread
              message={messages.customerSupportPhoneNumberLabel}
            />
          </Didactic>
          <$CustomerSupportPhoneNumber>
            <Link
              href={`tel:${safeFormattedIntlString(
                messages.customerSupportPhoneNumber,
              )}`}
              isExternal
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.customerSupportPhoneNumber}
              />
            </Link>
          </$CustomerSupportPhoneNumber>
        </Col>
      </$Row>
    </$NeedHelpModalContent>
  );
};

NeedHelpModalContent.displayName = 'NeedHelpModalContent';

export default NeedHelpModalContent;
