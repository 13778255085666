export const breakpoint = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const media = {
  minWidth: {
    sm: `(min-width: ${breakpoint.sm}px)`,
    md: `(min-width: ${breakpoint.md}px)`,
    lg: `(min-width: ${breakpoint.lg}px)`,
    xl: `(min-width: ${breakpoint.xl}px)`,
  },
  maxWidth: {
    xs: `(max-width: ${breakpoint.sm - 1}px)`,
    sm: `(max-width: ${breakpoint.md - 1}px)`,
    md: `(max-width: ${breakpoint.lg - 1}px)`,
    lg: `(max-width: ${breakpoint.xl - 1}px)`,
  },
  maxHeight: {
    xs: `(max-height: ${breakpoint.sm - 1}px)`,
    sm: `(max-height: ${breakpoint.md - 1}px)`,
    md: `(max-height: ${breakpoint.lg - 1}px)`,
    lg: `(max-height: ${breakpoint.xl - 1}px)`,
  },
};
