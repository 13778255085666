import React, { FunctionComponent } from 'react';

import { SafeFormattedMessageWithoutSpread } from '../../../../../../formatters';
import {
  $PlaceAutcompleteItem,
  $PlaceAutcompleteLoading,
} from '../../PlacesAutocomplete.styles';

import messages from './messages';

interface PlaceAutocompleteLoadingProps {
  dataTestId?: string;
}

const PlaceAutocompleteLoading: FunctionComponent<
  PlaceAutocompleteLoadingProps
> = ({ dataTestId }) => {
  return (
    <$PlaceAutcompleteItem>
      <$PlaceAutcompleteLoading data-testid={dataTestId}>
        <SafeFormattedMessageWithoutSpread message={messages.loadingMessage} />
      </$PlaceAutcompleteLoading>
    </$PlaceAutcompleteItem>
  );
};

PlaceAutocompleteLoading.displayName = 'PlaceAutocompleteLoading';

export default PlaceAutocompleteLoading;
