import { fromJS, Map } from 'immutable';
import get from 'lodash/get';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { DOCUMENT_STATUS } from '@savgroup-front-common/constants/src/shared';

import {
  GET_SHIPPING_LABEL,
  LOAD_CARRIERS_PRODUCTS,
  LOAD_EXTERNAL_CARRIERS,
  LOAD_LABELS,
  LOAD_TRANSPORT_METHODS,
  REGENERATE_LABEL_COMMAND,
  RMA_LABEL_DOCUMENT_GENERATION,
} from './actionTypes';
import { VERIFY_ADDRESS } from './verifiedAddress/actionTypes';
import {
  verifiedAddressErrored,
  verifiedAddressStart,
  verifiedAddressSuccess,
} from './verifiedAddress/reducer';

const initialState = fromJS({
  labels: fromJS({}),
  carrierLabelUrls: fromJS({}),
  externalCarriers: null,
  carriersProducts: null,
  transportMethods: null,
  verifiedAddress: fromJS({
    value: null,
    isLoaded: true,
    errorFields: null,
  }),
  homePickupSchedule: fromJS({}),
  appointedHomePickup: fromJS({}),
});

function onLoadLabelsStart(state, { meta }) {
  const { fileId } = meta;

  if (!fileId) {
    return state;
  }
  const oldValue = state.getIn(['labels', fileId, 'value']);
  const oldRegenerateLabelCommand = state.getIn([
    'labels',
    fileId,
    'regenerateLabel',
  ]);
  const oldRmaLabelDocumentStatus = state.getIn([
    'labels',
    fileId,
    'rmaDocumentStatus',
  ]);

  return state.setIn(
    ['labels', fileId],
    Map({
      isLoaded: false,
      regenerateLabel: oldRegenerateLabelCommand || [],
      rmaDocumentStatus: oldRmaLabelDocumentStatus || DOCUMENT_STATUS.UNKNOWN,
      value: oldValue,
    }),
  );
}
function onLoadLabelsError(state, { meta }) {
  const { fileId } = meta;

  if (!fileId) {
    return state;
  }

  return state.setIn(
    ['labels', fileId],
    fromJS({
      isLoaded: true,
      hasErrors: true,
    }),
  );
}
function onLoadLabelsSuccess(state, { payload, meta }) {
  const { fileId } = meta;

  const oldRegenerateLabelCommand = state.getIn([
    'labels',
    fileId,
    'regenerateLabel',
  ]);
  const oldRmaLabelDocumentStatus = state.getIn([
    'labels',
    fileId,
    'rmaDocumentStatus',
  ]);

  return state.setIn(
    ['labels', fileId],
    Map({
      isLoaded: true,
      regenerateLabel: oldRegenerateLabelCommand || [],
      rmaDocumentStatus: oldRmaLabelDocumentStatus || DOCUMENT_STATUS.UNKNOWN,
      value: payload.value,
    }),
  );
}

function onRegenerateLabelCommandCalled(state, { payload }) {
  const { regenerateLabel, fileId } = payload;

  return state.updateIn(['labels', fileId], (oldValue) =>
    oldValue.setIn(['regenerateLabel'], regenerateLabel),
  );
}

function onRmaLabelDocumentGeneration(state, { payload }) {
  const { rmaDocumentStatus, fileId } = payload;

  return state.updateIn(['labels', fileId], (oldValue) =>
    oldValue.setIn(['rmaDocumentStatus'], rmaDocumentStatus),
  );
}

function onLoadExternalCarriersSuccess(state, { payload }) {
  return state.setIn(['externalCarriers'], payload.value);
}

function shippingLabelLoading(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const internalId = get(meta, ['internalId']);
  const oldValue = state.getIn([
    'carrierLabelUrls',
    fileId,
    internalId,
    'value',
  ]);

  return state.setIn(
    ['carrierLabelUrls', fileId, internalId],
    Map({
      isLoaded: false,
      value: oldValue,
    }),
  );
}

function shippingLabelLoadingErrored(state, { meta }) {
  const fileId = get(meta, ['fileId']);
  const internalId = get(meta, ['internalId']);

  return state.updateIn(['carrierLabelUrls', fileId, internalId], (oldValue) =>
    oldValue.set('hasErrors', true),
  );
}

function shippingLabelLoaded(state, { payload, meta }) {
  const fileId = get(meta, ['fileId']);
  const internalId = get(meta, ['internalId']);
  const value = get(payload, ['value'], '');

  return state.setIn(
    ['carrierLabelUrls', fileId, internalId],
    fromJS({ isLoaded: true }).setIn(
      ['value'],
      APIConfiguration.carrier +
        value.replace('v1/', '').replace('{fileId}', fileId),
    ),
  );
}

function onLoadTransportMethodsSuccess(state, { payload }) {
  return state.setIn(['transportMethods'], payload.value);
}

function onLoadCarriersProductsSuccess(state, { payload }) {
  return state.setIn(['carriersProducts'], payload.value);
}

export default function carriersReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_LABELS.STARTED:
      return onLoadLabelsStart(state, action);
    case LOAD_LABELS.SUCCEEDED:
      return onLoadLabelsSuccess(state, action);
    case LOAD_LABELS.ERRORED:
      return onLoadLabelsError(state, action);

    case REGENERATE_LABEL_COMMAND.BASE:
      return onRegenerateLabelCommandCalled(state, action);
    case RMA_LABEL_DOCUMENT_GENERATION.BASE:
      return onRmaLabelDocumentGeneration(state, action);
    case LOAD_EXTERNAL_CARRIERS.SUCCEEDED:
      return onLoadExternalCarriersSuccess(state, action);

    case VERIFY_ADDRESS.BASE:
      return verifiedAddressStart(state, action);
    case VERIFY_ADDRESS.ERRORED:
      return verifiedAddressErrored(state, action);
    case VERIFY_ADDRESS.SUCCEEDED:
      return verifiedAddressSuccess(state, action);

    case GET_SHIPPING_LABEL.STARTED:
      return shippingLabelLoading(state, action);
    case GET_SHIPPING_LABEL.ERRORED:
      return shippingLabelLoadingErrored(state, action);
    case GET_SHIPPING_LABEL.SUCCEEDED:
      return shippingLabelLoaded(state, action);

    case LOAD_TRANSPORT_METHODS.SUCCEEDED:
      return onLoadTransportMethodsSuccess(state, action);
    case LOAD_CARRIERS_PRODUCTS.SUCCEEDED:
      return onLoadCarriersProductsSuccess(state, action);

    default:
      return state;
  }
}
