import React, { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import {
  Banner,
  MultiBanners,
} from '@savgroup-front-common/core/src/atoms/Banners';
import {
  Button,
  ButtonLink,
} from '@savgroup-front-common/core/src/atoms/button';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { getUniqueBuiltErrorNotifications } from '@savgroup-front-common/core/src/helpers';
import { InputHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import { AuthConfiguration } from '../../../configuration';
import { StepLayout, useClientInformationContext } from '../../atoms';

import useGetLoginError from './hooks/useGetLoginError';
import useLogMeIn from './hooks/useLogMeIn';
import useOrderNumberLogin from './hooks/useOrderNumberLogin';
import messages from './messages';
import { ORDER_NUMBER_FORM_FIELDS } from './OrderNumberLogin.types';

const OrderNumberLogin: FunctionComponent = () => {
  const { clientInformation } = useClientInformationContext();

  const {
    errorIdQuery,
    formContext,
    loginError,
    setLoginError,
    backErrors,
    setBackErrors,
  } = useOrderNumberLogin();
  const { handleSubmit, register, formState } = formContext;
  const { isSubmitting, isValidating, isValid, errors } = formState;

  useGetLoginError({ errorIdQuery, setLoginError });

  const handleLogin = useLogMeIn({
    setBackErrors,
  });

  const notifications = getUniqueBuiltErrorNotifications({
    errors: backErrors,
    extendedMessages: messages,
  });

  const isEnableLocalLogin =
    clientInformation.phoneNumberLoginEnable ||
    clientInformation.productReferenceLoginEnable;

  const visibleExternalProviders = clientInformation.externalProviders.filter(
    (externalProvider) => externalProvider.displayName,
  );

  if (!isEnableLocalLogin) {
    return null;
  }

  return (
    <StepLayout showImage>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(handleLogin)}>
          <Banner
            notificationType={NOTIFICATION_TYPES.ERROR}
            isOpen={Boolean(loginError)}
          >
            {loginError}
          </Banner>
          <MultiBanners notifications={notifications} />
          <FormGroup>
            <InputHookForm
              label={messages.orderNumber}
              name={ORDER_NUMBER_FORM_FIELDS.ORDER_NUMBER}
              dataTestId="order-number-input"
              register={register}
              disableBrowserAutocomplete
              errors={errors}
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <InputHookForm
              label={messages.phoneNumber}
              name={ORDER_NUMBER_FORM_FIELDS.PHONE_NUMBER}
              placeholder={messages.phoneNumberPlaceholder}
              dataTestId="phone-number-input"
              register={register}
              disableBrowserAutocomplete
              errors={errors}
              isRequired
            />
          </FormGroup>
          <FormGroup>
            <Button
              type={BUTTON_TYPES.SUBMIT}
              isLoading={isSubmitting && !isValidating && isValid}
              primary
              dataTestId="login-form-submit"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.connectButton}
              />
            </Button>
          </FormGroup>

          {visibleExternalProviders.length > 0 && (
            <FormGroup>
              <ul className="list-inline">
                {visibleExternalProviders.map((provider) => {
                  const queryParams = `?provider=${provider.authenticationScheme}`;
                  const href = `${AuthConfiguration.authority}/ExternalLogin${queryParams}`;

                  return (
                    <li key={provider.displayName}>
                      <ButtonLink href={href}>
                        Use existing account from {provider.displayName}
                      </ButtonLink>
                    </li>
                  );
                })}
              </ul>
            </FormGroup>
          )}
        </form>
      </FormProvider>
    </StepLayout>
  );
};

OrderNumberLogin.displayName = 'OrderNumberLogin';

export default OrderNumberLogin;
