import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

import { priceHelpers } from './helpers/prices.helpers';
import messages from './messages';

const choicePartnerSchema = ({ isBusiness }: { isBusiness: boolean }) => {
  if (isBusiness) {
    return Yup.object().shape({
      choicePartnerProduct: Yup.object()
        .test(
          'choicePartnerProduct',
          globalMessages.form.required,
          function test(option) {
            return option?.value;
          },
        )
        .required(globalMessages.form.required),
    });
  }

  return Yup.object().shape({
    choicePartnerProduct: Yup.object()
      .test(
        'choicePartnerProduct',
        globalMessages.form.required,
        function test(option) {
          return option?.value;
        },
      )
      .required(globalMessages.form.required),
    choicePartnerProductPrice: Yup.string()
      .test(
        'unitPriceShouldBeSafeIntegerOrFloat',
        messages.shouldBeValidNumber,
        priceHelpers.testIsSafeInteger,
      )
      .test(
        'unitPriceShouldBeStrictPositive',
        messages.shouldBeStrictPositive,
        priceHelpers.testPositive,
      )

      .test(
        'unitPriceShouldBeValid',
        globalMessages.form.invalidPrice,
        priceHelpers.testValid,
      )
      .nullable()
      .required(globalMessages.form.required),
  });
};

export default choicePartnerSchema;
