import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

import {
  ADDRESS_SUGGESTION_ORIGIN,
  ADDRESS_SUGGESTION_FIELDS as FIELDS,
} from './AddressSuggestion.types';

const AddressSuggestionSchema = Yup.object().shape({
  [FIELDS.ORIGIN]: Yup.string()
    .oneOf(Object.values(ADDRESS_SUGGESTION_ORIGIN))
    .required(globalMessages.form.required),
});

export default AddressSuggestionSchema;
