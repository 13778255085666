export enum INPUT_TYPES {
  SUBMIT = 'submit',
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEL = 'tel',
  EMAIL = 'email',
  HIDDEN = 'hidden',
  TIME = 'time',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SEARCH = 'search',
  FILE = 'file',
}

export const PLACEHOLDER_TO_DISABLE_NATIVE_AUTOCOMPLETE = 'off';
