import { COMMON_STEPS } from '@savgroup-front-common/constants/src';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import { OrderInformations, PriceOrderCustomerInformations } from './steps';

export enum PRICE_ORDER_LOGIN_STEP_NAMES {
  ORDER_INFORMATIONS = 'orderInformations',
  CUSTOMER_INFORMATION = 'customerInformation',
}

const priceInformationsStep: Step<Record<string, unknown>> = {
  name: PRICE_ORDER_LOGIN_STEP_NAMES.ORDER_INFORMATIONS,
  nextStep: PRICE_ORDER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  Component: OrderInformations,
  message: PRICE_ORDER_LOGIN_STEP_NAMES.ORDER_INFORMATIONS,
};

const customInformationStep: Step<Record<string, unknown>> = {
  name: PRICE_ORDER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: PriceOrderCustomerInformations,
  message: PRICE_ORDER_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
};

export const PRICE_ORDER_LOGIN_STEPS_CONFIG = [
  priceInformationsStep,
  customInformationStep,
];
