import es6Promise from 'es6-promise';
import 'fetch-everywhere';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { CLIENT_COMMON_ERROR } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';

es6Promise.polyfill();

async function fetchWithTimeout(resource, options) {
  const controller = new AbortController();
  const id = window.setTimeout(() => controller.abort(), options.timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(id);

  return response;
}

function APIClient({ url, config, currentLanguage, authToken }) {
  return new Promise((resolve, reject) => {
    const headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Max-Age', '600');

    if (config && !(config.body instanceof FormData)) {
      headers.append('Content-Type', 'application/json');
    }

    headers.append('Accept-Language', currentLanguage);
    if (authToken != null && !url.includes(APIConfiguration.catalogCDN)) {
      headers.append('Authorization', `Bearer ${authToken}`);
    }
    if (config) {
      for (const newHeader in config.headers) {
        if (Object.prototype.hasOwnProperty.call(config.headers, newHeader)) {
          headers.set(newHeader, config.headers[newHeader]);
        }
      }
    }

    const finalConfig = {
      method: SUPPORTED_METHODS.GET,
      ...config,
      headers,
    };

    if (config && config.json !== null && config.json !== undefined) {
      Object.assign(finalConfig, { body: JSON.stringify(config.json) });
    }

    const fetchMethod = finalConfig.timeout ? fetchWithTimeout : fetch;

    return fetchMethod(url, finalConfig)
      .then(async (response) => {
        const contentType = response.headers.get('content-type');

        const isSerialisable =
          contentType && contentType.includes('application/json');

        if (response.status === 200 || response.status === 201) {
          if (import.meta.env.VITE_API_MODE === 'MOCK' || isSerialisable) {
            return response.json().then((result) => {
              resolve(result);
            });
          }

          if (config.responseType !== 'blob') {
            resolve(response.text());
          } else {
            const file = await response.blob();
            const fileNameIndexOf = contentType.indexOf('filename=');
            const fileName = contentType.slice(fileNameIndexOf + 9);

            if (!file.name) {
              file.name = fileName;
            }

            resolve(file);
          }
        } else if (isSerialisable) {
          response
            .json()
            .then((err) => reject({ ...err, statusCode: response.status }));
        } else {
          reject({
            failure: true,
            errors: [{ message: response.statusText }],
            statusCode: response.status,
          });
        }

        return null;
      })
      .catch((err) => {
        const errors =
          err.message === 'Failed to fetch'
            ? [{ name: CLIENT_COMMON_ERROR.FAILED_TO_FETCH }]
            : [{ message: err.message }];

        reject({
          failure: true,
          errors,
        });
      });
  });
}

export default APIClient;
