import React from 'react';

interface ModernTvIconProps {
  color?: string;
}

 
const ModernTvIcon: React.FC<ModernTvIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 42C12.5 41.1716 13.1716 40.5 14 40.5H34C34.8284 40.5 35.5 41.1716 35.5 42C35.5 42.8284 34.8284 43.5 34 43.5H14C13.1716 43.5 12.5 42.8284 12.5 42Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7.5V32.5H42.5V7.5H5.5ZM3.29081 5.29081C3.79716 4.78446 4.48391 4.5 5.2 4.5H42.8C43.5161 4.5 44.2028 4.78446 44.7092 5.29081C45.2155 5.79716 45.5 6.48392 45.5 7.2V32.8C45.5 33.5161 45.2155 34.2028 44.7092 34.7092C44.2028 35.2155 43.5161 35.5 42.8 35.5H5.2C4.48392 35.5 3.79716 35.2155 3.29081 34.7092C2.78446 34.2028 2.5 33.5161 2.5 32.8V7.2C2.5 6.48391 2.78446 5.79716 3.29081 5.29081Z"
        fill="currentColor"
      />
    </svg>
  );
};

ModernTvIcon.displayName = 'ModernTvIcon';

export default ModernTvIcon;
