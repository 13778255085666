import { darken } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { rem } from '../../../../helpers';
import { getTextColorBasedOnProps } from '../../../../theme/helpers';
import { decelerateTimingFunctionTransition } from '../../../../animations/timingFunction';

const dot = 30;
const width = 60;
const border = 3;

interface $StyledSwitchProps {
  theme: DefaultTheme;
  disabled?: boolean;
  danger?: boolean;
}

const colorBasedOnProps = ({ theme, disabled, danger }: $StyledSwitchProps) => {
  if (disabled) {
    return theme.colors.disabled;
  }
  if (danger) {
    return theme.colors.danger;
  }

  return theme.colors.default;
};

export const $Dot = styled.div<{ $isActive: boolean }>`
  top: ${rem(dot / 2 - 2)};
  opacity: ${({ $isActive }) => ($isActive ? 0.7 : 0.5)};

  background: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.white : theme.colors.black};
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  transition-duration: 200ms;
  ${decelerateTimingFunctionTransition};
  &:nth-child(1) {
    left: ${rem(width / 6 - 2)};
  }
  &:nth-child(2) {
    left: ${rem((width / 6) * 3 - 2)};
  }
  &:nth-child(3) {
    left: ${rem((width / 6) * 5 - 2)};
  }
`;

export const $DotContainer = styled.div`
  width: ${rem(width)};
  height: ${rem(dot)};
  position: absolute;
  pointer-events: none;
  top: 0;
`;

const SwitchContainerMixin = css<$StyledSwitchProps>`
  display: flex;
  position: relative;
  color: ${getTextColorBasedOnProps};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  span {
    line-height: ${rem(dot)};
  }
`;

export const $SwitchContainer = styled.div`
  ${SwitchContainerMixin}
`;

export const $StyledSwitch = styled.span<$StyledSwitchProps>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: ${rem(width)};
  height: ${rem(dot)};
  border-radius: ${rem(width)};
  background-color: ${colorBasedOnProps};
  transition-duration: 0.2s;
  ${decelerateTimingFunctionTransition};
  transform-origin: center center;

  &::after {
    content: '';
    display: block;
    position: relative;
    border-radius: 50%;
    transform: translateY(${rem(border)}) translateX(${rem(border)}) scale(1);
    width: ${rem(dot - border * 2)};
    height: ${rem(dot - border * 2)};
    background-color: #ffffff;
    box-shadow: 0 ${rem(4)} ${rem(4)} 0 rgba(10, 31, 68, 0.16);
    transition-duration: 0.2s;
    ${decelerateTimingFunctionTransition};
  }
`;

export const $SwitchRadio = styled.input.attrs({
  type: 'radio',
})`
  position: relative;
  z-index: 1;
  width: ${rem(width / 3)};
  height: ${rem(dot)};
  opacity: 0;

  &:not(:disabled) {
    cursor: pointer;
    ~ ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.default};
    }
  }
  &:disabled {
    cursor: not-allowed;
    ~ ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.disabled};
    }
  }

  &:focus,
  &:hover {
    &:not(:disabled) {
      &:nth-child(1) ~ ${$StyledSwitch} {
        background-color: ${({ theme }) => darken(0.15, theme.colors.danger)};

        ${$DotContainer} ${$Dot} {
          background: ${({ theme }) => theme.colors.white};
          &:nth-child(1) {
            transform: scale(2);
          }
        }
      }
      &:nth-child(2) ~ ${$StyledSwitch} {
        background-color: ${({ theme }) => darken(0.15, theme.colors.default)};
        ${$DotContainer} ${$Dot} {
          background: ${({ theme }) => theme.colors.black};
          &:nth-child(2) {
            transform: scale(2);
          }
        }
      }
      &:nth-child(3) ~ ${$StyledSwitch} {
        background-color: ${({ theme }) => darken(0.15, theme.colors.primary)};

        ${$DotContainer} ${$Dot} {
          background: ${({ theme }) => theme.colors.white};
          &:nth-child(3) {
            transform: scale(2);
          }
        }
      }
    }
  }

  &:active:checked {
    &:nth-child(1) ~ ${$StyledSwitch}::after {
      transform: translateY(${rem(border)}) translateX(${rem(border)})
        scale(0.9);
    }
    &:nth-child(2) ~ ${$StyledSwitch}::after {
      transform: translateY(${rem(border)})
        translateX(${rem((width + border * 2 - dot) / 2)}) scale(0.9);
    }
    &:nth-child(3) ~ ${$StyledSwitch}::after {
      transform: translateY(${rem(border)})
        translateX(${rem(width + border - dot)}) scale(0.9);
    }
  }

  // excluded
  &:checked:nth-child(1) {
    ~ ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.danger};

      &::after {
        transform: translateY(${rem(border)}) translateX(${rem(border)})
          scale(1);
      }
    }
  }

  // iddle
  &:checked:nth-child(2) {
    ~ ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.default};

      &::after {
        transform: translateY(${rem(border)})
          translateX(${rem((width + border * 2 - dot) / 2)}) scale(1);
      }
    }
  }

  // included
  &:checked:nth-child(3) {
    ~ ${$StyledSwitch} {
      background-color: ${({ theme }) => theme.colors.primary};

      &::after {
        transform: translateY(${rem(border)})
          translateX(${rem(width + border - dot)}) scale(1);
      }
    }
  }
`;

export const $Label = styled.label`
  ${SwitchContainerMixin}
`;

export const $LabelContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  & span {
    line-height: 1.2 !important;
  }
`;
