import styled, { css } from 'styled-components';

import { buildStateAnimation } from '../../animations/helper';
import { rem } from '../../helpers';
import { StateAnimationProps } from '../../animations/stateAnimation';

const loaderAnimation = buildStateAnimation({
  enter: css`
    transform: translateY(-1rem);
    opacity: 0;
  `,
  steady: css`
    transform: translateY(0);
    opacity: 1;
  `,
  exit: css`
    transform: translateY(1rem);
    opacity: 0;
  `,
});

export const $LoaderContainer = styled.div<StateAnimationProps>`
  ${loaderAnimation};
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  height: 100%;
  right: calc(50% - ${rem(8)});
  pointer-events: none;
`;

export const $ButtonChildContainer = styled.span<StateAnimationProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${loaderAnimation};
`;
