const DEFAULT_LAT = 48.858253;
const DEFAULT_LNG = 2.294343;
const DEFAULT_RADIUS = 50000;
const DEFAULT_TYPES = ['address'];

export const getSearchOptions: () => Omit<
  google.maps.places.AutocompletionRequest,
  'input'
> = () => ({
  radius: DEFAULT_RADIUS,
  types: DEFAULT_TYPES,
  location: new window.google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG),
});
