import { createAction } from '@reduxjs/toolkit';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { LANGUAGES } from '@savgroup-front-common/constants';
import { createRequest } from '../../services/request';

export const types = {
  SET_LANGUAGE_PREFERENCE: createRequest('SET_LANGUAGE_PREFERENCE'),
  CHANGE_LOCALE_LANGUAGE: createRequest('LOCALE/CHANGE_LOCALE_LANGUAGE'),
};

export interface ChangeLocaleLanguagePayload {
  locale: LANGUAGES;
}
export interface SetPreferenceLanguageMeta {
  setLanguageEndPoint: string;
}

export const changeLocaleLanguage = createAction<ChangeLocaleLanguagePayload>(
  types.CHANGE_LOCALE_LANGUAGE.BASE,
);

export const setUserLanguagePreference = createAction(
  types.SET_LANGUAGE_PREFERENCE.BASE,
  ({ locale }: ChangeLocaleLanguagePayload) => ({
    payload: {
      locale,
    },
    meta: {
      setLanguageEndPoint: `${APIConfiguration.authorization}users/me/preferences`,
    },
  }),
);

export const setOwnerLanguagePreference = createAction(
  types.SET_LANGUAGE_PREFERENCE.BASE,
  ({ locale }: ChangeLocaleLanguagePayload) => ({
    payload: {
      locale,
    },
    meta: {
      setLanguageEndPoint: `${APIConfiguration.owner}owners/me/preferences`,
    },
  }),
);

export const creators = {
  setUserLanguagePreference,
  changeLocaleLanguage,
  setOwnerLanguagePreference,
};
