import { MutableRefObject, Ref, useEffect, useRef } from 'react';

export default function useCombinedRefs<T = any>(
  ...refs: Array<Ref<any> | (() => void)>
) {
  const targetRef = useRef<any>();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {return;}

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
         
        (ref as MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
