import React, { FunctionComponent } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Autocomplete, FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import {
  PostLabelTooltip,
  StepLayout,
  useClientInformationContext,
} from '../../../../atoms';
import { ProductReferenceLoginContext } from '../../ProductReferenceLogin.types';

import ModelInput from './Components/ModelInput';
import useModelSearch from './hooks/useModelSearch';
import messages from './messages';
import { formatSellerOption } from './ModelSearch.helpers';
import {
  $LinkButton,
  $StartButtonContainer,
  $SwitchLoginModeLink,
} from './ModelSearch.styles';
import { MODEL_SEARCH_FIELDS, ModelSearchValues } from './ModelSearch.types';

const ModelSearchContent: FunctionComponent = () => {
  const { nextStep, values, context } = useStepOrchestratorContext<
    ModelSearchValues,
    ProductReferenceLoginContext
  >();
  const { changeActiveComponent } = context;

  const { clientInformation, sellers } = useClientInformationContext();

  const { isWithTicketNumberLoginEnabled } = clientInformation;

  const { formContext, handleModelSearchSubmit } = useModelSearch({
    nextStep,
    values,
    sellers,
  });
  const { handleSubmit, formState, control } = formContext;
  const { errors, isSubmitting, isValidating, isValid } = formState;

  const options =
    sellers
      .filter((seller) => {
        return (
          !seller.name.includes('Supporter') &&
          !seller.name.includes('SPB Services') &&
          !seller.name.includes('Boulanger Demo') &&
          !seller.name.includes('Boulanger Seller') &&
          !seller.name.includes('ONEY Demo') &&
          !seller.name.includes('ONEY')
        );
      })
      .map(formatSellerOption) || [];

  return (
    <>
      <BannersConsumer />
      <StepLayout showImage>
        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(handleModelSearchSubmit)}>
            <FormGroup isOpen={sellers.length > 1}>
              <Controller
                name={MODEL_SEARCH_FIELDS.SELLER}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    ref={field.ref}
                    label={messages.seller}
                    errors={errors}
                    name={MODEL_SEARCH_FIELDS.SELLER}
                    onChange={field.onChange}
                    value={field.value}
                    options={options}
                    postLabel={
                      <PostLabelTooltip>
                        <SafeFormattedMessageWithoutSpread
                          message={messages.sellerTooltip}
                        />
                      </PostLabelTooltip>
                    }
                    isRequired
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <ModelInput />
            </FormGroup>

            {isWithTicketNumberLoginEnabled && (
              <$SwitchLoginModeLink>
                <SafeFormattedMessageWithoutSpread
                  message={messages.goToTicketNumberLogin}
                />
                <$LinkButton
                  onClick={changeActiveComponent}
                  type={BUTTON_TYPES.BUTTON}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.clickHere}
                  />
                </$LinkButton>
              </$SwitchLoginModeLink>
            )}

            <FormGroup>
              <Row between="xs">
                <$StartButtonContainer>
                  <Button
                    type={BUTTON_TYPES.SUBMIT}
                    isLoading={isSubmitting && isValidating}
                    isError={!isValid}
                    primary
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.startButton}
                    />
                  </Button>
                </$StartButtonContainer>
              </Row>
            </FormGroup>
          </form>
        </FormProvider>
      </StepLayout>
    </>
  );
};

ModelSearchContent.displayName = 'ModelSearchContent';

export default ModelSearchContent;
