import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { CatalogCdnService } from 'authenticator/api';
import { NetworkLink } from 'authenticator/types';

const GetNetworkLinks = 'getNetworkLinks';

interface UseNetworkLinksArgs {
  clientId?: string;
}

interface UseNetworkLinksReturnValues {
  networkLinks: NetworkLink[];
}

const useNetworkLinks = ({
  clientId,
}: UseNetworkLinksArgs): UseNetworkLinksReturnValues => {
  const { data } = useQuery(
    [
      GetNetworkLinks,
      {
        clientId,
      },
    ],
    async () => {
      if (!clientId) {
        return undefined;
      }

      const response = await CatalogCdnService.getNetworkLinks({ clientId });

      if (response.failure) {
        logError(`Got an error to networkLinks fetch`);

        return undefined;
      }

      return response;
    },
    {
      enabled: !!clientId,
    },
  );

  return {
    networkLinks: data?.links || [],
  };
};

export default useNetworkLinks;
