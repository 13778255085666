import React from 'react';

import Icon from './Icon';

interface CardWalletIconProps {
  color?: string;
  size?: string;
}

const CardWalletIcon: React.FC<CardWalletIconProps> = ({
  color = 'black',
  size = '24px',
}) => {
  return (
    <Icon size={size} color={color} viewBox="0 0 40 40" fill="none">
      <path
        d="M11.6667 11.6667V6C11.6667 5.73478 11.772 5.48043 11.9596 5.29289C12.1471 5.10536 12.4014 5 12.6667 5H27.3333C27.4647 5 27.5947 5.02587 27.716 5.07612C27.8373 5.12638 27.9476 5.20003 28.0404 5.29289C28.1333 5.38575 28.207 5.49599 28.2572 5.61732C28.3075 5.73864 28.3333 5.86868 28.3333 6V11.6667M16.6667 5V11.6667M20 5V11.6667M31.6667 33.3333H8.33333C7.44928 33.3333 6.60143 32.9821 5.97631 32.357C5.35119 31.7319 5 30.8841 5 30V15C5 14.1159 5.35119 13.2681 5.97631 12.643C6.60143 12.0179 7.44928 11.6667 8.33333 11.6667H31.6667C32.5507 11.6667 33.3986 12.0179 34.0237 12.643C34.6488 13.2681 35 14.1159 35 15V30C35 30.8841 34.6488 31.7319 34.0237 32.357C33.3986 32.9821 32.5507 33.3333 31.6667 33.3333Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M27.5001 23.3333C27.2791 23.3333 27.0671 23.2455 26.9108 23.0893C26.7545 22.933 26.6667 22.721 26.6667 22.5C26.6667 22.279 26.7545 22.067 26.9108 21.9107C27.0671 21.7545 27.2791 21.6667 27.5001 21.6667C27.7211 21.6667 27.9331 21.7545 28.0893 21.9107C28.2456 22.067 28.3334 22.279 28.3334 22.5C28.3334 22.721 28.2456 22.933 28.0893 23.0893C27.9331 23.2455 27.7211 23.3333 27.5001 23.3333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

CardWalletIcon.displayName = 'CardWalletIcon';

export default CardWalletIcon;
