import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import {
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  ADDITIONAL_INFORMATION_TYPES,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { intl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.claim}additionalInformation/entities/token/${COMMON_QUERY_PARAMS.TOKEN}`;

enum TARGET_TYPE {
  CLAIM = 'Claim',
  FILE = 'File',
}

interface AdditionalInformationEntitySummary {
  id: string;
  uniqueName: string;
  informationType: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  referenceType: string;
  targetType: TARGET_TYPE;
  sellerId: string;
}

export interface LocalizedAdditionalInformation {
  additionalInformationEntity: AdditionalInformationEntitySummary;
  label: string;
  id: string;
}

interface GetAdditionalInformationEntitiesSummariesAndTokenCommandArgs {
  ids: string[];
  countryCode?: COUNTRY_CODES | string;
  token: string;
}

export async function getAdditionalInformationEntitiesSummariesAndTokenCommand({
  ids,
  countryCode = intl?.locale,
  token,
}: GetAdditionalInformationEntitiesSummariesAndTokenCommandArgs): Promise<
  BackResponse<LocalizedAdditionalInformation[]> | BackResponseFailure
> {
  try {
    return apiCall<BackResponse<LocalizedAdditionalInformation[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.TOKEN, token),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          ids,
          countryCode,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
