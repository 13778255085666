import React from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { StepsOrchestrator } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

import useGetOwnerSummary from './hooks/useGetOwnerSummary';
import {
  CREATE_CLAIM_CONFIG,
  CREATE_CLAIM_PAGE_NAME,
} from './MyAccountLoginPageNewDesign.steps';
import { useCreateClaimContent } from './MyAccountLoginPageNewDesignContent.hooks';

const MyAccountLoginPageNewDesignContent = () => {
  const { clientInformation, isLoading } = useGetClientInformation();

  const { ownerSummary, ownerSummaryIsLoading } = useGetOwnerSummary();

  const { handleSubmit } = useCreateClaimContent();
  const hasSellerId =
    clientInformation?.sellerId || clientInformation?.tenantId;

  if (isLoading || ownerSummaryIsLoading || !hasSellerId) {
    return <BaseLoader />;
  }

  return (
    <StepsOrchestrator<CreateClaimValues, CreateClaimContext>
      initialContext={{
        hasInvoice: undefined,
      }}
      initialStep={CREATE_CLAIM_PAGE_NAME.CHOICE_INVOICE}
      initialValues={{
        sellerId: clientInformation?.sellerId || clientInformation?.tenantId,
        sellerName: clientInformation?.clientName,
        sellerType: clientInformation?.sellerType,
        tenantId: clientInformation?.tenantId,
        activatedSellerPartners: clientInformation?.activatedSellerPartners,
        hasPartner: !!clientInformation?.activatedSellerPartners?.length,
        orderLoginConfiguration: clientInformation.orderLoginConfiguration,
        enableGenericProduct: clientInformation.enableGenericProduct,
        owner: ownerSummary,
      }}
      config={CREATE_CLAIM_CONFIG}
      withWizard={false}
      onSubmit={(values, context) => handleSubmit({ values, context })}
    />
  );
};

MyAccountLoginPageNewDesignContent.displayName = 'CreateClaimContent';

export default MyAccountLoginPageNewDesignContent;
