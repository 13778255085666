import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { SellerService } from 'authenticator/api';

import messages from '../messages';
import adaptOnboardingActivities from '../OnboardingActivities.adpaters';
import { OnboardingActivitiesSubmitValues } from '../OnboardingActivities.types';

const useSavePersonalizedDemoContextCommand = () => {
  const params = useParams<{ emailId: string }>();
  const history = useHistory();

  const { mutateAsync: handleSubmitSalesLead, isLoading } = useMutation(
    [],
    async (data: OnboardingActivitiesSubmitValues) => {
      const adaptedOnboardingActivities = adaptOnboardingActivities({
        activities: data,
      });

      const response = await SellerService.savePersonalizedDemoContextCommand({
        email: params.emailId,
        personalizationKeys: adaptedOnboardingActivities,
      });

      if (response.failure) {
        toast.error(messages.saveContextError);

        return undefined;
      }

      history.push(`/onboarding/${params.emailId}/confirmation`);

      return undefined;
    },
  );

  return { handleSubmitSalesLead, isLoading };
};

export default useSavePersonalizedDemoContextCommand;
