import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const DishwasherIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 32 32" fill="none">
    <g id="Group">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66667 2.66675C3.22464 2.66675 2.80072 2.84234 2.48816 3.1549C2.17559 3.46746 2 3.89139 2 4.33341V25.6667C2 26.1088 2.17559 26.5327 2.48816 26.8453C2.80072 27.1578 3.22464 27.3334 3.66667 27.3334H22.3333C22.7754 27.3334 23.1993 27.1578 23.5118 26.8453C23.8244 26.5327 24 26.1088 24 25.6667V4.33341C24 3.89139 23.8244 3.46746 23.5118 3.1549C23.1993 2.84234 22.7754 2.66675 22.3333 2.66675H3.66667ZM1.07394 1.74069C1.76158 1.05306 2.69421 0.666748 3.66667 0.666748H22.3333C23.3058 0.666748 24.2384 1.05306 24.9261 1.74069C25.6137 2.42832 26 3.36095 26 4.33341V25.6667C26 26.6392 25.6137 27.5718 24.9261 28.2595C24.2384 28.9471 23.3058 29.3334 22.3333 29.3334H3.66667C2.69421 29.3334 1.76157 28.9471 1.07394 28.2595C0.386308 27.5718 0 26.6392 0 25.6667V4.33341C0 3.36095 0.386308 2.42832 1.07394 1.74069ZM21.686 4.92548C22.0947 5.29699 22.1248 5.92943 21.7533 6.33809L21.7399 6.35276C21.3684 6.76141 20.736 6.79153 20.3273 6.42002C19.9187 6.04851 19.8886 5.41606 20.2601 5.00741L20.2734 4.99274C20.6449 4.58408 21.2774 4.55397 21.686 4.92548Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63604 9.96954C8.32387 8.28171 10.6131 7.3335 13 7.3335C15.3869 7.3335 17.6761 8.28171 19.364 9.96954C21.0518 11.6574 22 13.9465 22 16.3335C22 18.7204 21.0518 21.0096 19.364 22.6975C17.6761 24.3853 15.3869 25.3335 13 25.3335C10.6131 25.3335 8.32387 24.3853 6.63604 22.6975C4.94821 21.0096 4 18.7204 4 16.3335C4 13.9465 4.94821 11.6574 6.63604 9.96954ZM13 9.3335C11.1435 9.3335 9.36301 10.071 8.05025 11.3837C6.7375 12.6965 6 14.477 6 16.3335C6 18.19 6.7375 19.9705 8.05025 21.2832C9.36301 22.596 11.1435 23.3335 13 23.3335C14.8565 23.3335 16.637 22.596 17.9497 21.2832C19.2625 19.9705 20 18.19 20 16.3335C20 14.477 19.2625 12.6965 17.9497 11.3837C16.637 10.071 14.8565 9.3335 13 9.3335Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15.3335C9.55228 15.3335 10 15.7812 10 16.3335C10 17.1291 10.3161 17.8922 10.8787 18.4548C11.4413 19.0174 12.2044 19.3335 13 19.3335C13.5523 19.3335 14 19.7812 14 20.3335C14 20.8858 13.5523 21.3335 13 21.3335C11.6739 21.3335 10.4021 20.8067 9.46447 19.869C8.52678 18.9313 8 17.6596 8 16.3335C8 15.7812 8.44772 15.3335 9 15.3335Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

DishwasherIcon.displayName = 'DishwasherIcon';

export default DishwasherIcon;
