import React, { FunctionComponent, Ref } from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { $OptionName } from '../../../Autocomplete.styles';
import {
  $EmailAutcompleteButton,
  $EmailAutcompleteItem,
} from '../../EmailAutocomplete.styles';

import { $InlineCol } from './EmailAutocompleteResult.styles';

interface EmailAutocompleteResultProps {
  emailDomain: string;
  onSelect: (email: string) => void;
  isSelected: boolean;
  dataTestId?: string;
  value: string;
}
interface EmailAutocompleteResultPropsWithRef
  extends EmailAutocompleteResultProps {
  forwardedRef: Ref<HTMLButtonElement>;
}

const EmailAutocompleteResult: FunctionComponent<EmailAutocompleteResultPropsWithRef> =
  ({ emailDomain, onSelect, isSelected, forwardedRef, dataTestId, value }) => {
    const indexOfAt = value.indexOf('@');
    const emailSliced = value.slice(indexOfAt);
    const emailDomainAutocompleted = emailDomain.replace(emailSliced, '');

    return (
      <$EmailAutcompleteItem>
        <$EmailAutcompleteButton
          title={`${value}${emailDomainAutocompleted}`}
          type={BUTTON_TYPES.BUTTON}
          onClick={() => onSelect(`${value}${emailDomainAutocompleted}`)}
          $isSelected={isSelected}
          tabIndex={-1}
          ref={forwardedRef}
          data-testid={dataTestId}
        >
          <Row middle="xs">
            <$InlineCol>
              <$OptionName>{value}</$OptionName>
              <div>{emailDomainAutocompleted}</div>
            </$InlineCol>
          </Row>
        </$EmailAutcompleteButton>
      </$EmailAutcompleteItem>
    );
  };

EmailAutocompleteResult.displayName = 'EmailAutocompleteResult';

export default React.forwardRef<
  HTMLButtonElement,
  EmailAutocompleteResultProps
>((props, ref) => <EmailAutocompleteResult forwardedRef={ref} {...props} />);
