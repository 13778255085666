import React from 'react';

interface FramePictureIconProps {
  color?: string;
}

 
const FramePictureIcon: React.FC<FramePictureIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <g clipPath="url(#clip0_2073_4389)">
        <path
          d="M39.9061 12.2435C39.9061 12.167 39.9061 12.0835 39.8922 12.0139C39.8574 11.7913 39.76 11.5896 39.6 11.4365C39.44 11.2765 39.2452 11.1722 39.0226 11.1443C38.9461 11.1304 38.8626 11.1304 38.807 11.1304H35.0922L28.56 5.0713C28.6991 4.67478 28.7687 4.25739 28.7687 3.82609C28.7687 1.71826 27.0504 0 24.9426 0C22.8348 0 21.1165 1.71826 21.1165 3.82609C21.1165 4.25043 21.1861 4.66783 21.3252 5.0713L14.793 11.1304H11.113C11.0365 11.1304 10.953 11.1304 10.8835 11.1443C10.6609 11.1791 10.4591 11.2765 10.3061 11.4365C10.1461 11.5965 10.0417 11.7913 10.0139 12.0139C10 12.0904 10 12.1739 10 12.2296V46.8939C10 46.9704 10 47.0539 10.0139 47.1235C10.0487 47.3461 10.1461 47.5478 10.3061 47.7009C10.4661 47.8609 10.6609 47.9652 10.8765 47.993C10.953 48.007 11.0296 48.007 11.0991 48.007H38.793C38.793 48.007 38.8348 48.007 38.8626 48.007C38.9183 48.007 38.9739 48.007 39.0296 47.993C39.2522 47.9583 39.4539 47.8609 39.607 47.7009C39.767 47.5409 39.8713 47.3461 39.8991 47.1235C39.913 47.047 39.913 46.9635 39.913 46.9078V12.2435H39.9061ZM17.8678 11.1304L22.5357 6.79652C23.8991 7.90261 26 7.90261 27.3635 6.79652L32.0313 11.1304H17.8678ZM24.9496 5.56522C23.9896 5.56522 23.2104 4.78609 23.2104 3.82609C23.2104 2.86609 23.9896 2.08696 24.9496 2.08696C25.9096 2.08696 26.6887 2.86609 26.6887 3.82609C26.6887 4.78609 25.9096 5.56522 24.9496 5.56522ZM37.8191 14.6922V44.4383L32.9496 39.5687V19.5617L37.8191 14.6922ZM18.4243 41.0435H31.4748L36.3443 45.913H13.5548L18.4243 41.0435ZM19.0365 38.9565V20.1739H30.8626V38.9565H19.0365ZM18.4243 18.087L13.5548 13.2174H36.3443L31.4748 18.087H18.4243ZM16.9496 19.5617V39.5687L12.08 44.4383V14.6922L16.9496 19.5617Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

FramePictureIcon.displayName = 'FramePictureIcon';

export default FramePictureIcon;
