import md5 from 'md5';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';

import { AuthConfiguration } from '../../../configuration';

import { $BannerImageContainer } from './BannerImage.styles';

const BannerImage: FunctionComponent = () => {
  const imgRef = useRef<HTMLImageElement>(null);

  const hashedClientId = useMemo(() => md5(AuthConfiguration.clientId), []);

  const onError = useCallback(() => {
    if (imgRef && imgRef.current) {
      imgRef.current.style.display = 'none';
    }
  }, []);

  return (
    <$BannerImageContainer>
      <img
        ref={imgRef}
        alt="login-banners"
        src={`${APIConfiguration.uiStylesCDN}login-banners/${hashedClientId}`}
        onError={onError}
      />
    </$BannerImageContainer>
  );
};

BannerImage.displayName = 'BannerImage';

export default BannerImage;
