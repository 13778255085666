import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { SellerService } from 'authenticator/api';
import { useQuery } from 'react-query';
import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

interface UseSellerSelfcareConfigurationArgs {
  sellerId?: string;
}

const GetSellerSelfcareConfigurationQuery =
  'getSellerSelfcareConfigurationQuery';

const useSellerSelfcareConfiguration = ({
  sellerId,
}: UseSellerSelfcareConfigurationArgs) => {
  const { data: sellerConfiguration, isLoading: sellerConfigurationIsLoading } =
    useQuery(
      [GetSellerSelfcareConfigurationQuery, { sellerId }],
      async () => {
        if (!sellerId) {
          return undefined;
        }

        const response =
          await SellerService.getSellerSelfcareConfigurationQuery({
            sellerId,
            cultureCode: COUNTRY_CODES.FR,
          });

        if (response.failure) {
          logError(
            `An error is occured to get the seller configuration ${response.errors}`,
          );

          return undefined;
        }

        return response.value;
      },
      {
        staleTime: Infinity,
      },
    );

  return {
    sellerConfiguration,
    sellerConfigurationIsLoading,
  };
};

export default useSellerSelfcareConfiguration;
