import { size } from 'lodash';
import React, { ReactElement } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { MultiBanners } from '@savgroup-front-common/core/src/atoms/Banners';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { getUniqueBuiltErrorNotifications } from '@savgroup-front-common/core/src/helpers';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import CustomerAddressForm from './CustomerAddressForm';
import { $ActionsContainer } from './CustomerInformation.styles';
import { PartialCustomerInformationValues } from './CustomerInformation.types';
import useCustomerInformation from './hooks/useCustomerInformation';
import messages from './messages';
import SuggestedAddressModal from './SuggestedAddressModal';

function CustomerInformation(): ReactElement {
  const { previousStep } = useStepOrchestratorContext<
    PartialCustomerInformationValues,
    any
  >();
  const theme = useTheme();

  const {
    isSuggestedModalOpen,
    backErrors,
    formContext,
    isSubmitLoading,
    suggestedAddress,
    isSuggestedAddressLoading,
    handleAddressManagementSubmit,
    onClose,
    handleCustomerInformationSubmit,
  } = useCustomerInformation();
  const { handleSubmit, formState } = formContext;
  const { isSubmitting, isValidating, isValid, errors } = formState;

  const notifications = getUniqueBuiltErrorNotifications({
    errors: backErrors,
    extendedMessages: messages,
  });

  return (
    <>
      <Heading level={2} color={theme.colors.black}>
        <SafeFormattedMessageWithoutSpread
          message={messages.customerInformationTitle}
        />
      </Heading>

      <MultiBanners isFluid notifications={notifications} />

      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(handleAddressManagementSubmit)}>
          <CustomerAddressForm />

          <SuggestedAddressModal
            isOpen={isSuggestedModalOpen}
            suggestedAddress={suggestedAddress}
            onClose={onClose}
            onSubmit={() => {
              return handleSubmit(handleCustomerInformationSubmit)();
            }}
            isLoading={isSuggestedAddressLoading || isSubmitLoading}
            addressError={!suggestedAddress}
          />

          <MultiBanners notifications={notifications} />
          <$ActionsContainer>
            <FormGroup>
              <Button
                onClick={() => previousStep()}
                naked
                icon={<ChevronLeftIcon />}
                componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
                type={BUTTON_TYPES.BUTTON}
              >
                <SafeFormattedMessageWithoutSpread message={messages.back} />
              </Button>
            </FormGroup>
            <FormGroup>
              <Button
                type={BUTTON_TYPES.SUBMIT}
                isLoading={
                  (isSubmitting && !isValidating && isValid) ||
                  isSuggestedAddressLoading ||
                  isSubmitLoading
                }
                isError={size(errors) > 0}
                primary
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.connectButton}
                />
              </Button>
            </FormGroup>
          </$ActionsContainer>
        </form>
      </FormProvider>
    </>
  );
}

CustomerInformation.displayName = 'CustomerInformation';

export default CustomerInformation;
