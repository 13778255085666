import { useCallback } from 'react';

import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { OwnerService } from 'authenticator/api';
import { useClientInformationContext } from 'authenticator/view/atoms';

import prepareAuthenticateWithProductReferencePayload from '../adapters/prepareAuthenticateWithProductReferencePayload';
import { ProductReferenceLoginValues } from '../ProductReferenceLogin.types';

const useHandleSubmitProductReferenceLogin = (): ((
  data: ProductReferenceLoginValues,
) => Promise<void>) => {
  const { pushErrors } = useBanners();

  const { clientInformation } = useClientInformationContext();
  const tenantId = clientInformation?.tenantId;

  const handleLogin = async (data: ProductReferenceLoginValues) => {
    const payload = prepareAuthenticateWithProductReferencePayload({
      tenantId,
      data,
    });

    const response = await OwnerService.createOrderAndShareLink(payload);

    if (response.failure) {
      pushErrors(response.errors);
    } else {
      window.location = response.value;
    }
  };

  return useCallback(handleLogin, [pushErrors, tenantId]);
};

export default useHandleSubmitProductReferenceLogin;
