import { defineMessages } from 'react-intl';

export default defineMessages({
  reliable: {
    id: 'RequestReliability.Reliable',
    defaultMessage: 'Reliable',
  },
  toCheck: {
    id: 'RequestReliability.ToCheck',
    defaultMessage: 'ToCheck',
  },
  toReference: {
    id: 'RequestReliability.ToReference',
    defaultMessage: 'ToReference',
  },
});
