import { TransitionStatus } from 'react-transition-group/Transition';

import { ANIMATION_STATE } from '@savgroup-front-common/constants/src/shared';

export const isAnimationEntering = (
  animationState: TransitionStatus,
): boolean =>
  [ANIMATION_STATE.ENTERED, ANIMATION_STATE.ENTERING].includes(animationState);

export const isAnimationExiting = (animationState: TransitionStatus): boolean =>
  [ANIMATION_STATE.EXITING, ANIMATION_STATE.EXITED].includes(animationState);

export const isAnimationUnmounted = (
  animationState: TransitionStatus,
): boolean =>
  [ANIMATION_STATE.UNMOUNTED, ANIMATION_STATE.EXITED].includes(animationState);

export const isAnimationMounted = (animationState: TransitionStatus): boolean =>
  [
    ANIMATION_STATE.ENTERED,
    ANIMATION_STATE.ENTERING,
    ANIMATION_STATE.EXITING,
  ].includes(animationState);

export const isAnimationTransitioning = (
  animationState: TransitionStatus,
): boolean =>
  [ANIMATION_STATE.EXITING, ANIMATION_STATE.ENTERING].includes(animationState);

export const isAnimationStoped = (animationState: TransitionStatus): boolean =>
  [ANIMATION_STATE.ENTERED, ANIMATION_STATE.EXITED].includes(animationState);
