import { darken } from 'polished';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { rem } from '../../../helpers';

export default {
  [SUPPORTED_PROPERTIES.COLOR]: {
    fallback: 'colors.white',
  },
  [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
    fallback: 'colors.primary',
  },
  [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
    value: darken(0.075, '#205DF3'),
  },
  [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
    fallback: 'colors.disabled',
  },
  [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
    fallback: 'colors.disabled',
  },
  [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
    value: 'inherit',
  },
  [SUPPORTED_PROPERTIES.PADDING]: {
    value: `${rem(14)} ${rem(21)}`,
  },
  [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
    value: 500,
  },
};
