import {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useReducer,
} from 'react';

import { EVENT_KEY } from '@savgroup-front-common/constants';

import {
  emailAutocompleteInit,
  emailAutocompleteReducer,
} from '../EmailAutocomplete.reducers';
import { EMAIL_AUTOCOMPLETE_ACTION_TYPES } from '../EmailAutocomplete.types';
import getPossibleEmailDomains from '../helpers/getPossibleEmailDomains';

interface UseEmailAutocompleteArgs {
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const useEmailAutocomplete = ({
  value,
  onChange,
}: UseEmailAutocompleteArgs) => {
  const [{ selectedIndex, emailDomains, isOpen }, dispatch] = useReducer(
    emailAutocompleteReducer,
    {},
    emailAutocompleteInit,
  );

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const indexOfAt = value.indexOf('@');

    if (indexOfAt > 0) {
      const possibleEmailDomains = getPossibleEmailDomains(value);

      dispatch({
        type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CHANGE_TEXT_INPUT,
        payload: { emailDomains: possibleEmailDomains },
      });
    }
    if (onChange) {
      onChange(event);
    }
  };

  const handleSelect = (email: string) => {
    dispatch({
      type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.ON_SELECT,
    });
    if (onChange) {
      onChange({
        target: { value: email },
        currentTarget: { value: email },
      } as any);
    }
  };

  const shoudlClear = value.length === 0;

  useEffect(() => {
    if (shoudlClear) {
      dispatch({ type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLEAR_INPUT });
    }
  }, [shoudlClear]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === EVENT_KEY.ARROW_DOWN ||
      event.key === EVENT_KEY.ARROW_UP
    ) {
      dispatch({
        type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.ARROW_NAVIGATION,
        payload: {
          key: event.key,
        },
      });
    }

    if (
      event.key === EVENT_KEY.ENTER &&
      isOpen &&
      typeof selectedIndex === 'number'
    ) {
      if (emailDomains.length === 0) {
        handleSelect(value);

        return;
      }
      const indexOfAt = value.indexOf('@');
      const emailSliced = value.slice(indexOfAt);
      const emailDomainAutocompleted =
        emailDomains[selectedIndex] &&
        emailDomains[selectedIndex].replace(emailSliced, '');

      handleSelect(`${value}${emailDomainAutocompleted}`);
      event.preventDefault();
    }
  };

  const handleOpen = useCallback(() => {
    dispatch({ type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.OPEN_DROPDOWN });
  }, []);
  const handleClose = useCallback(() => {
    dispatch({ type: EMAIL_AUTOCOMPLETE_ACTION_TYPES.CLOSE_DROPDOWN });
  }, []);

  return {
    handleKeyDown,
    handleChange,
    handleSelect,
    selectedIndex,
    emailDomains,
    isOpen: isOpen && value.length > 0 && emailDomains.length > 0,
    handleOpen,
    handleClose,
  };
};

export default useEmailAutocomplete;
