import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  OwnerSummary,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}anonymous/import/orders`;

interface CreateOrderPayload {
  tenantId: string;
  sellerId: string;
  orderReference: string;
  owner: Partial<OwnerSummary>;
}

async function createAnonymousOrder({
  tenantId,
  owner,
  sellerId,
  orderReference,
}: CreateOrderPayload): Promise<BackResponse<Location> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<Location>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        tenantId,
        owner,
        sellerId,
        orderReference,
      },
    });

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createAnonymousOrder;
