import React from 'react';

interface ReadingLampIconProps {
  color?: string;
}

 
const ReadingLampIcon: React.FC<ReadingLampIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M16.4995 42H31.4995C32.4995 42 32.9995 42.5 32.9995 43.5C32.9995 44.5 32.4995 45 31.4995 45H16.4995C15.4995 45 14.9995 44.5 14.9995 43.5C14.9995 42.5 15.4995 42 16.4995 42ZM14.4056 6L9.74055 27H38.2585L33.5935 6H14.4056ZM13.2056 3H34.7965C35.138 3.00013 35.4691 3.11675 35.7353 3.33057C36.0015 3.54439 36.1868 3.84263 36.2605 4.176L41.5945 28.176C41.643 28.3952 41.6417 28.6224 41.5907 28.841C41.5397 29.0595 41.4403 29.2639 41.2998 29.4389C41.1593 29.614 40.9813 29.7552 40.7789 29.8523C40.5766 29.9495 40.355 29.9999 40.1305 30H7.86855C7.6441 29.9999 7.42253 29.9495 7.22018 29.8523C7.01782 29.7552 6.83983 29.614 6.69934 29.4389C6.55885 29.2639 6.45943 29.0595 6.40841 28.841C6.35739 28.6224 6.35607 28.3952 6.40455 28.176L11.7386 4.176C11.8123 3.84263 11.9976 3.54439 12.2638 3.33057C12.5299 3.11675 12.8611 3.00013 13.2026 3H13.2056Z"
        fill="currentColor"
      />
      <path
        d="M31.5 26.9991C32.5 26.9991 33 27.4991 33 28.4991V34.4991C33 35.4991 32.5 35.9991 31.5 35.9991C30.5 35.9991 30 35.4991 30 34.4991V28.4991C30 27.4991 30.5 26.9991 31.5 26.9991ZM22.5 26.9961H25.5V44.9991H22.5V26.9961Z"
        fill="currentColor"
      />
    </svg>
  );
};

ReadingLampIcon.displayName = 'ReadingLampIcon';

export default ReadingLampIcon;
