import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import {
  ADDITIONAL_INFORMATION_TYPES,
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { intl, prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.claim}additionalInformation/entities`;

enum TARGET_TYPE {
  CLAIM = 'Claim',
  FILE = 'File',
}

interface AdditionalInformationEntitySummary {
  id: string;
  uniqueName: string;
  informationType: ADDITIONAL_INFORMATION_TYPES;
  uniquenessByProduct: boolean;
  referenceType: string;
  targetType: TARGET_TYPE;
  sellerId: string;
}

export interface LocalizedAdditionalInformation {
  additionalInformationEntity: AdditionalInformationEntitySummary;
  label: string;
  id: string;
}

interface GetAdditionalInformationEntitiesSummariesArgs {
  ids: string[];
  countryCode?: COUNTRY_CODES | string;
}

async function getAdditionalInformationEntitiesSummaries({
  ids,
  countryCode = intl?.locale,
}: GetAdditionalInformationEntitiesSummariesArgs): Promise<
  BackResponse<LocalizedAdditionalInformation[]> | BackResponseFailure
> {
  try {
    return apiCall<BackResponse<LocalizedAdditionalInformation[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        ids,
        countryCode,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getAdditionalInformationEntitiesSummaries;
