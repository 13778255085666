import { defineMessages } from 'react-intl';

export default defineMessages({
  nextButton: {
    id: 'global.stepsOrchestrator.nextButton',
    defaultMessage: 'Next',
  },
  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  submit: {
    id: 'global.stepsOrchestrator.submitButton',
    defaultMessage: 'Submit',
  },
});
