 
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import GoogleConfigDEFAULT from './google.config.DEFAULT';
import GoogleConfigDEMO from './google.config.DEMO';
import GoogleConfigDEV from './google.config.DEV';
import GoogleConfigLOCAL from './google.config.LOCAL';
import GoogleConfigPROD from './google.config.PROD';

const getCurrentGoogleConfigs = () => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return GoogleConfigDEMO;
    case APP_ENVS.DEV:
      return GoogleConfigDEV;
    case APP_ENVS.LOCAL:
      return GoogleConfigLOCAL;
    case APP_ENVS.PROD:
      return GoogleConfigPROD;
    case APP_ENVS.DEFAULT:
    default:
      return GoogleConfigDEFAULT;
  }
};

export default getCurrentGoogleConfigs();
