import { useCallback } from 'react';

import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { BackError } from '@savgroup-front-common/types';
import { OwnerService } from 'authenticator/api';
import { useClientInformationContext } from 'authenticator/view/atoms';

import {
  ORDER_NUMBER_FORM_FIELDS,
  OrderNumberLoginFormValues,
} from '../OrderNumberLogin.types';

interface UseLogMeInArgs {
  setBackErrors: (errors: BackError[]) => void;
}

const useLogMeIn = ({ setBackErrors }: UseLogMeInArgs) => {
  const { clientInformation } = useClientInformationContext();

  const tenantId = clientInformation?.tenantId;

  const handleLogin = async (data: OrderNumberLoginFormValues) => {
    setBackErrors([]);

    try {
      const response = await OwnerService.createShareLinkByOrderReference({
        phoneNumberLastFourDigits: data[ORDER_NUMBER_FORM_FIELDS.PHONE_NUMBER],
        orderReference: data[ORDER_NUMBER_FORM_FIELDS.ORDER_NUMBER],
        tenantId,
      });

      if (!response.failure) {
        window.location = response.value;
      } else {
        setBackErrors(response.errors);
      }
    } catch (e) {
      if (e instanceof GotAnErrorFromEndpoint) {
        setBackErrors(e.errors as BackError[]);
      } else {
        throw e;
      }
    }
  };

  return useCallback(handleLogin, [setBackErrors, tenantId]);
};

export default useLogMeIn;
