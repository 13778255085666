import { UniverseModelType } from 'authenticator/types';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const SELLER_ID = 'SELLER_ID';
const UNIVERSE_ID = 'UNIVERSE_ID';

const ENDPOINT = `${APIConfiguration.catalog}modeltypes/generic?SellerId=${SELLER_ID}&UniverseId=${UNIVERSE_ID}`;

interface GetModelTypeGenericArgs {
  sellerId: string;
  universeId: string;
}

const getModelTypeGeneric = async ({
  sellerId,
  universeId,
}: GetModelTypeGenericArgs): Promise<
  BackResponse<UniverseModelType[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(SELLER_ID, sellerId).replace(
      UNIVERSE_ID,
      universeId,
    );

    const response = await apiCall<BackResponse<UniverseModelType[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getModelTypeGeneric;
