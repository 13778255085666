import { OwnerSummary } from '@savgroup-front-common/types';

export enum ADDRESS_SUGGESTION_ORIGIN {
  USER_VALUES = 'userValues',
  SUGGESTION = 'suggestion',
}

export enum ADDRESS_SUGGESTION_FIELDS {
  ORIGIN = 'origin',
}
export interface AddressSuggestionFormValues {
  [ADDRESS_SUGGESTION_FIELDS.ORIGIN]: ADDRESS_SUGGESTION_ORIGIN;
}

export interface AddressSuggestionStepValues {
  owner: OwnerSummary;
  suggestedOwner: OwnerSummary;
}
export interface AddressSuggestionStepContext {
  hasSuggestedAddress: boolean;
  unmatchingFields: string[];
}
