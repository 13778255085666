import React from 'react';

interface UmbrellaIconProps {
  color?: string;
}

 
const UmbrellaIcon: React.FC<UmbrellaIconProps> = ({ color = 'black' }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <g clipPath="url(#clip0_457_751)">
        <path
          d="M32.9634 6.73833C29.5017 3.46833 24.8967 1.66667 20.0001 1.66667C15.1034 1.66667 10.5001 3.46667 7.03675 6.73833C3.57341 10.01 1.66675 14.36 1.66675 18.985C1.66675 19.545 2.14841 20 2.74008 20C3.33341 20 3.81508 19.545 3.81508 18.985C3.81508 17.2967 5.26841 15.9233 7.05508 15.9233C10.2101 15.9233 9.05175 20 11.3717 20C13.6884 20 12.5317 15.9233 15.6851 15.9233C18.8401 15.9233 20.0001 20 20.0001 20C20.0001 20 21.1617 15.9233 24.3151 15.9233C27.4684 15.9233 26.6467 20 28.6301 20C30.6134 20 29.7901 15.9233 32.9451 15.9233C34.7317 15.9233 36.1851 17.2967 36.1851 18.985C36.1851 19.545 36.6668 20 37.2601 20C37.8518 20 38.3334 19.545 38.3334 18.985C38.3334 14.36 36.4267 10.01 32.9634 6.73833V6.73833Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M20 20V33.3333C20 40 10 40 10 33.3333"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_457_751">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

UmbrellaIcon.displayName = 'UmbrellaIcon';

export default UmbrellaIcon;
