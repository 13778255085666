import styled, { css } from 'styled-components';

import { rem } from '../../helpers';
import { StateAnimationProps } from '../../animations/stateAnimation';
import { transitionRightOrLeft } from '../../animations/complexeStateAnimation';

interface $StepsOrchestratorAnimationProps extends StateAnimationProps {
  $parentPadding: number;
}

export const $StepsOrchestratorAnimation = styled.div<$StepsOrchestratorAnimationProps>`
  ${transitionRightOrLeft()}
  padding-left: ${({ $parentPadding }) => rem($parentPadding)};
  padding-right: ${({ $parentPadding }) => rem($parentPadding)};
`;

const hideScrollBar = css`
  overflow: -moz-scrollbars-none;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

interface $StepsOrchestratorContainerProps {
  $isAnimating: boolean;
  $parentPadding: number;
}

export const $StepsOrchestratorContainer = styled.div<$StepsOrchestratorContainerProps>`
  ${({ $isAnimating }) => ($isAnimating ? hideScrollBar : null)};
  position: relative;
  margin-left: -${({ $parentPadding }) => rem($parentPadding)};
  margin-right: -${({ $parentPadding }) => rem($parentPadding)};
`;
