import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';

import { buildUrl } from '../../helpers';

const ENDPOINT = 'https://api-adresse.data.gouv.fr/search';

export enum GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES {
  HOUSE_NUMBER = 'housenumber',
  STREET = 'street',
  LOCALITY = 'locality',
  MUNICIPALITY = 'municipality',
}

interface GeocodeAddressFromDataGouvArgs {
  query: string;
  type?: GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES;
}

interface GeocodeAddressFromDataGouvGeometry {
  type: string;
  coordinates: [number, number];
}

interface GeocodeAddressFromDataGouvFeatureProperties {
  label: string;
  score: number;
  housenumber: string;
  id: string;
  name: string;
  postcode: string;
  citycode: string;
  x: number;
  y: number;
  city: string;
  district: string;
  context: string;
  type: GEOCODE_ADDRESS_FROM_DATA_GOUV_TYPES;
  importance: string;
  street: string;
}

interface GeocodeAddressFromDataGouvFeature {
  type: string;
  geometry: GeocodeAddressFromDataGouvGeometry;
  properties: GeocodeAddressFromDataGouvFeatureProperties;
}

export interface GeocodeAddressFromDataGouvResponse {
  type: string;
  version: string;
  features: GeocodeAddressFromDataGouvFeature[];
  attribution: string;
  licence: string;
  query: string;
  limit: number;
}

export const geoCodeAddressFromDataGouv = async ({
  query,
  type,
}: GeocodeAddressFromDataGouvArgs): Promise<GeocodeAddressFromDataGouvResponse> => {
  const requestHeader = new Headers({
    Accept: 'application/json',
  });

  const url = buildUrl(ENDPOINT, {
    q: query,
    type,
  });

  const response = await fetch(url, {
    method: SUPPORTED_METHODS.GET,
    headers: requestHeader,
  });

  return response.json();
};
