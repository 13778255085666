import { TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { isAnimationEntering } from '../../helpers/animation';
import { rem } from '../../helpers/theme';
import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import { slideTopToBottom } from '../../animations/stateAnimation';

interface ContainerPros {
  $height: number;
  animationState: TransitionStatus;
}

export const $AddAndRemoveContainer = styled.li<ContainerPros>`
  height: ${({ $height, animationState }) =>
    isAnimationEntering(animationState) ? rem($height + 14) : 0};
  padding-bottom: ${({ animationState }) =>
    isAnimationEntering(animationState) ? '1.5rem' : 0};
  ${decelerateTimingFunctionTransition};
  transition: 0.3s;
`;

export const $AddAndRemoveSizer = styled.div`
  ${slideTopToBottom};
`;

export const AddAndRemoveList = styled(TransitionGroup)`
  list-style: none;
  margin: 0;
  padding: 0;
`;
