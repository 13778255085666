import styled from 'styled-components';

export const $NetworkLinks = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  & > * {
    margin-right: 1.5rem;
  }
  flex-wrap: wrap;
`;
