import React, { FunctionComponent } from 'react';

import {
  OnSubmit,
  StepsOrchestrator,
} from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { OwnerSummary } from '@savgroup-front-common/types';

import {
  OWNER_INFORMATIONS_STEP_NAMES,
  OWNER_INFORMATIONS_STEPS_CONFIG,
} from './OwnerInformations.steps';
import {
  OwnerInformationsContext,
  OwnerInformationsValues,
} from './OwnerInformations.types';

interface CustomerInformationsContentProps {
  onSubmit: OnSubmit<OwnerInformationsValues, OwnerInformationsContext>;
  owner?: OwnerSummary;
  onPreviousStep?: () => void;
}

const CustomerInformationsContent: FunctionComponent<
  React.PropsWithChildren<CustomerInformationsContentProps>
> = ({ onSubmit, owner, onPreviousStep }) => {
  return (
    <StepsOrchestrator<OwnerInformationsValues, OwnerInformationsContext>
      config={OWNER_INFORMATIONS_STEPS_CONFIG}
      initialStep={OWNER_INFORMATIONS_STEP_NAMES.ADDRESS_INFORMATIONS}
      onSubmit={onSubmit}
      initialContext={{ onPreviousStep }}
      initialValues={{ owner }}
      withWizard={false}
    />
  );
};

CustomerInformationsContent.displayName = 'CustomerInformationsContent';

export default CustomerInformationsContent;
