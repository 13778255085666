import { ActorService } from 'authenticator/api';
import { RawSalesChannelFromBack } from 'authenticator/api/Actor/getSalesChannelsBySellerId';
import { useEffect, useState } from 'react';

import { sortBy } from '@savgroup-front-common/core/src/helpers';

import adaptSalesChannelToOption from '../adapters/adaptSalesChannelToOption';
import { SalesChannelOption } from '../ComplementaryInformation.types';

interface UseGetSalesChannelOptionsArgs {
  sellerId?: string;
}

interface UseGetSalesChannelOptionsReturnValue {
  salesChannelOptions: SalesChannelOption[];
  isLoading: boolean;
}

const useGetSalesChannelOptions = ({
  sellerId,
}: UseGetSalesChannelOptionsArgs): UseGetSalesChannelOptionsReturnValue => {
  const [salesChannels, setSalesChannels] = useState<RawSalesChannelFromBack[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const callApi = async () => {
      setIsLoading(true);

      const response = await ActorService.getSalesChannelsBySellerId({
        sellerId,
      });

      setSalesChannels(response);
      setIsLoading(false);
    };

    callApi();
  }, [sellerId]);

  return {
    salesChannelOptions: salesChannels
      .sort(sortBy({ fieldName: 'name' }))
      .reduce(adaptSalesChannelToOption, []),
    isLoading,
  };
};

export default useGetSalesChannelOptions;
