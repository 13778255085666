import React from 'react';

interface VeganCircleIconProps {
  color?: string;
}

 
const VeganCircleIcon: React.FC<VeganCircleIconProps> = ({
  color = 'black',
}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M24.1667 19.1667C21.25 22.3033 18.3333 30 18.3333 30C18.3333 30 14.1667 19.1667 10 16.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0249 12.8833L30.5199 18.0167C30.8399 21.3467 28.3432 24.3117 25.0132 24.6333C21.7466 24.9467 18.7866 22.5583 18.4716 19.2917C18.3966 18.5147 18.4754 17.7305 18.7036 16.984C18.9317 16.2374 19.3047 15.5432 19.8012 14.9408C20.2977 14.3384 20.908 13.8398 21.5973 13.4733C22.2865 13.1069 23.0412 12.8798 23.8182 12.805L29.2916 12.2783C29.469 12.2616 29.6458 12.3159 29.7832 12.4293C29.9207 12.5427 30.0076 12.706 30.0249 12.8833Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 36.6667C29.2049 36.6667 36.6666 29.205 36.6666 20C36.6666 10.795 29.2049 3.33333 19.9999 3.33333C10.7949 3.33333 3.33325 10.795 3.33325 20C3.33325 29.205 10.7949 36.6667 19.9999 36.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

VeganCircleIcon.displayName = 'VeganCircleIcon';

export default VeganCircleIcon;
