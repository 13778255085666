import { css, keyframes } from 'styled-components';

import { getAnimationDelay, getAnimationDuration } from '../helper';
import { decelerateTimingFunctionAnimation } from '../timingFunction/animation';

import { SimpleAnimationProps } from './types';

const steps = [
  css`
    transform: scale(1.4);
  `,
  css`
    transform: scale(0.8);
  `,
];
const steady = css`
  transform: scale(1);
`;

const enteringKeyframes = keyframes`
  0% { ${steady} }
  60% { ${steps[0]} }
  85% { ${steps[1]} }
  100% { ${steady} }
`;

export default css<SimpleAnimationProps>`
  ${steady};
  animation-name: ${enteringKeyframes};
  animation-duration: ${getAnimationDuration};
  animation-delay: ${getAnimationDelay};
  animation-fill-mode: forwards;
  ${decelerateTimingFunctionAnimation};
`;
