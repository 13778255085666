import React, { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { RadioButtonGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  BulbIcon,
  QuestionIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { StepLayout } from '../../../../atoms';
import { CustomerInformationsFooter } from '../../components';

import {
  ADDRESS_SUGGESTION_FIELDS,
  ADDRESS_SUGGESTION_ORIGIN,
} from './AddressSuggestion.types';
import { SelectAddressRadio } from './components';
import {
  useAddressSuggestionForm,
  useAddressSuggestionStepContext,
  useAddressSuggestionSubmit,
} from './hooks';
import messages from './messages';

const AddressSuggestionContent: FunctionComponent = () => {
  const formContext = useAddressSuggestionForm();
  const { values, previousStep } = useAddressSuggestionStepContext();
  const { handleAddressSuggestionSubmit, isLoading } =
    useAddressSuggestionSubmit();
  const { owner, suggestedOwner } = values;
  const theme = useTheme();

  const {
    formState: { errors },
    handleSubmit,
  } = formContext;

  return (
    <FormProvider {...formContext}>
      <BannersConsumer />
      <form onSubmit={handleSubmit(handleAddressSuggestionSubmit)}>
        <RadioButtonGroup
          errors={errors}
          label={messages.addressGroupLabel}
          name={ADDRESS_SUGGESTION_FIELDS.ORIGIN}
        >
          <StepLayout>
            <SelectAddressRadio
              value={ADDRESS_SUGGESTION_ORIGIN.SUGGESTION}
              owner={suggestedOwner}
              icon={<BulbIcon color={theme.colors.primary} />}
              label={messages.suggestion}
            />
            <SelectAddressRadio
              value={ADDRESS_SUGGESTION_ORIGIN.USER_VALUES}
              owner={owner}
              icon={<QuestionIcon color={theme.colors.primary} />}
              label={messages.userValues}
            />
          </StepLayout>
        </RadioButtonGroup>
        <CustomerInformationsFooter
          dataTestId="CustomerInformations-suggestion-submit"
          isLoading={isLoading}
          isError={Object.keys(errors).length > 0}
          onPreviousStepClick={() =>
            previousStep(
              {},
              { hasSuggestedAddress: false, unmatchingFields: [] },
            )
          }
        />
      </form>
    </FormProvider>
  );
};

AddressSuggestionContent.displayName = 'AddressSuggestionContent';

export default AddressSuggestionContent;
