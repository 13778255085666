import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

import { buildUrl } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention/timeSlots`;

export interface TimeslotFromBack {
  start: string;
  finish: string;
}

interface ConfirmPaymentIntentCommandResponse extends BaseBackResponse {
  value: TimeslotFromBack[];
}

interface GetTechnicalInterventionTimeSlotsPayload {
  fileId: FileId;
  startDate?: string;
  endDate?: string;
}

/**
 * @deprecated use getTechnicalInterventionTimeSlots instead
 * @param fileId
 * @param startDate
 * @param endDate
 */
async function oldGetTechnicalInterventionTimeSlots({
  fileId,
  startDate,
  endDate,
}: GetTechnicalInterventionTimeSlotsPayload): Promise<TimeslotFromBack[]> {
  const functionName = 'oldGetTechnicalInterventionTimeSlots';

  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided('fileId', functionName);
  }

  const url = buildUrl(ENDPOINT, {
    fileId,
    startDate,
    endDate,
  });

  const response: ConfirmPaymentIntentCommandResponse = await apiCall(url, {
    method: SUPPORTED_METHODS.GET,
  });

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(
      `Got an exception trying to ${functionName}`,
      response.errors,
    );
  }

  return response.value;
}

export default oldGetTechnicalInterventionTimeSlots;
