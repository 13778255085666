import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneNumberIsRequired: {
    id: 'view.MyAccountLoginPage.schema.phoneNumberIsRequired',
    defaultMessage: 'Phone number is required',
  },
  orderNumberIsRequired: {
    id: 'view.MyAccountLoginPage.schema.orderNumberIsRequired',
    defaultMessage: 'Order number is required',
  },
  digitsOnlyAreAuthorized: {
    id: 'view.MyAccountLoginPage.schema.digitsOnlyAreAuthorized',
    defaultMessage: 'Please fill phone number 4 last digits',
  },
  connectButton: {
    id: 'view.MyAccountLoginPage.connectButton',
    defaultMessage: 'Connect',
  },
  orderNumber: {
    id: 'view.MyAccountLoginPage.orderNumber.label',
    defaultMessage: 'Order number',
  },
  phoneNumber: {
    id: 'view.MyAccountLoginPage.phoneNumber.label',
    defaultMessage: 'Phone number',
  },
  phoneNumberPlaceholder: {
    id: 'view.MyAccountLoginPage.phoneNumber.placeholder',
    defaultMessage: 'Fill phone number 4 last digits',
  },
  finalizeClaimTitle: {
    id: 'view.finalizeClaim.title',
    defaultMessage: 'Finalize claim',
  },
  logoutInProgress: {
    id: 'view.loginPage.logoutInProgress',
    defaultMessage: 'Logout in progress ...',
  },
  'Reverse.Common.Errors.Authentication.Unauthorized': {
    id: 'view.MyAccountLoginPage.invalidCredentials',
    defaultMessage: 'Invalid credentials',
  },
});
