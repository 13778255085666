import { defineMessages } from 'react-intl';

export default defineMessages({
  needHelpModalTitle: {
    id: 'components.needHelp.modal.title',
    defaultMessage: 'Contact us',
  },
  customerSupportPhoneNumber: {
    id: 'components.needHelp.customerSupportPhoneNumber',
    defaultMessage: '03 5930 0000',
  },
  customerSupportPhoneNumberLabel: {
    id: 'components.needHelp.customerSupportPhoneNumber.label',
    defaultMessage: 'You can join our customer support on this number',
  },
});
