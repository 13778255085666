import React, { FunctionComponent } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { Notification } from '@savgroup-front-common/types';

import BannerBaseWithAnimation from './BannerBaseWithAnimation';

interface MultiNotificationsProps {
  animationDuration?: number;
  notifications: Notification[];
  isFluid?: boolean;
}

const MultiBanners: FunctionComponent<MultiNotificationsProps> = ({
  animationDuration = 300,
  notifications = [],
  isFluid = false,
}) => {
  return (
    <TransitionGroup component={null}>
      {notifications.map(
        ({
          id,
          children,
          notificationType,
          options,
          onClose,
          isFluid: innerIsFluid = isFluid,
        }) => (
          <Transition timeout={animationDuration} key={id}>
            {(animationState) => (
              <BannerBaseWithAnimation
                isFluid={innerIsFluid}
                dataTestId={id}
                animationState={animationState}
                animationDuration={animationDuration}
                hollow={options?.hollow}
                onClose={onClose}
                notificationType={notificationType}
              >
                {children}
              </BannerBaseWithAnimation>
            )}
          </Transition>
        ),
      )}
    </TransitionGroup>
  );
};

MultiBanners.displayName = 'MultiNotifications';

export default MultiBanners;
