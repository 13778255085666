import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Autocomplete } from '@savgroup-front-common/core/src/atoms/Form';
import { FieldMessage } from '@savgroup-front-common/core/src/atoms/Form/common';
import { FIELD_STATUS } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import { countryList } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { InputHookForm } from '@savgroup-front-common/core/src/molecules/Form';

import AgreementCheckbox from './AgreementCheckbox/AgreementCheckbox';
import useGetJobTitleOptions from './hooks/useGetJobTitleOptions';
import useOnboardingFormContext from './hooks/useOnboardingFormContext';
import messages from './messages';
import { $Form, $InputRow, $SubmitButton } from './OnboardingForm.styles';

const OnboardingForm = ({
  handleUserEmail,
}: {
  handleUserEmail: (userEmail: string) => void;
}) => {
  const { handleFormSubmit, formContext, isLoading } = useOnboardingFormContext(
    { handleUserEmail },
  );
  const jobTitleOptions = useGetJobTitleOptions();
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = formContext;
  const countries = useSelector(countryList);

  return (
    <$Form onSubmit={handleSubmit(handleFormSubmit)}>
      <$InputRow>
        <InputHookForm
          name="email"
          errors={errors}
          label={messages.email}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="lastName"
          errors={errors}
          label={messages.lastName}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="firstName"
          errors={errors}
          label={messages.firstName}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="companyName"
          errors={errors}
          label={messages.companyName}
          isRequired
          register={register}
        />
      </$InputRow>
      <$InputRow>
        <Controller
          control={control}
          name="country"
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              errors={errors}
              label={messages.country}
              options={countries}
              onChange={field.onChange}
              value={field.value}
              isRequired
            />
          )}
        />
      </$InputRow>
      <$InputRow>
        <Controller
          control={control}
          name="jobTitle"
          render={({ field }) => (
            <Autocomplete
              name={field.name}
              errors={errors}
              label={messages.jobTitle}
              options={jobTitleOptions}
              onChange={field.onChange}
              value={field.value}
              isRequired
            />
          )}
        />
      </$InputRow>
      <$InputRow>
        <InputHookForm
          name="phone"
          errors={errors}
          label={messages.phone}
          register={register}
          isRequired
        />
      </$InputRow>
      <$InputRow>
        <Controller
          control={control}
          name="cgv"
          render={({ field }) => {
            return (
              <AgreementCheckbox
                isChecked={field.value}
                onChange={field.onChange}
                isError={!!errors.cgv?.message}
                isNewUi
              />
            );
          }}
        />
        <FieldMessage
          message={errors.cgv?.message}
          status={FIELD_STATUS.ERROR}
        />
      </$InputRow>
      <$SubmitButton
        type={BUTTON_TYPES.SUBMIT}
        fluid
        primary
        isNewUi
        isLoading={isLoading}
        isError={!!Object.keys(errors).length}
      >
        <SafeFormattedMessageWithoutSpread message={messages.trialStart} />
      </$SubmitButton>
    </$Form>
  );
};

OnboardingForm.displayName = 'OnboardingForm';

export default OnboardingForm;
