import { Phone } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from './Icon.types';

const PhoneIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <Phone width={size} height={size} color={color} />;

PhoneIcon.displayName = 'PhoneIcon';

export default PhoneIcon;
