import React, { FunctionComponent } from 'react';

import { $CustomGrid } from './Grid.styles';

const Grid: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <$CustomGrid>{children}</$CustomGrid>;
};

Grid.displayName = 'Grid';

export default Grid;
