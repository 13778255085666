import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

const MultiValue: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const { data, innerProps } = props;

  return (
    <components.MultiValue
      {...props}
      innerProps={{ ...innerProps, 'data-value': data?.value }}
    />
  );
};

MultiValue.displayName = 'MultiValue';

export default MultiValue;
