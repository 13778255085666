import styled, { css } from 'styled-components';

import { rem } from '../../../../helpers';

const mixin = css`
  display: flex;
  align-items: center;
`;

export const FieldLoaderStyled = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: ${rem(10)};
  ${mixin}
`;

export const SelectFieldLoaderStyled = styled.div`
  ${mixin}
`;
