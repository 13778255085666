import styled from 'styled-components';

import { POSITIONS } from '@savgroup-front-common/constants';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';
import { rem } from '@savgroup-front-common/core/src/helpers';
import { getTextColorBasedOnProps } from '@savgroup-front-common/core/src/theme/helpers';
import { DefaultStyledProps } from '@savgroup-front-common/types';

import {
  colorBasedOnProps,
  colorHoverBasedOnProps,
} from './RadioButton.helpers';

interface RadioInputProps extends DefaultStyledProps {
  danger?: boolean;
  isHighlighted: boolean;
}
export const $RadioInput = styled.input<RadioInputProps>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: ${rem(22)};
  height: ${rem(22)};
  opacity: 0;

  & {
    ~ div > span {
      transition: color 100ms ease-in-out, border-color 100ms ease-in-out;

      border-color: ${(props) => {
        const { theme, isHighlighted } = props;

        if (isHighlighted) {
          return theme.colors.primary;
        }

        return null;
      }};
      color: ${(props) => {
        const { theme, isHighlighted } = props;

        if (isHighlighted) {
          return theme.colors.primary;
        }

        return null;
      }};
    }
  }

  &:hover,
  &:focus {
    ~ div > span {
      border-color: ${(props) =>
        props.disabled || props.danger ? null : colorHoverBasedOnProps(props)};
    }
  }

  &:checked {
    ~ div > span {
      &::before {
        transform: scale(1);
        transform-origin: 50% 50%;
        background-color: ${(props) => colorHoverBasedOnProps(props)};
      }
    }
  }
`;

interface RadioSpanProps extends DefaultStyledProps {
  disabled?: boolean;
  $isChecked?: boolean;
  $labelPosition?: POSITIONS;
}
export const $RadioSpan = styled.span<RadioSpanProps>`
  position: relative;
  display: block;
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: 50%;
  border-width: 1px;
  border-color: ${(props) =>
    props.$isChecked ? props.theme.colors.primary : colorBasedOnProps(props)};
  border-style: solid;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition-duration: 0.2s;
  ${decelerateTimingFunctionTransition};
  background: ${(props) =>
    props.$isChecked ? props.theme.colors.primary : 'transparent'};
`;

interface LabelProps extends DefaultStyledProps {
  $isFluid?: boolean;
  $isChecked?: boolean;
  $labelPosition?: POSITIONS;
  disabled?: boolean;
}
export const $RadioLabel = styled.label<LabelProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  line-height: 22px;
  width: ${({ $isFluid }) => ($isFluid ? '100%' : 'fit-content')};
  min-height: ${rem('18px')};
  padding: ${rem('15px')};
  border: ${rem(1)} solid
    ${(props) =>
      props.$isChecked ? props.theme.colors.primary : colorBasedOnProps(props)};
  border-radius: ${rem('8px')};

  margin: 0 0 ${rem('12px')} 0;
  color: ${(props) => getTextColorBasedOnProps(props)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin: 0;
  }
  &:hover,
  &:focus {
    border-color: ${(props) =>
      props.disabled ? null : colorHoverBasedOnProps(props)};
  }
`;

export const $RadioSpanContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${rem(20)};
  height: ${rem(20)};
`;
