import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '../../services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';

const ENDPOINT = `${APIConfiguration.workflow}salesforce/context`;

interface CreateContextIfNotExistCommandPayload {
  fileId: FileId;
}

async function createContextIfNotExistCommand({
  fileId,
}: CreateContextIfNotExistCommandPayload): Promise<BaseBackResponse> {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        fileId,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createContextIfNotExistCommand;
