import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { CheckIcon } from '../../../../protons/icons';

import {
  $StepProgressCircle,
  $StepProgressItem,
  $StepProgressLabel,
  $StepProgressSeparator,
  $StepProgressWrapper,
} from './WizardNewUi.styles';

interface Step {
  name: string;
  message: string | MessageType;
}

const WizardNewUi = ({
  orderedStep,
  activeStepName,
}: {
  orderedStep: Step[];
  activeStepName: string;
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();
  let isAfterActive = false;

  return (
    <$StepProgressWrapper>
      {orderedStep.map((step, index) => {
        const isActive = activeStepName === step.name;

        isAfterActive = isActive ? true : isAfterActive;

        return (
          <React.Fragment key={step.name}>
            <$StepProgressItem>
              <$StepProgressCircle $active={isActive || !isAfterActive}>
                {isAfterActive && index + 1}
                {!isAfterActive && <CheckIcon color={theme.colors.white} />}
              </$StepProgressCircle>
              {!isMobileView && (
                <$StepProgressLabel $active={isActive}>
                  <SafeFormattedMessageWithoutSpread message={step.message} />
                </$StepProgressLabel>
              )}
            </$StepProgressItem>
            <$StepProgressSeparator $isAfterActive={isAfterActive} />
          </React.Fragment>
        );
      })}
    </$StepProgressWrapper>
  );
};

WizardNewUi.displayName = 'WizardNewUi';

export default WizardNewUi;
