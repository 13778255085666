import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const WatchIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 19 30" fill="none">
    <g id="Group">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 2.66675C6.22464 2.66675 5.80072 2.84234 5.48816 3.1549C5.1756 3.46746 5 3.89139 5 4.33341V9.03741C5 9.5897 4.55228 10.0374 4 10.0374C3.44772 10.0374 3 9.5897 3 9.03741V4.33341C3 3.36095 3.38631 2.42832 4.07394 1.74069C4.76158 1.05306 5.69421 0.666748 6.66667 0.666748H12C12.9725 0.666748 13.9051 1.05306 14.5927 1.74069C15.2804 2.42832 15.6667 3.36095 15.6667 4.33341V9.03741C15.6667 9.5897 15.219 10.0374 14.6667 10.0374C14.1144 10.0374 13.6667 9.5897 13.6667 9.03741V4.33341C13.6667 3.89139 13.4911 3.46746 13.1785 3.1549C12.866 2.84234 12.442 2.66675 12 2.66675H6.66667ZM4 19.9627C4.55228 19.9627 5 20.4105 5 20.9627V25.6667C5 26.1088 5.17559 26.5327 5.48816 26.8453C5.80072 27.1578 6.22464 27.3334 6.66667 27.3334H12C12.442 27.3334 12.8659 27.1578 13.1785 26.8453C13.4911 26.5327 13.6667 26.1088 13.6667 25.6667V20.9627C13.6667 20.4105 14.1144 19.9627 14.6667 19.9627C15.219 19.9627 15.6667 20.4105 15.6667 20.9627V25.6667C15.6667 26.6392 15.2804 27.5718 14.5927 28.2595C13.9051 28.9471 12.9725 29.3334 12 29.3334H6.66667C5.69421 29.3334 4.76158 28.9471 4.07394 28.2595C3.38631 27.5718 3 26.6392 3 25.6667V20.9627C3 20.4105 3.44772 19.9627 4 19.9627Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96929 8.63604C4.65712 6.94821 6.9463 6 9.33325 6C11.7202 6 14.0094 6.94821 15.6972 8.63604C17.385 10.3239 18.3333 12.6131 18.3333 15C18.3333 17.3869 17.385 19.6761 15.6972 21.364C14.0094 23.0518 11.7202 24 9.33325 24C6.9463 24 4.65712 23.0518 2.96929 21.364C1.28146 19.6761 0.333252 17.3869 0.333252 15C0.333252 12.6131 1.28146 10.3239 2.96929 8.63604ZM9.33325 8C7.47674 8 5.69626 8.7375 4.3835 10.0503C3.07075 11.363 2.33325 13.1435 2.33325 15C2.33325 16.8565 3.07075 18.637 4.3835 19.9497C5.69626 21.2625 7.47674 22 9.33325 22C11.1898 22 12.9702 21.2625 14.283 19.9497C15.5958 18.637 16.3333 16.8565 16.3333 15C16.3333 13.1435 15.5958 11.363 14.283 10.0503C12.9702 8.7375 11.1898 8 9.33325 8Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33325 11.3335C9.88554 11.3335 10.3333 11.7812 10.3333 12.3335V14.0002H11.9999C12.5522 14.0002 12.9999 14.4479 12.9999 15.0002C12.9999 15.5524 12.5522 16.0002 11.9999 16.0002H9.33325C8.78097 16.0002 8.33325 15.5524 8.33325 15.0002V12.3335C8.33325 11.7812 8.78097 11.3335 9.33325 11.3335Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

WatchIcon.displayName = 'WatchIcon';

export default WatchIcon;
