import { CIVILITY, COUNTRY_CODES } from '@savgroup-front-common/constants';
import { AutocompleteOptionForHookForm } from '@savgroup-front-common/types';

export enum CUSTOMER_INFORMATION_FIELDS {
  CIVILITY = 'civility',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  COMPANY_NAME = 'companyName',
  COUNTRY_CODE = 'countryCode',
  ADDRESS = 'address',
  CITY = 'city',
  ADDITIONAL_ADDRESS = 'additionalAddress',
  POSTAL_CODE = 'postalCode',
  KEEP_SUGGESTED_ADDRESS = 'keepSuggestedAddress',
}

export interface CustomerInformationFormValues {
  [CUSTOMER_INFORMATION_FIELDS.CIVILITY]?: CIVILITY;
  [CUSTOMER_INFORMATION_FIELDS.FIRST_NAME]: string;
  [CUSTOMER_INFORMATION_FIELDS.LAST_NAME]: string;
  [CUSTOMER_INFORMATION_FIELDS.EMAIL]: string;
  [CUSTOMER_INFORMATION_FIELDS.PHONE]?: string;
  [CUSTOMER_INFORMATION_FIELDS.COMPANY_NAME]?: string;
  [CUSTOMER_INFORMATION_FIELDS.COUNTRY_CODE]: AutocompleteOptionForHookForm;
  [CUSTOMER_INFORMATION_FIELDS.ADDRESS]: string;
  [CUSTOMER_INFORMATION_FIELDS.CITY]: string;
  [CUSTOMER_INFORMATION_FIELDS.ADDITIONAL_ADDRESS]?: string;
  [CUSTOMER_INFORMATION_FIELDS.POSTAL_CODE]: string;
  [CUSTOMER_INFORMATION_FIELDS.KEEP_SUGGESTED_ADDRESS]: string;
}

interface CustomerInformationStepValues {
  civility: CIVILITY;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  countryCode: COUNTRY_CODES;
  address: string;
  city: string;
  additionalAddress: string;
  postalCode: string;
}

export interface PartialCustomerInformationValues {
  customerInformation: {
    civility?: CIVILITY;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    companyName?: string;
    countryCode?: COUNTRY_CODES;
    address?: string;
    city?: string;
    additionalAddress?: string;
    postalCode?: string;
  };
}
export interface CustomerInformationValues {
  customerInformation: CustomerInformationStepValues;
}
