import React, { FunctionComponent } from 'react';
import { components } from 'react-select';

const Option: FunctionComponent<React.PropsWithChildren<any>> = (props) => {
  const { data, innerProps } = props;

  if (data?.isHidden) {
    return null;
  }

  return (
    <components.Option
      {...props}
      innerProps={{
        ...innerProps,
        'data-option': true,
        'data-option-label': data?.label,
        'data-option-value': data?.value,
      }}
    />
  );
};

Option.displayName = 'Option';

export default Option;
