import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const BykeIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 32 32" fill="none">
    <g id="Bike">
      <path
        id="Vector"
        d="M18.6667 9.33333C20.1395 9.33333 21.3333 8.13943 21.3333 6.66667C21.3333 5.19391 20.1395 4 18.6667 4C17.1939 4 16 5.19391 16 6.66667C16 8.13943 17.1939 9.33333 18.6667 9.33333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M24 28C26.2092 28 28 26.2092 28 24C28 21.7908 26.2092 20 24 20C21.7908 20 20 21.7908 20 24C20 26.2092 21.7908 28 24 28Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M8 28C10.2091 28 12 26.2092 12 24C12 21.7908 10.2091 20 8 20C5.79087 20 4 21.7908 4 24C4 26.2092 5.79087 28 8 28Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M15.3334 24.0001L17.3334 18.6667L10.8236 16.0001L14.8239 11.3335L18.8239 14.6669H23.4906"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);

BykeIcon.displayName = 'BykeIcon';

export default BykeIcon;
