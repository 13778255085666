import React, { FunctionComponent } from 'react';

import { $ActionRow } from './ActionRow.styles';

interface ActionRowProps {
  withoutMarginBottom?: boolean;
  withoutPadding?: boolean;
}

const ActionRow: FunctionComponent<React.PropsWithChildren<ActionRowProps>> = ({
  children,
  withoutMarginBottom = false,
  withoutPadding = false,
}) => {
  return (
    <$ActionRow
      $withoutMarginBottom={withoutMarginBottom}
      $withoutPadding={withoutPadding}
    >
      {children}
    </$ActionRow>
  );
};

ActionRow.displayName = 'ActionRow';

export default ActionRow;
