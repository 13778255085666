import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers/theme';

export const $OrderInformationsFooter = styled.footer`
  padding-top: 1.5rem;
`;
export const $OrderRefDidacticImg = styled.img`
  display: block;
  max-width: ${rem(330)};
  margin-bottom: 0.5rem;
`;
