import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { v4 } from 'uuid';

import {
  COUNTRY_CODES,
  EMPTY_UUID,
} from '@savgroup-front-common/constants/src';
import { ErrorFromBack } from '@savgroup-front-common/core/src/helpers';
import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { OwnerSummary } from '@savgroup-front-common/types';
import { useClientInformationContext } from 'authenticator/view/atoms';

import { AddressInformationsFormValues } from '../AddressInformations.types';

import useAddressInformationStepContext from './useAddressInformationStepContext';
import useHandleSuggestion from './useHandleSuggestion';

const getUnmatchingFields = (
  address1: OwnerSummary,
  address2: OwnerSummary,
): string[] => {
  const returnValue = [];

  if (
    address1.ownerAddress.address.toUpperCase() !==
    address2.ownerAddress.address.toUpperCase()
  ) {
    returnValue.push('address');
  }
  if (
    (address1.ownerAddress.additionalAddress?.toUpperCase() || '') !==
    (address2.ownerAddress.additionalAddress?.toUpperCase() || '')
  ) {
    returnValue.push('additionalAddress');
  }
  if (
    address1.ownerAddress.city.toUpperCase() !==
    address2.ownerAddress.city.toUpperCase()
  ) {
    returnValue.push('city');
  }
  if (
    address1.countryCode.toUpperCase() !== address2.countryCode.toUpperCase()
  ) {
    returnValue.push('countryCode');
  }

  if (
    address1.ownerAddress.postalCode.toUpperCase() !==
    address2.ownerAddress.postalCode.toUpperCase()
  ) {
    returnValue.push('postalCode');
  }

  return returnValue;
};

const useHandleAddressInformationsSubmit = () => {
  const {
    nextStep,
    onSubmit,
    values: { owner },
    context: { onPreviousStep },
  } = useAddressInformationStepContext();
  const {
    clientInformation: { tenantId },
  } = useClientInformationContext();

  const { handleSuggestion } = useHandleSuggestion();
  const [isLoading, setIsLoading] = useState(false);

  const { pushErrors } = useBanners();

  const handleAddressInformationsSubmit: SubmitHandler<
    AddressInformationsFormValues
  > = async ({
    civility,
    additionalAddress,
    address,
    city,
    companyName,
    countryCode,
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    email,
  }) => {
    setIsLoading(true);

    const customerInformations: OwnerSummary = {
      ...owner,
      id: owner?.id && owner.id !== EMPTY_UUID ? owner.id : v4(),
      civility,
      ownerAddress: {
        address,
        additionalAddress,
        city,
        civility,
        firstname: firstName,
        lastname: lastName,
        countryCode: countryCode.value as COUNTRY_CODES,
        postalCode,
        phone: phoneNumber,
        companyName,
      },
      email,
      countryCode: countryCode.value as COUNTRY_CODES,
      firstName,
      lastName,
      phone: phoneNumber,
      addresses: [
        {
          address,
          additionalAddress,
          city,
          civility,
          firstname: firstName,
          lastname: lastName,
          countryCode: countryCode.value as COUNTRY_CODES,
          postalCode,
          phone: phoneNumber,
          companyName,
        },
      ],
      tenantId,
    };

    const suggestedCustomerInformations = await handleSuggestion(
      customerInformations,
    );

    if (!suggestedCustomerInformations) {
      setIsLoading(false);

      return;
    }
    const unmatchingFields = getUnmatchingFields(
      customerInformations,
      suggestedCustomerInformations,
    );

    if (unmatchingFields.length === 0) {
      try {
        await onSubmit(
          {
            owner: customerInformations,
          },
          { hasSuggestedAddress: false, unmatchingFields },
        );
      } catch (errors) {
        pushErrors(errors as ErrorFromBack[]);
        setIsLoading(false);
      }

      return;
    }

    nextStep(
      {
        owner: customerInformations,
        suggestedOwner: suggestedCustomerInformations,
      },
      { hasSuggestedAddress: true, unmatchingFields },
    );
  };

  return {
    handleAddressInformationsSubmit,
    isLoading,
    onPreviousStep,
  };
};

export default useHandleAddressInformationsSubmit;
