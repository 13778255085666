import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { CIVILITY, COUNTRY_CODES } from '@savgroup-front-common/constants';

import AddressInformationsSchema from '../AddressInformations.schema';
import {
  AddressInformationsFormValues,
  ADDRESS_INFORMATIONS_FIELDS as FIELDS,
} from '../AddressInformations.types';

import useAddressInformationStepContext from './useAddressInformationStepContext';

const useAddressInformationForm = () => {
  const { values } = useAddressInformationStepContext();

  const formContext = useForm<AddressInformationsFormValues>({
    resolver: yupResolver(AddressInformationsSchema),
    defaultValues: {
      [FIELDS.CIVILITY]: values?.owner?.civility || CIVILITY.NOT_SET,
      [FIELDS.ADDITIONAL_ADDRESS]:
        values?.owner?.ownerAddress.additionalAddress || '',
      [FIELDS.ADDRESS]: values?.owner?.ownerAddress.address || '',
      [FIELDS.CITY]: values?.owner?.ownerAddress.city || '',
      [FIELDS.COMPANY_NAME]: values?.owner?.ownerAddress.companyName || '',
      [FIELDS.COUNTRY_CODE]: values?.owner?.ownerAddress.countryCode
        ? { value: values?.owner?.ownerAddress.countryCode }
        : { value: COUNTRY_CODES.FR },
      [FIELDS.FIRST_NAME]: values?.owner?.firstName || '',
      [FIELDS.LAST_NAME]: values?.owner?.lastName || '',
      [FIELDS.PHONE_NUMBER]: values?.owner?.phone || '',
      [FIELDS.POSTAL_CODE]: values?.owner?.ownerAddress.postalCode || '',
      [FIELDS.EMAIL]: values?.owner?.email || '',
    },
  });

  return formContext;
};

export default useAddressInformationForm;
