import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { BaseBackResponse } from '@savgroup-front-common/types';

import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.carrier}homePickup/create/${COMMON_QUERY_PARAMS.FILE_ID}`;

interface CreateHomePickupCommandPayload {
  fileId: string;
  appointmentTime: {
    startTimeInLocalRecipientTimezone: string;
    endTimeInLocalRecipientTimezone: string;
  };
}

async function createHomePickupCommand({
  fileId,
  appointmentTime,
}: CreateHomePickupCommandPayload): Promise<BaseBackResponse> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, fileId);

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        startTimeInLocalRecipientTimezone:
          appointmentTime?.startTimeInLocalRecipientTimezone,
        endTimeInLocalRecipientTimezone:
          appointmentTime?.endTimeInLocalRecipientTimezone,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default createHomePickupCommand;
