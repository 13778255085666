import { defineMessages } from 'react-intl';

export default defineMessages({
  logoutInProgress: {
    id: 'view.loginPage.logoutInProgress',
    defaultMessage: 'Logout in progress ...',
  },
  invalidLoginRequest: {
    id: 'view.MyAccountLoginPage.invalidLoginRequest',
    defaultMessage: 'There are no login schemes configured for this client.',
  },
});
