import { css } from 'styled-components';

import { buildStateAnimation } from '../helper';

const enter = css`
  opacity: 1;
  transform: scaleY(1);
`;

const steady = css`
  opacity: 1;
  transform: scaleY(0.6);
`;

const exit = css`
  opacity: 1;
  transform: scaleY(0.02);
`;

const shrinkHeight = buildStateAnimation({ enter, steady, exit });

export default shrinkHeight;
