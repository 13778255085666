import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

import { Notification } from '@savgroup-front-common/types';

import ToastsProviderContext from './ToastsProvider.context';
import {
  toastsProviderInit,
  toastsProviderReducer,
} from './ToastsProvider.reducers';
import {
  NotificationId,
  TOASTS_PROVIDER_ACTION_TYPES,
} from './ToastsProvider.types';

const ToastsProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(
    toastsProviderReducer,
    null,
    toastsProviderInit,
  );

  const pushNotifications = useCallback(
    (notifications: Notification[]): NotificationId[] => {
      dispatch({
        type: TOASTS_PROVIDER_ACTION_TYPES.ADD_TOASTS,
        payload: { notifications },
      });

      return notifications.map(({ id }) => id);
    },
    [],
  );
  const removeNotification = useCallback((notificationId: NotificationId) => {
    dispatch({
      type: TOASTS_PROVIDER_ACTION_TYPES.CLOSE_TOAST,
      payload: { notificationId },
    });
  }, []);
  const removeAllNotifications = useCallback(() => {
    dispatch({
      type: TOASTS_PROVIDER_ACTION_TYPES.CLOSE_ALL_TOASTS,
    });
  }, []);

  const value = useMemo(
    () => ({
      pushNotifications,
      removeNotification,
      removeAllNotifications,
      notifications: state.notifications,
    }),
    [
      pushNotifications,
      removeAllNotifications,
      removeNotification,
      state.notifications,
    ],
  );

  return (
    <ToastsProviderContext.Provider value={value}>
      {children}
    </ToastsProviderContext.Provider>
  );
};

ToastsProvider.displayName = 'ToastsProvider';

export default ToastsProvider;
