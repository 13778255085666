import md5 from 'md5';
import React, { FunctionComponent, useCallback, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Tooltip } from '../../atoms/Tooltip';
import { safeFormattedIntlString } from '../../formatters';
import { QuestionIcon } from '../../protons/icons';

import messages from './messages';
import { $NeedHelpButton, $NeedHelpContainer } from './NeedHelp.styles';
import { NEED_HELP_POSITION_TYPES } from './NeedHelp.types';
import NeedHelpModalContent from './NeedHelpModalContent';
import { Modal, MODAL_SIZES } from '../../atoms/modal';

const AUTHORIZED_CLIENT_IDS_ENCRYPTED_MD5 = [
  'd8e1e2333e963c0f5794cd2b157c0ac6',
  'bba8341afb6918f34f5051ab9de1a701',
  '5a21a6e93c1a4a9b74805a0c6843b0c4',
];

interface NeedHelpProps {
  position?: NEED_HELP_POSITION_TYPES;
  clientId: string;
}

const NeedHelp: FunctionComponent<NeedHelpProps> = ({
  position = NEED_HELP_POSITION_TYPES.BOTTOM,
  clientId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (
    !AUTHORIZED_CLIENT_IDS_ENCRYPTED_MD5.includes(md5(clientId.toLowerCase()))
  ) {
    return <></>;
  }

  return (
    <$NeedHelpContainer $position={position}>
      <Tooltip
        title={safeFormattedIntlString(messages.needHelpTooltipTitle)}
        position="top"
      >
        <$NeedHelpButton
          type={BUTTON_TYPES.BUTTON}
          onClick={() => setIsOpen(true)}
        >
          <QuestionIcon />
        </$NeedHelpButton>
      </Tooltip>

      <Modal size={MODAL_SIZES.SMALL} onClose={handleClose} isOpen={isOpen}>
        <NeedHelpModalContent />
      </Modal>
    </$NeedHelpContainer>
  );
};

NeedHelp.displayName = 'NeedHelp';

export default NeedHelp;
