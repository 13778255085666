import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.actor}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/salesChannels`;

interface GetSalesChannelsBySellerIdPayload {
  sellerId?: string;
}

export interface RawSalesChannelFromBack {
  id: string;
  name: string;
  salesChannelType: string;
}

interface GetSalesChannelsBySellerIdResponse extends BaseBackResponse {
  value: RawSalesChannelFromBack[];
}

async function getSalesChannelsBySellerId({
  sellerId,
}: GetSalesChannelsBySellerIdPayload): Promise<RawSalesChannelFromBack[]> {
  const functionName = 'getSalesChannelsBySellerId';

  if (!sellerId) {
    throw new RequiredPayloadFieldIsNotProvided('sellerId', `${functionName}`);
  }

  const response = await apiCall<GetSalesChannelsBySellerIdResponse>(
    ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(functionName, response.errors);
  }

  return response.value;
}

export default getSalesChannelsBySellerId;
