import React, { FunctionComponent, useContext } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { NOTIFICATION_TYPES } from '@savgroup-front-common/constants';

import { toast } from '../../../atoms/Toast';

import ToastsServiceContext from './ToastsProvider.context';

const ToastConsumer: FunctionComponent = () => {
  const { notifications, removeNotification } =
    useContext(ToastsServiceContext);

  useDeepCompareEffect(() => {
    notifications.forEach(({ id, children, notificationType }) => {
      if (children !== null) {
        switch (notificationType) {
          case NOTIFICATION_TYPES.ERROR:
            toast.error(children, {
              onOpen: () => removeNotification(id),
              onClose: () => removeNotification(id),
            });
            break;
          case NOTIFICATION_TYPES.ALERT:
            toast.warn(children, {
              onOpen: () => removeNotification(id),
              onClose: () => removeNotification(id),
            });
            break;
          case NOTIFICATION_TYPES.INFO:
            toast.info(children, {
              onOpen: () => removeNotification(id),
              onClose: () => removeNotification(id),
            });
            break;
          case NOTIFICATION_TYPES.SUCCESS:
            toast.success(children, {
              onOpen: () => removeNotification(id),
              onClose: () => removeNotification(id),
            });
            break;
          default:
            break;
        }
      }
    });
  }, [notifications, removeNotification]);

  return <></>;
};

ToastConsumer.displayName = 'ToastConsumer';

export default ToastConsumer;
