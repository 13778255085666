import styled from 'styled-components';

export const $BannerImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  & > img {
    width: 100%;
  }
  padding-bottom: 5rem;
`;
