import { rgba } from 'polished';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { getCustomComponentProperty, rem } from '../../../helpers';
import { useIsNewUiEnabled } from '../../../hooks/useIsNewUiEnabled';
import { getThemeFromProps } from '../../../theme/helpers';
import { InputStatus } from '../common/helpers/getFinalFieldState.types';
import { getBorderColor, getOnHoverBorderColor } from '../Input/Input.helpers';

import {
  getInputBorderColor,
  getInputBorderHoverColor,
  getOptionBackgroundColor,
} from './Autocomplete.styles';

export const useGetAutocompleteStyle = ({
  status,
}: {
  status?: InputStatus;
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const isNewUiEnabled = useIsNewUiEnabled();

  const AutocompleteStyle = {
    container: (styles: any, props: any) => {
      const theme = getThemeFromProps({
        theme: props.selectProps.themeStyled,
      });

      const minHeight = getCustomComponentProperty({
        theme,
        componentName: props.selectProps.componentThemeName,
        property: SUPPORTED_PROPERTIES.HEIGHT,
        fallback: rem(40),
      });

      return {
        ...styles,
        width: '100%',
        minHeight,
      };
    },
    indicatorsContainer: (styles: any, props: any) => {
      return {
        ...styles,
        opacity: props.selectProps.isDisabled ? 0.25 : 1,
      };
    },
    indicatorSeparator: (styles: any) => {
      return {
        ...styles,
        display: 'none',
      };
    },
    singleValue: (styles: any) => {
      return {
        ...styles,
      };
    },
    control: (styles: any, props: any) => {
      const theme = getThemeFromProps({
        theme: props.selectProps.themeStyled,
      });

      const defaultBorderColor = getBorderColor({
        theme,
        $isNewUiEnabled: isNewUiEnabled,
        status,
      });
      const defaultHoverBorderColor = getOnHoverBorderColor({
        theme,
        $isNewUiEnabled: isNewUiEnabled,
        status,
      });

      const minHeight = getCustomComponentProperty({
        theme,
        componentName: props.selectProps.componentThemeName,
        property: SUPPORTED_PROPERTIES.HEIGHT,
        fallback: rem(40),
      });

      const baseStyle = {
        ...styles,
        minHeight,
        padding: 0,
        backgroundColor: props.selectProps.isDisabled
          ? theme.colors.disabled
          : theme.colors.white,
        color: theme.colors.mainTextColor,
        fontSize: rem(14),
        borderColor: getInputBorderColor(props, theme, defaultBorderColor),
        borderStyle: 'solid',
        borderWidth: theme.borders.thickness,
        borderRadius: theme.borders.radius,
        boxShadow: 'none',
        marginBottom: 0,
        cursor: 'pointer',
        height: '100%',
        '&:hover, &:focus, &:active': {
          borderColor: getInputBorderHoverColor(
            props,
            theme,
            theme.colors.primary,
          ),
          borderWidth: theme.borders.thickness,
          boxShadow: `0px 0px 4px ${rgba(
            getInputBorderColor(props, theme, defaultHoverBorderColor),
            0.4,
          )}`,
        },
      };

      if (props.selectProps.isSearchable) {
        baseStyle.padding = 0;
      }

      return baseStyle;
    },
    multiValue: (styles: any, props: any) => {
      const theme = getThemeFromProps({
        theme: props.selectProps.themeStyled,
      });

      const multiValueBackgroundColor = isNewUiEnabled
        ? theme.newUI.variationColors.light5
        : theme.colors.lightGrey;

      if (isNewUiEnabled) {
        return {
          ...styles,
          backgroundColor: multiValueBackgroundColor,
          fontSize: theme.fonts.size.megaSmall,
          fontWeight: theme.fonts.weight.mediumFont,
        };
      }

      return {
        ...styles,
        backgroundColor: multiValueBackgroundColor,
      };
    },
    option: (styles: any, props: any) => {
      const { isSelected, isDisabled } = props;
      const theme = getThemeFromProps({
        theme: props.selectProps.themeStyled,
      });

      const color = isSelected
        ? `${theme.colors.primary}!important`
        : theme.colors.mainTextColor;

      return {
        ...styles,
        '*': {
          color:
            isSelected && !isDisabled
              ? `${theme.colors.primary}!important`
              : null,
        },
        backgroundColor: getOptionBackgroundColor({ ...props, theme }),
        fontWeight: isSelected
          ? theme.fonts.weight.mediumBold
          : theme.fonts.weight.normal,
        padding: isMobileView
          ? `${rem(20)} ${rem(14)}`
          : `${rem(10)} ${rem(14)}`,
        minHeight: rem(30),
        cursor: 'pointer',
        color: isDisabled ? theme.colors.disabled : color,
        '&:hover': {
          color: isDisabled ? theme.colors.disabled : theme.colors.primary,
          backgroundColor: isDisabled
            ? undefined
            : rgba(theme.colors.primary, 0.05),
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          border: 'none',
        },
      };
    },
    valueContainer: (styles: any) => ({
      ...styles,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: rem(14),
      paddingLeft: rem(14),
      gap: '3px',
    }),
    placeholder: (styles: any, props: any) => ({
      ...styles,
      color: props.selectProps.themeStyled.colors.default,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    menu: (styles: any, props: any) => {
      return {
        ...styles,
        marginTop: rem(2),
        left: 0,
        backgroundColor: props.selectProps.themeStyled.colors.white,
        zIndex: 40,
        borderRadius: rem(4),
        overflow: 'hidden',
        borderColor: '#C9CED6',
        borderWidth: rem(1),
        borderStyle: 'solid',
        boxShadow: 'none',
        width: props.selectProps.shouldMenuFitContent ? 'fit-content' : '100%',
      };
    },
    menuList: (styles: any) => ({
      ...styles,
      padding: 0,
    }),
    group: () => ({
      padding: '0.5rem 0 0 0',
    }),
    multiValueRemove: (styles: any, props: any) => {
      if (props.data?.clearableValue === undefined) {
        return styles;
      }

      return {
        ...styles,
        ...(props.data?.clearableValue
          ? {}
          : {
              visibility: 'hidden',
              width: '4px',
            }),
      };
    },
  };

  return AutocompleteStyle;
};
