import React, { FunctionComponent } from 'react';
import { ToastTransitionProps } from 'react-toastify';
import { Transition } from 'react-transition-group';
import { useMeasure } from 'react-use';

import ToastContainerProxy, { $ToastContainer } from './ToastContainer.styles';

interface ToastContainerProps {
  topOffset?: string;
}

const FadeInAndOut: FunctionComponent<
  React.PropsWithChildren<ToastTransitionProps>
> = ({ isIn, ...props }) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  function onEntered() {
    const node = props.nodeRef.current;

    if (node) {
      node.dispatchEvent(new Event('d'));

      node.removeEventListener('animationend', onEntered);
      node.removeEventListener('animationcancel', onEntered);
    }
  }

  return (
    <Transition timeout={300} in={isIn} appear onEntered={onEntered}>
      {(animationState) => (
        <$ToastContainer
          animationState={animationState}
          animationDuration={300}
          $height={height}
        >
          <div ref={ref}>{props.children}</div>
        </$ToastContainer>
      )}
    </Transition>
  );
};

FadeInAndOut.displayName = 'FadeInAndOut';

const ToastContainer: FunctionComponent<
  React.PropsWithChildren<ToastContainerProps>
> = ({ topOffset = '0' }) => {
  return (
    <ToastContainerProxy
      $topOffset={topOffset}
      pauseOnFocusLoss={false}
      transition={FadeInAndOut}
    />
  );
};

ToastContainer.displayName = 'ToastContainer';

export default ToastContainer;
