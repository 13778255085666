import styled from 'styled-components';

export const $ConfirmHeader = styled.div`
  margin-bottom: 1rem;
`;

export const $ConfirmFooter = styled.footer`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
