import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  ClaimContextSummary,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/solution`;

interface SetClaimGroupSolutionCommandPayload {
  claimGroupId: string;
  solutionTypeId: string;
  solutionPrice?: ReverseMoneyNumberAmount;
}

export const setClaimGroupSolutionCommand = async ({
  claimGroupId,
  solutionTypeId,
  solutionPrice,
}: SetClaimGroupSolutionCommandPayload): Promise<
  BackResponse<ClaimContextSummary[]> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BackResponse<ClaimContextSummary[]>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        solutionTypeId,
        solutionPrice,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
