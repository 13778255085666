import { defineMessages } from 'react-intl';

export default defineMessages({
  next: {
    id: 'view.claimModal.next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'view.claimModal.goBack',
    defaultMessage: 'Back to previous step',
  },
});
