import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

import { rem } from '../../../helpers';

export default {
  [SUPPORTED_PROPERTIES.COLOR]: {
    fallback: 'colors.white',
  },
  [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
    fallback: 'colors.headingTextColor',
  },
  [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
    fallback: 'colors.disabled',
  },
  [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
    fallback: 'colors.disabled',
  },
  [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
    value: 'inherit',
  },
  [SUPPORTED_PROPERTIES.PADDING]: {
    value: `${rem(14)} ${rem(21)}`,
  },
  [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
    value: 500,
  },
  [SUPPORTED_PROPERTIES.BORDER_RADIUS]: {
    value: '0.25rem',
  },
  [SUPPORTED_PROPERTIES.SPACE_BETWEEN_ICON_AND_TEXT]: {
    value: `${rem(21)}`,
  },
};
