import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import { apiCall } from '../../services';
import { BaseBackResponse, FileId } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention`;

interface CreateTechnicalInterventionPayload {
  fileId: FileId;
  start: string;
  end: string;
}

async function createTechnicalIntervention({
  fileId,
  start,
  end,
}: CreateTechnicalInterventionPayload): Promise<void> {
  const functionName = 'createTechnicalIntervention';

  if (!fileId || !start || !end) {
    throw new RequiredPayloadFieldIsNotProvided(
      'fileId && start && end',
      functionName,
    );
  }

  const response: BaseBackResponse = await apiCall(ENDPOINT, {
    method: SUPPORTED_METHODS.POST,
    json: {
      fileId,
      start,
      end,
    },
  });

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(
      `Got an exception trying to ${functionName}`,
      response.errors,
    );
  }
}

export default createTechnicalIntervention;
