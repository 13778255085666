import { uniqBy } from 'lodash';

import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { Notification } from '@savgroup-front-common/types';

import {
  TOASTS_PROVIDER_ACTION_TYPES,
  TOASTS_PROVIDER_ACTIONS,
} from './ToastsProvider.types';

interface ToastServiceState {
  notifications: Notification[];
}

export const toastsProviderInit = (): ToastServiceState => {
  return {
    notifications: [],
  };
};
export const toastsProviderReducer = (
  state: ToastServiceState,
  action: TOASTS_PROVIDER_ACTIONS,
): ToastServiceState => {
  switch (action.type) {
    case TOASTS_PROVIDER_ACTION_TYPES.ADD_TOASTS:
      return {
        ...state,
        notifications: uniqBy(
          [...action.payload.notifications, ...state.notifications],
          'id',
        ),
      };
    case TOASTS_PROVIDER_ACTION_TYPES.CLOSE_TOAST:
      return {
        ...state,
        notifications: state.notifications.filter(
          (toast) => toast.id !== action.payload.notificationId,
        ),
      };
    case TOASTS_PROVIDER_ACTION_TYPES.CLOSE_ALL_TOASTS:
      return {
        ...state,
        notifications: [],
      };

    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
