import { useCallback, useContext } from 'react';

import {
  ErrorFromBack,
  getUniqueBuiltErrorNotifications,
  MessageDescriptorMap,
} from '../../../../helpers/errorsMessage';
import ToastsServiceContext from '../ToastsProvider.context';
import { NotificationId } from '../ToastsProvider.types';

export type UsePushErrorsReturnType = (
  errors: (Error | ErrorFromBack)[],
) => NotificationId[];

interface UsePushErrorsArgs {
  extendedMessages?: MessageDescriptorMap;
}

const usePushErrors = ({
  extendedMessages,
}: UsePushErrorsArgs = {}): UsePushErrorsReturnType => {
  const { pushNotifications } = useContext(ToastsServiceContext);

  return useCallback(
    (errors: (Error | ErrorFromBack)[]) => {
      const toasts = getUniqueBuiltErrorNotifications({
        errors,
        extendedMessages,
      });

      return pushNotifications(toasts);
    },
    [extendedMessages, pushNotifications],
  );
};

export default usePushErrors;
