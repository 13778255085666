import { defineMessages } from 'react-intl';

export default defineMessages({
  ShippingLabel: {
    id: 'view.folders.tabs.claim.shippingLabel',
    defaultMessage: 'Shipping label: {sourceAndDestination}',
  },
  Owner: {
    id: 'view.folders.tabs.claim.owner',
    defaultMessage: 'Owner',
  },
  Repairer: {
    id: 'view.fileDocuments.repairer',
    defaultMessage: 'Repairer',
  },
  RiskCarrier: {
    id: 'view.folders.tabs.claim.riskCarrier',
    defaultMessage: 'RiskCarrier',
  },
  Seller: {
    id: 'view.folders.tabs.claim.seller',
    defaultMessage: 'Seller',
  },
  Supplier: {
    id: 'view.folders.tabs.claim.supplier',
    defaultMessage: 'Supplier',
  },
  DepositStore: {
    id: 'view.folders.tabs.claim.depositStore',
    defaultMessage: 'Deposit store',
  },
  Store: {
    id: 'view.folders.tabs.claim.store',
    defaultMessage: 'Store',
  },
  Technical: {
    id: 'view.folders.tabs.claim.technical',
    defaultMessage: 'Technical',
  },
  Warehouse: {
    id: 'view.folders.tabs.claim.hub',
    defaultMessage: 'Hub',
  },
  ReturnVoucher: {
    id: 'view.folders.tabs.claim.returnVoucher',
    defaultMessage: 'Return voucher',
  },
  ProductSummary: {
    id: 'view.folders.tabs.claim.ProductSummary',
    defaultMessage: 'Product summary',
  },
  EuropeanWarrantyForm: {
    id: 'view.folders.tabs.claim.EuropeanWarrantyForm',
    defaultMessage: 'European warranty form',
  },
  ExchangeVoucher: {
    id: 'view.folders.tabs.claim.exchangeVoucher',
    defaultMessage: 'Exchange voucher',
  },
  Quote: {
    id: 'view.folder.nav.quotation',
    defaultMessage: 'Quote',
  },
  InterventionReport: {
    id: 'view.interventionReport.title',
    defaultMessage: 'Intervention report',
  },
  DeliveryReceipt: {
    id: 'view.folders.tabs.claim.deliveryReceipt',
    defaultMessage: 'Bon de livraison',
  },
  CashingReceipt: {
    id: 'view.folders.tabs.claim.cashingReceipt',
    defaultMessage: 'Bon pour encaissement',
  },
  HandBackVoucher: {
    id: 'view.folders.tabs.claim.handBackVoucher',
    defaultMessage: 'Bon de restitution',
  },
  bill: {
    id: 'view.folders.tabs.claim.bill',
    defaultMessage: 'Bill: {fileName}',
  },
  DestructionVoucher: {
    id: 'view.folders.tabs.claim.destructionVoucher',
    defaultMessage: 'Bon de destruction',
  },
  DepositVoucher: {
    id: 'view.folders.tabs.claim.depositVoucher',
    defaultMessage: 'Bon de dépôt',
  },
  RefundVoucher: {
    id: 'view.folders.tabs.claim.refundVoucher',
    defaultMessage: 'Bon de remboursement',
  },
  ProofOfPurchase: {
    id: 'view.folders.tabs.claim.proofOfPurchase',
    defaultMessage: "Reconstitution de la preuve d'achat",
  },
  ShippingVoucher: {
    id: 'view.folders.tabs.claim.shippingVoucher',
    defaultMessage: "Bon d'expédition",
  },
  ShippingSummary: {
    id: 'view.folders.tabs.claim.shippingSummary',
    defaultMessage: "Récapitulatif d'expédition",
  },
  FileSummaryReport: {
    id: 'view.folders.tabs.claim.fileSummaryReport',
    defaultMessage: 'Rapport de demande',
  },
  HandlingVoucher: {
    id: 'view.folders.tabs.claim.handlingVoucher',
    defaultMessage: 'Bon de prise en charge',
  },
  additional_file_rma: {
    id: 'view.folders.tabs.claim.additionalFileRma',
    defaultMessage: 'Information complémentaire RMA : {documentName}',
  },
  bill_rma: {
    id: 'view.folders.tabs.claim.billRma',
    defaultMessage: "Preuve d'achat RMA : {documentName}",
  },
  additionalFile: {
    id: 'view.folders.tabs.claim.additionalFile',
    defaultMessage: 'Additional file: {documentName}',
  },
  UnexpectedReturnVoucher: {
    id: 'view.folders.tabs.claim.unexpectedReturnVoucher',
    defaultMessage: 'Bon de retour non attendu',
  },
  DataLossLiabilityWaiver: {
    id: 'view.folders.tabs.claim.dataLossLiabilityWaiver',
    defaultMessage: 'Bon de décharge informatique',
  },
  documentImportFail: {
    id: 'view.folders.tabs.claim.documentImportFail',
    defaultMessage: 'Document import fail',
  },
  Billing: {
    id: 'view.folders.tabs.claim.billing',
    defaultMessage: 'Billing',
  },
});
