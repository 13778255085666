import { css, keyframes } from 'styled-components';

import { getAnimationDelay, getAnimationDuration } from '../helper';
import { decelerateTimingFunctionAnimation } from '../timingFunction/animation';

import { SimpleAnimationProps } from './types';

const steady = css`
  transform: rotate(0);
`;

const enteringKeyframes = keyframes`
  from { ${steady}; }
  to { transform: rotate(360deg); }
`;

export default css<SimpleAnimationProps>`
  ${steady};
  animation-name: ${enteringKeyframes};
  animation-duration: ${getAnimationDuration};
  animation-delay: ${getAnimationDelay};
  animation-fill-mode: forwards;
  ${decelerateTimingFunctionAnimation};
`;
