import { defineMessages } from 'react-intl';

import { CLIENT_COMMON_ERROR } from '@savgroup-front-common/constants/src/shared';

export default defineMessages({
  [CLIENT_COMMON_ERROR.FAILED_TO_FETCH]: {
    id: 'common.errors.failedToFetch',
    defaultMessage: 'Check your internet connection.',
  },
});
