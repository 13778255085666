import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';

import * as types from './actionTypes';
import callAndGetResponse from '../../services/callAndGetResponse';

function* getSellerWorker(): Generator {
  yield call(
    callAndGetResponse,
    types.GET_SELLER,
    `${APIConfiguration.config}user/sellers`,
    { method: SUPPORTED_METHODS.GET },
    {},
  );

  yield put(types.GET_SELLER.end());
}
function* getSellerWatcher() {
  yield takeEvery(types.GET_SELLER.BASE, getSellerWorker);
}

export default function* sellerSaga(): Generator {
  try {
    yield all([getSellerWatcher()]);
  } catch (error) {
    logCritical(error as any);
  }
}
