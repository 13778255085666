/*
This should only be modified or handled by developers
Note that the property flexboxgrid is the key value for the react-styled-flexboxgrid lib.
Related to : https://github.com/LoicMahieu/react-styled-flexboxgrid
*/
import { breakpoint } from '@savgroup-front-common/constants';

import { em } from '../helpers';

import { Breakpoint } from './gridTheme.types';

interface GridThemeValues {
  flexboxgrid: {
    gridSize: number;
    gutterWidth: number;
    outerMargin: number;
    mediaQuery: string;
    /* Unit : rem */
    container: {
      sm: number;
      md: number;
      lg: number;
    };
    /* Unit : em */
    breakpoints: Breakpoint;
  };
}
const GridTheme: GridThemeValues = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    mediaQuery: 'only screen',
    /* Unit : rem */
    container: {
      sm: 56,
      md: 61,
      lg: 76,
    },
    /* Unit : em with base 16px */
    breakpoints: {
      xs: parseInt(em(breakpoint.xs, 16).replace('em', ''), 10),
      sm: parseInt(em(breakpoint.sm, 16).replace('em', ''), 10),
      md: parseInt(em(breakpoint.md, 16).replace('em', ''), 10),
      lg: parseInt(em(breakpoint.lg, 16).replace('em', ''), 10),
    },
  },
};

export default GridTheme;
