import * as Yup from 'yup';

import messages from './messages';
import { MODEL_SEARCH_FIELDS } from './ModelSearch.types';

const ModelSearchSchema = Yup.object().shape({
  [MODEL_SEARCH_FIELDS.MODEL]: Yup.object()
    .nullable()
    .test('required-model', messages.productIsRequired, (option) => {
      if (!option) {
        return false;
      }

      return option.value !== null && option.value !== undefined;
    }),
  [MODEL_SEARCH_FIELDS.SELLER]: Yup.object()
    .nullable()
    .test('required-seller', messages.sellerIsRequired, (option) => {
      if (!option) {
        return false;
      }

      return option.value !== null && option.value !== undefined;
    }),
});

export default ModelSearchSchema;
