import React, { FC, forwardRef, PropsWithChildren } from 'react';

import { MODAL_SIZES } from '../../atoms/modal';
import { ConfirmModal } from '../../molecules/ConfirmModal';
import messages from '../Quotation/QuotationPendingCustomerValidation/messages';

import { useConfirmationContext } from './ConfirmationContextProvider';

interface ConfirmationProps {
  onClick: (status: boolean) => void;
  forwardRef?: React.Ref<HTMLDivElement>;
}

const Confirmation: FC<PropsWithChildren<ConfirmationProps>> = ({
  children,
  onClick,
  forwardRef,
}) => {
  const { title, didactic, isOpen } = useConfirmationContext();

  return (
    <ConfirmModal
      isOpen={isOpen}
      size={MODAL_SIZES.SMALL}
      title={title}
      didactic={didactic}
      cancelMessage={messages.cancel}
      confirmMessage={messages.confirmActionButton}
      onCancel={() => onClick(false)}
      onConfirm={() => onClick(true)}
      ref={forwardRef}
    >
      {children}
    </ConfirmModal>
  );
};

Confirmation.displayName = 'Confirmation';

 
export default forwardRef<HTMLDivElement, PropsWithChildren<ConfirmationProps>>(
  (props, ref) => <Confirmation {...props} forwardRef={ref} />,
);
