import uniqBy from 'lodash/uniqBy';

import {
  API_COMMON_ERROR,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import {
  MessageType,
  Notification,
  NotificationOptions,
} from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../formatters';

import globalMessages from './i18n';

export interface ErrorFromBack {
  code: string;
  subject: string;
  message: string;
  isWarning: boolean;
}

export type MessageDescriptorMap = Record<string, MessageType>;

interface GetErrorMessages {
  extendedMessages?: MessageDescriptorMap;
  key?: string | null;
}
function isErrorFromBack(
  message: ErrorFromBack | Error,
): message is ErrorFromBack {
  return (message as ErrorFromBack).code !== undefined;
}

const getErrorProperty = (error: ErrorFromBack | Error): string | null => {
  if (isErrorFromBack(error)) {return error.code;}

  return error.message || error.name || null;
};

function getMessageByErrorKey({
  extendedMessages = {},
  key = null,
}: GetErrorMessages = {}): MessageType {
  if (!key) {
    return globalMessages.apiCommonErrorMessages[API_COMMON_ERROR.UNHANDLED];
  }

  if (extendedMessages[key]) {
    return extendedMessages[key];
  }

  if (globalMessages.apiCommonErrorMessages[key]) {
    return globalMessages.apiCommonErrorMessages[key];
  }

  if (globalMessages.clientCommonErrorMessages[key]) {
    return globalMessages.clientCommonErrorMessages[key];
  }

  return globalMessages.apiCommonErrorMessages[API_COMMON_ERROR.UNHANDLED];
}

type HandleMessage = (arg: ErrorFromBack | Error) => MessageType;

function handleMessage({
  extendedMessages,
}: {
  extendedMessages: MessageDescriptorMap;
}): HandleMessage {
  return (error: ErrorFromBack | Error) => {
    return getMessageByErrorKey({
      extendedMessages,
      key: getErrorProperty(error),
    });
  };
}

interface BuildNotificationArgs {
  notificationType: NOTIFICATION_TYPES;
  message: MessageType | string;
  options?: NotificationOptions;
}

const isMessage = (input: MessageType | string): input is MessageType => {
  return typeof input !== 'string';
};

export const buildNotification = ({
  notificationType,
  message,
  options = {},
}: BuildNotificationArgs): Notification => {
  if (isMessage(message)) {
    return {
      id: message.id,
      children: SafeFormattedMessage(message),
      notificationType,
      options,
    };
  }

  return {
    id: message,
    children: SafeFormattedMessage(message),
    notificationType,
    options,
  };
};

export const buildErrorNotification = (
  message: MessageType | string,
): Notification => {
  return buildNotification({
    message,
    notificationType: NOTIFICATION_TYPES.ERROR,
  });
};

export const getUniqErrorMessages = (
  errors: ErrorFromBack[] | Error[] | (ErrorFromBack | Error)[] = [],
  extendedMessages: MessageDescriptorMap = {},
): MessageType[] => {
  const result = uniqBy(
    uniqBy<ErrorFromBack | Error>(
      uniqBy<ErrorFromBack | Error>(errors, 'name'),
      'code',
    ).map(handleMessage({ extendedMessages })),
    'id',
  );

  return result;
};

interface GetUniqueBuiltErrorNotifications {
  errors: ErrorFromBack[] | Error[] | (ErrorFromBack | Error)[];
  extendedMessages?: MessageDescriptorMap;
}
export const getUniqueBuiltErrorNotifications = ({
  errors = [],
  extendedMessages = {},
}: GetUniqueBuiltErrorNotifications): Notification[] => {
  return getUniqErrorMessages(errors, extendedMessages).map(
    buildErrorNotification,
  );
};
