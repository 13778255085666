import { defineMessages } from 'react-intl';

export default defineMessages({
  productPlaceholder: {
    id: 'view.MyAccountLoginPage.product.placeholder',
    defaultMessage: 'Search product with reference',
  },
  noOptionsMessage: {
    id: 'view.MyAccountLoginPage.product.noOptionsMessage',
    defaultMessage: 'No product found',
  },
  startTyping: {
    id: 'view.MyAccountLoginPage.product.startTyping',
    defaultMessage: 'Start typing...',
  },
  product: {
    id: 'view.MyAccountLoginPage.product.label',
    defaultMessage: 'Product',
  },
  seller: {
    id: 'view.MyAccountLoginPage.seller.label',
    defaultMessage: 'Seller',
  },
  sellerTooltip: {
    id: 'view.MyAccountLoginPage.seller.postLabel',
    defaultMessage: 'Seller tooltip',
  },
  productTooltip: {
    id: 'view.MyAccountLoginPage.product.postLabel',
    defaultMessage: 'Product tooltip',
  },
  electroDepotToolTip: {
    id: 'view.MyAccountLoginPage.product.electroDepotToolTip',
    defaultMessage: 'Electro depot toolTip',
  },
  startButton: {
    id: 'view.MyAccountLoginPage.startButton',
    defaultMessage: 'Start',
  },
  productIsRequired: {
    id: 'view.MyAccountLoginPage.schema.productIsRequired',
    defaultMessage: 'Product is required',
  },
  sellerIsRequired: {
    id: 'view.MyAccountLoginPage.schema.sellerIsRequired',
    defaultMessage: 'Seller is required',
  },
  goToTicketNumberLogin: {
    id: 'view.MyAccountLoginPage.goToTicketNumberLogin',
    defaultMessage: 'Go to ticket number login ? ',
  },
  clickHere: {
    id: 'view.MyAccountLoginPage.clickHere',
    defaultMessage: 'Click here',
  },
});
