import React, { FunctionComponent } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { SafeFormattedMessage } from '../../../../formatters';
import { ReversToastOptions, ToastContent } from '../../Toast.types';

import { $ToastTitle } from './ToastBody.styles';

interface ToastBodyProps {
  message: MessageType;
  options: ReversToastOptions;
  content: ToastContent;
}

const ToastBody: FunctionComponent<ToastBodyProps> = ({
  options,
  message,
  content,
}) => {
  return (
    <div>
      <$ToastTitle>
        {SafeFormattedMessage(options.title || message)}
      </$ToastTitle>
      {SafeFormattedMessage(content)}
    </div>
  );
};

ToastBody.displayName = 'ToastBody';

export default ToastBody;
