import { COMMON_STEPS } from '@savgroup-front-common/constants';
import { Step } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';

import CustomerInformation from '../common/steps/CustomerInformation';

import { PRODUCT_REFERENCE_LOGIN_STEP_NAMES } from './ProductReferenceLogin.types';
import ComplementaryInformation from './steps/ComplementaryInformation';
import ModelSearch from './steps/ModelSearch';

const searchModelStep: Step<Record<string, unknown>> = {
  name: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.SEARCH_MODEL,
  nextStep: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.COMPLEMENTARY_INFORMATION,
  Component: ModelSearch,
  message: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.SEARCH_MODEL,
};

const complementaryInformationStep: Step<Record<string, unknown>> = {
  name: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.COMPLEMENTARY_INFORMATION,
  nextStep: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  Component: ComplementaryInformation,
  message: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.COMPLEMENTARY_INFORMATION,
};

const customInformationStep: Step<Record<string, unknown>> = {
  name: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
  nextStep: COMMON_STEPS.SUBMIT,
  Component: CustomerInformation,
  message: PRODUCT_REFERENCE_LOGIN_STEP_NAMES.CUSTOMER_INFORMATION,
};

export const PRODUCT_REFERENCE_LOGIN_STEPS_CONFIG = [
  searchModelStep,
  complementaryInformationStep,
  customInformationStep,
];
