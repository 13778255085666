import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types/src/Theme';

import {
  getCustomComponentProperty,
  SUPPORTED_ICON_POSITIONS,
} from '../../helpers/theme';

import { getIconColor } from './Button.helpers';
import { CustomIcon } from './Icon.styles';

export interface IconProps {
  position?: SUPPORTED_ICON_POSITIONS;
  icon: ReactElement;
  componentThemeName?: string;
  $isNewUiEnabled?: boolean;
  dataTestId?: string;
  tertiary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
}

export const Icon: FunctionComponent<React.PropsWithChildren<IconProps>> = ({
  children,
  position = SUPPORTED_ICON_POSITIONS.LEFT,
  icon,
  componentThemeName,
  dataTestId,
  tertiary,
  secondary,
  disabled,
  $isNewUiEnabled,
}) => {
  const theme = useTheme();

  const iconPositionToBeUsed = getCustomComponentProperty({
    theme,
    property: SUPPORTED_PROPERTIES.ICON_POSITION,
    componentName: componentThemeName,
    fallback: position,
  });

  const left = iconPositionToBeUsed === SUPPORTED_ICON_POSITIONS.LEFT;
  const right = iconPositionToBeUsed === SUPPORTED_ICON_POSITIONS.RIGHT;
  const none = iconPositionToBeUsed === SUPPORTED_ICON_POSITIONS.NONE;

  const color = getIconColor({
    position,
    icon,
    componentThemeName,
    dataTestId,
    theme,
    secondary,
    tertiary,
    $isNewUiEnabled,
    disabled,
  });

  return (
    <>
      {left && !none ? <CustomIcon icon={icon} color={color} /> : null}
      {children ? <span className="children">{children}</span> : null}
      {right && !none ? <CustomIcon icon={icon} color={color} /> : null}
    </>
  );
};

Icon.displayName = 'Icon';

export default Icon;
