import { defineMessages } from 'react-intl';

export default defineMessages({
  addressGroupLabel: {
    id: 'modal.editAddress.suggestion.addressGroupLabel.label',
    defaultMessage: 'Please, select a choice',
  },
  userValues: {
    id: 'modal.editAddress.suggestion.radio.title.userValues',
    defaultMessage: 'Your address',
  },
  suggestion: {
    id: 'modal.editAddress.suggestion.radio.title.suggestion',
    defaultMessage: 'Our suggestion',
  },
});
