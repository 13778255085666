import styled, { css } from 'styled-components';

import { rem } from '../../../../helpers';
import { simpleSlideFromTopAnimation } from '../../../../animations/simpleAnimation';

export const $EmailAutcompleteDropdownContainer = styled.div`
  position: relative;
`;

export const $EmailAutcompleteDropdown = styled.ul`
  background: ${({ theme }) => theme.colors.white};
  list-style: none;
  margin-top: ${rem(2)};
  position: absolute;
  top: 0;
  left: 0;
  border-color: ${({ theme }) => theme.colors.default};
  z-index: 10;
  border-width: ${rem(1)};
  border-style: solid;
  padding: 0;
  width: 100%;

  border-radius: ${rem(4)};
  overflow: hidden;
  ${simpleSlideFromTopAnimation};

  box-shadow: ${({ theme }) =>
    theme.shadows.elevation__1.replace('box-shadow: ', '')};
`;

export const $EmailAutcompleteItem = styled.li``;
const optionMixin = css<{ $isSelected?: boolean }>`
  padding: 1rem;
  background: ${({ $isSelected = false, theme }) =>
    $isSelected ? theme.colors.default : 'none'};
  outline: 0;
  border: 0;
  width: 100%;
  text-align: left;
`;

export const $EmailAutcompleteButton = styled.button`
  ${optionMixin};
  cursor: pointer;
  &:hover,
  &:focus {
    outline: 0;
    border: 0;
    background: ${({ theme }) => theme.colors.default};
  }
`;
