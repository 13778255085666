import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import { v4 } from 'uuid';

import {
  COUNTRY_CODES,
  REVALIDATE_MODES,
  EMPTY_UUID,
} from '@savgroup-front-common/constants';
import { DataGouvService } from '@savgroup-front-common/core/src/api';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { AddressInfoDto } from '@savgroup-front-common/types';

import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';

import CustomerInformationAddressSchema from './CustomerInformationAddress.schema';
import { CustomerInformationAddressValues } from './CustomerInformationAddress.types';
import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

interface UseCustomerInformationAddressArgs {
  values: CreateClaimValues;
}

interface UseCustomerInformationAddressReturnValues {
  formContext: UseFormReturn<CustomerInformationAddressValues>;
  isLoading: boolean;
  onSubmit: SubmitHandler<CustomerInformationAddressValues>;
  isOpenModal: boolean;
  handleCloseSuggestedAddressModal: () => void;
  addressEntered: AddressInfoDto | undefined;
  suggestedAddress: AddressInfoDto | undefined;
}

const useCustomerInformationAddressForm = ({
  values,
}: UseCustomerInformationAddressArgs): UseCustomerInformationAddressReturnValues => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();

  const formContext = useForm<CustomerInformationAddressValues>({
    shouldUnregister: false,
    resolver: yupResolver(CustomerInformationAddressSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      address: values?.owner?.ownerAddress?.address || '',
      additionalAddress: values?.owner?.ownerAddress?.additionalAddress || '',
      postalCode: values?.owner?.ownerAddress?.postalCode || '',
      city: values?.owner?.ownerAddress?.city || '',
      countryCode: {
        label: '',
        value:
          values?.owner?.ownerAddress?.countryCode.toUpperCase() ||
          COUNTRY_CODES.FR,
      },
    },
  });

  const { getValues } = formContext;

  const { address, additionalAddress, postalCode, city, countryCode } =
    getValues();

  const {
    data: suggestedAddressData,
    mutateAsync: handleValidateAddress,
    isLoading,
  } = useMutation([], async (newAddress: AddressInfoDto | undefined) => {
    if (!newAddress) {
      return undefined;
    }

    try {
      const response = await DataGouvService.geoCodeAddressFromDataGouv({
        query: [newAddress?.address, newAddress?.postalCode, newAddress?.city]
          .filter((x) => x)
          .join(' '),
      });

      if (response.features.length) {
        const [feature] = response.features;

        return {
          ...newAddress,
          address: feature.properties.name,
          city: feature.properties.city,
          postalCode: feature.properties.postcode,
        };
      }

      return undefined;
    } catch (error: any) {
      logError(error);

      return undefined;
    }
  });

  const handleCloseSuggestedAddressModal = () => setIsOpenModal(false);

  const handleCompareAddress = useCallback(
    (suggestedAddress: any, addressForm: any) => {
      if (suggestedAddress && addressForm) {
        const hasSameAddress =
          suggestedAddress?.address === addressForm?.address;
        const hasSamePostalCode =
          suggestedAddress?.postalCode === addressForm?.postalCode;
        const hasSameCountryCode =
          suggestedAddress?.countryCode === addressForm?.countryCode;
        const hasSameCity = suggestedAddress?.city === addressForm?.city;

        return Boolean(
          hasSameAddress &&
            hasSamePostalCode &&
            hasSameCountryCode &&
            hasSameCity,
        );
      }

      return true;
    },
    [],
  );

  const onSubmit = useCallback(
    async ({
      address,
      additionalAddress,
      postalCode,
      city,
      countryCode,
    }: CustomerInformationAddressValues): Promise<any | undefined> => {
      const { firstname, lastname, phone } = <AddressInfoDto>(
        values.owner.ownerAddress
      );

      const addressForm = {
        firstname,
        lastname,
        phone,
        address,
        additionalAddress,
        postalCode,
        city,
        countryCode: countryCode.value as COUNTRY_CODES,
      };

      if (countryCode.value === COUNTRY_CODES.FR) {
        const suggestedAddress = await handleValidateAddress(addressForm);

        if (suggestedAddress) {
          const hasSameAddress = handleCompareAddress(
            suggestedAddress,
            addressForm,
          );

          if (hasSameAddress) {
            return nextStep({
              ...values,
              owner: {
                ...values.owner,
                id:
                  values.owner?.id && values.owner.id !== EMPTY_UUID
                    ? values.owner.id
                    : v4(),
                ownerAddress: {
                  firstname,
                  lastname,
                  phone,
                  address,
                  additionalAddress,
                  postalCode,
                  city,
                  countryCode: countryCode.value as COUNTRY_CODES,
                },
              },
            });
          }

          return setIsOpenModal(true);
        }
      }

      return nextStep({
        ...values,
        owner: {
          ...values.owner,
          id:
            values.owner?.id && values.owner.id !== EMPTY_UUID
              ? values.owner.id
              : v4(),
          ownerAddress: {
            firstname,
            lastname,
            phone,
            address,
            additionalAddress,
            postalCode,
            city,
            countryCode: countryCode.value as COUNTRY_CODES,
          },
          countryCode: countryCode.value as COUNTRY_CODES,
        },
      });
    },
    [handleCompareAddress, handleValidateAddress, nextStep, values],
  );

  return {
    formContext,
    isLoading,
    onSubmit,
    isOpenModal,
    handleCloseSuggestedAddressModal,
    addressEntered: {
      ...values.owner.ownerAddress,
      phone: values.owner.ownerAddress?.phone || '',
      address,
      additionalAddress,
      postalCode,
      city,
      countryCode: countryCode.value as COUNTRY_CODES,
    },
    suggestedAddress: suggestedAddressData || undefined,
  };
};

export default useCustomerInformationAddressForm;
