import { MinimalSeller } from 'authenticator/types';

import { SELLERS } from '@savgroup-front-common/constants';
import {
  MessageType,
  StrictAutocompleteOption,
} from '@savgroup-front-common/types';

import messages from './messages';
import { SearchModel } from './ModelSearch.types';

export const formatSellerOption = (
  seller: MinimalSeller,
): StrictAutocompleteOption<MinimalSeller> => ({
  value: seller.id,
  label: seller.name,
  data: seller,
});

export const formatModelOption = (
  model: SearchModel,
): StrictAutocompleteOption<SearchModel> => ({
  data: model,
  value: model.sku,
  label: model.label,
});

interface ProductPostLabelMessageArgs {
  seller?: {
    id: string;
    name: string;
  };
}

export const productPostLabelMessage = ({
  seller,
}: ProductPostLabelMessageArgs): MessageType => {
  const sellerName = seller?.name;

  switch (sellerName) {
    case SELLERS.ELECTRO_DEPOT:
      return messages.electroDepotToolTip;
    default:
      return messages.productTooltip;
  }
};
