import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $NeedHelpModalContent = styled.div`
  text-align: left;
`;

export const $Row = styled(Row)`
  padding: 1rem 0 1rem 0;
`;

export const $PhoneIconCoCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const $CustomerSupportPhoneNumber = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.large};
`;
