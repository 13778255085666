 
import styled from 'styled-components';

import { rem } from '../../../../helpers';
import { ProgressBar } from '../../../ProgressBar';

export const $AttachmentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 32px;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const $StyledProgressBar = styled(ProgressBar)`
  width: 100%;
  height: 32px;
  border-width: 1px;
  border-color: ${({ isSuccess, isError, theme }) => {
    if (isError) {
      return theme.colors.danger;
    }

    if (isSuccess) {
      return theme.colors.success;
    }

    return theme.colors.lightGrey;
  }};
  border-style: solid;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-radius: 8px;
  & > * :last-child {
    white-space: nowrap;
  }
`;

interface AttachmentContentProps {
  $fixed?: boolean;
}

export const $AttachmentContent = styled.div<AttachmentContentProps>`
  flex: ${({ $fixed }) => (!$fixed ? 1 : null)};
  min-width: ${({ $fixed }) => (!$fixed ? 0 : null)};
  max-width: ${({ $fixed }) => (!$fixed ? rem(300) : null)};
  white-space: ${({ $fixed }) => ($fixed ? 'nowrap' : null)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const $AttachmentsListFileName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
  height: 100%;
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.mainTextColor};

  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
