import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import uploadAttachmentWithProgress from '../Attachment/uploadAttachmentWithProgress';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/additionalInformation/${COMMON_QUERY_PARAMS.CLAIM_ID}/${COMMON_QUERY_PARAMS.ID}/file/token/${COMMON_QUERY_PARAMS.TOKEN}`;

interface SetFileAdditionalInformationWithFilesAndTokenCommandArgs {
  fileId: string;
  claimId: string;
  additionalInformationId: string;
  token: string;
  file?: File;
  files?: File[];
}

export async function setFileAdditionalInformationWithFilesAndTokenCommand({
  fileId,
  claimId,
  additionalInformationId,
  token,
  file,
  files,
}: SetFileAdditionalInformationWithFilesAndTokenCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.ID,
      additionalInformationId,
    )
      .replace(COMMON_QUERY_PARAMS.FILE_ID, fileId)
      .replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId)
      .replace(COMMON_QUERY_PARAMS.TOKEN, token);

    const response = await uploadAttachmentWithProgress({
      file,
      files,
      endpoint: url,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
