import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'view.editableItem.fieldIsEmpty',
    defaultMessage: 'Field cannot be empty',
  },
  invalid: {
    id: 'global.form.invalid',
    defaultMessage: 'Field is invalid',
  },
  submit: {
    id: 'global.form.submit',
    defaultMessage: 'Submit',
  },
  edit: {
    id: 'global.form.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'global.form.delete',
    defaultMessage: 'Delete',
  },
  new: {
    id: 'global.form.add',
    defaultMessage: 'New',
  },
  yes: {
    id: 'global.form.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'global.form.no',
    defaultMessage: 'No',
  },
  invalidPostalCode: {
    id: 'global.form.invalide.postalCode',
    defaultMessage: "Postal code isn't valid",
  },
  invalidEmail: {
    id: 'global.form.invalide.invalidEmail',
    defaultMessage: "Email isn't valid",
  },
  invalidAddress: {
    id: 'global.form.invalide.invalidAddress',
    defaultMessage: "Address isn't valid",
  },
  invalidPhoneNumber: {
    id: 'global.form.invalide.invalidPhone',
    defaultMessage: "Phone number isn't valid",
  },
  invalidUrl: {
    id: 'global.form.invalide.invalidUrl',
    defaultMessage: "Url isn't valid",
  },
  invalidPrice: {
    id: 'global.form.invalide.invalidPrice',
    defaultMessage: "Price isn't valid",
  },
  invalidPositivePrice: {
    id: 'global.form.invalide.notPostivePrice',
    defaultMessage: "Price isn't positive",
  },
  invalidNegativePrice: {
    id: 'global.form.invalide.notNegativePrice',
    defaultMessage: "Price isn't negative",
  },
  invalidSpecificLengtgh: {
    id: 'global.form.invalide.SpecificLengtgh',
    defaultMessage: 'This field should have {count} characters',
  },
  minNumberCount: {
    id: 'global.form.invalid.number.min',
    defaultMessage: 'This field value should at least {min}',
  },
  minStringLength: {
    id: 'global.form.invalid.string.minLength',
    defaultMessage: 'Minimum {min} characters',
  },
  fileRequired: {
    id: 'global.form.invalid.fileRequired',
    defaultMessage: 'File is required',
  },
  tooManyFiles: {
    id: 'global.form.invalid.tooManyFile',
    defaultMessage: 'Too many files (max: {count})',
  },
  maximumFileSize: {
    id: 'global.form.invalid.maximumFileSize',
    defaultMessage: 'Maximum file size is 5 Mo',
  },
  unsupportedFileType: {
    id: 'global.form.invalid.unsupportedFileType',
    defaultMessage:
      'Unsupported file type, accepted file types: word, excel, pdf, jpeg, png, mp4',
  },
  unsupportedFileTypeWithSpecificSupportedOne: {
    id: 'global.form.invalid.unsupportedFileTypeWithSpecificSupportedOne',
    defaultMessage:
      'Unsupported file type, accepted file types: {supportedTypes}',
  },
  cancel: {
    id: 'view.component.updateStatus.cancel',
    defaultMessage: 'Cancel',
  },
});
