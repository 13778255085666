import { StrictAutocompleteOptionForHookForm } from '@savgroup-front-common/types';
import { RawModelFromBack } from 'authenticator/api/Catalog/getModelsByFullTextSearch';

import { SearchModel } from '../ModelSearch.types';

const adaptRawModelsFromBackToOptions = (
  rawModels: RawModelFromBack[],
): StrictAutocompleteOptionForHookForm<SearchModel>[] => {
  return rawModels.map((rawModel) => {
    return {
      value: rawModel.sku,
      label: rawModel.label,
      data: rawModel,
      description: [rawModel.brandLabel, rawModel.modelType]
        .filter((v) => v)
        .join(' - '),
    };
  });
};

export default adaptRawModelsFromBackToOptions;
