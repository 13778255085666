import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { ClientService } from 'authenticator/api';
import { AuthConfiguration } from 'authenticator/configuration';
import { ClientInformation } from 'authenticator/types';

const GetClientInformation = 'getClientInformation';

interface UseGetClientInformationReturnValues {
  clientInformation?: ClientInformation;
  isLoading: boolean;
}

const useGetClientInformation = (): UseGetClientInformationReturnValues => {
  const query = useSearchParams();
  const idP = query.get('idP') || '';
  const { clientId } = AuthConfiguration;

  const { data: clientInformation, isLoading } = useQuery(
    [
      GetClientInformation,
      {
        clientId,
        idP,
      },
    ],
    async () => {
      if (!clientId) {
        return undefined;
      }
      const response = await ClientService.getClientInformations({
        clientId,
        idP,
      });

      if (response.failure) {
        logError(
          `Got an empty getClientInformation for clientId: ${clientId}.`,
        );

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
    },
  );

  return { clientInformation, isLoading };
};

export default useGetClientInformation;
