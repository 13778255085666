import React, { FunctionComponent } from 'react';

import Icon from '../Icon';
import { IconPropsInterface } from '../Icon.types';

const SendMailIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => (
  <Icon size={size} color={color} viewBox="0 0 120 69" fill="none">
    <g id="Group">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3226 19.5549C44.5385 18.047 47.0026 17.6396 48.8263 18.6448L70.4373 30.5564L92.0484 18.6448C93.8721 17.6396 96.3362 18.047 97.552 19.5549C98.7679 21.0627 98.2751 23.0999 96.4513 24.1051L72.6388 37.2301C71.3057 37.9649 69.569 37.9649 68.2359 37.2301L44.4234 24.1051C42.5996 23.0999 42.1068 21.0627 43.3226 19.5549ZM0.322754 27.9375C0.322754 26.1253 2.09962 24.6562 4.2915 24.6562H25.4582C27.6501 24.6562 29.4269 26.1253 29.4269 27.9375C29.4269 29.7497 27.6501 31.2187 25.4582 31.2187H4.2915C2.09962 31.2187 0.322754 29.7497 0.322754 27.9375ZM10.9061 41.0625C10.9061 39.2503 12.683 37.7812 14.8748 37.7812H25.4582C27.6501 37.7812 29.4269 39.2503 29.4269 41.0625C29.4269 42.8747 27.6501 44.3437 25.4582 44.3437H14.8748C12.683 44.3437 10.9061 42.8747 10.9061 41.0625Z"
        fill="currentColor"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0413 7.15625C34.287 7.15625 32.6046 7.73242 31.3641 8.75801C30.1236 9.7836 29.4268 11.1746 29.4268 12.625V14.8125C29.4268 16.6247 27.6499 18.0938 25.458 18.0938C23.2661 18.0938 21.4893 16.6247 21.4893 14.8125V12.625C21.4893 9.43411 23.0224 6.37392 25.7515 4.11762C28.4805 1.86132 32.1819 0.59375 36.0413 0.59375H104.833C108.692 0.59375 112.394 1.86132 115.123 4.11762C117.852 6.37392 119.385 9.43411 119.385 12.625V56.375C119.385 59.5659 117.852 62.6261 115.123 64.8824C112.394 67.1387 108.692 68.4062 104.833 68.4062H36.0413C32.1819 68.4062 28.4805 67.1387 25.7515 64.8824C23.0224 62.6261 21.4893 59.5659 21.4893 56.375V54.1875C21.4893 52.3753 23.2661 50.9062 25.458 50.9062C27.6499 50.9062 29.4268 52.3753 29.4268 54.1875V56.375C29.4268 57.8254 30.1236 59.2164 31.3641 60.242C32.6046 61.2676 34.287 61.8438 36.0413 61.8438H104.833C106.587 61.8438 108.27 61.2676 109.51 60.242C110.751 59.2164 111.448 57.8254 111.448 56.375V12.625C111.448 11.1746 110.751 9.7836 109.51 8.75801C108.27 7.73242 106.587 7.15625 104.833 7.15625H36.0413Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

SendMailIcon.displayName = 'SendMailIcon';

export default SendMailIcon;
