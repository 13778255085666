import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { BackResponseFailure, Theme } from '@savgroup-front-common/types';
import { AuthConfiguration } from 'authenticator/configuration';

const ENDPOINT = `${APIConfiguration.catalogCDN}miscellaneous/themes/${AuthConfiguration.clientId}.json`;

const getClientTheme = async (): Promise<Theme | BackResponseFailure> => {
  try {
    const response = await fetch(ENDPOINT, {
      method: SUPPORTED_METHODS.GET,
    });

    return await response.json();
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getClientTheme;
