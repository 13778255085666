import styled from 'styled-components';

export const $CopyContainer = styled.div`
  cursor: pointer;
  outline-color: ${({ theme }) => theme.colors.secondary};
  outline: 0;
`;

export const $Span = styled.span`
  overflow: hidden;
`;
