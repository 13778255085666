import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';
import messages from './messages';

const PhoneNumberLoginEnableSchema = Yup.object().shape({
  orderReference: Yup.string().required(globalMessages.form.required),
  phoneNumber: Yup.string()
    .required(messages.phoneNumberIsRequired)
    .matches(/^\d{4}$/, { message: messages.digitsOnlyAreAuthorized }),
});

export default PhoneNumberLoginEnableSchema;
