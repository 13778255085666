import React, { FunctionComponent, ReactElement } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import {
  MessageType,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import { $CustomerInformationsFooter } from './CustomerInformationsFooter.styles';
import messages from './messages';

interface CustomerInformationsFooterProps {
  isLoading?: boolean;
  isError?: boolean;
  onPreviousStepClick?: () => void;
  isDisabled?: boolean;
  nextIcon?: ReactElement;
  nextMessage?: MessageType;
  dataTestId?: string;
}

const CustomerInformationsFooter: FunctionComponent<
  React.PropsWithChildren<CustomerInformationsFooterProps>
> = ({
  nextIcon,
  nextMessage = messages.next,
  isError = false,
  isLoading = false,
  isDisabled = false,
  onPreviousStepClick,
  dataTestId,
}) => {
  return (
    <$CustomerInformationsFooter>
      <Row between="xs" reverse>
        <Col>
          <Button
            primary
            disabled={isDisabled}
            isLoading={isLoading}
            icon={nextIcon}
            type={BUTTON_TYPES.SUBMIT}
            isError={isError}
            dataTestId={dataTestId && `${dataTestId}-next`}
          >
            <SafeFormattedMessageWithoutSpread message={nextMessage} />
          </Button>
        </Col>
        {onPreviousStepClick && (
          <Col>
            <Button
              componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
              naked
              disabled={isLoading}
              type={BUTTON_TYPES.BUTTON}
              onClick={onPreviousStepClick}
              icon={<ChevronLeftIcon />}
              dataTestId={dataTestId && `${dataTestId}-previous`}
            >
              <SafeFormattedMessageWithoutSpread message={messages.previous} />
            </Button>
          </Col>
        )}
      </Row>
    </$CustomerInformationsFooter>
  );
};

CustomerInformationsFooter.displayName = 'CustomerInformationsFooter';

export default CustomerInformationsFooter;
