import React, { FunctionComponent } from 'react';

import { BannersProvider } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import AddressInformationsContent from './AddressInformationsContent';

const AddressInformations: FunctionComponent = () => {
  return (
    <BannersProvider>
      <AddressInformationsContent />
    </BannersProvider>
  );
};

AddressInformations.displayName = 'AddressInformations';

export default AddressInformations;
