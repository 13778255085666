import getThemeFromProps from './getThemeFromProps';

/**
 * @deprecated: No Need to use this function, need just be sure that theme is well injected
 * (if styled it's done automatically)
 * @param props
 * @returns {*}
 */
const getBordersBasedOnProps = (props) => {
  const theme = getThemeFromProps(props);

  return theme.borders;
};

export default getBordersBasedOnProps;
