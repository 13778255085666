import { CreateOrderBySaleReceiptIdPayload } from 'authenticator/api/EnterpriseAuchan/createOrderBySaleReceiptId';

import { CustomerInformationValues } from '../common/steps/CustomerInformation/CustomerInformation.types';

interface PrepareAuthenticateWithTicketNumberPayloadArgs
  extends CustomerInformationValues {
  saleReceiptId: string;
  sellerId: string;
}

export const prepareAuthenticateWithTicketNumberPayload = (
  data: PrepareAuthenticateWithTicketNumberPayloadArgs,
): CreateOrderBySaleReceiptIdPayload => {
  const { sellerId, saleReceiptId, customerInformation } = data;
  const payload = {
    sellerId,
    saleReceiptId,
    owner: {
      civility: customerInformation.civility,
      customerLastName: customerInformation.lastName,
      customerFirstName: customerInformation.firstName,
      phoneNumber: customerInformation.phone,
      customerMail: customerInformation.email,
      address: {
        civility: customerInformation.civility,
        firstname: customerInformation.firstName,
        companyName: customerInformation.companyName,
        lastname: customerInformation.lastName,
        address: customerInformation.address,
        additionalAddress: customerInformation.additionalAddress,
        postalCode: customerInformation.postalCode,
        city: customerInformation.city,
        countryCode: customerInformation.countryCode,
        phone: customerInformation.phone,
        isDefault: true,
      },
    },
  };

  return payload;
};
