import React, { ChangeEventHandler, FunctionComponent } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import {
  $AgreementText,
  $StyledCheckbox,
  $StyledLink,
} from './AgreementCheckbox.styles';
import messages from './messages';

interface AgreementCheckboxProps {
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isError?: boolean;
  isNewUi?: boolean;
}

const AgreementCheckbox: FunctionComponent<
  React.PropsWithChildren<AgreementCheckboxProps>
> = ({ isChecked, onChange, isError, isNewUi }) => {
  const agreementUrl = `${APIConfiguration.catalogCDN}miscellaneous/CGU%20Revers.io%20-%20Client%20final_fr.pdf`;

  return (
    <$StyledCheckbox
      name="agreement"
      checked={isChecked}
      onChange={onChange}
      isError={isError}
      isNewUi={isNewUi}
      label={
        <$AgreementText>
          <SafeFormattedMessageWithoutSpread
            message={messages.termsAndConditions}
          />{' '}
          <$StyledLink
            href={agreementUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.goToConditions}
            />
          </$StyledLink>
        </$AgreementText>
      }
    />
  );
};

AgreementCheckbox.displayName = 'AgreementCheckbox';

export default AgreementCheckbox;
