 
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import DEMO_CONFIG from './hotjar.config.DEMO';
import DEV_CONFIG from './hotjar.config.DEV';
import LOCAL_CONFIG from './hotjar.config.LOCAL';
import PREPROD_CONFIG from './hotjar.config.PREPROD';
import PROD_CONFIG from './hotjar.config.PROD';

interface GetHotjarConfigurationEnabledReturnType {
  enabled: true;
  hjid: number;
  hjsv: number;
}
interface GetHotjarConfigurationDisabledReturnType {
  enabled: false;
}

const HOTJAR_CONFIG = (():
  | GetHotjarConfigurationEnabledReturnType
  | GetHotjarConfigurationDisabledReturnType => {
  switch (currentAppEnvironment) {
    case APP_ENVS.DEMO:
      return DEMO_CONFIG as GetHotjarConfigurationDisabledReturnType;
    case APP_ENVS.DEV:
      return DEV_CONFIG as GetHotjarConfigurationDisabledReturnType;
    case APP_ENVS.LOCAL:
      return LOCAL_CONFIG as GetHotjarConfigurationDisabledReturnType;
    case APP_ENVS.PROD:
      return PROD_CONFIG as GetHotjarConfigurationDisabledReturnType;
    case APP_ENVS.PREPROD:
      return PREPROD_CONFIG as GetHotjarConfigurationDisabledReturnType;
    default:
      throw new Error(`${currentAppEnvironment} is not supported as APP_ENVS.`);
  }
})();

export default HOTJAR_CONFIG;
