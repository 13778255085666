import { transparentize } from 'polished';
import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import {
  getCustomComponentProperty,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

interface HeaderContainerProps {
  componentThemeName: SUPPORTED_COMPONENTS;
}

export const $HeaderContainer = styled.div<HeaderContainerProps>`
  width: 100%;
  height: 72px;
  background-color: ${({ theme, componentThemeName }) => {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-bottom: ${({ theme }) =>
    `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`};
`;

export const MenuContainer$ = styled.div`
  align-items: center;
  height: ${rem(72)};
  display: flex;
  justify-content: space-between;
`;

export const $ButtonCol = styled(Col)`
  @media (min-width: 670px) {
    display: flex !important;
    align-items: center !important;
  }
  @media (max-width: 670px) {
    padding: 0;
  }
`;

export const $RightSide = styled.div`
  display: flex;
`;
