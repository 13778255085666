import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

export default {
  [SUPPORTED_PROPERTIES.COLOR]: {
    fallback: `components.defaultButtons.${SUPPORTED_PROPERTIES.COLOR}`,
  },
  [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
    fallback: `components.defaultButtons.${SUPPORTED_PROPERTIES.BACKGROUND_COLOR}`,
  },
  [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
    fallback: `components.defaultButtons.${SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR}`,
  },
  [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
    fallback: `components.defaultButtons.${SUPPORTED_PROPERTIES.DISABLED_COLOR}`,
  },
  [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
    fallback: `components.defaultButtons.${SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR}`,
  },
};
