import { defineMessages } from 'react-intl';

export default defineMessages({
  orderSearchError: {
    id: 'view.card.client.orderSearchError',
    defaultMessage: 'An error has occurred',
  },
  orderReferenceLabel: {
    id: 'view.card.client.orderReferenceLabel',
    defaultMessage: 'Order Reference Label',
  },
  orderReferenceHelperLabel: {
    id: 'view.card.client.orderReferenceHelperLabel',
    defaultMessage: 'help',
  },
  orderReferenceHelperTitle: {
    id: 'view.MyAccountLoginPage.onlyWithOrderReference.helper.title',
    defaultMessage: 'Où trouver votre numéro de facture ?',
  },
});
