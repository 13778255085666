import { defineMessages } from 'react-intl';

export default defineMessages({
  receiptNumber: {
    id: 'view.MyAccountLoginPage.saleReceipt.receiptNumber',
    defaultMessage: 'Receipt number',
  },
  startButton: {
    id: 'view.MyAccountLoginPage.startButton',
    defaultMessage: 'Start',
  },
  goToProductReferenceLogin: {
    id: 'view.MyAccountLoginPage.goToProductReferenceLogin',
    defaultMessage: 'Go to product reference login ? ',
  },
  clickHere: {
    id: 'view.MyAccountLoginPage.clickHere',
    defaultMessage: 'Click here',
  },
  receiptSearchPlaceholder: {
    id: 'view.MyAccountLoginPage.receiptSearchPlaceholder',
    defaultMessage: '0114023345682778927305913872',
  },
  receiptSearchTooltip: {
    id: 'view.MyAccountLoginPage.receiptSearchTooltip',
    defaultMessage: 'Ticket number is under the barcode on your receipt',
  },
  'Reverse.Common.Errors.Data.NotFound': {
    id: 'view.MyAccountLoginPage.saleReceipt.notFound',
    defaultMessage: 'Sale receipt not found',
  },
});
