export enum LANGUAGES {
  FR = 'fr',
  EN = 'en',
  DE = 'de',
  ES = 'es',
  IT = 'it',
  PT = 'pt',
  NL = 'nl',
  BE = 'be',
  CH = 'ch'
}

export const availableNamedLanguages: LANGUAGES[] = [
  LANGUAGES.FR,
  LANGUAGES.EN,
  LANGUAGES.DE,
  LANGUAGES.ES,
  LANGUAGES.IT,
  LANGUAGES.PT,
  LANGUAGES.NL,
];
