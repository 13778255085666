import React from 'react';

import { Notification } from '@savgroup-front-common/types';

import { NotificationId } from './BannersProvider.types';

interface BannersProviderContextValue {
  pushNotifications: (notifications: Notification[]) => NotificationId[];
  removeNotification: (notificationId: NotificationId) => void;
  removeAllNotifications: () => void;
  notifications: Notification[];
}

const BannersProviderContext = React.createContext<BannersProviderContextValue>(
  {
    pushNotifications: () => [],
    removeNotification: () => undefined,
    removeAllNotifications: () => undefined,
    notifications: [],
  },
);

export default BannersProviderContext;
