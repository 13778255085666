import { NavArrowLeft } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const ArrowLeftIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <NavArrowLeft width={size} height={size} color={color} />;

ArrowLeftIcon.displayName = 'ArrowLeftIcon';

export default ArrowLeftIcon;
