import { uniqBy } from 'lodash';

import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';
import { Notification } from '@savgroup-front-common/types';

import {
  BANNERS_PROVIDER_ACTION_TYPES,
  BANNERS_PROVIDER_ACTIONS,
} from './BannersProvider.types';

interface BannersProviderState {
  notifications: Notification[];
}

export const bannersProviderInit = (): BannersProviderState => {
  return {
    notifications: [],
  };
};
export const bannersProviderReducer = (
  state: BannersProviderState,
  action: BANNERS_PROVIDER_ACTIONS,
): BannersProviderState => {
  switch (action.type) {
    case BANNERS_PROVIDER_ACTION_TYPES.ADD_BANNERS:
      return {
        ...state,
        notifications: uniqBy(
          [...action.payload.notifications, ...state.notifications],
          'id',
        ),
      };
    case BANNERS_PROVIDER_ACTION_TYPES.CLOSE_BANNER:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload.notificationId,
        ),
      };
    case BANNERS_PROVIDER_ACTION_TYPES.CLOSE_ALL_BANNERS:
      return {
        ...state,
        notifications: [],
      };

    default:
      throw new ActionTypeIsNotSupportedError();
  }
};
