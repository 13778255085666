import md5 from 'md5';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { DOCUMENT_TYPES, EXTENSIONS } from '@savgroup-front-common/constants';

import { AuthConfiguration } from '../configuration';

const useUpdateFavicon = ({ sellerId }: { sellerId?: string }): void => {
  const [listAlternatives, setListAlternatives] = useState<string[]>([]);

  useDeepCompareEffect(() => {
    const favicon: HTMLLinkElement | null = document.querySelector(
      'link[rel="shortcut icon"]',
    );

    const [firstAlternative, ...rest] = listAlternatives;
    const hashedClientId = md5(AuthConfiguration.clientId);
    const defaultFavicon = `${APIConfiguration.uiStylesCDN}favicons/${hashedClientId}`;

    if (sellerId) {
      const faviconFromPublicDocuments = `${
        APIConfiguration.imageCDN
      }sellers/${sellerId}/${DOCUMENT_TYPES.FAVICON.toLocaleLowerCase()}${
        EXTENSIONS.ICO
      }`;

      setListAlternatives(
        [faviconFromPublicDocuments, defaultFavicon].filter((item) => item),
      );

      const onError = () => {
        if (favicon) {
          favicon.onerror = rest.length > 0 ? onError : null;
          favicon.href = firstAlternative;

          setListAlternatives(rest);
        }
      };

      if (favicon) {
        favicon.href = firstAlternative;
        favicon.onerror = onError;
      }
    }
  }, [listAlternatives, sellerId]);
};

export default useUpdateFavicon;
