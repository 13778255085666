import React, {
  ChangeEvent,
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';

import { INPUT_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import CheckboxNaked from '../../atoms/checkbox/CheckboxNaked';
import { getFinalFieldState } from '../../atoms/Form/common/helpers/getFinalFieldState';
import {
  FIELD_STATUS,
  FieldMessages,
} from '../../atoms/Form/common/helpers/getFinalFieldState.types';
import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '../../formatters';

import {
  $ImageContainer,
  $PostTitle,
  $RadioCardBody,
  $RadioCardContainer,
  $RadioCardInfo,
  $RadioCardLabel,
  $RadioCardLabelContainer,
  $RadioCardTitle,
  $TextContainer,
} from './RadioCardColumn.styles';

export interface RadioCardColumnProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  title: ReactElement | string | number | MessageType;
  image: ReactNode;
  name: string;
  label?: string | number | MessageType;
  didactics?: string | number | MessageType;
  dataTestId?: string;
  value?: string;
  children?: ReactNode;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  postTitle?: ReactNode;
}

interface RadioCardWithRefProps extends RadioCardColumnProps {
  forwardedRef?: Ref<HTMLInputElement>;
}

const RadioCardColumn: FunctionComponent<
  React.PropsWithChildren<RadioCardWithRefProps>
> = ({
  isChecked = undefined,
  onChange = undefined,
  onClick = undefined,
  forwardedRef = null,
  title,
  label,
  didactics = undefined,
  children = null,
  isError = false,
  isWarning = false,
  isSuccess = false,
  errors = {},
  warnings = {},
  successes = {},
  image,
  name,
  value,
  dataTestId,
  postTitle,
}) => {
  const [status] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$RadioCardLabelContainer $status={status}>
      <CheckboxNaked
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
        ref={forwardedRef}
        name={name}
        danger={status === FIELD_STATUS.ERROR}
        value={value}
        type={INPUT_TYPES.RADIO}
        dataTestId={dataTestId}
        isHidden
      />
      <$RadioCardContainer $status={status}>
        <$RadioCardInfo>
          <$ImageContainer $status={status}>{image}</$ImageContainer>
          <$TextContainer
            title={label ? safeFormattedIntlString(label) : undefined}
          >
            {title && (
              <$RadioCardTitle>{SafeFormattedMessage(title)}</$RadioCardTitle>
            )}
            {label && (
              <$RadioCardLabel>{SafeFormattedMessage(label)}</$RadioCardLabel>
            )}
            {didactics && (
              <$RadioCardLabel>
                {SafeFormattedMessage(didactics)}
              </$RadioCardLabel>
            )}
            {postTitle && <$PostTitle>{postTitle}</$PostTitle>}
          </$TextContainer>
        </$RadioCardInfo>
        {children && <$RadioCardBody>{children}</$RadioCardBody>}
      </$RadioCardContainer>
    </$RadioCardLabelContainer>
  );
};

RadioCardColumn.displayName = 'RadioCardColumn';

export default React.forwardRef<HTMLInputElement, RadioCardColumnProps>(
  (props, ref) => <RadioCardColumn forwardedRef={ref} {...props} />,
);
