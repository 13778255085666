const geocodeByPlaceId = ({
  placeId,
}: {
  placeId: string;
}): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        resolve(results || []);

        return;
      }
      if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
        resolve([]);

        return;
      }
      reject(new Error(status));
    });
  });
};

export default geocodeByPlaceId;
