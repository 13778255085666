import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $StepHeaderContainer = styled.div`
  margin-bottom: 1rem;
  h2 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    @media ${media.maxWidth.sm} {
      font-size: 20px;
    }
  }
`;
