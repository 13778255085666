import { datadogRum } from '@datadog/browser-rum';

import {
  APP_ENVS,
  currentAppEnvironment,
  getDatadogEnv,
} from '@savgroup-front-common/constants';

export const useInitializeDatadog = ({ ratio } = { ratio: 5 }) => {
  datadogRum.init({
    applicationId: 'e1b7b2b6-0b4a-4cb7-b5d3-fda3dcf1badd',
    clientToken: import.meta.env.VITE_DATA_DOG_PUBLIC_KEY,
    site: 'datadoghq.eu',
    service: 'revers-front-authenticator',
    env: getDatadogEnv(currentAppEnvironment),
    version: `${import.meta.env.VITE_BUILD_NUMBER}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate:
      currentAppEnvironment === APP_ENVS.PROD ? ratio : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://dev-api.revers.io',
      'https://demo-api.revers.io',
      'https://preprod-api.revers.io',
      'https://api.revers.io',
    ],
  });

  datadogRum.startSessionReplayRecording();
};
