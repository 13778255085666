import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  CIVILITY,
  COUNTRY_CODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import { GotAnErrorFromEndpoint } from '@savgroup-front-common/exceptions';
import { BaseBackResponse } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.enterpriseAuchan}creation/bySaleReceiptId/orders`;

interface AddressInfo {
  addressId?: string;
  civility?: CIVILITY;
  firstname: string;
  companyName?: string;
  lastname: string;
  address: string;
  additionalAddress?: string;
  additionalInformation?: string;
  doorCode?: string;
  floor?: string;
  postalCode: string;
  city: string;
  countryCode: COUNTRY_CODES;
  phone?: string;
  isDefault?: boolean;
}

export interface CreateOrderBySaleReceiptIdPayload {
  saleReceiptId: string;
  sellerId: string;
  owner: {
    civility?: CIVILITY;
    customerLastName: string;
    customerFirstName: string;
    address: AddressInfo;
    phoneNumber?: string;
    customerMail: string;
  };
}

interface CreateOrderBySaleReceiptIdResponse extends BaseBackResponse {
  value: string;
}

async function createOrderBySaleReceiptId({
  saleReceiptId,
  sellerId,
  owner,
}: CreateOrderBySaleReceiptIdPayload): Promise<string> {
  const functionName = 'createOrderBySaleReceiptId';

  const response = await apiCall<CreateOrderBySaleReceiptIdResponse>(ENDPOINT, {
    method: SUPPORTED_METHODS.POST,
    json: {
      saleReceiptId,
      sellerId,
      owner,
    },
  });

  if (response.failure) {
    throw new GotAnErrorFromEndpoint(functionName, response.errors);
  }

  return response.value;
}

export default createOrderBySaleReceiptId;
