import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import {
  formatAmountToNumber,
  formatAmountToString,
} from '@savgroup-front-common/core/src/formatters';
import useStepOrchestratorNextStep from '@savgroup-front-common/core/src/molecules/StepsOrchestrator/hooks/useStepOrchestratorNextStep';
import { CUSTOMER_TYPE } from '@savgroup-front-common/types';
import { CreateClaimContext, CreateClaimValues } from 'authenticator/types';
import useGetClientInformation from 'authenticator/view/atoms/ClientInformationProvider/hooks/useGetClientInformation';

import choicePartnerSchema from './ChoicePartnerProduct.schema';
import { ChoicePartnerProductValues } from './ChoicePartnerProduct.types';

interface UseChoicePartnerArgs {
  values: CreateClaimValues;
}

const useChoicePartner = ({ values }: UseChoicePartnerArgs) => {
  const { isLoading: isGetClientInformationLoading } =
    useGetClientInformation();
  const nextStep = useStepOrchestratorNextStep<
    CreateClaimValues,
    CreateClaimContext
  >();
  const customerType = values.activatedSellerPartnersIdSelected?.customerType;
  const isBusiness = customerType === CUSTOMER_TYPE.BUSINESS;

  const businessDefaultPartnerProductPrice = formatAmountToString(1);
  const defaultChoicePartnerProductPrice = values.productReference?.productPrice
    ? formatAmountToString(values.productReference?.productPrice)
    : undefined;

  const formContext = useForm<ChoicePartnerProductValues>({
    shouldUnregister: false,
    resolver: yupResolver(choicePartnerSchema({ isBusiness })),
    mode: REVALIDATE_MODES.ON_CHANGE,
    defaultValues: {
      choicePartnerProduct: values.productReference?.model || undefined,
      choicePartnerProductPrice: isBusiness
        ? businessDefaultPartnerProductPrice
        : defaultChoicePartnerProductPrice,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formContext;

  const onSubmit = handleSubmit(
    async ({ choicePartnerProduct, choicePartnerProductPrice }) => {
      return nextStep({
        ...values,
        productReference: {
          seller: values.activatedSellerPartnersIdSelected
            ? {
                id: values.activatedSellerPartnersIdSelected?.sellerId,
                name: values.activatedSellerPartnersIdSelected?.sellerName,
                customerType:
                  values.activatedSellerPartnersIdSelected?.customerType ||
                  CUSTOMER_TYPE.NOT_SET,
              }
            : undefined,
          model: choicePartnerProduct.data,
          productPrice: choicePartnerProductPrice
            ? formatAmountToNumber(choicePartnerProductPrice)
            : undefined,
        },
      });
    },
  );

  const isLoading = isGetClientInformationLoading;

  return {
    formContext,
    onSubmit,
    errors,
    isLoading,
    partnerSelected: values.activatedSellerPartnersIdSelected
      ? {
          id: values.activatedSellerPartnersIdSelected.sellerId,
          name: values.activatedSellerPartnersIdSelected.sellerName,
        }
      : undefined,
  };
};

export default useChoicePartner;
