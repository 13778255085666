import { XmarkCircle } from 'iconoir-react';
import React, { FunctionComponent } from 'react';

import { IconPropsInterface } from '../Icon.types';

const CrossCircleIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <XmarkCircle width={size} height={size} color={color} />;

CrossCircleIcon.displayName = 'CrossCircleIcon';
export default CrossCircleIcon;
