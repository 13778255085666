import React, { FunctionComponent } from 'react';

import ArrowLeftIcon from '../Arrow/ArrowLeft.icon';
import { IconPropsInterface } from '../Icon.types';

const ChevronLeftIcon: FunctionComponent<IconPropsInterface> = ({
  color = 'black',
  size = '24px',
}) => <ArrowLeftIcon size={size} color={color} />;

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

export default ChevronLeftIcon;
