import { Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import { getBorderRadius } from './MultiFileUploadHookForm.helpers';
import { decelerateTimingFunctionTransition } from '../../../animations/timingFunction';

interface IconContainerProps {
  $isDragActive: boolean;
}

interface DropzoneProps {
  $isDragActive: boolean;
}

export const $FileInput = styled.input`
  display: none;
`;

const iconContainerHoverMixin = css<DropzoneProps>`
  > svg {
    opacity: 1;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
`;

export const $IconContainer = styled.div<IconContainerProps>`
  position: relative;
  > svg {
    width: 4rem;
    height: 4rem;
    position: absolute;
    opacity: 0;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(0.8, 0.8, 0.8);
    pointer-events: none;
    transition-duration: 0.3s;

    ${decelerateTimingFunctionTransition};
  }
  ${({ $isDragActive }) => ($isDragActive ? iconContainerHoverMixin : null)};
  &:hover {
    ${iconContainerHoverMixin}
  }
`;

const dropZoneHoverMixin = css<DropzoneProps>`
  border-color: ${({ theme }) => theme.colors.primary};
  p {
    filter: blur(2px);
  }
`;

export const $Dropzone = styled.button<DropzoneProps>`
  border: 1px solid ${({ theme }) => theme.colors.default};

  height: 10rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: ${getBorderRadius};

  transition-duration: 0.3s;
  ${decelerateTimingFunctionTransition};
  p {
    font-size: ${({ theme }) => theme.fonts.size.small};
    transition-duration: 0.3s;
    ${decelerateTimingFunctionTransition};
  }

  ${({ $isDragActive }) => ($isDragActive ? dropZoneHoverMixin : null)};
  &:hover {
    ${dropZoneHoverMixin};
  }
`;

export const $Col = styled(Col)<{ $dropzone?: boolean }>`
  && {
    flex: ${({ $dropzone }) => ($dropzone ? null : 0)};
    margin-bottom: 1rem;
  }
`;
