import styled from 'styled-components';

import { simpleFadeInAnimation } from '../../animations/simpleAnimation';
import { SimpleAnimationProps } from '../../animations/simpleAnimation/types';
import { slideRightToLeft } from '../../animations/stateAnimation';
import { StateAnimationProps } from '../../animations/stateAnimation/types';
import { rem } from '../../helpers';

import { BASE_LOADER_DIRECTION } from './BaseLoader.types';

interface $LoaderWrapperProps extends SimpleAnimationProps {
  $hasMargin: boolean;
  $hasHeightAuto: boolean;
  $size: string;
  $hasMessage: boolean;
}

export const $Container = styled.div<$LoaderWrapperProps>`
  position: relative;
  ${simpleFadeInAnimation};
  padding-top: ${({ $hasMargin }) => ($hasMargin ? rem(128) : 0)};
  padding-bottom: ${({ $hasMargin }) => ($hasMargin ? rem(128) : 0)};
  flex: 1;
  height: ${({ $hasHeightAuto }) => ($hasHeightAuto ? null : '100%')};
  min-height: ${({ $size, $hasMessage, $hasHeightAuto }) => {
    if ($hasHeightAuto) {
      return null;
    }

    return $hasMessage ? '7rem' : rem($size);
  }};
`;

interface $LoaderTextProps extends StateAnimationProps {
  $align: 'start' | 'center' | 'end';
  $direction: BASE_LOADER_DIRECTION;
  $hasMargin: boolean;
  $hasHeightAuto: boolean;
  $isLargeFontSize: boolean;
}

export const $LoaderText = styled.div<$LoaderTextProps>`
  position: absolute;
  width: 100%;
  top: ${({ $hasMargin }) => {
    return $hasMargin ? '50%' : 0;
  }};
  transform: ${({ $hasHeightAuto }) =>
    $hasHeightAuto ? 'translateY(-50%)' : null};

  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: ${({ $align }) => {
    switch ($align) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';

      default:
        return $align;
    }
  }};
  > div {
    margin: 0 ${rem(5)};
  }
  span {
    font-size: ${({ theme, $isLargeFontSize }) =>
      $isLargeFontSize ? theme.fonts.size.large : theme.fonts.size.small};
    color: ${({ theme }) => theme.colors.paragraphTextColor};
    white-space: nowrap;
  }
  ${slideRightToLeft};
`;

export const $LoaderContainer = styled.div<{ $size: string }>`
  display: flex;
  width: ${({ $size }) => $size};
`;
