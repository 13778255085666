import GridTheme from '../gridTheme';

// Getting breakpoints from GridTheme
const sizes = GridTheme.flexboxgrid.breakpoints;

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `@media (max-width: ${sizes[label]}em)`;

  return acc;
}, {});

export default media;
