import React, { FunctionComponent, ReactElement, Ref } from 'react';

import { MessageType } from '@savgroup-front-common/types';

import { Modal, MODAL_SIZES } from '../../atoms/modal';

import ConfirmModalContent from './ConfirmModalContent';
import messages from './messages';

interface ConfirmModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  shouldCloseOnDimmerClick?: boolean;
  showCrossButton?: boolean;
  isDisabled?: boolean;
  isDanger?: boolean;
  title?: ReactElement | MessageType;
  didactic?: ReactElement | MessageType;
  cancelMessage?: MessageType;
  confirmMessage?: MessageType;
  onCancel: () => void;
  onConfirm: () => void;
  dataTestId?: string;
  size?: MODAL_SIZES;
}

const ConfirmModal: FunctionComponent<
  React.PropsWithChildren<
    ConfirmModalProps & { forwardedRef: Ref<HTMLDivElement> }
  >
> = ({
  isOpen = false,
  title = messages.title,
  didactic = messages.body,
  cancelMessage = messages.buttonCancel,
  confirmMessage = messages.buttonDelete,
  shouldCloseOnDimmerClick = false,
  showCrossButton = false,
  isLoading = false,
  onCancel,
  onConfirm,
  isDanger = false,
  isDisabled = false,
  children,
  dataTestId = 'confirmModal',
  size = MODAL_SIZES.TINY,
  forwardedRef,
}) => {
  return (
    <Modal
      dataTestId={dataTestId}
      isOpen={isOpen}
      onClose={onCancel}
      size={size}
      shouldCloseOnDimmerClick={shouldCloseOnDimmerClick}
      showCrossButton={showCrossButton}
      forwardedRef={forwardedRef}
    >
      <ConfirmModalContent
        title={title}
        didactic={didactic}
        cancelMessage={cancelMessage}
        confirmMessage={confirmMessage}
        isLoading={isLoading}
        onCancel={onCancel}
        onConfirm={onConfirm}
        isDanger={isDanger}
        isDisabled={isDisabled}
      >
        {children}
      </ConfirmModalContent>
    </Modal>
  );
};

ConfirmModal.displayName = 'ConfirmModal';

export default React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ConfirmModalProps>
>((props, ref) => {
  return <ConfirmModal {...props} forwardedRef={ref} />;
});
