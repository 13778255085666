import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { getAuthToken } from '../helpers/authUtils';
import { getCurrentLanguage } from '../helpers/intl';
import { BaseBackResponse } from '@savgroup-front-common/types';

import APIClient from './APIClient';
import { UserManagerConfig } from './cahedUserManagerData';

export interface Config {
  json?: any;
  body?: any;
  method: SUPPORTED_METHODS;
  responseType?: string;
  timeout?: number;
  signal?: AbortSignal;
}

const apiCall = async <T = BaseBackResponse>(
  url: string,
  config: Config,
): Promise<T> => {
  const { cache: userManagerConfigCache } = UserManagerConfig;
  const userManagerConfig = userManagerConfigCache?.userManagerConfig;
  const getToken = () =>
    userManagerConfig
      ? getAuthToken(window.localStorage, userManagerConfig())
      : null;

  if (typeof url !== 'string') {
    throw new Error(`Url should be string got: ${typeof url}`);
  }

  return APIClient({
    url,
    config,
    currentLanguage: getCurrentLanguage(),
    authToken: getToken(),
  });
};

export default apiCall;
