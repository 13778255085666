import React, { FunctionComponent, useContext } from 'react';

import { MultiBanners } from '../../../atoms/Banners';

import BannersProviderContext from './BannersProvider.context';

interface BannersConsumer {
  isFluid?: boolean;
}

const BannersConsumer: FunctionComponent<BannersConsumer> = ({ isFluid }) => {
  const { notifications, removeNotification } = useContext(
    BannersProviderContext,
  );

  return (
    <MultiBanners
      isFluid={isFluid}
      notifications={notifications.map((notification) => ({
        ...notification,
        onClose: () => removeNotification(notification.id),
      }))}
    />
  );
};

BannersConsumer.displayName = 'BannersConsumer';

export default BannersConsumer;
