import { MinimalSeller } from 'authenticator/types';

export enum MODEL_SEARCH_FIELDS {
  MODEL = 'model',
  SELLER = 'seller',
}

export interface SearchModel {
  brandLabel: string;
  modelType: string;
  label: string;
  sku: string;
  id?: string;
}

export interface SubmitModelSearchFormValues {
  [MODEL_SEARCH_FIELDS.MODEL]: SearchModel;
  [MODEL_SEARCH_FIELDS.SELLER]: MinimalSeller;
}

export interface ModelSearchValues {
  [MODEL_SEARCH_FIELDS.MODEL]?: SearchModel;
  [MODEL_SEARCH_FIELDS.SELLER]?: MinimalSeller;
}
