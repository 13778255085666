import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { rem, SUPPORTED_ICON_POSITIONS } from '../../helpers';

export default {
  colors: {
    primary: '#0970E6',
    primaryHover: '#0E3368',
    secondary: '#E81E26',
    success: '#199A57',
    danger: '#E81E26',
    alert: '#FF8000',
    default: '#C9CED6',
    disabled: '#E1E4E8',
    mainTextColor: '#343434',
    headingTextColor: '#0A1F44',
    paragraphTextColor: '#8A94A6',
    iconColor: '#000000',
  },
  colorsExtension: {
    brand1: '#095BA5',
    brand2: '#E81E26',
    brand3: '#575757',
    brand4: '#F0F3F6',
    blueDark: '#0E3368',
    blueMedium: '#0970E6',
    blueLight: '#C4EFFF',
    grey3: '#D2D2D2',
    grey4: '#929292',
  },
  interactions: {
    basicTiming: '.2s',
    transformTiming: '.25s',
    activeScale: '.96',
    buttonHoverAsHollow: false,
  },
  shadows: {
    elevation__1: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '1px',
    )} ${rem('1px')} 0 rgba(10,31,68,0.08);`,
    elevation__2: `box-shadow: 0 0 ${rem('1px')} 0 rgba(10,31,68,0.08), 0 ${rem(
      '3px',
    )} ${rem('4px')} 0 rgba(10,31,68,0.1);`,
  },
  borders: {
    radius: '4px',
    thickness: '1px',
    thicknessSpecialInput: '2px',
  },
  components: {
    [SUPPORTED_COMPONENTS.DEFAULT_BUTTONS]: {
      [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
        fallback: 'colorsExtension.grey3',
      },
      [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
        fallback: 'colorsExtension.grey3',
      },
      [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
        value: 'uppercase',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem('10px')} ${rem('10px')}`,
      },
      [SUPPORTED_PROPERTIES.FONT_WEIGHT]: {
        value: 700,
      },
    },
    [SUPPORTED_COMPONENTS.VIEW_FILE_DETAILS_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#FFFFFF',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid #D2D2D2',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_COLOR]: {
        value: '#FFFFFF',
      },
    },
    [SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#FFFFFF',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid #D2D2D2',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_COLOR]: {
        value: '#FFFFFF',
      },
    },
    [SUPPORTED_COMPONENTS.DECLARE_CLAIM_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colorsExtension.primary',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.primaryHover',
      },
      [SUPPORTED_PROPERTIES.ICON_POSITION]: {
        value: SUPPORTED_ICON_POSITIONS.RIGHT,
      },
    },
    [SUPPORTED_COMPONENTS.IRSH_ICONS]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
    },
    [SUPPORTED_COMPONENTS.CANCEL_CLAIM_BUTTON]: {
      [SUPPORTED_PROPERTIES.ICON_POSITION]: {
        value: SUPPORTED_ICON_POSITIONS.RIGHT,
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        value: '#929292',
      },
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid #D2D2D2',
      },
    },
    [SUPPORTED_COMPONENTS.LOGOUT_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.primaryHover',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem('10px')} ${rem('24px')}`,
      },
    },
    [SUPPORTED_COMPONENTS.SMALL_LOGOUT_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.primary',
      },
    },
    [SUPPORTED_COMPONENTS.BACK_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
        value: 'unset',
      },
    },
    [SUPPORTED_COMPONENTS.FILE_DOWNLOADER_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colorsExtension.primary',
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.primaryHover',
      },
      [SUPPORTED_PROPERTIES.ICON_POSITION]: {
        value: SUPPORTED_ICON_POSITIONS.RIGHT,
      },
      [SUPPORTED_PROPERTIES.JUSTIFY_CONTENT]: {
        value: 'center',
      },
      [SUPPORTED_PROPERTIES.PADDING]: {
        value: `${rem('10px')} ${rem('10px')}`,
      },
      [SUPPORTED_PROPERTIES.WIDTH]: {
        value: 'auto',
      },
    },
    [SUPPORTED_COMPONENTS.BACK_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
      [SUPPORTED_PROPERTIES.TEXT_TRANSFORM]: {
        value: 'unset',
      },
    },
    [SUPPORTED_COMPONENTS.UPLOAD_FILES_BUTTON]: {
      [SUPPORTED_PROPERTIES.ICON_POSITION]: {
        value: SUPPORTED_ICON_POSITIONS.RIGHT,
      },
    },
    [SUPPORTED_COMPONENTS.ISSUE_CARD_BUTTON]: {
      [SUPPORTED_PROPERTIES.WIDTH]: {
        value: 'auto',
      },
    },
  },
};
