import { SalesChannel } from '@savgroup-front-common/types';
import { MinimalSeller } from 'authenticator/types';

import { CustomerInformationValues } from '../common/steps/CustomerInformation/CustomerInformation.types';

import { SearchModel } from './steps/ModelSearch/ModelSearch.types';

export enum PRODUCT_REFERENCE_LOGIN_STEP_NAMES {
  SEARCH_MODEL = 'searchModel',
  COMPLEMENTARY_INFORMATION = 'complementaryInformation',
  CUSTOMER_INFORMATION = 'customerInformation',
}

export interface ProductReferenceLoginValues extends CustomerInformationValues {
  model: SearchModel;
  seller: MinimalSeller;
  salesChannel: SalesChannel;
  purchaseDate: Date;
  productPrice: number;
}

export interface ProductReferenceLoginContext {
  changeActiveComponent: () => void;
}
