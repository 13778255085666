import apiCommonErrorMessages from './messages/apiCommonErrorMessages';
import clientCommonErrorMessages from './messages/clientCommonErrorMessages';
import documentMessages from './messages/documentMessages';
import fileStateCategoryMessages from './messages/fileStateCategoryMessages';
import form from './messages/formMessages';
import partnerScenarioTypeMessages from './messages/partnerScenarioTypeMessages';
import priceMessages from './messages/priceMessages';
import quotationStateMessages from './messages/quotationStateMessages';
import shortcutLabelMessages from './messages/shortcutLabelMessages';
import sparePartRequestLinesStatusMessages from './messages/sparePartRequestLinesStatusMessages';
import sparePartRequestReliabilityMessages from './messages/sparePartRequestReliabilityMessages';
import stepsOrchestratorMessages from './messages/stepsOrchestratorMessages';

export * from './getQuotationLineTypeMessage';
export * from './getRecipientTypeMessage';

const globalMessages = {
  shortcutLabelMessages,
  form,
  fileStateCategoryMessages,
  documentMessages,
  stepsOrchestratorMessages,
  sparePartRequestLinesStatusMessages,
  priceMessages,
  apiCommonErrorMessages,
  clientCommonErrorMessages,
  partnerScenarioTypeMessages,
  quotationStateMessages,
  sparePartRequestReliabilityMessages,
};

export default globalMessages;
