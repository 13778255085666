import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants';
import { toast } from '@savgroup-front-common/core/src/atoms/Toast';
import { SellerService } from 'authenticator/api';

import messages from '../messages';
import { SET_UP_PROGRESS } from '../OnboardingSetUp.helpers';

const CONTROL_BASE_BY_ENV: Record<APP_ENVS, string> = {
  DEFAULT: 'dev-app.revers.io',
  DEMO: 'demo-app.revers.io',
  PREPROD: 'preprod-app.revers.io',
  PROD: 'app.revers.io',
  DEV: 'dev-app.revers.io',
  LOCAL: 'localhost:3002',
};

const useGetSalesLeadOnboardingStatusQuery = ({
  isLoading,
  isFailed,
  handleMessagesList,
}: {
  isLoading: boolean;
  isFailed: boolean;
  handleMessagesList: (setUpProgress: SET_UP_PROGRESS) => void;
}) => {
  const { requestId, requestToken } = useParams<{
    requestId: string;
    requestToken: string;
  }>();
  const queryClient = useQueryClient();

  useQuery(
    ['getSalesLeadOnboardingStatus', { requestId, isLoading, isFailed }],
    async () => {
      if (isLoading || isFailed) {
        return undefined;
      }
      const response = await SellerService.getSalesLeadOnboardingStatusQuery({
        id: requestId,
      });

      if (response.failure) {
        toast.error(messages.salesLeadConfirmEmailCommandError);

        return undefined;
      }
      if (response.value.demoContextFullyProvisionned) {
        const controlBase = CONTROL_BASE_BY_ENV[currentAppEnvironment];

        window.location.href = `https://${controlBase}?invitation_id=${response.value.invitationId}&invitation_token=${requestToken}`;
      }
      if (response.value.demoContextDemoOrdersGenerated) {
        handleMessagesList(SET_UP_PROGRESS.ORDERS_GENERATED);
      } else if (response.value.demoContextEntitiesAndRulesDuplicated) {
        handleMessagesList(SET_UP_PROGRESS.ENTITIES_AND_RULES);
      } else if (response.value.demoContextInfrastructureBuilt) {
        handleMessagesList(SET_UP_PROGRESS.INFRASTRUCTURE_BUILDING);
      }

      setTimeout(async () => {
        await queryClient.invalidateQueries(['getSalesLeadOnboardingStatus']);
      }, 5000);

      return undefined;
    },
    {},
  );
};

export default useGetSalesLeadOnboardingStatusQuery;
