import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  BackResponse,
  BackResponseFailure,
  BaseBackResponse,
  TechnicalIntervention,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/${COMMON_QUERY_PARAMS.FILE_ID}/actions`;

export interface SetUserActionOldCommandPayload {
  fileId: string;
  module: {
    id: string;
    definitionId: string;
    referencedModuleType: string;
    wave: number;
    parameters: {
      additionalProp1: string;
      additionalProp2: string;
      additionalProp3: string;
    };
  };
  fromState: string;
  toState: string;
  comment: string;
  toCustomerComment: string;
  sessionId: string;
}

export async function setUserActionOldCommand({
  fileId,
  module,
  fromState,
  toCustomerComment,
  comment,
  toState,
  sessionId,
}: SetUserActionOldCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT, {
      fileId,
    });

    const response: BackResponse<TechnicalIntervention> = await apiCall(url, {
      method: SUPPORTED_METHODS.PUT,
      json: {
        module,
        fromState,
        toCustomerComment,
        comment,
        toState,
        sessionId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
