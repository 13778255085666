import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions/src/client/errors/RequiredPayloadFieldIsNotProvided';
import {
  BackResponse,
  BackResponseFailure,
  FileId,
  TechnicalIntervention,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.workflow}files/technicalIntervention`;

interface GetTechnicalInterventionQueryPayload {
  fileId: FileId;
}

async function getTechnicalInterventionQuery({
  fileId,
}: GetTechnicalInterventionQueryPayload): Promise<
  BackResponse<TechnicalIntervention> | BackResponseFailure
> {
  const functionName = 'getTechnicalInterventionQuery';

  if (!fileId) {
    throw new RequiredPayloadFieldIsNotProvided('fileId', functionName);
  }

  try {
    const url = buildUrl(ENDPOINT, {
      fileId,
    });

    const response: BackResponse<TechnicalIntervention> = await apiCall(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getTechnicalInterventionQuery;
