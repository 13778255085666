import React, { FunctionComponent } from 'react';
import { sub } from 'date-fns';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  CURRENCIES,
  INPUT_TYPES,
} from '@savgroup-front-common/constants';
import {
  Datepicker,
  FloatInput,
  Autocomplete,
  autocompleteMessages,
  DescriptionOptionFormatter,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  formatCurrencyCode,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { formatStringToIntlPrice } from '@savgroup-front-common/core/src/formatters/intl';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useStepOrchestratorContext } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { ChevronLeftIcon } from '@savgroup-front-common/core/src/protons/icons';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import PostLabelTooltip from '../../../../atoms/PostLabelTooltip';
import { ProductReferenceLoginContext } from '../../ProductReferenceLogin.types';

import {
  COMPLEMENTARY_INFORMATION_FIELDS,
  ComplementaryInformationValues,
} from './ComplementaryInformation.types';
import useComplementaryInformation from './hooks/useComplementaryInformation';
import useGetSalesChannelOptions from './hooks/useGetSalesChannelOptions';
import messages from './messages';

const ComplementaryInformation: FunctionComponent = () => {
  const { previousStep, values } = useStepOrchestratorContext<
    ComplementaryInformationValues,
    ProductReferenceLoginContext
  >();
  const theme = useTheme();

  const { salesChannelOptions, isLoading } = useGetSalesChannelOptions({
    sellerId: values?.seller?.id,
  });

  const { formContext, handleComplementaryInformationSubmit } =
    useComplementaryInformation();
  const { handleSubmit, formState, control } = formContext;

  const { isSubmitting, isValidating, isValid, errors } = formState;

  return (
    <>
      <Heading level={2} color={theme.colors.black}>
        <SafeFormattedMessageWithoutSpread
          message={messages.complementaryInformationTitle}
        />
      </Heading>

      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread message={messages.subtitle} />
      </Heading>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(handleComplementaryInformationSubmit)}>
          <BannersConsumer />

          <Card>
            <FormGroup>
              <Controller
                control={control}
                name={COMPLEMENTARY_INFORMATION_FIELDS.SALES_CHANNEL}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      ref={field.ref}
                      name={field.name}
                      errors={errors}
                      label={messages.salesChannel}
                      isLoading={isLoading}
                      options={salesChannelOptions}
                      formatOptionLabel={DescriptionOptionFormatter}
                      noOptionsMessage={autocompleteMessages.noOptionsMessage}
                      onChange={field.onChange}
                      value={field.value}
                      postLabel={
                        <PostLabelTooltip>
                          <SafeFormattedMessageWithoutSpread
                            message={messages.salesChannelTooltip}
                          />
                        </PostLabelTooltip>
                      }
                      isRequired
                    />
                  );
                }}
              />
            </FormGroup>

            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Controller
                    control={control}
                    name={COMPLEMENTARY_INFORMATION_FIELDS.PRODUCT_PRICE}
                    render={({ field }) => {
                      return (
                        <FloatInput
                          ref={field.ref}
                          selectAllOnFocus
                          type={INPUT_TYPES.TEXT}
                          label={messages.productPrice}
                          name={field.name}
                          errors={errors}
                          isRequired
                          suffix={formatCurrencyCode(CURRENCIES.EUR)}
                          disableBrowserAutocomplete
                          dataTestId="unit-price-product"
                          value={field.value}
                          onBlur={(event) =>
                            field.onChange(
                              formatStringToIntlPrice({
                                amount: event.target.value,
                                currency: CURRENCIES.EUR,
                              }),
                            )
                          }
                          onChange={field.onChange}
                          postLabel={
                            <PostLabelTooltip>
                              <SafeFormattedMessageWithoutSpread
                                message={messages.productPriceTooltip}
                              />
                            </PostLabelTooltip>
                          }
                        />
                      );
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <Controller
                    control={control}
                    name={COMPLEMENTARY_INFORMATION_FIELDS.PURCHASE_DATE}
                    render={({ field }) => {
                      return (
                        <Datepicker
                          ref={field.ref}
                          errors={errors}
                          label={messages.purchaseDate}
                          name={field.name}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                          }}
                          value={field.value}
                          postLabel={
                            <PostLabelTooltip>
                              <SafeFormattedMessageWithoutSpread
                                message={messages.purchaseDateTooltip}
                              />
                            </PostLabelTooltip>
                          }
                          minDate={sub(new Date(), { years: 100 })}
                          maxDate={new Date()}
                          isRequired
                        />
                      );
                    }}
                    defaultValue={undefined}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Card>

          <ActionRow>
            <FormGroup>
              <Button
                onClick={() => previousStep()}
                naked
                icon={<ChevronLeftIcon />}
                componentThemeName={SUPPORTED_COMPONENTS.BACK_BUTTON}
                type={BUTTON_TYPES.BUTTON}
              >
                <SafeFormattedMessageWithoutSpread message={messages.back} />
              </Button>
            </FormGroup>
            <FormGroup>
              <Button
                type={BUTTON_TYPES.SUBMIT}
                isLoading={isSubmitting && !isValidating && isValid}
                primary
              >
                <SafeFormattedMessageWithoutSpread message={messages.next} />
              </Button>
            </FormGroup>
          </ActionRow>
        </form>
      </FormProvider>
    </>
  );
};

ComplementaryInformation.displayName = 'ComplementaryInformation';

export default ComplementaryInformation;
