import { useCallback } from 'react';

import { useBanners } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { NextStep } from '@savgroup-front-common/core/src/molecules/StepsOrchestrator';
import { isBackResponseFailure } from '@savgroup-front-common/types';
import { EnterpriseAuchan } from 'authenticator/api';
import { MinimalSeller } from 'authenticator/types';

import { TicketNumberLoginContext } from '../../../TicketNumberLogin.types';
import messages from '../messages';
import { SubmitReceiptSearchFormValues } from '../ReceiptSearch.types';

const AUCHAN = 'Auchan';

interface UseCheckExistingReceiptArgs {
  sellers: MinimalSeller[];
  saleReceiptId: string;
  nextStep: NextStep<SubmitReceiptSearchFormValues, TicketNumberLoginContext>;
  onCheckReceiptExist: ({ isLoading }: { isLoading: boolean }) => void;
}

const useCheckExistingReceipt = ({
  sellers,
  saleReceiptId,
  nextStep,
  onCheckReceiptExist,
}: UseCheckExistingReceiptArgs): (() => void) => {
  const { pushErrors, removeAllNotifications } = useBanners({
    extendedMessages: messages,
  });

  return useCallback(async () => {
    const auchanSeller = sellers.find((seller) => seller.name === AUCHAN);

    try {
      onCheckReceiptExist({ isLoading: true });

      removeAllNotifications();

      const sellerId = auchanSeller?.id;
      const redirectUrl = await EnterpriseAuchan.getSaleReceiptById({
        sellerId,
        saleReceiptId,
      });

      onCheckReceiptExist({ isLoading: false });

      if (redirectUrl && redirectUrl.length > 0) {
        window.location.href = redirectUrl;
      } else {
        nextStep({
          sellerId,
          saleReceiptId,
        });
      }
    } catch (error) {
      onCheckReceiptExist({ isLoading: false });

      if (isBackResponseFailure(error)) {
        pushErrors(error?.errors || [error]);
      }
    }
  }, [
    nextStep,
    onCheckReceiptExist,
    pushErrors,
    removeAllNotifications,
    saleReceiptId,
    sellers,
  ]);
};

export default useCheckExistingReceipt;
